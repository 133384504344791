import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


class ModalRemarksView extends React.Component {
    
    render() {
        return (
            <Modal toggle={this.props.cancelCallback} isOpen={this.props.isOpen} className={"modal-content " + this.props.modalClassName}>
                <ModalHeader className={this.props.modalHeaderClassName} >{this.props.contentHeader}</ModalHeader>
                <ModalBody>{this.props.contentBody}</ModalBody>
                <ModalFooter>
                    {this.props.cancelCallback && <Button color="backward" size="sm" onClick={this.props.cancelCallback}>{this.props.cancelText}</Button>}
                    {this.props.confirmCallback && <Button color="forward" size="sm" onClick={this.props.confirmCallback}>{this.props.confirmText}</Button>}
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalRemarksView;