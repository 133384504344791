import React from 'react';
import { compose } from 'recompose';
import { ENQUIRY_PATH, GET_FORM_URL, INBOX_PATH, IS_DEMO } from '../../App/AppSettings';
import { fetchRequest, getFileRequest, getParams, navigateTo } from '../Actions';
import { withPageNotFound } from '../Error';
import history from '../History';
import { withLoader } from '../Loading';
import { withModalWrapper } from '../Modal/ModalWrapper';

export const withDynaFormWrapper = (FormComponent) => {

    const RETRIEVE_URL = GET_FORM_URL;

    class DynaFormWrapper extends React.Component {

        retrieveNewFormData = async () => {
            const { state } = history.location;

            if (IS_DEMO) {
                if (history.location.state.from === ENQUIRY_PATH || history.location.state.from === INBOX_PATH) {
                    // Retrieve values from dummy data
                    const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
                    this.retrieveFromServer(response);
                }
            }
            else {
                if (state && state.ID) {
                    // Retrieve response from backend
                    const response = await fetchRequest(RETRIEVE_URL + state.ID, getParams(), IS_DEMO);
                    this.retrieveFromServer(response);
                }
            }
        };

        refreshPage = async ({ response }) => {
            // Retrieve response from backend
            const dataResponse = await fetchRequest((IS_DEMO) ? RETRIEVE_URL : RETRIEVE_URL + response.body.FormID, getParams(), IS_DEMO);
            this.retrieveFromServer(dataResponse);
        };

        getFile = async (url, fileName) => {
            const { loader } = this.props;

            loader.start();

            const response = await getFileRequest(url, IS_DEMO, fileName);

            loader.done();

            return response;
        };

        back = () => {
            navigateTo(history.location.state.from);
        };

        render() {
            return (
                <FormComponent

                    getFile={this.getFile}
                    {...this.props}
                />
            );
        }
    }

    return compose(withModalWrapper, withPageNotFound, withLoader)(DynaFormWrapper);
};
