import React from 'react';
import { ListGroup } from 'reactstrap';

class SMARTListGroup extends React.Component {
    render() {
        return (
            <React.Fragment>
                <ListGroup className="SMARTListGroup">
                    {this.props.children}
                </ListGroup>
            </React.Fragment>
        )
    }
}

export default SMARTListGroup;