import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose } from 'recompose';
import { APPLICATION_DETAILS_PATH, DASHBOARD_PATH, GET_LICENCE_DOCUMENT_FILE_URL, GET_LICENCE_APPLICATION_HISTORY_URL, GET_LICENCE_DETAILS_URL, GET_LICENCE_RENEWAL_DETAILS_URL, LAYOUT_TYPE, PRINT_LICENCE_URL } from '../../App/AppSettings';
import { getPremiseOperatingAddress } from '../../Content/FormComponents/FormUtils';
import Layout from '../../Layout';
import { fetchRequest, getFileRequest, getParams, navigateTo } from '../../Shared/Actions';
import { MIN_FILTER_CHARS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withPageNotFound } from '../../Shared/Error';
import { AccordionContainer, Plaintext, ScrollToTop, SMARTView, toastError } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const FORM_CONTEXT = 'LicenceDetails';

class LicenceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licence: null,
            isRenewable: null,
        };
        if (history.location.state) {
            this.state = {
                licence: history.location.state.licence
            };
        }
    }

    componentDidMount() {
        const { loader, pageNotFound } = this.props;
        const licence = this.state.licence;

        if (licence === null || licence === undefined) {
            pageNotFound();
        } else {
            loader.start();
            this.getLicenceData(loader);
        }
    }

    getLicenceData = async (loader) => {
        const { t } = this.props;
        const response = await fetchRequest(GET_LICENCE_DETAILS_URL + this.state.licence.LicenceID, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {
            this.setState((prevState) => {
                return {
                    licence: Object.assign(prevState.licence, Data, { LicenceData: JSON.parse(Data.LicenceData) }),
                    licenceDetailsLoaded: true
                };
            }, () => {
                this.getLicenceRenewalDetails(loader);
            });
        } else {
            this.setState({
                licenceDetailsLoaded: true,
            });
            toastError(t(ERROR.LICENCE_DATA_NOT_FETCHED));
            loader.done();
        }
    }

    getLicenceRenewalDetails = async (loader) => {
        const { t } = this.props;
        const response = await fetchRequest(GET_LICENCE_RENEWAL_DETAILS_URL + this.state.licence.ApplicationRefNumber, getParams(), false);
        const { IsSuccess, Data } = response.body;
        if (IsSuccess) {
            this.setState((prevState) => {
                return {
                    licenceRenew: Data,
                    isRenewable: Data.IsUpForRenewal,
                    licenceRenewLoaded: true,
                };
            });
            this.stopLoader(loader);
        } else {
            this.setState({
                licenceRenewLoaded: true,
            });
            toastError(t(ERROR.LICENCE_DATA_NOT_FETCHED));
            this.stopLoader(loader);
        }
    }

    // Loader done
    stopLoader = async (loader) => {
        const { licenceDetailsLoaded, licenceRenewLoaded } = this.state;

        if (licenceDetailsLoaded && licenceRenewLoaded) {
            loader.done();
        }
    }

    setContent = (rowData) => {
        const applicationData = {
            ApplicationRefNumber: rowData.ApplicationRefNumber,
            SFormId: rowData.SFormId
        };
        return <div className='dashboard-application-link'
            onClick={() => navigateTo(APPLICATION_DETAILS_PATH, { application: applicationData })}>
            {rowData.ApplicationRefNumber}
        </div>;
    }

    setAppTypeTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    getTranslatedString = (rowData) => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName));
    }

    getCustomDocument = async (id, fileName, licenceId, documentName) => {
        const { loader, } = this.props;
        loader.start();
        let url = licenceId ? PRINT_LICENCE_URL.concat(licenceId) : GET_LICENCE_DOCUMENT_FILE_URL + "id=" + id + "&fileName=" + fileName;

        const response = await getFileRequest(url, false, documentName);

        loader.done();

        return response;

    }

    renderLicenceDocumentLink = rowData => {
        return <div className="application-link div-table-link" onClick={() => this.getCustomDocument(rowData.Id, rowData.Filename, rowData.LicenceID, rowData.DocumentName)}>{rowData.DocumentName}</div>;
    }

    render() {
        const { t } = this.props;
        const SECTION_NAMES = {
            SERVICE_OVERVIEW: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_SECTION_SERVICEOVERVIEW), status: true },
            SERVICE_DOCUMENT: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_SECTION_SERVICEDOCUMENT), status: true },
            SERVICE_APPLICATION_HISTORY: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_SECTION_SERVICEAPPLICATIONHISTORY), status: true }
        };

        const IsRenewable = this.state.isRenewable;
        const Licence = this.state.licence;

        if (Licence !== null && Licence !== undefined) {
            const { Agency, LicenceID, LicenceData, LicenceDocuments = [], LicenceNumber, LicenceName,
                LicenceStatus, IssuedDate, ExpiryDate, LicenceTypeID, DocumentsFailedToGenerate } = Licence;
            const { GeneralInformation, LicenceInformation } = LicenceData ? LicenceData : '';
            const Entity = GeneralInformation ? GeneralInformation.GI_EntitydetailsEntityname : null;
            const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(LicenceName);

            return (
                <>
                    <ScrollToTop />
                    <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                        <div className='main-content'>
                            <nav aria-label='breadcrumb' className='breadcrumb-main'>
                                <div className='container'>
                                    <ol className='breadcrumb'>
                                        <li className='breadcrumb-item'><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</Link></li>
                                        <li className='breadcrumb-item'><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_NAVIGATION_MENU_VIEWSERVICEDETAILS)}</Link></li>
                                        <li className='breadcrumb-item active' aria-current='page'>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, LicenceTypeID))}</li>
                                    </ol>
                                    <div className='back-btn'>
                                        <Button
                                            color='header'
                                            className='btn-header'
                                            onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                    </div>
                                </div>
                            </nav>

                            <div className='container'>
                                {IsRenewable &&
                                    <>
                                        <div className='detailed-box db_lice_renew_details'>
                                            <div className='db-details-head'>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_DUEFORRENEWAL2)}</div>
                                            <div className='details-box'>

                                                <h5>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_LICENCEDUERENEWALTEXT1)}<br />{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_LICENCEDUERENEWALTEXT2)}</h5>
                                                <div className='details-footer'>
                                                    <p className='date-fee'>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_INFORMATION_LICENCEEXPIRYDATE)}<strong>{ExpiryDate}</strong></p>
                                                    <p className='date-fee'>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_INFORMATION_LICENCERENEWALFEE)}<strong>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL)}{this.state.licenceRenew.RenewalFee}</strong></p>
                                                </div>
                                            </div>
                                            <div className='details-buttons'>
                                                <Button
                                                    color='renew'
                                                    className='btn btn-bordered-small  btn-apply'
                                                    onClick={() => navigateTo('/renewLicence', { Licence: Licence })}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_BUTTON_RENEWLICENCE)}</Button>
                                            </div>
                                        </div>
                                    </>
                                }

                                <SMARTView
                                    formContext={FORM_CONTEXT}
                                    isDummy={false}
                                >
                                    {({ toggleSection, toggleAllSections }) => (
                                        <>
                                            <div className={`${IsRenewable ? 'form-collapse detailed-box-form' : 'form-collapse detailed-box-form margin'}`}>
                                                <div className='collapse-expand'>
                                                    <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                                                    <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                                                </div>
                                                <AccordionContainer
                                                    title={SECTION_NAMES.SERVICE_OVERVIEW.title}
                                                    active={SECTION_NAMES.SERVICE_OVERVIEW.status}
                                                    onClick={toggleSection}
                                                    isIndividual={true}
                                                    sections={SECTION_NAMES}
                                                >
                                                    {LicenceData && GeneralInformation &&
                                                        <>
                                                            <div className='card-body'>
                                                                <div className='form-body form-preview'>
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENAME)}
                                                                        name='LicenceName'
                                                                        value={t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, LicenceTypeID))}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENUMBER)}
                                                                        name='LicenceNumber'
                                                                        value={LicenceNumber}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_APPLICANT)}
                                                                        name='Licensee'
                                                                        value={GeneralInformation.GI_Name}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICESTATUS)}
                                                                        name='LicenceStatus'
                                                                        value={LicenceStatus}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_AGENCY)}
                                                                        name='Agency'
                                                                        value={Agency}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    {Entity &&
                                                                        <Plaintext
                                                                            label='Entity'
                                                                            name='Entity'
                                                                            value={Entity}
                                                                            labelSize={LABEL_SIZE}
                                                                            inputSize={INPUT_SIZE}
                                                                        />
                                                                    }
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_ISSUEDDATE)}
                                                                        name='IssuedDate'
                                                                        value={IssuedDate}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_EXPIRYDATE)}
                                                                        name='ExpiryDate'
                                                                        value={ExpiryDate}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                    <Plaintext
                                                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_PREMISEOPERATINGADDR)}
                                                                        name='PremiseOperatingAddress'
                                                                        value={getPremiseOperatingAddress(LicenceInformation)}
                                                                        labelSize={LABEL_SIZE}
                                                                        inputSize={INPUT_SIZE}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </AccordionContainer>
                                                {LicenceDocuments &&
                                                    <AccordionContainer
                                                        title={SECTION_NAMES.SERVICE_DOCUMENT.title}
                                                        active={SECTION_NAMES.SERVICE_DOCUMENT.status}
                                                        onClick={toggleSection}
                                                        isIndividual={true}
                                                        sections={SECTION_NAMES}
                                                    >
                                                        {DocumentsFailedToGenerate && DocumentsFailedToGenerate.length > 0 &&
                                                            <p className="ml-3 text-danger text-left">
                                                                {t(ERROR.UNABLETOGENARATEDOCUMENT) + " " + DocumentsFailedToGenerate.join(', ')}
                                                                <br />
                                                                {t(ERROR.CONTACTAGENCYFORSUPPORT)}
                                                            </p>
                                                        }

                                                        <DataTable
                                                            pageSize="5"
                                                            minFilterChars={MIN_FILTER_CHARS}
                                                            data={LicenceDocuments}
                                                            noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCEDOCUMENTSFOUND)}
                                                            components={getCustomComponents()}
                                                            columns={
                                                                {
                                                                    DocumentName: {
                                                                        title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DOCUMENTNAME), width: '20%', DBkey: 'DocumentName',
                                                                        setContent: this.renderLicenceDocumentLink
                                                                    },
                                                                    ApplicationType: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONTYPE), width: '20%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getTranslatedString },
                                                                    Status: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_STATUS), width: '20%', DBkey: 'Status' },
                                                                    CreatedDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_ISSUEDDATE), width: '15%', DBkey: 'CreatedDate',  isDate: true }
                                                                }
                                                            }
                                                        />
                                                    </AccordionContainer>
                                                }

                                                {Licence.LicenceData &&
                                                    <AccordionContainer
                                                        title={SECTION_NAMES.SERVICE_APPLICATION_HISTORY.title}
                                                        active={SECTION_NAMES.SERVICE_APPLICATION_HISTORY.status}
                                                        onClick={toggleSection}
                                                        isIndividual={true}
                                                        sections={SECTION_NAMES}
                                                    >
                                                        <DataTable
                                                            pageSize='5'
                                                            minFilterChars={MIN_FILTER_CHARS}
                                                            url={GET_LICENCE_APPLICATION_HISTORY_URL + LicenceNumber}
                                                            noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOSERVICEAPPHISTORYFOUND)}
                                                            components={getCustomComponents()}
                                                            columns={
                                                                {
                                                                    ApplicationRefNumber: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER), width: '20%', DBkey: 'ApplicationRefNumber', setContent: this.setContent },
                                                                    ApplicationType: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONTYPE), width: '20%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getTranslatedString },
                                                                    SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONDATE), width: '20%', DBkey: 'SubmissionDate', isDate: true },
                                                                    Status: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_STATUS), width: '15%', DBkey: 'Status' }
                                                                }
                                                            }
                                                            initialSort={
                                                                { id: 'SubmissionDate', sortAscending: false }
                                                            }
                                                        />

                                                    </AccordionContainer>
                                                }

                                                <div className='button-center'>
                                                    <Button className='btn' onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                                    {Licence.IsCancellable &&
                                                        <Button color='hazard' className='btn' onClick={() => navigateTo('/services/devform/start', { applicationTypeBanner: 'CANCEL', LicenceId: LicenceID, applicationType: 'CANCEL' })}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CANCELSERVICEAPPLICATION)}</Button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </SMARTView>
                            </div>
                        </div>
                    </Layout>
                </>
            );
        }
        return null;
    }
}
export default withTranslation()(withRouter(compose(withLoader, withPageNotFound)(LicenceDetails)));