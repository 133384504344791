import React from 'react';
import LicenceSummary from '../../Content/FormComponents/LicenceSummary';
import GeneralInformationLicenceSummary from '../../Content/FormComponents/LicenceSummary/GeneralInformationLicenceSummary';
import GeneralInformationCorporateLicenceSummary from '../../Content/FormComponents/LicenceSummary/GeneralInformationCorporateLicenceSummary'
import DeclarationSection from '../FormComponents/DeclarationComponent/DeclarationSection';
import AuthHelper from '../../Shared/Authentication/AuthHelper';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

let isCorporateProfile;

// Auth
AuthHelper.getUser(user => {
    if (user !== undefined && user !== null) {
        isCorporateProfile = (user.profile.uen !== undefined);
    }
});
// Auth

class RFAReviewAndSubmit extends React.Component {
    render() {
        const { values, toggleSection, toggleAllSections, onChangeField, errors } = this.props.smartFormValues;
        const { applicationDetails, declarations, displayDocuments, t } = this.props;

        const generalDefinition = applicationDetails.GeneralDefinition;
        const formFileList = [];
        applicationDetails.DocumentList.forEach(doc => {
            values['SupportingDocument'][doc.Name].forEach((v, vindex) => {
                formFileList.push(values['SupportingDocument'][doc.Name][vindex].fileName);
            });
        });


        return (
            <>
                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_REVIEWANDSUBMIT)}</div>

                <div className="review-submit">
                    {isCorporateProfile ?
                        <GeneralInformationCorporateLicenceSummary
                            formName={t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}
                            values={values.GeneralInformation}
                            formSections={generalDefinition.Sections}
                            key={generalDefinition.FormName}
                            commonFieldList={[]}
                            documentList={[]}
                            toggleSection={toggleSection}>
                        </GeneralInformationCorporateLicenceSummary>
                        :
                        <GeneralInformationLicenceSummary
                            formName={t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}
                            values={values.GeneralInformation}
                            formSections={generalDefinition.Sections}
                            key={generalDefinition.FormName}
                            commonFieldList={[]}
                            documentList={[]}
                            toggleSection={toggleSection}>
                        </GeneralInformationLicenceSummary>
                    }

                    <LicenceSummary
                        formName={applicationDetails.FormDefinition.FormName}
                        commonFieldList={[]}
                        valuesFormInfo={values[applicationDetails.FormDefinition.FormType]}
                        formSections={applicationDetails.FormDefinition.Sections}
                        documentList={formFileList}
                        key={applicationDetails.FormDefinition.FormName}
                        toggleAllSections={toggleAllSections}
                        toggleSection={toggleSection}
                        displayDocuments={displayDocuments}
                    >
                    </LicenceSummary>
                    {declarations &&
                        <DeclarationSection
                            declarations={declarations}
                            values={values.DeclarationAgreement}
                            errors={errors.DeclarationAgreement}
                            key={values.DeclarationAgreement}
                            onChangeField={onChangeField}
                            labelSize={0}
                            labelRequired />
                    }
                </div>

            </>

        );
    }
}

export default withTranslation()(RFAReviewAndSubmit);
