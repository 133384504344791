import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { withBaseControl } from './BaseControl';
import { DIRECTIONS } from '../Constants';


class Text extends React.Component {

    render() {
        let TextInput =
            <Input
                dir={DIRECTIONS.AUTO}
                type="text"
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                autoComplete="off"
                readOnly={this.props.readOnly}
            />;

        return (
            <React.Fragment>
                {(this.props.append) ?

                    // InputGroupAddon Append
                    <InputGroup>
                        {TextInput}
                        {(!this.props.unitOfMeasurement) ?
                            (null) :
                            (<InputGroupAddon addonType="append">
                                {this.props.unitOfMeasurement}
                            </InputGroupAddon>)
                        }
                    </InputGroup>
                    :

                    // InputGroupAddon Prepend
                    <InputGroup>
                        {(!this.props.unitOfMeasurement) ?
                            (null) :
                            (<InputGroupAddon addonType="prepend">
                                {this.props.unitOfMeasurement}
                            </InputGroupAddon>)
                        }
                        {TextInput}
                    </InputGroup>

                }
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Text.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    unitOfMeasurement: PropTypes.string,
    appendFront: PropTypes.string
};


export default withBaseControl(Text);