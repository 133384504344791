import React from 'react';
import { withTranslation } from 'react-i18next';
import { LICENCE_CART_PATH } from '../../App/AppSettings';
import { navigateTo } from '../../Shared/Actions';
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DirectionContextConsumer } from '../../Shared/DirectionContext';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

class ExclusiveLicenceItemResult extends React.Component {

    displayDescription = (desc, isShow) => {
        if (isShow) {
            return (
                <div dangerouslySetInnerHTML={{ __html: desc }} />
            );
        }
        else {
            return null;
        }
    };

    displayCartButton = (licence, isShow, updateCart, currentCart) => {
        const {t} = this.props;
        if (isShow) {
            //licence already in the cart
            if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length > 0) {
                return (
                    <button
                        className='btn btn-exclusive-add-to-cart added'
                        key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE) + licence.Id}
                        onClick={async () => {
                            await updateCart(licence, CART_ACTION_REMOVE);
                            // Funtion to enable Exclusive 'Continue' button
                            this.props.customUpdateCartCallback();
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE)}
                    </button>
                );
            } else {
                return (
                    <button
                        className='btn btn-exclusive-add-to-cart'
                        key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART) + licence.Id}
                        onClick={async () => {
                            await updateCart(licence, CART_ACTION_ADD);
                            // Funtion to disable Exclusive 'Continue' button
                            this.props.customUpdateCartCallback();
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART)}
                    </button>
                );
            }
        }
        else {
            return null;
        }
    };

    displayApplyButton = (licence, displayText, isShow, updateCart, currentCart) => {
        const {t} = this.props;
        if (isShow) {
            return (
                <button className='btn btn-apply' key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY) + licence.Id} onClick={() => this.pressApplyButton(licence, updateCart, currentCart)}>{displayText}</button>
            );
        }
        else {
            return null;
        }
    };

    pressApplyButton = (licence, updateCart, currentCart) => {
        if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length === 0) {
            updateCart(licence, CART_ACTION_ADD);
        }
        navigateTo(LICENCE_CART_PATH);
    };

    displayFeeTime = (licence, isShow) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        if (isShow) {
            return (
                <>
                    <p className='fee'>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_FEE)}&nbsp;:&nbsp;$
                        <strong>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_FEEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId))}</strong>
                    </p>
                    <p className='time'>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_PROCESSINGTIME)}&nbsp;:&nbsp;
                        <strong>{licence.TimeDesc}</strong> {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_WORKINGDAYS)}
                    </p>
                </>
            );
        } else {
            return null;
        }
    };

    displayDetailsButton = (id, onClick, isShow) => {
        const { t } = this.props;
        if (isShow) {
            return (
                <button className='btn btn-details' onClick={onClick} >{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_DETAILS)}        </button>
            );
        }
        else {
            return null;
        }
    };

    externalLink = (e, url) => {
        e.preventDefault();
        const httpSubstring = 'http';
        let newUrl = url;
        if (url.indexOf(httpSubstring) !== 0) {
            newUrl = `http://${url}`;
        }
        window.open(newUrl, '_blank');
    };

    render() {
        const { t, licence, isShowAddToCart, isShowApply } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        return (
            <LicenceCartContext.Consumer>
                {({ updateCart, currentCart }) => (
                    <>
                        <div className='exclusive-result-block'>
                            <div className='result-details'>
                                <div className={this.props.context.direction === 'ltr' ? 'float-left' : 'float-right'}>
                                    <h5>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                                </div>
                                <div className={this.props.context.direction === 'ltr' ? 'float-right' : 'float-left'}>
                                    {licence.IsExternalLicence ?
                                        (
                                            <>
                                                <div className='search-licence-link link-margin-left' onClick={(e) => this.externalLink(e, licence.Url)}>
                                                    {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_AGENCYWEBSITE)}</div>
                                            </>
                                        ) :
                                        ([
                                            this.displayCartButton(licence, isShowAddToCart, updateCart, currentCart),
                                            this.displayApplyButton(licence, t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY), isShowApply, updateCart, currentCart)
                                        ])}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </LicenceCartContext.Consumer>
        );
    }
}

ExclusiveLicenceItemResult.defaultProps = {
    customUpdateCartCallback: () => { /*default empty function */ }
};

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ direction }) => (
                        <WrappedComponent
                            context={{ direction }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};

export default withTranslation()(withDirectionContext(ExclusiveLicenceItemResult));
