import React from 'react';
import { Plaintext } from '../../Shared/Forms';
import { getPremiseOperatingAddress } from '../../Content/FormComponents/FormUtils';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

class LicenceOverviewContents extends React.Component {
    render() {
        const { values, licence, formType } = this.props;
        const licenceInfo = values[formType];
        const Entity = values.GeneralInformation.GI_EntitydetailsEntityname;

        return (
            <>
                <div className="form-body form-preview">
                    <Plaintext
                        label="Licence Name"
                        name="LicenceName"
                        className="overview-margin"
                        value={licence.LicenceName}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    <Plaintext
                        label="Licence Number"
                        name="LicenceNumber"
                        className="overview-margin"
                        value={licence.LicenceNumber}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    <Plaintext
                        label="Licensee"
                        name="Licensee"
                        className="overview-margin"
                        value={values.GeneralInformation.GI_Name}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    <Plaintext
                        label="Licence Status"
                        name="LicenceStatus"
                        className="overview-margin"
                        value={licence.LicenceStatus}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    <Plaintext
                        label="Agency"
                        name="Agency"
                        className="overview-margin"
                        value={licence.Agency}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    {
                        Entity &&
                        <Plaintext
                            label="Entity"
                            name="Entity"
                            className="overview-margin"
                            value={Entity}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                    }
                    <Plaintext
                        label="Issued Date"
                        name="IssuedDate"
                        className="overview-margin"
                        value={licence.IssuedDate}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    <Plaintext
                        label="Expiry Date"
                        name="ExpiryDate"
                        className="overview-margin"
                        value={licence.ExpiryDate}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                    <Plaintext
                        label="Premise Operating Address"
                        name="PremiseOperatingAddress"
                        className="overview-margin"
                        value={getPremiseOperatingAddress(licenceInfo)}
                        labelSize={LABEL_SIZE}
                        inputSize={INPUT_SIZE}
                    />
                </div>
            </>

        );
    }
}
export default LicenceOverviewContents;
