import * as Yup from 'yup';
import { ADDRESS, DEFAULT_OVERSEAS_COUNTRY_ID_TYPE, GENERAL_INFORMATION_FORMTYPE, REGEX, TABLE_LAYOUT } from '../../../App/AppSettings';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';

const createValidationSchemaForGeneralInfoIndividualInfo = (t) => {
    return {
        GI_Email: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).email(t(ERROR.EMAIL_INVALID)),
        GI_Name: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_DateOfBirth: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_Gender: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_Nationality: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_Salutation: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_PrimaryContact: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_IDtype: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_IDnumber: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_Passportissuingcountry: Yup.string()
            .when('GI_IDtype', {
                is: DEFAULT_OVERSEAS_COUNTRY_ID_TYPE,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_MobileNumber: Yup.string().when('GI_PrimaryContact', {
            is: 'Mobile',
            then: Yup.string().matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID)).required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            otherwise: Yup.string().matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID))
        }),
        GI_OfficeNumber: Yup.string().when('GI_PrimaryContact', {
            is: 'Office',
            then: Yup.string().matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID)).required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            otherwise: Yup.string().matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID))
        }),
        GI_HomeNumber: Yup.string().when('GI_PrimaryContact', {
            is: 'Home',
            then: Yup.string().matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID)).required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            otherwise: Yup.string().matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID))
        }),
        GI_FaxNumber: Yup.string()
            .matches(REGEX.TELEPHONE, (ERROR.LABEL) + t(ERROR.INVALID)),
        GI_Designation: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_AltEmailAddress: Yup.string().email(t(ERROR.EMAIL_INVALID)),
        GI_AddressAddressType: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_AddressTypeOfPremises: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_AddressPostalCode: Yup.string()
            .matches(/^[0-9 ]*$/, (ERROR.LABEL) + t(ERROR.INVALID))
            .required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_AddressAddressLine1BlockNo: Yup.string()
            .when('GI_AddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.LOCAL,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_AddressAddressLine1StreetName: Yup.string()
            .when('GI_AddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.LOCAL,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_AddressCountry: Yup.string()
            .when('GI_AddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_AddressState: Yup.string()
            .when('GI_AddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_AddressAddressLine1AddressLine1: Yup.string()
            .when('GI_AddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_AddressAddressLine2AddressLine2: Yup.string()
            .when('GI_AddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_MailingAddressAddressType: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_MailingAddressTypeOfPremises: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_MailingAddressPostalCode: Yup.string()
            .matches(/^[0-9 ]*$/, (ERROR.LABEL) + t(ERROR.INVALID))
            .required((ERROR.LABEL) + t(ERROR.REQUIRED)),

        GI_MailingAddressAddressLine1BlockNo: Yup.string()
            .when('GI_MailingAddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.LOCAL,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_MailingAddressAddressLine1StreetName: Yup.string()
            .when('GI_MailingAddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.LOCAL,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_MailingAddressCountry: Yup.string().when('GI_MailingAddressAddressType', {
            is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
            then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
        }),
        GI_MailingAddressState: Yup.string().when('GI_MailingAddressAddressType', {
            is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
            then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
        }),
        GI_MailingAddressAddressLine1AddressLine1: Yup.string()
            .when('GI_MailingAddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            }),
        GI_MailingAddressAddressLine2AddressLine2: Yup.string()
            .when('GI_MailingAddressAddressType', {
                is: ADDRESS.ADDRESS_TYPE.OVERSEAS,
                then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            })
    };
};

const createValidationSchemaForGeneralInfoEntityInfo = (t) => {
    return {
        GI_EntitydetailsUEN: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntitydetailsEntityname: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntitydetailsOrganisationType: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_EntityregisteredaddressTypeofpremise: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        GI_EntityregisteredaddressPostalcode: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityregisteredaddressBlockHousenumber: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityregisteredaddressStreetname: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityregisteredaddressLevel: Yup.string().min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityregisteredaddressUnitNumber: Yup.string().min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityregisteredaddressBuildingName: Yup.string().min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressTypeofpremise: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressPostalcode: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressBlockHousenumber: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressStreetname: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressLevel: Yup.string().min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressUnitNumber: Yup.string().min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntityoperatingaddressBuildingName: Yup.string().min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        GI_EntitycontactdetailsTelephone: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).matches(REGEX.TELEPHONE, t(ERROR.TELEPHONE_INVALID)),
        GI_EntitycontactdetailsFaxnumber: Yup.string().matches(REGEX.TELEPHONE, t(ERROR.TELEPHONE_INVALID)),
        GI_EntitycontactdetailsBusinessemail: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID))
    };
};

export const createValidationSchemaForGeneralInfo = (t, generalInfoType) => {
    const generalInfoIndivInfo = createValidationSchemaForGeneralInfoIndividualInfo(t);
    switch (generalInfoType) {
        case GENERAL_INFORMATION_FORMTYPE.INDIVIDUAL:
            return Yup.object().shape(generalInfoIndivInfo);
        case GENERAL_INFORMATION_FORMTYPE.CORPORATE:
            const entityInfo = createValidationSchemaForGeneralInfoEntityInfo(t);
            const generalCorpInfo = { ...generalInfoIndivInfo, ...entityInfo };
            return Yup.object().shape(generalCorpInfo);
        default:
            return Yup.object().shape(generalInfoIndivInfo);
    }
};

export const createValidationSchemaForLicenceFields = (t, formDefinition) => {
    const licTemp = {};
    formDefinition.Sections.forEach(section => {
        section.Fields && section.Fields.forEach(field => {
            // Start a single validation rule
            let validationRule = Yup.string();

            // Required
            if (field.Validation != null
                && field.Validation.Required) {
                validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED)).nullable();

                if (field.InputType === 'Email') {
                    validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                } else if (field.InputType === 'Number') {
                    validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                } else if (field.InputType === "Multiselect") {
                    validationRule = Yup.array().ensure().min(1, (ERROR.LABEL) + t(ERROR.REQUIRED)).of(Yup.string());
                } else {
                    if ('MinimumLength' in field.Validation) {
                        validationRule = validationRule.min(field.Validation.MinimumLength, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                    }
                    if ('MaximumLength' in field.Validation) {
                        validationRule = validationRule.max(field.Validation.MaximumLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                    }
                }
            }
            licTemp[field.Name] = validationRule;
        });

        if (section.Table) {
            const licTableTemp = {};

            section.Table.Fields && section.Table.Fields.forEach(field => {
                // Start a single validation rule
                let validationRule = Yup.string();

                // Required
                if (field.Validation != null
                    && field.Validation.Required) {
                    validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED));

                    if (field.InputType === 'Email') {
                        validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                    }
                    else if (field.InputType === 'Number') {
                        validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                    }
                    else {
                        if ('MinLength' in field.Validation) {
                            validationRule = validationRule.min(field.Validation.MinLength, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                        }
                        if ('MaxLength' in field.Validation) {
                            validationRule = validationRule.max(field.Validation.MaxLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                        }
                    }
                }
                licTableTemp[field.Name] = validationRule;
            });

            /****************** Start Of Table Row Validation ******************/
            if (('MinimumTableRows' in section.Table) && ('MaximumTableRows' in section.Table)) {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp)).required(`Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`)
                    .min(section.Table.MinimumTableRows, `Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`)
                    .max(section.Table.MaximumTableRows, `Maximum quota hit: Maximum ${section.Table.MaximumTableRows} record(s)`);
            }
            else if (('MaximumTableRows' in section.Table)) {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp))
                    .max(section.Table.MaximumTableRows, `Maximum quota hit: Maximum ${section.Table.MaximumTableRows} record(s)`);
            }
            else if (('MinimumTableRows' in section.Table)) {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp)).required(`Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`)
                    .min(section.Table.MinimumTableRows, `Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`);
            }
            else {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp));
            }
            /****************** End Of Table Row Validation ******************/
        }
    });
    return Yup.object().shape(licTemp);
};

export const createDraftValidationSchemaForLicenceFields = (t, formDefinition) => {
    const licTemp = {};
    formDefinition.Sections.forEach(section => {
        //for popup of big table only
        if (section.Table && section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) {
            const licTableTemp = {};

            section.Table.Fields && section.Table.Fields.forEach(field => {
                // Start a single validation rule
                let validationRule = Yup.string();

                // Required
                if (field.Validation != null
                    && field.Validation.Required) {
                    validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED));

                    if (field.InputType === 'Email') {
                        validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                    }
                    else if (field.InputType === 'Number') {
                        validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                    }
                    else {
                        if ('MinLength' in field.Validation) {
                            validationRule = validationRule.min(field.Validation.MinLength, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                        }
                        if ('MaxLength' in field.Validation) {
                            validationRule = validationRule.max(field.Validation.MaxLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                        }
                    }
                }
                licTableTemp[field.Name] = validationRule;
            });
            licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp));
        }
    });
    return Yup.object().shape(licTemp);
};

export const createValidationSchemaForSupportingDocuments = (t, documentList) => {
    const fileTemp = {};
    documentList.forEach(fileField => {
        // Start single validation rule
        let validationRule = Yup.array();
        // Required
        if (fileField.Validation != null
            && fileField.Validation.Required) {
            validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED))
                .min(1,(ERROR.LABEL) + t(ERROR.REQUIRED));
        }

        fileTemp[fileField.Name] = validationRule;
    });
    return Yup.object().shape(fileTemp);
};

export const createValidationSchemaForDeclaration = (t) => {
    return Yup.array()
        .of(
            Yup.string()
        )
        .required(t(ERROR.TICK_REQUIRED))
        .min(1,t(ERROR.TICK_REQUIRED));

}

export const createValidationForCommonInfo = (t, fieldsDefinition) => {
    const commonTemp = {};
    fieldsDefinition.forEach(section => {
        section.Fields.forEach(field => {
            // Start a single validation rule
            let validationRule = Yup.string();

            // Required
            if (field.Validation.Required !== null
                && field.Validation.Required !== undefined
                && field.Validation.Required === true) {
                validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED));

                if (field.InputType === 'Email') {
                    validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                }
                else if (field.InputType === 'Number') {
                    validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                }
                else {
                    if ('MinLength' in field.Validation) {
                        validationRule = validationRule.min(field.Validation.MinLength, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                    }
                    if ('MaxLength' in field.Validation) {
                        validationRule = validationRule.max(field.Validation.MaxLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                    }
                }
            }

            commonTemp[field.Name] = validationRule;
        });
    });
    return Yup.object().shape(commonTemp);
};