import React from 'react';
import { Route } from 'react-router-dom';
import { AuthConsumer } from './AuthContext';
import ErrorPage from '../Error/ErrorPage';
import AuthHelper from './AuthHelper';
import { SESSIONSTORAGE_IS_LOGIN } from '../../App/AppSettings';
import Login from '../../Content/Login';
import { withSMARTLayout } from '../../Layout/SMARTLayout';
const LoginWrapper = withSMARTLayout(Login);

const isLogin = sessionStorage.getItem(SESSIONSTORAGE_IS_LOGIN);

const ProtectedRoute = ({
  component: Component,
  roles: pageRoles,
  props,
  ...rest
}) => (
  <AuthConsumer>
    {({ isAuthenticated, roles }) => (
      <>
        <Route
          render={(props) => {
            if (isAuthenticated) {
              if (
                typeof pageRoles === 'undefined' ||
                pageRoles.length === 0 ||
                AuthHelper.intersect(pageRoles, roles).length > 0
              ) {
                return <Component {...props} />;
              } else {
                return (
                  <ErrorPage
                    errorHeader='404'
                    errorContent='This page is unavailable. Please contact your site administrator.'
                  />
                );
              }
            } else {
              //if it isn't logged in, try to log in once
              if (isLogin !== 'true') {
                return (
                  <React.Fragment>
                    <Route
                      render={(props) => (
                        <LoginWrapper
                          redirectURL={props.location.pathname}
                          {...props}
                        />
                      )}
                    />
                  </React.Fragment>
                );
              }
              return null;
            }
          }}
          {...rest}
        />
      </>
    )}
  </AuthConsumer>
);

export default ProtectedRoute;
