import React from "react";
import { Button } from "reactstrap";
import { PAYMENT_OPTIONS } from "../../../App/AppSettings";
import { withTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from "../../../Shared/Constants/LanguageKeys";
import {
  getLicenceTypeNameTranslationKey,
  getLangKey,
} from "../../../Content/DisplayComponents/DisplayUtils";

class PaymentSummary extends React.Component {
  render() {
    const {
      choosePaymentOption,
      licenceFees,
      totalLicenceFee,
      hidePaymentOption,
      onlineBtn,
      offlineBtn,
      t,
    } = this.props;

    const hideOfflineBtn = totalLicenceFee == 0;

    return (
      <>
        <div className="heading-bg">
          {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_PAYMENTS)}{" "}
        </div>
        <div className="payments">
          {!hidePaymentOption && (
            <p className="verify">
              {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_MESSAGE_NOTE_PAYMENTTEXT1)}
            </p>
          )}
          {hidePaymentOption && (
            <p className="verify">
              {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_MESSAGE_NOTE_PAYMENTTEXT2)}
            </p>
          )}
          <div className="data-table">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      {t(
                        LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_TABLE_TITLE_SERVICES
                      )}
                    </th>
                    <th>
                      {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_TABLE_TITLE_FEES)}
                    </th>
                    <th>
                      {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_TABLE_TITLE_TAX)}
                    </th>
                    <th>
                      {t(
                        LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_TABLE_TITLE_SUBTOTAL
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {licenceFees.map((licenceFeeItem, i) => {
                    const licenceTypeNameTranslationKey =
                      getLicenceTypeNameTranslationKey(
                        licenceFeeItem.LicenceName
                      );

                    return (
                      <React.Fragment key={licenceFeeItem.LicenceName}>
                        <tr>
                          <td colSpan="4">
                            {t(
                              getLangKey(
                                LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY,
                                licenceTypeNameTranslationKey,
                                licenceFeeItem.LicenceTypeId
                              )
                            )}
                          </td>
                        </tr>
                        {licenceFeeItem.LicenceFeeTypeItems.map(
                          (feeType, i) => {
                            return (
                              <tr
                                className="child-row"
                                key={licenceFeeItem.LicenceName + i}
                              >
                                <td>
                                  <span>
                                    {t(
                                      getLangKey(
                                        LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY,
                                        licenceTypeNameTranslationKey,
                                        feeType.FeeId
                                      )
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL
                                  )}
                                  {feeType.MainFee}
                                </td>
                                <td>
                                  {t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL
                                  )}
                                  {feeType.TaxFee}
                                </td>
                                <td className="payment-fee">
                                  {t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL
                                  )}
                                  {feeType.TotalFee}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <span>
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GRANDTOTAL
                        )}{" "}
                        <strong>
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL
                          )}
                          {totalLicenceFee}
                        </strong>
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {!hidePaymentOption && (
            <div className="choose-mode">
              <p>
                {t(
                  LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_NOTE_CHOOSEPAYMENTMODE
                )}{" "}
                <span>
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_NOTE_CLICKANYBELOW
                  )}
                </span>
              </p>
              <Button
                className={onlineBtn}
                color="payment"
                onClick={() => choosePaymentOption(PAYMENT_OPTIONS.ONLINE)}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_ONLINE)}
              </Button>
              {!hideOfflineBtn && (
                <Button
                  className={offlineBtn}
                  color="payment"
                  onClick={() => choosePaymentOption(PAYMENT_OPTIONS.OFFLINE)}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_OFFLINE
                  )}
                </Button>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(PaymentSummary);
