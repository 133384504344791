import React from "react";
import { withTranslation } from 'react-i18next';
import { includesMinCharValidation } from "../../Shared/Constants";
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DirectionContextConsumer } from "../../Shared/DirectionContext";
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import ItemResult from './ItemResult';

const INITIAL_VALUES = '*'; //will use this to take it that i will need to load all

class ResultContainer extends React.Component {

  render() {
    const { t, agencies, criteria, handleAgencyClick, selectedId, isResultDisplayed } = this.props;
    const { currLang } = this.props.context;

    let isError;
    let isSuccessNull;
    //const searchMsg = 'Search Results';
    const message = t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER_ENTERVALIDKEYWORD);
    const messageNoResults = t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER_NORESULTS);
    
    if (criteria === INITIAL_VALUES) {
      isError = false;
    }
    else if ((includesMinCharValidation(currLang) && criteria.length < 3) || criteria === undefined) {
      isError = true;
    } else if (agencies !== undefined && agencies.length === 0) {
      isSuccessNull = true;
    }

    return (
      <React.Fragment>
        {isError || isSuccessNull ? (
          <React.Fragment>
            <h3 className="error-message-header">{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCHRESULTS)}</h3>
            {isError && <p className="error-message">{message}</p>}
            {isSuccessNull && <p className="error-message">{messageNoResults}</p>}
            <p className="error-message"></p>
          </React.Fragment>
        ) : (
            <ul className={`${isResultDisplayed ? "nav nav-tabs show" : "nav nav-tabs hide"}`}>
              {agencies !== undefined && agencies.map((agency, i) => {
                return (
                  <ItemResult
                    key={i}
                    agency={agency}
                    GetLicence={handleAgencyClick}
                    selectedId={selectedId}
                  />
                );
              })}

            </ul>
          )}

      </React.Fragment>
    );
  }
}
const withDirectionContext = WrappedComponent => {
  class DirectionOnPageContext extends React.Component {
    render() {
      return (
        <DirectionContextConsumer>
          {({ currLang }) => (
            <WrappedComponent
              context={{ currLang }}
              {...this.props}
            />
          )}
        </DirectionContextConsumer>
      );
    }
  }

  return DirectionOnPageContext;
};
export default withTranslation()(withModalWrapper(withDirectionContext(ResultContainer)));
