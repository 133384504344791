import React from 'react';
import PropTypes from 'prop-types';
import { TabHeader, WizardHeader } from '../Shared/Forms';
import { SECTION_WRAPPER_TYPES } from '../Shared/Constants';

class SectionWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
    }

    renderHeader({ type, tabs, sectionState, toggleSection }) {
        switch (type) {
            case SECTION_WRAPPER_TYPES.TAB:
            default:
                return ( // Tab 
                    <React.Fragment>
                        <TabHeader
                            tabs={tabs}
                            activeTab={sectionState}
                            toggleTab={toggleSection}
                        >
                            {this.props.children}
                        </TabHeader>
                    </React.Fragment>
                );

            case SECTION_WRAPPER_TYPES.ACCORDION:
                return ( // Accordion 
                    <React.Fragment>
                        {this.props.children}
                    </React.Fragment>
                );

            case SECTION_WRAPPER_TYPES.WIZARD:
                return ( // Wizard 
                    <React.Fragment>
                        <WizardHeader
                            tabs={tabs}
                            activeTab={sectionState}
                            toggleTab={toggleSection}
                        >
                            {this.props.children}
                        </WizardHeader>
                    </React.Fragment>
                );
            case SECTION_WRAPPER_TYPES.ACCORDION_MODAL:
                return ( // Accordion in modal
                    <React.Fragment>
                        <div id="accordion">
                            {this.props.children}
                        </div>
                    </React.Fragment>
                );
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderHeader(this.props)}
            </React.Fragment>
        );
    }

}

// PropTypes: For prop validation
SectionWrapper.propTypes = {
    type: PropTypes.string,
    tabs: PropTypes.object,
    toggleSection: PropTypes.func
};

// PropTypes: Defaulting value for optional props
SectionWrapper.defaultProps = {
    type: SECTION_WRAPPER_TYPES.TAB,
    sectionName: ""
};

export default SectionWrapper;