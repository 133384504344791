import React from 'react';
import { Card, CardBody } from 'reactstrap';

class CardContainer extends React.Component {

    render() {

        return (
            <React.Fragment>
                <Card className={"start-card"}>
                    <CardBody className="start-card-body">
                        {this.props.children}
                    </CardBody>
                </Card>
            </React.Fragment>
        );

    }
}

export default CardContainer;