import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

class SimpleCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
    }

    checkboxLabel = () => {
        return (this.props.labelRequired ? "checkbox-label required" : "checkbox-label")
    }

    render() {
        const { name, onChange, value, label } = this.props;
        return (
            <FormGroup check inline>
                    <Label className="checkbox-group" check>
                        {/* Hide original Checkbox UI, but all react behavior still happens here */}
                        <Input
                            /* for checkboxes that do not have a limit set or if is check */
                            id={name}
                            className="checkbox-control"
                            type="checkbox"
                            name={name}
                            onChange={onChange}
                            checked={value}
                        />

                        {/* Re-styled Checkbox UI for cross-browser compatibility */}
                        <Label for={name} className="checkbox-display"></Label>

                        {/* Checkbox Text */}
                        <Label for={name} className={this.checkboxLabel()}>{label}</Label>
                    </Label>
                </FormGroup>
        )
    }
}

export default SimpleCheckbox;

