import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { TABLE_LAYOUT } from '../../../App/AppSettings';
import { AccordionContainer } from '../../../Shared/Forms';
import RenderFieldComponent from '../RenderFieldComponent';
import { BigTable, SmallTable } from '../TableComponent';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

class LicenceInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionNames: undefined
        };
    }

    componentDidUpdate() {
        const { form } = this.props;

        const sectionNamesObject = {};
        if (form.Sections != null && form.Sections != undefined) {
            form.Sections.forEach(formSection => {
                sectionNamesObject[formSection.Name] = { key: formSection.Name, title: formSection.DisplayName, status: true };
            });
        }

        this.setState(prevState => {
            if (prevState.sectionNames === undefined) {
                return {
                    sectionNames: sectionNamesObject
                };
            } else { return null; }
        });
    }

    render() {
        const { values, onChange, onChangeField, errors, toggleSection, toggleAllSections } = this.props.smartFormValues;
        const { sectionNames } = this.state;
        const { t, form, optionDataList, formName } = this.props;
        const formType = form.FormType;

        return (
            <>
                <div className="heading-bg" key={formName}>{formName}</div>
                {(form.Sections == null || form.Sections.length === 0) ?
                    // If all the fields are common, there will be no fields in the form tab
                    <div>{t(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_ALLFIELDSARECOMMON)}</div>
                    :
                    <>
                        <div className="collapse-expand">
                            <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(sectionNames, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                            <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(sectionNames, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                        </div>
                        {form.Sections.map((formSection, i) => {
                            return (
                                <React.Fragment key={formSection.DisplayName + i}>
                                    {sectionNames &&
                                        <AccordionContainer
                                            className="accordion"
                                            title={sectionNames && t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, sectionNames[formSection.Name].key))}
                                            active={sectionNames && sectionNames[formSection.Name].status}
                                            onClick={toggleSection}
                                            isIndividual={true}
                                            sections={sectionNames}
                                        >
                                            <div className="card-body">
                                                {formSection.Fields && formSection.Fields.map((field, index) => {
                                                    return (<RenderFieldComponent
                                                        key={index}
                                                        formType={form.FormType}
                                                        formName={form.FormName}
                                                        field={field}
                                                        values={values}
                                                        onChange={onChange}
                                                        onChangeField={onChangeField}
                                                        errors={errors}
                                                        optionDataList={optionDataList}
                                                    />);
                                                })}
                                                {formSection.Table && (formSection.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                                    <SmallTable
                                                        smartFormValues={this.props.smartFormValues}
                                                        formType={formType}
                                                        formSection={formSection}
                                                        optionDataList={optionDataList}
                                                    />
                                                }
                                                {formSection.Table && formSection.Table.Fields.length > TABLE_LAYOUT.COLUMNS &&
                                                    <BigTable
                                                        smartFormValues={this.props.smartFormValues}
                                                        formType={formType}
                                                        formSection={formSection}
                                                        form={form}
                                                        optionDataList={optionDataList}
                                                    />
                                                }
                                            </div>
                                        </AccordionContainer>
                                    }
                                </React.Fragment>
                            );
                        })}
                    </>
                }
            </>
        );

    }
}

export default withTranslation()(LicenceInformation);
