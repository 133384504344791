import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Spinner from '../Loading/Spinner';
import { deleteCookie } from '../SmartMe/Actions'
import { REDIRECT_URI } from '../SmartMe/Constants'
import AuthHelper from './AuthHelper';

class Callback extends Component {
  constructor() {
    super();
    this.state = {
      path: 'Error/Page404'
    }

    let mgr;

    // Get login type from cookies
    mgr = AuthHelper.getManager();
    mgr.signinRedirectCallback().then(() => {
      deleteCookie(REDIRECT_URI);
    })
  }

  render() {
    return (
      <div>
      <Spinner visible={true} />
      </div>
    );
  }
}

export default withRouter(Callback);