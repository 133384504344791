import { faBook, faComments, faInbox } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Nav } from 'reactstrap';
import { ENQUIRY_PATH, INBOX_PATH, SERVICES_PATH } from '../App/AppSettings';
import { NavbarAuthExtension, NavbarExtension, NavbarServiceExtension } from '../Extensions/NavbarExtension';
import { AuthConsumer } from '../Shared/Authentication/AuthContext';
import { withLoader } from '../Shared/Loading';
import { withModalWrapper } from '../Shared/Modal/ModalWrapper';
import NavContainer from './NavContainer';
import NavLayout1D from './NavLayout1D';
import NavLayout1M from './NavLayout1M';
import NavLayout2D from './NavLayout2D';
import NavLayout2M from './NavLayout2M';
import NavLink from './NavLink';

// Constant: Navbar Item Names
const NAVITEM = {
    Home: 'Home',
    INBOX: 'Inbox',
    ENQUIRY: 'Enquiry',
    SERVICES: 'Services',
    ADMIN: 'Admin',
    CONTACTUS: "Contact Us",
    FAQS: "FAQ"
};

class Navbar extends React.Component {
    NavbarContent = () => {
        return (
            <Nav>
                <AuthConsumer>
                    {({ isAuthenticated, profile, logout }) => ((profile && isAuthenticated) && (
                        <React.Fragment>
                            <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={INBOX_PATH} icon={faInbox} navPath={NAVITEM.INBOX}>{NAVITEM.INBOX}</NavLink>
                            <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to={ENQUIRY_PATH} icon={faComments} navPath={NAVITEM.ENQUIRY}>{NAVITEM.ENQUIRY}</NavLink>
                            <NavContainer navbarLayout={this.props.navbarLayout} path={SERVICES_PATH} name={NAVITEM.SERVICES} icon={faBook} navPath={NAVITEM.SERVICES}>
                                {/* Birth Registration */}
                                <NavContainer navbarLayout={this.props.navbarLayout} path='/services/birthregistration' dropdownlevel="1" name='Birth Registration' navPath={NAVITEM.SERVICES}>
                                    {/* BirthRegistration - Create Applications Bundle */}
                                    <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/services/birthregistration/v1/start' dropdownlevel="2" navPath={NAVITEM.SERVICES} includePaths={['/services/birthregistration/v1/new', '/services/birthregistration/v1/preview', '/services/birthregistration/v1/success']}>New Birth Registration</NavLink>
                                </NavContainer>

                                {/* Death Registration */}
                                <NavContainer navbarLayout={this.props.navbarLayout} path='/services/deathregistration/' dropdownlevel="1" name='Death Registration' navPath={NAVITEM.SERVICES}>
                                    {/* DeathRegistration - Create Applications Bundle */}
                                    <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/services/deathregistration/v1/start' dropdownlevel="2" navPath={NAVITEM.SERVICES} includePaths={['/services/deathregistration/v1/new', '/services/deathregistration/v1/preview', '/services/deathregistration/v1/success']}>New Death Registration</NavLink>
                                </NavContainer>

                                {/* Marriage Registration */}
                                <NavContainer navbarLayout={this.props.navbarLayout} path='/services/marriageregistration/' dropdownlevel="1" name='Marriage Registration' navPath={NAVITEM.SERVICES}>
                                    {/* MarriageRegistration - Create Applications Bundle */}
                                    <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/services/marriageregistration/v1/start' dropdownlevel="2" navPath={NAVITEM.SERVICES} includePaths={['/services/marriageregistration/v1/new', '/services/marriageregistration/v1/preview', '/services/marriageregistration/v1/success']}>New Marriage Registration</NavLink>
                                </NavContainer>

                                <NavbarServiceExtension onClick={this.props.onClick} navPath={NAVITEM.SERVICES} navbarLayout={this.props.navbarLayout} />
                            </NavContainer>
                            <NavbarAuthExtension onClick={this.props.onClick} navPath={NAVITEM.SERVICES} navbarLayout={this.props.navbarLayout} />
                        </React.Fragment>
                    ))}
                </AuthConsumer>
                <NavbarExtension onClick={this.props.onClick} navbarLayout={this.props.navbarLayout}></NavbarExtension>
            </Nav>
        )
    }

    LayoutToggle = () => {
        switch (this.props.navbarLayout) {
            case "1D":
            default:
                return (
                    <NavLayout1D navbarLayout={this.props.navbarLayout}>
                        {this.NavbarContent()}
                    </NavLayout1D>
                );
            case "1M":
                return (
                    <NavLayout1M navbarLayout={this.props.navbarLayout} collapseNavbar={this.props.collapseNavbar} onUpdate={this.props.onUpdate} onClick={this.props.onClick}>
                        {this.NavbarContent()}
                    </NavLayout1M>
                );
            case "2D":
                return (
                    <NavLayout2D 
                        showNavDisplay={this.props.showNavDisplay} 
                        navbarLayout={this.props.navbarLayout}
                        cartLicences={this.props.cartLicences}
                        modal={this.props.modal}>
                        {this.NavbarContent()}
                    </NavLayout2D>
                );
            case "2M":
                return (
                    <NavLayout2M
                        navbarLayout={this.props.navbarLayout}
                        isSidebarOpen={this.props.isSidebarOpen}
                        sidebarToggleClickHandler={this.props.sidebarToggleClickHandler}
                        cartLicences={this.props.cartLicences}
                        modal={this.props.modal}
                    >
                        {this.NavbarContent()}
                    </NavLayout2M>
                );
        }
    }

    render() {
        return this.LayoutToggle()

    }
}

export default withModalWrapper(withLoader(Navbar));