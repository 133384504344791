import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, getIn } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Button, Label, Table } from 'reactstrap';
import * as Yup from 'yup';
import { REGEX } from '../../../App/AppSettings';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';
import { isValidForm } from '../../../Shared/Actions';
import { Plaintext } from '../../../Shared/Forms';
import { SMARTFormContext, SMARTSectionContext } from '../../../Shared/Forms/SMARTContext';
import { ModalConfirm } from '../../../Shared/Modal';
import RenderTableFieldComponent from '../RenderTableFieldComponent';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';
import { withTranslation } from 'react-i18next';

const MODAL_NAMES = { TablePopUp: 'TablePopUp', AddRowPopUp: 'AddRowPopUp' };

const withSMARTContext = WrappedComponent => {
    class SMARTContext extends React.Component {
        render() {
            return (
                <SMARTFormContext.Consumer>
                    {updateControlList => (
                        <SMARTSectionContext.Consumer>
                            {sectionName => (
                                <WrappedComponent
                                    context={{
                                        updateControlList,
                                        sectionName
                                    }}
                                    {...this.props}
                                />
                            )}
                        </SMARTSectionContext.Consumer>
                    )}
                </SMARTFormContext.Consumer>
            );
        }
    }

    return SMARTContext;
};


class BigTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newValues: _.cloneDeep(this.props.smartFormValues.values),
            validationSchema: '',
            fieldsError: false
        };
    }

    // Create validation schema
    createValidationSchema = formSection => {
        const licTableTemp = {};
        const { t } = this.props;

        // Loop through fields to assign field validation
        formSection.Table.Fields && formSection.Table.Fields.forEach(field => {

            let validationRule = Yup.string();
            // Required
            if (field.Validation != null
                && field.Validation.Required) {
                validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED));

                if (field.InputType === 'Email') {
                    validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                } else if (field.InputType === 'Number') {
                    validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                } else {
                    if ('MinLength' in field.Validation) {
                        validationRule = validationRule.min(field.Validation.MinLength, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                    }
                    if ('MaxLength' in field.Validation) {
                        validationRule = validationRule.max(field.Validation.MaxLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                    }
                }
            }
            licTableTemp[`${field.Name}`] = validationRule;
        });
        return Yup.object().shape(licTableTemp);
    };

    // Lifecycle: Called once when component is mounted
    componentDidMount() {
        // Get context
        const { updateControlList, sectionName } = this.props.context;
        const { formType, formSection } = this.props;

        // Control calls callback to inform its parent SMARTForm their existence
        updateControlList && updateControlList(`${formType}.${formSection.Name}`, sectionName);

    }

    // Confirm for both Add and Edit Modal
    toggleModalConfirm = async (modalName, sectionName, modalValues, modalStateError, arrayHelpers, rowIndex, formType) => {

        // Check if there are errors
        const displayError = await this.tableDisplayError(modalValues);

        // If no errors, add values to table and close pop up
        if (!displayError) {
            this.setState({
                modalState: (this.state.modalState !== modalName) ? modalName : undefined
            });

            // If "Add row" modal, push values into arrayhelper else, edit the existing values
            if (modalName === 'AddRowPopUp') {
                arrayHelpers.push(modalValues);
            } else {
                Object.assign(this.props.smartFormValues.values[formType][sectionName][rowIndex], modalValues);
            }
            modalStateError(false);
        } else {
            modalStateError(true);
        }
    };

    // Open Edit Modal
    toggleModal = (modalName, ID, modalValues) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID,
            modalValues
        });
    };

    // Cancel Edit Modal
    toggleCancelEditModal = async (formType, formSection, modalName, ID, modalValues, sectionName) => {
        formSection.forEach(section => {
            if (section.Table && section.Name === sectionName) {
                section.Table.Fields.forEach(field => {
                    // Get previous field value and compare with the new value. If there is a difference in the value then reset it back to the previous value.
                    if (modalValues[field.Name] !== this.state.modalValues[field.Name]) {
                        this.handleChangeField(field.Name, this.state.modalValues[field.Name], field.Name, ID, formType, sectionName);
                    }
                });
            }
        });

        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    // Open Add Modal
    toggleModalAddRow = (modalName, modalStateError) => {
        modalStateError(false);
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined
        });
    };

    // Set state to true if Maximum Row reached
    maximumErrorMessage = maximumRows => {
        this.setState({
            showMaximumRows: maximumRows
        });
    };

    handleChange = (e, fieldName, rowIndex, formType, formSectionName) => {
        const tempValues = Object.assign({}, this.state.newValues);
        tempValues[formType][formSectionName][rowIndex][fieldName] = e.target.value;
        this.setState({
            newValues: tempValues
        });
    };

    handleChangeField = (name, value, fieldName, rowIndex, formType, formSectionName) => {
        const tempValues = Object.assign({}, this.state.newValues);
        tempValues[formType][formSectionName][rowIndex][fieldName] = value;
        this.setState({
            newValues: tempValues
        });
    };

    // Check if values match validation
    tableDisplayError = values => {
        if (isValidForm(this.state.validationSchema, values)) {
            // If matches, return false to indicate no errors
            this.setState({
                fieldsError: false
            });
            return false;
        } else {
            // Else, return true to indicate errors
            this.setState({
                fieldsError: true
            });
            return true;
        }
    };

    render() {
        const { values, errors, modalError, modalStateError } = this.props.smartFormValues;
        const { form, formType, formSection, optionDataList, t } = this.props;
        const newRow = {};
        const { newValues, fieldsError } = this.state;

        return (
            <FieldArray
                name={`${formType}.${formSection.Name}`}
                render={arrayHelpers => (
                    <div>
                        <div className="table-add-row">
                            <Button
                                color="neutral"
                                size="md"
                                type="button"
                                className="generic-table-add"
                                onClick=
                                {
                                    // Check table row, if table maximum row not reached, user can continue to add row
                                    (values[formType][formSection.Name] && values[formType][formSection.Name].length !== formSection.Table.MaximumTableRows)
                                        || formSection.Table.MaximumTableRows === undefined
                                        || formSection.Table.MaximumTableRows === 0
                                        ? () => {
                                            this.toggleModalAddRow(MODAL_NAMES.AddRowPopUp, modalStateError);
                                            newValues[formType][formSection.Name].push(newRow);
                                            this.setState({
                                                validationSchema: this.createValidationSchema(formSection)
                                            });
                                        }
                                        : () => this.maximumErrorMessage(true)
                                }
                                disabled={this.state.showMaximumRows ? true : false}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add
                            </Button>
                        </div>

                        <div className="table-requirement-label">
                            {/* Display label for min & max row */}
                            <Label>
                                {
                                    formSection.Table.MinimumTableRows ?
                                        <p>Require at least {formSection.Table.MinimumTableRows} record(s)</p>
                                        : null
                                }
                                {
                                    formSection.Table.MaximumTableRows ?
                                        <p>Maximum of {formSection.Table.MaximumTableRows} record(s)</p>
                                        : null
                                }
                            </Label>
                            <br />
                            {
                                !Array.isArray(getIn(errors, `${formType}.${formSection.Name}`))
                                && getIn(errors, `${formType}.${formSection.Name}`) !== ''
                                && getIn(errors, `${formType}.${formSection.Name}`) !== undefined
                                && <Label className="required label-error">
                                    {
                                        getIn(errors, `${formType}.${formSection.Name}`)
                                    }
                                </Label>
                            }
                            {
                                this.state.showMaximumRows ?
                                    <Label className="required label-error">
                                        Maximum quota hit: Maximum {formSection.Table.MaximumTableRows} record(s)
                                    </Label>
                                    : null
                            }
                        </div>
                        <Table className="griddle-table">

                            {/* Generate table headers start*/}
                            <thead className="griddle-table-heading">
                                <tr>
                                    {
                                        /* Loop through table section to retrieve column details */
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            newRow[field.Name] = '';

                                            if (field.ShowField === 'Show') {
                                                if (field.Validation.Required === true) {
                                                    return (
                                                        <th key={findex} className="griddle-table-heading-cell required">
                                                            <span>{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}</span>
                                                        </th>
                                                    );
                                                } else {
                                                    return (
                                                        <th key={findex} className="griddle-table-heading-cell">
                                                            <span>{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}</span>
                                                        </th>
                                                    );
                                                }
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    <th className="griddle-table-heading-cell"><span>Actions</span></th>
                                </tr>
                            </thead>
                            {/* Generate table headers end*/}

                            <tbody className="griddle-table-body">
                                {values[formType][formSection.Name] && values[formType][formSection.Name].map((row, rindex) => {
                                    return (
                                        <tr key={rindex} className="griddle-row">
                                            {
                                                formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                                    return (
                                                        field.ShowField === 'Show' && <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                            {
                                                                field.InputType !== 'FileUpload' && <Plaintext
                                                                    key={findex + rindex}
                                                                    name={field.Name}
                                                                    value={row[field.Name]}
                                                                    Label={undefined}
                                                                    labelSize={null}
                                                                />
                                                            }
                                                        </td>
                                                    );
                                                })
                                            }
                                            <td className="griddle-cell table-griddle-cell">
                                                <div className="action-button-group table-button">
                                                    <Button
                                                        className="action-btn"
                                                        color="neutral"
                                                        type="button"
                                                        onClick={() => this.toggleModal(
                                                            MODAL_NAMES.TablePopUp,
                                                            rindex,
                                                            values[formType][formSection.Name][rindex]
                                                        )}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Button
                                                        className="action-btn"
                                                        color="hazard"
                                                        type="button"
                                                        onClick={() => {
                                                            arrayHelpers.remove(rindex);
                                                            newValues[formType][formSection.Name].splice(rindex, 1);
                                                            this.maximumErrorMessage(false);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}

                                {/* Edit Table Modal */}
                                <ModalConfirm
                                    className={'modal-table'}
                                    isOpen={this.state.modalState === MODAL_NAMES.TablePopUp}
                                    contentHeader={`Edit ${formSection.DisplayName}`}
                                    contentBody={
                                        newValues[formType][formSection.Name] && newValues[formType][formSection.Name][this.state.modalID] &&
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            return (
                                                <RenderTableFieldComponent
                                                    optionDataList={optionDataList}
                                                    popUp={true}
                                                    key={findex + this.state.modalID}
                                                    sectionName={formSection.Name}
                                                    rowIndex={this.state.modalID}
                                                    formType={formType}
                                                    name={field.Name}
                                                    field={field}
                                                    values={newValues}
                                                    onChange={e => this.handleChange(e, field.Name, this.state.modalID, formType, formSection.Name)}
                                                    onChangeField={(name, value) => this.handleChangeField(name, value, field.Name, this.state.modalID, formType, formSection.Name)}
                                                    errors={modalError}
                                                    helpLabel={field.HelpText}
                                                    fieldsError={fieldsError}
                                                />
                                            );
                                        })

                                    }
                                    confirmText="Confirm"
                                    confirmCallback={() => {
                                        this.toggleModalConfirm(
                                            MODAL_NAMES.TablePopUp,
                                            formSection.Name,
                                            newValues[formType][formSection.Name][this.state.modalID],
                                            modalStateError,
                                            arrayHelpers,
                                            this.state.modalID,
                                            formType
                                        );
                                    }}
                                    cancelText="Cancel"
                                    cancelCallback={() => {
                                        this.toggleCancelEditModal(
                                            formType,
                                            form.Sections,
                                            MODAL_NAMES.TablePopUp,
                                            this.state.modalID,
                                            newValues[formType][formSection.Name][this.state.modalID],
                                            formSection.Name
                                        );
                                    }}
                                />
                                {/* Add Table Modal */}
                                <ModalConfirm
                                    className={'modal-table'}
                                    isOpen={this.state.modalState === MODAL_NAMES.AddRowPopUp}
                                    contentHeader={`Add ${t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, formSection.Name))} test`}
                                    contentBody={
                                        newValues[formType][formSection.Name] && newValues[formType][formSection.Name].length > 0 &&
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            return (
                                                <RenderTableFieldComponent
                                                    optionDataList={optionDataList}
                                                    popUp={true}
                                                    key={findex + newValues[formType][formSection.Name].length - 1}
                                                    sectionName={formSection.Name}
                                                    rowIndex={newValues[formType][formSection.Name].length - 1}
                                                    name={field.Name}
                                                    field={field}
                                                    formType={formType}
                                                    values={newValues}
                                                    onChange={e => this.handleChange(e, field.Name, newValues[formType][formSection.Name].length - 1, formType, formSection.Name)}
                                                    onChangeField={(name, value) => this.handleChangeField(name, value, field.Name, newValues[formType][formSection.Name].length - 1, formType, formSection.Name)}
                                                    errors={modalError}
                                                    helpLabel={field.HelpText}
                                                    fieldsError={fieldsError}
                                                />
                                            );
                                        })
                                    }
                                    confirmText="Confirm"
                                    confirmCallback={() => {
                                        this.toggleModalConfirm(
                                            MODAL_NAMES.AddRowPopUp,
                                            formSection.Name,
                                            newValues[formType][formSection.Name][newValues[formType][formSection.Name].length - 1],
                                            modalStateError,
                                            arrayHelpers,
                                            newValues[formType][formSection.Name].length - 1,
                                            formType
                                        );
                                    }}
                                    cancelText="Cancel"
                                    cancelCallback={modalError[formSection.Name] !== undefined
                                        ? () => {
                                            this.toggleModal(
                                                MODAL_NAMES.AddRowPopUp,
                                                newValues[formType][formSection.Name].pop()
                                            );
                                            modalStateError(false);
                                        }
                                        : () => this.toggleModal(
                                            MODAL_NAMES.AddRowPopUp,
                                            newValues[formType][formSection.Name].pop()
                                        )
                                    }
                                />
                            </tbody>
                        </Table>
                    </div>
                )}
            />
        );
    }
}
export default withTranslation()(withSMARTContext(BigTable));
