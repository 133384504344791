import React from 'react';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../DisplayComponents/DisplayUtils';

class ItemResult extends React.Component {

  handleItemClick = () => {
    const { Id, Title, Description, CodeName } = this.props.item;
    this.props.GetItemDetails(Id, Title, Description, CodeName);
  };

  render() {
    const { Id, CodeName } = this.props.item;
    const { selectedId, t } = this.props;

    return (
      <React.Fragment>
        <li className="nav-item">
          <div onClick={this.handleItemClick} className={`nav-link ${selectedId === Id ? 'active' : ''}`}>
            <strong>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_INDUSTRY_TITLE_KEY, CodeName))}</strong>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_INDUSTRY_DESCRIPTION_KEY, CodeName))}
          </div>
        </li>
      </React.Fragment>
    );
  }
}
export default withTranslation()(ItemResult);
