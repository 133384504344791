import { default as React } from 'react';
import { withRouter } from 'react-router-dom';
import { Input } from 'reactstrap';
import { GET_BUSINESS_INTENT_URL, IS_DEMO, LAYOUT_TYPE, SEARCH_INTENT_PATH } from '../../../App/AppSettings';
import Layout from '../../../Layout';
import { fetchRequest, getParams } from '../../../Shared/Actions';
import { withSMARTWrapper } from '../../../Shared/Forms';
// import history from '../../../Shared/History';
import ResultContainer from './ResultContainer';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { withTranslation } from 'react-i18next'


const RETRIEVE_URL = GET_BUSINESS_INTENT_URL;
const INITIAL_VALUES = '';
const FORM_INITIAL_VALUES = '';

class SearchIntent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: undefined
        }
        
        if (this.props.location.search) {
            //Passes search query when 'Enter' button is clicked
            const params = new URLSearchParams(this.props.location.search);
            const title = params.get("title");
            if (title !== '') {
                this.state = { values: title };
            } else {
                this.state = { values: INITIAL_VALUES }
            }
        }
        
        if (this.state.values === undefined || this.state.values === "") {
            this.state = {
                values: INITIAL_VALUES
            };
        }

        this.state = {
            values: this.state.values,
            businessIntent: null,
            relatedBusinessIntents: [],
            displayValue: INITIAL_VALUES
        }
    }

    componentDidMount() {
        this.getBusinessIntent();
    }

    searchResult = () => {
        this.props.history.replace({ pathname: SEARCH_INTENT_PATH, search: '?title=' + this.state.values });
    }

    getBusinessIntent = async () => {
        const { loader } = this.props;

        const queryString = Object.assign({}, FORM_INITIAL_VALUES, { title: this.state.values });

        // Start loading
        loader.start();

        const response = await fetchRequest(RETRIEVE_URL, getParams(queryString), IS_DEMO);
        const { Data, IsSuccess } = response.body;
        
        if (IsSuccess) {
            if (Data !== null) {

                this.setState(() => {
                    return {
                        businessIntent: {
                            BusinessIntentId: Data.BusinessIntentId,
                            BusinessIntentName: Data.BusinessIntentName,
                            BusinessIntentDesc: Data.BusinessIntentDesc
                        },
                        relatedBusinessIntents: Data.RelatedBusinessIntents,
                        displayValue: this.state.values
                    };
                });
            }
            else {
                this.setState(() => {
                    return {
                        businessIntent: null,
                        relatedBusinessIntents: [],
                        displayValue: this.state.values
                    };
                });
            }
        }

        // End loading
        loader.done();
    };

    displayResultContainer = () => {
        return (
            <ResultContainer
                businessIntent={this.state.businessIntent}
                relatedBusinessIntent={this.state.relatedBusinessIntents}
                criteria={this.state.displayValue}
            //showRecommendBanner = {true}
            />
        );
    };

    //update the input to show the user input
    inputOnChangeHandler = e => {
        if (e.target.value === undefined || e.target.value === '') {
            this.setState({
                values: INITIAL_VALUES
            });
        } else {
            this.setState({
                values: e.target.value
            });
        }
    };

    searchValue = () => {
        if (this.state.values === undefined || this.state.values === '') {
            this.setState({
                values: INITIAL_VALUES
            });
        }
        this.getBusinessIntent();
    };

    inputKeyDownHandler = e => {
        if (e.key === 'Enter') {
            if (this.state.values === undefined || this.state.values === '') {
                this.setState({
                    values: INITIAL_VALUES
                });
            }
            this.searchResult();
        }
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className="main-content">

                        <div className="banner-background">
                            <div className="guided-journey-banner">
                                <div className="tagline">{t(LANGUAGE_KEYS.BLS_PUBLICHOME_CONTENT_TITLE_TELLUSABOUT)}<strong>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_CONTENT_TITLE_YOURBIZ)}</strong></div>
                                <div className="inner-serach">
                                    <div className="licence-serach">
                                        <div className="input-group">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                value={this.state.values}
                                                name="title"
                                                placeholder={t(LANGUAGE_KEYS.BLS_PUBLICHOME_CONTENT_MODAL_ABOUTBIZ)}
                                                onChange={this.inputOnChangeHandler}
                                                minLength={0}
                                                maxLength={140}
                                                onKeyDown={this.inputKeyDownHandler}
                                            />

                                            <span className="input-group-btn"><button className="btn btn-orange" type="button" onClick={this.searchResult}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_BUTTON_ENTER)}</button></span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="guided-journey">
                                {this.displayResultContainer()}
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}
export default withTranslation()(withRouter(withSMARTWrapper(SearchIntent)));

