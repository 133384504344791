import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Card, CardTitle, CardBody, Col, Collapse, Row } from 'reactstrap';
import './Faq.css';

class AccordionQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            collapseIcon: faPlusCircle
        };

    }

    Caret = () => {
        return (this.props.active) ? faMinusCircle : faPlusCircle;
    };

    toggleCollapse = () => {
        this.setState((prevState) => {
            return {
                active: (!prevState.active),
                collapseIcon: ((prevState.active) ? (faPlusCircle) : (faMinusCircle))
            };
        });
    };

    render() {
        const { questionSet, questionSetIndex } = this.props;
        return (
            <>
                <Card className='card-container' key={questionSetIndex}>
                    <CardBody className='key-card-body'>
                        <CardTitle className={'display-question'  + (this.state.active ? ' active': ' closed')}>
                            <FontAwesomeIcon
                                className='key-card-action-button action-button-group'
                                fixedWidth
                                icon={this.state.collapseIcon}
                                color="#666666"
                                onClick={() => this.toggleCollapse()}
                            />
                            <div className={'accordion-question'}>
                                <div>{questionSet.Question}</div>
                            </div>

                        </CardTitle>

                        <Collapse isOpen={this.state.active} className='display-answer'>
                            <Row >
                                <Col>
                                    {ReactHtmlParser(questionSet.Answer)}
                                </Col>
                            </Row>
                        </Collapse>
                    </CardBody>
                </Card>

            </>
        );
    }
}

export default AccordionQuestion;
