import React from 'react';
import { withTranslation } from 'react-i18next';
import { IS_DEMO, LICENCE_CART_PATH, SEARCH_LICENCE_BY_ID_LIST } from '../../App/AppSettings';
import { getLicenceTypeNameTranslationKey, getLangKey, DISPLAY_RICH_TEXT, getTranslation } from '../../Content/DisplayComponents/DisplayUtils';
import { fetchRequest, getParams, navigateTo } from '../Actions';
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from '../Constants';
import LicenceDetailsModalContent from '../../Content/DisplayComponents/LicenceDetailsModalContent';
import { LicenceCartContext } from '../LicenceCartContext';
import { withModalWrapper } from '../Modal/ModalWrapper';
import PreRequisiteItem from './PreRequisiteItem';
import { DirectionContextConsumer } from '../DirectionContext';
import SearchExculsiveLicenceModal from './SearchExculsiveLicenceModal';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

const MODAL_NAMES = { DETAILS: 'Details', EXCLUSIVECART: 'Exclusive-Cart' };

const EmptyLicence = {
    Description: '',
    DocumentDesc: '',
    FaqDesc: '',
    FeeDesc: '',
    Id: '',
    TimeDesc: '',
    Title: '',
    Url: '',
    DocumentDict: {}
};

class PreRequisiteLicenceItemResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLicence: EmptyLicence,
            hidden: true,
            preRequisite: [],
            showPreReq: false,
            showPreReqIcon: false,
            exclusiveCart: []
        };
    }

    displayDescription = (desc, isShow) => {
        if (isShow) {
            return (
                <div dangerouslySetInnerHTML={{ __html: desc }} />
            );
        } else {
            return null;
        }
    };

    displayPreReq = (licence, isShow) => {
        const { t } = this.props;
        if (isShow) {
            return (
                <div className="div-pre-req">
                    <div className="div-pre-req-badge">{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_TITLE_NOTE)}</div>
                    <span className="div-pre-req-desc">{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NOTEINFO)}</span>
                    <span className={`div-pre-req-show-hide ${this.state.showPreReqIcon ? 'div-pre-req-hide' : 'div-pre-req-show'}`} onClick={() => this.toggleDisplay(licence)}>
                        {this.state.showPreReq ? t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_HIDENOTEINFO) : t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_SHOWNOTEINFO)}
                    </span>
                </div>
            );
        } else {
            return null;
        }
    };

    toggleDisplay = async (licence) => {
        let RETRIEVE_URL = SEARCH_LICENCE_BY_ID_LIST;
        // Append id of licences to retrieve
        licence.PreRequisite.forEach(prereq => {
            RETRIEVE_URL += `idList=${prereq.LicenceTypeId}&`;
        });

        // Retrieve licence details
        const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data } = response.body;
        if (response.success && IsSuccess) {
            this.setState(prevState => ({
                hidden: !prevState.hidden,
                preRequisite: Data,
                showPreReq: !prevState.showPreReq,
                showPreReqIcon: !prevState.showPreReqIcon
            }));
        }
    };

    displayCartButton = (licence, isShow, updateCart, currentCart) => {
        const {t} = this.props;
        if (isShow) {
            //licence already in the cart
            if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length > 0) {
                return (
                    <button
                        className='btn btn-add-to-cart added'
                        key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE) + licence.Id}
                        onClick={async () => {
                            await updateCart(licence, CART_ACTION_REMOVE);
                            // Funtion to enable Exclusive 'Continue' button
                            this.props.customUpdateCartCallback();
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE)}
                    </button>
                );
            } else {
                return (
                    <button
                        className='btn btn-add-to-cart'
                        key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART) + licence.Id}
                        onClick={async () => {
                            this.getPreRequisiteLicences(licence, updateCart, currentCart);
                            // Funtion to disable Exclusive 'Continue' button
                            this.props.customUpdateCartCallback();
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART)}
                    </button>
                );
            }
        } else {
            return null;
        }
    };

    displayApplyButton = (licence, displayText, isShow, updateCart, currentCart) => {
        const {t} = this.props;
        if (isShow) {
            return (
                <button className='btn btn-apply' key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY) + licence.Id} onClick={() => this.pressApplyButton(licence, updateCart, currentCart)}>{displayText}</button>
            );
        } else {
            return null;
        }
    };

    pressApplyButton = async (licence, updateCart, currentCart) => {
        if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length === 0) {
            const exclusiveLicenceCart = this.displayExclusiveModal(licence, currentCart);

            if (exclusiveLicenceCart.length === 0) {
                await this.getPreRequisiteLicences(licence, updateCart, currentCart);
                navigateTo(LICENCE_CART_PATH);
            } else {
                this.setState({
                    exclusiveCart: exclusiveLicenceCart,
                    modalState: MODAL_NAMES.EXCLUSIVECART
                });
            }
        } else {
            navigateTo(LICENCE_CART_PATH);
        }
    };

    displayExclusiveModal = (licence, currentCart) => {
        const exclusiveLicenceCart = [];

        // If currentCart is not empty, loop through currentCart (currentCartLicence)
        currentCart.length > 0 && currentCart.forEach(currentCartLicence => {
            const cartObj = {};
            let cartArray = [];
            // first check: Current licence being added IS an exclusive licence that is in the cart
            // If currentCartlicence have any exclusive licence, loop through the exclusive licences (e)
            currentCartLicence.Exclusive && currentCartLicence.Exclusive.length > 0 && currentCartLicence.Exclusive.forEach(e => {
                // Check if exclusiveLicenceCart already have the combination of Licence 1 (currentCartLicence Id) and Licence 2 (exclusive licence type Id)
                if ((
                    !exclusiveLicenceCart.some((exclusiveLicencePair, index) =>
                        // check that the exclusive pair does not already exist in exclusiveLicenceCart
                        exclusiveLicencePair[index].some(c => c.Id === currentCartLicence.Id) &&
                        exclusiveLicencePair[index].some(c => c.Id === e.LicenceTypeId))
                    &&
                    // Check if exclusive Licence exist in current licence
                    licence.Id === e.LicenceTypeId)
                ) {
                    // if there is an exclusive dependency, push the pair into the overall exclusiveLicenceCart
                    cartArray.push(licence);
                    cartArray.push(currentCartLicence);
                    cartObj[exclusiveLicenceCart.length] = cartArray;
                    exclusiveLicenceCart.push(cartObj);
                    cartArray = [];
                }
            });

            // second bidirectional check: Current licence being added HAS an exclusive licence that is in the cart
            if (
                // Check if exclusiveLicenceCart already have the combination of Licence 1 (CurrentCart Id) and Licence 2 (current licence Id)
                !exclusiveLicenceCart.some((exclusiveLicencePair, index) =>
                    // check that the exclusive pair does not already exist in exclusiveLicenceCart
                    exclusiveLicencePair[index].some(c => c.Id === currentCartLicence.Id) &&
                    exclusiveLicencePair[index].some(c => c.Id === licence.Id)
                ) &&
                // Check that the current licence have exclusive licence(s)
                // If there exists exclusive licence, check if the exclusive licence exist in CurrentCart
                licence.Exclusive.some(exclusiveLicence => currentCartLicence.Id === exclusiveLicence.LicenceTypeId)
            ) {
                cartArray.push(licence);
                cartArray.push(currentCartLicence);
                cartObj[exclusiveLicenceCart.length] = cartArray;
                exclusiveLicenceCart.push(cartObj);
                cartArray = [];
            }
        });

        return exclusiveLicenceCart;
    };

    toggleModal = modalName => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            errorMessage: false
        });
    };

    getPreRequisiteLicences = async (licence, updateCart, currentCart) => {
        const exclusiveLicenceCart = this.displayExclusiveModal(licence, currentCart);
        if (exclusiveLicenceCart.length === 0) {
            await updateCart(licence, CART_ACTION_ADD);
            let RETRIEVE_URL = SEARCH_LICENCE_BY_ID_LIST;
            const idList = licence.PreRequisite.map(required => {
                if (!currentCart.some(existing => existing.Id === required.LicenceTypeId)) {
                    return `idList=${required.LicenceTypeId}`;
                } else {
                    return null;
                }
            });
            RETRIEVE_URL += idList.join('&');
            const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
            const { IsSuccess, Data } = response.body;
            if (response.success && IsSuccess) {
                await updateCart(Data, CART_ACTION_ADD);
            }
        } else {
            this.setState({
                exclusiveCart: exclusiveLicenceCart,
                modalState: MODAL_NAMES.EXCLUSIVECART
            });
        }
    };

    displayFeeTime = (licence, isShow) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        if (isShow) {
            return (
                <>
                    <p className='fee'>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_FEE)}&nbsp;:&nbsp;$
                        <strong>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_FEEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId))}</strong>
                    </p>
                    <p className='time'>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_PROCESSINGTIME)}&nbsp;:&nbsp;
                        <strong>{licence.TimeDesc}</strong> {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_WORKINGDAYS)}
                    </p>
                </>
            );
        } else {
            return null;
        }
    };

    displayDetailsButton = (id, onClick, isShow) => {
        const { t } = this.props;
        if (isShow) {
            return (
                <button className='btn btn-details' onClick={onClick} >
                    {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_DETAILS)}
                </button>
            );
        } else {
            return null;
        }
    };

    externalLink = (e, url) => {
        e.preventDefault();
        const httpSubstring = 'http';
        let newUrl = url;
        if (url.indexOf(httpSubstring) !== 0) {
            newUrl = `http://${url}`;
        }
        window.open(newUrl, '_blank');
    };

    // For the 'detail' button to open modal
    handleDetailClick = (licence, modal) => {
        modal.toggleModal(MODAL_NAMES.DETAILS);
        this.setState({ stateLicence: licence });
    };

    render() {
        const { t, licence, isShowDescription, isShowFeeTime, onClick, isShowDetails, isShowAddToCart, isShowApply, isShowPreReq, modal } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);
        const { preRequisite, stateLicence } = this.state;

        return (
            <LicenceCartContext.Consumer>
                {({ updateCart, currentCart }) => (
                    <>
                        <div className='prereq-result-block'>
                            <div className='result-details'>
                                <h5>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                                {this.displayDescription(
                                    getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId), DISPLAY_RICH_TEXT),
                                    isShowDescription
                                )}
                                {this.displayPreReq(licence, isShowPreReq)}
                            </div>
                            <div className='prereq-first-licence-result-footer'>
                                <div className={this.props.context.direction === 'ltr' ? 'float-left' : 'float-right'}>
                                    {this.displayFeeTime(licence, isShowFeeTime)}

                                </div>
                                <div className={this.props.context.direction === 'ltr' ? 'float-right' : 'float-left'}>
                                    {this.displayDetailsButton(licence.Id, onClick, isShowDetails)}
                                    {licence.IsExternalLicence ?
                                        (
                                            <>
                                                <div className='search-licence-link link-margin-left' onClick={e => this.externalLink(e, licence.Url)}>
                                                    {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_AGENCYWEBSITE)}</div>
                                            </>
                                        ) :
                                        ([
                                            this.displayCartButton(licence, isShowAddToCart, updateCart, currentCart),
                                            this.displayApplyButton(licence, t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY), isShowApply, updateCart, currentCart)
                                        ])}
                                </div>
                            </div>
                            <SearchExculsiveLicenceModal
                                isOpen={this.state.modalState === MODAL_NAMES.EXCLUSIVECART}
                                closeCallBack={() => this.toggleModal(MODAL_NAMES.EXCLUSIVECART)}
                                contentHeader={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_EXCLUSIVELICENCES)}
                                exclusiveCart={this.state.exclusiveCart}
                            />
                            <div className='prereq-following-licence-result' hidden={this.state.hidden}>
                                {
                                    preRequisite.length > 0 &&
                                    <>
                                        <h5>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_PREREQUISITELICENCE)}</h5>
                                        <p>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_PREREQUISITELICENCESREQUIREDMESSAGE)}</p>
                                        {preRequisite.map((pr, index) => {
                                            return (<PreRequisiteItem
                                                key={index}
                                                licence={pr}
                                                onClick={() => this.handleDetailClick(pr, modal)}
                                                isTdNavigation={false}
                                                inLineStyle={null}
                                                isShowDescription={true}
                                                isShowFeeTime={true}
                                                isShowDetails={true}
                                            />);
                                        })}

                                    </>
                                }
                            </div>
                        </div>
                        {stateLicence && stateLicence !== EmptyLicence &&
                            <LicenceDetailsModalContent
                                stateLicence={stateLicence}
                                modal={modal}
                            />
                        }
                    </>
                )}
            </LicenceCartContext.Consumer>
        );
    }
}

PreRequisiteLicenceItemResult.defaultProps = {
    customUpdateCartCallback: () => { /*default empty function */ }
};

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ direction }) => (
                        <WrappedComponent
                            context={{ direction }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};

export default withTranslation()(withModalWrapper(withDirectionContext(PreRequisiteLicenceItemResult)));
