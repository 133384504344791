import PropTypes from 'prop-types';
import React from 'react';

class Spinner extends React.Component {

    render() {
        return (this.props.visible) ?
            (
                <div className="overlay-container" >
                    <span className={"spinner"}></span>
                </div>
            ) :
            (null);
    }
}

// PropTypes: For prop validation
Spinner.propTypes = {
    visible: PropTypes.bool.isRequired
};


export default Spinner;