import React from 'react';
import { Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as Yup from 'yup';
import {
    DASHBOARD_PATH, DEFAULT_PAYMENT_OPTION, GENERAL_INFORMATION_VALIDFORMTYPE, GET_FEES_LICENCES_URL,
    GET_SFORM_FORM_DATA_FROM_APP_TYPE_URL, LAYOUT_TYPE, PAYMENT_OPTIONS, RENEW_AMEND_LICENCE_URL
} from '../../App/AppSettings';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { BLS_FORM_APP_TYPES, BUTTON_TYPE, DELETE_FILES_ARRAY, SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { SMARTForm } from '../../Shared/Forms';
import { toastError } from '../../Shared/Forms/Toaster';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import { getFormNames, getRemoteList, getTableListValues } from '../FormComponents/FormUtils';
import { GeneralInformationComponent } from '../FormComponents/GeneralInformation/GeneralInformationComponent';
import { getAddressTypeOptions } from '../FormComponents/GeneralInformation/Utils.js';
import {
    createValidationSchemaForDeclaration, createValidationSchemaForGeneralInfo, createValidationSchemaForLicenceFields,
    createValidationSchemaForSupportingDocuments
} from '../FormComponents/LicenceApplicationInformation/ValidationSchema';
import PaymentSummary from '../FormComponents/PaymentSummary';
import SupportingDocuments from '../FormComponents/SupportingDocuments';
import RFAReviewAndSubmit from '../RFAResponse/RFAReviewAndSubmit';
import FormRenew from './FormRenew';
import { withTranslation } from 'react-i18next';
import { ERROR, LANGUAGE_KEYS, WIZARD_TAB_NAMES } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../DisplayComponents/DisplayUtils';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.WIZARD;
const FORM_CONTEXT = 'RenewLicence';

const PAYMENT_TAB_DISPLAY = true;

class RenewLicence extends React.Component {
    constructor(props) {

        super(props);
        if (history.location.state) {
            const Licence = history.location.state.Licence;
            this.state = {
                licence: Licence,
                formNames: null,
                values: null,
                addressTypeOptions: getAddressTypeOptions(),
                licenceFees: [],
                totalLicenceFee: "",
                selectedIdTypeArr: [],
                idNumberArr: [],
                optionDataList: {},
                onlineBtn: BUTTON_TYPE.ACTIVE,
                offlineBtn: BUTTON_TYPE.INACTIVE
            };
        }
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();

        this.getLicenceRenewalData(loader);
    }

    async getLicenceRenewalData(loader) {
        const { licence } = this.state;
        const { t } = this.props;
        // Construct Licence URL
        const params = {
            licenceId: licence.LicenceID,
            appType: BLS_FORM_APP_TYPES.RENEW
        };
        // Hit API endpoint to get Licence Renewal Data
        const response = await fetchRequest(GET_SFORM_FORM_DATA_FROM_APP_TYPE_URL, getParams(params));

        // Handle response
        const { IsSuccess, Data, Messages } = response.body;
        if (response.success && IsSuccess) {
            const licenceDetails = Object.assign({}, Data, { FormData: JSON.parse(Data.FormData) });

            await this.getOptionsDataList(licenceDetails);

            this.getFormFees(licenceDetails.FormDefinition.FormType, licence.LicenceID);

            this.setState(() => {
                return {
                    licenceDetails,
                    formNames: getFormNames(licence.LicenceName, licenceDetails.DocumentList, PAYMENT_TAB_DISPLAY),
                    values: this.getFilledValues(licenceDetails.FormDefinition, licenceDetails.FormData, licenceDetails.GeneralDefinition,
                        licenceDetails.DocumentList, licenceDetails.GeneralDefinition.FormType, licence.ApplicationRefNumber),
                    validationSchema: this.createValidationSchema(licenceDetails, licenceDetails.GeneralDefinition.FormType),
                    declarations: licenceDetails.DeclarationList,
                    generalInformationType: licenceDetails.GeneralDefinition.FormType
                };
            }, () => {
                loader.done();
            });
        } else {
            loader.done();
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    }

    getOptionsDataList = async devData => {
        const { t } = this.props;
        const listOfCategory = [];
        //loop through all the fields to collate all the options required for the entire form

        //GeneralDefinition
        devData.GeneralDefinition.Sections.forEach(section => {
            section.Fields.forEach(field => {
                const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(field.DataParameters.Category) < 0) {
                    listOfCategory.push(field.DataParameters.Category);
                }
            });
        });

        //FormDefinition
        devData.FormDefinition.Sections.forEach(formDefSection => {
            formDefSection.Fields && formDefSection.Fields.forEach(formDefField => {
                const isRemote = formDefField.DataSource !== undefined && formDefField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefField.DataParameters.Category);
                }
            });
            formDefSection.Table && formDefSection.Table.Fields.forEach(formDefTableField => {
                const isRemote = formDefTableField.DataSource !== undefined && formDefTableField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefTableField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefTableField.DataParameters.Category);
                }
            });
        });
        let totalOptionsList = null;
        if (listOfCategory.length !== 0) {
            totalOptionsList = await getRemoteList(t, listOfCategory);
        }

        this.setState({
            optionDataList: totalOptionsList
        });
    }

    getFilledValues = (formDefinition, formData, generalInfoFields, documentList, generalInformationType, appRefNumber) => {
        const licenceFieldNames = {};
        const formTypeNames = {
            FormTypes: [formDefinition.FormType, GENERAL_INFORMATION_VALIDFORMTYPE]
        };

        const licenceInfo = formData.LicenceInformation;
        const generalInfo = formData.GeneralInformation;
        licenceFieldNames[formDefinition.FormType] = {};

        const tableValueKeys = Object.keys(licenceInfo);

        //get filled values for licence fields
        formDefinition.Sections.forEach(section => {
            section.Fields && section.Fields.forEach(field => {
                if (licenceInfo[field.Name] !== undefined && licenceInfo[field.Name] !== '') {
                    licenceFieldNames[formDefinition.FormType][field.Name] = licenceInfo[field.Name];
                }
                else {
                    licenceFieldNames[formDefinition.FormType][field.Name] = '';
                }
            });

            if (section.Table) {
                licenceFieldNames[formDefinition.FormType][section.Name] = [];
                const sectionTableList = getTableListValues(tableValueKeys, section.Name);

                sectionTableList && sectionTableList[section.Name].forEach((row, rindex) => {
                    const newTableArrObj = {};
                    section.Table.Fields && section.Table.Fields.forEach(field => {
                        if (licenceInfo[`${section.Name}.${rindex}.${field.Name}`] !== undefined && licenceInfo[`${section.Name}.${rindex}.${field.Name}`] !== '') {
                            newTableArrObj[field.Name] = licenceInfo[`${section.Name}.${rindex}.${field.Name}`];
                        }
                        else {
                            newTableArrObj[field.Name] = '';
                        }
                    });
                    licenceFieldNames[formDefinition.FormType][section.Name].push(newTableArrObj);
                });
            }
        });

        const generalFieldNames = {};

        //get filled values for general info fields
        generalFieldNames.GeneralInformation = {};
        generalInfoFields.Sections.forEach(section => {
            section.Fields.forEach(field => {
                if (generalInfo[field.Name] !== undefined && generalInfo[field.Name] !== '') {
                    generalFieldNames.GeneralInformation[field.Name] = generalInfo[field.Name];
                }
                else {
                    generalFieldNames.GeneralInformation[field.Name] = '';
                }
            });
        });

        const supportingDocumentNames = {
            SupportingDocument: {}
        };
        documentList.forEach(field => {
            supportingDocumentNames.SupportingDocument[field.Name] = [];
        });

        const finalFields = {
            ...formTypeNames, ...generalFieldNames, ...licenceFieldNames, ...supportingDocumentNames,
            GeneralInformationType: generalInformationType,
            PaymentOption: DEFAULT_PAYMENT_OPTION
        };
        finalFields[DELETE_FILES_ARRAY] = [];
        finalFields.AppRefNum = appRefNumber;
        return finalFields;
    }

    choosePaymentOption = (paymentOption, values) => {
        values.PaymentOption = paymentOption;

        if (paymentOption === PAYMENT_OPTIONS.ONLINE) {
            this.setState({
                onlineBtn: BUTTON_TYPE.ACTIVE,
                offlineBtn: BUTTON_TYPE.INACTIVE
            });
        } else {
            this.setState({
                onlineBtn: BUTTON_TYPE.INACTIVE,
                offlineBtn: BUTTON_TYPE.ACTIVE
            });
        }
    }

    async getFormFees(formType, licenceId) {
        const queryString = {
            feeType: BLS_FORM_APP_TYPES.RENEW,
            formTypes: formType,
            licenceId
        };
        const response = await fetchRequest(GET_FEES_LICENCES_URL, getParams(queryString), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {
            const isDataPresent = Data && Data.LicenceFeeItemList && Data.LicenceFeeItemList.length > 0;
            this.setState({
                licenceFees: isDataPresent ? Data.LicenceFeeItemList : [],
                totalLicenceFee: isDataPresent ? Data.TotalLicenceFee : ''
            });
        }
    }

    createValidationSchema = (devData, generalInformationType) => {
        const { t } = this.props;
        const validationObject = {};
        /***********************/
        /*** General Section ***/
        /***********************/
        validationObject.GeneralInformation = createValidationSchemaForGeneralInfo(t, generalInformationType);

        /***********************/
        /*** Licence Section ***/
        /***********************/
        validationObject[devData.FormDefinition.FormType] = createValidationSchemaForLicenceFields(t, devData.FormDefinition);

        // /***********************************/
        // /*** Supporting Document Section ***/
        // /***********************************/
        validationObject.SupportingDocument = createValidationSchemaForSupportingDocuments(t, devData.DocumentList);

        /***************************/
        /*** Declaration Section ***/
        /***************************/
        validationObject.DeclarationAgreement = createValidationSchemaForDeclaration(t);

        return Yup.object().shape(validationObject);
    }

    render() {
        const { t } = this.props;
        const { licenceDetails, values, licence, generalInformationType, formNames, declarations, optionDataList } = this.state;
        if (!values || !licenceDetails || !licence) {
            return null;
        } else {
            const GeneralInformationComponentInput = GeneralInformationComponent[generalInformationType];

            return (
                <>
                    <div className="main-content">
                        <nav aria-label="breadcrumb" className="breadcrumb-main">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</Link></li>
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_RENEWLICENCE)}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{licence.LicenceName}</li>
                                </ol>
                            </div>
                        </nav>
                        <Layout type={LAYOUT_TYPE.FORM} footer>
                            <SMARTForm
                                formContext={FORM_CONTEXT}
                                className="container"
                                sectionNames={formNames}
                                defaultSection={Object.keys(formNames)[0]}
                                formValues={this.state.values}
                                validationSchema={this.state.validationSchema}
                                validateOnSubmit={true}
                                serverURL={RENEW_AMEND_LICENCE_URL}
                            >
                                {({ toggleSection, toggleAllSections, sectionState, onChange, onChangeField, values,
                                    submitForm, errors, currentStep, prevStep, nextStep, modalError, modalStateError }) => {

                                    const smartFormValues = {
                                        toggleSection,
                                        toggleAllSections,
                                        values,
                                        onChange,
                                        onChangeField,
                                        errors,
                                        modalError,
                                        modalStateError
                                    };
                                    return (
                                        <React.Fragment>
                                            <Col>
                                                <SectionWrapper
                                                    type={SECTION_LAYOUT_TYPE}
                                                    tabs={formNames}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}>
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}
                                                        key={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}>
                                                        <GeneralInformationComponentInput
                                                            smartFormValues={smartFormValues}
                                                            state={this.state}
                                                        />
                                                    </Section>
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={licence.LicenceName}
                                                    >
                                                        <FormRenew
                                                            smartFormValues={smartFormValues}
                                                            formDefinition={licenceDetails.FormDefinition}
                                                            formName={licence.LicenceName}
                                                            licence={licence}
                                                            formType={licenceDetails.FormDefinition.FormType}
                                                            optionDataList={optionDataList}
                                                        />
                                                    </Section>
                                                    {licenceDetails && licenceDetails.DocumentList && licenceDetails.DocumentList.length > 0 &&
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}
                                                            key={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}>
                                                            <SupportingDocuments
                                                                smartFormValues={smartFormValues}
                                                                files={licenceDetails.DocumentList}
                                                            />
                                                        </Section>
                                                    }
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}
                                                        key={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}>
                                                        <RFAReviewAndSubmit
                                                            smartFormValues={smartFormValues}
                                                            applicationDetails={licenceDetails}
                                                            declarations={declarations}
                                                            displayDocuments={licenceDetails && licenceDetails.DocumentList && licenceDetails.DocumentList.length > 0}
                                                        />
                                                    </Section>

                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={t(WIZARD_TAB_NAMES.PAYMENTS)}
                                                        key={t(WIZARD_TAB_NAMES.PAYMENTS)}>
                                                        <PaymentSummary
                                                            licenceFees={this.state.licenceFees}
                                                            totalLicenceFee={this.state.totalLicenceFee}
                                                            choosePaymentOption={option => this.choosePaymentOption(option, values)}
                                                            onlineBtn={this.state.onlineBtn}
                                                            offlineBtn={this.state.offlineBtn}
                                                        />
                                                    </Section>
                                                </SectionWrapper>

                                            </Col>
                                            <PageFooter type={LAYOUT_TYPE.FORM}>
                                                <div className="button-center">

                                                    {/* Wizard Previous Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== 0)) ?
                                                        <Button color="backward" size="sm" type="button" onClick={prevStep}>
                                                            {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_PREVIOUS)}
                                                        </Button> : ''
                                                    }

                                                    {/* Submit Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep === Object.keys(this.state.formNames).length - 1)) ?
                                                        <Button color="forward" size="sm" type="button" onClick={submitForm}>
                                                            {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_PAY)}
                                                        </Button> :
                                                        ((LAYOUT_TYPE.FORM_CONTENT !== 3) ?
                                                            <Button color="forward" size="sm" type="button" onClick={submitForm}>
                                                                {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_PAY)}
                                                            </Button> : '')
                                                    }

                                                    {/* Wizard Next Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== Object.keys(this.state.formNames).length - 1)) ?
                                                        <Button color="forward" size="sm" type="button" onClick={nextStep}>
                                                            {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_NEXT)}
                                                        </Button> : ''
                                                    }
                                                </div>
                                            </PageFooter>
                                        </React.Fragment>
                                    );
                                }
                                }

                            </SMARTForm>
                        </Layout>
                    </div>

                </>
            );
        }
    }
}

export default withTranslation()(compose(withLoader)(RenewLicence));

