import React from 'react';
import { withTranslation } from 'react-i18next';
import { LICENCE_CART_PATH } from '../../App/AppSettings';
import { DISPLAY_RICH_TEXT, getLangKey, getLicenceTypeNameTranslationKey, getTranslation } from '../../Content/DisplayComponents/DisplayUtils';
import { navigateTo } from '../../Shared/Actions';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from '../Constants';
import { LANGUAGE_KEYS } from '../Constants/LanguageKeys';
import SearchExculsiveLicenceModal from './SearchExculsiveLicenceModal';

const MODAL_NAMES = { EXCLUSIVECART: 'Exclusive-Cart' };

class LicenceItemResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exclusiveCart: []
        };
    }

    displayDescription = (desc, isShow) => {
        if (isShow) {
            return (
                desc
            );
        } else {
            return null;
        }
    };

    displayCartButton = (licence, isShow, updateCart, currentCart) => {
        const { t } = this.props;
        if (isShow) {
            //licence already in the cart
            if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length > 0) {
                return (
                    <button
                        className='btn btn-add-to-cart added'
                        key={getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE) + licence.Id}
                        onClick={async () => {
                            await updateCart(licence, CART_ACTION_REMOVE);
                            // Funtion to enable Exclusive 'Continue' button
                            this.props.customUpdateCartCallback();
                        }}
                    >
                        {getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE)}
                    </button>
                );
            } else {
                return (
                    <button
                        className='btn btn-add-to-cart'
                        key={getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART) + licence.Id}
                        onClick={async () => {
                            this.pressAddToCartButton(licence, updateCart, currentCart);
                            // Funtion to disable Exclusive 'Continue' button
                            this.props.customUpdateCartCallback();
                        }}
                    >
                        {getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART)}
                    </button>
                );
            }
        }
        else {
            return null;
        }
    };

    pressAddToCartButton = async (licence, updateCart, currentCart) => {
        const exclusiveLicenceCart = this.displayExclusiveModal(licence, currentCart);
        if (exclusiveLicenceCart.length === 0) {
            await updateCart(licence, CART_ACTION_ADD);
        } else {
            this.setState({
                exclusiveCart: exclusiveLicenceCart,
                modalState: MODAL_NAMES.EXCLUSIVECART
            });
        }
    };

    displayApplyButton = (licence, displayText, isShow, updateCart, currentCart) => {
        const { t } = this.props;
        if (isShow) {
            return (
                <button className='btn btn-apply' key={getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY) + licence.Id} onClick={() => this.pressApplyButton(licence, updateCart, currentCart)}>{displayText}</button>
            );
        }
        else {
            return null;
        }
    };

    pressApplyButton = (licence, updateCart, currentCart) => {
        if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length === 0) {
            const exclusiveLicenceCart = this.displayExclusiveModal(licence, currentCart);

            if (exclusiveLicenceCart.length === 0) {
                updateCart(licence, CART_ACTION_ADD);
                navigateTo(LICENCE_CART_PATH);
            } else {
                this.setState({
                    exclusiveCart: exclusiveLicenceCart,
                    modalState: MODAL_NAMES.EXCLUSIVECART
                });
            }
        } else {
            navigateTo(LICENCE_CART_PATH);
        }
    };

    displayExclusiveModal = (licence, currentCart) => {
        const exclusiveLicenceCart = [];

        // If currentCart is not empty, loop through currentCart (currentCartLicence)
        currentCart.length > 0 && currentCart.forEach(currentCartLicence => {
            const cartObj = {};
            let cartArray = [];
            // first check: Current licence being added IS an exclusive licence that is in the cart
            // If currentCartlicence have any exclusive licence, loop through the exclusive licences (e)
            currentCartLicence.Exclusive && currentCartLicence.Exclusive.length > 0 && currentCartLicence.Exclusive.forEach(e => {
                // Check if exclusiveLicenceCart already have the combination of Licence 1 (currentCartLicence Id) and Licence 2 (exclusive licence type Id)
                if ((
                    !exclusiveLicenceCart.some((exclusiveLicencePair, index) =>
                        // check that the exclusive pair does not already exist in exclusiveLicenceCart
                        exclusiveLicencePair[index].some(c => c.Id === currentCartLicence.Id) &&
                        exclusiveLicencePair[index].some(c => c.Id === e.LicenceTypeId))
                    &&
                    // Check if exclusive Licence exist in current licence
                    licence.Id === e.LicenceTypeId)
                ) {
                    // if there is an exclusive dependency, push the pair into the overall exclusiveLicenceCart
                    cartArray.push(licence);
                    cartArray.push(currentCartLicence);
                    cartObj[exclusiveLicenceCart.length] = cartArray;
                    exclusiveLicenceCart.push(cartObj);
                    cartArray = [];
                }
            });

            // second bidirectional check: Current licence being added HAS an exclusive licence that is in the cart
            if (
                // Check if exclusiveLicenceCart already have the combination of Licence 1 (CurrentCart Id) and Licence 2 (current licence Id)
                !exclusiveLicenceCart.some((exclusiveLicencePair, index) =>
                    // check that the exclusive pair does not already exist in exclusiveLicenceCart
                    exclusiveLicencePair[index].some(c => c.Id === currentCartLicence.Id) &&
                    exclusiveLicencePair[index].some(c => c.Id === licence.Id)
                ) &&
                // Check that the current licence have exclusive licence(s)
                // If there exists exclusive licence, check if the exclusive licence exist in CurrentCart
                licence.Exclusive.some(exclusiveLicence => currentCartLicence.Id === exclusiveLicence.LicenceTypeId)
            ) {
                cartArray.push(licence);
                cartArray.push(currentCartLicence);
                cartObj[exclusiveLicenceCart.length] = cartArray;
                exclusiveLicenceCart.push(cartObj);
                cartArray = [];
            }
        });

        return exclusiveLicenceCart;
    };

    toggleModal = modalName => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            errorMessage: false
        });
    };

    displayFeeTime = (licence, isShow) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        if (isShow) {
            return (
                <>
                    <p className='fee'>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_FEE)} : 
                        <strong><span>$</span> {getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_FEEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId))}</strong>
                    </p>
                    <p className='time'>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_PROCESSINGTIME)} : 
						<strong>{licence.TimeDesc} <span>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_WORKINGDAYS)}</span></strong>
                    </p>
                </>
            );
        } else {
            return null;
        }
    };

    displayDetailsButton = (id, onClick, isShow) => {
        const { t } = this.props;
        if (isShow) {
            return (
                <button className='btn btn-details' onClick={onClick} >{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_DETAILS)}        </button>
            );
        }
        else {
            return null;
        }
    };

    externalLink = (e, url) => {
        e.preventDefault();
        const httpSubstring = 'http';
        let newUrl = url;
        if (url.indexOf(httpSubstring) !== 0) {
            newUrl = `http://${url}`;
        }
        window.open(newUrl, '_blank');
    };

    render() {
        const { t, licence, isShowDescription, isShowFeeTime, onClick, isShowDetails, isShowAddToCart, isShowApply } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        return (
            <LicenceCartContext.Consumer>
                {({ updateCart, currentCart }) => (
                    <>
                        <div className='result-block'>
                            <div className='result-details'>
                                <h5>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                                {this.displayDescription(
                                    getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId), DISPLAY_RICH_TEXT),
                                    isShowDescription
                                )}
                            </div>
                            <div className='result-footer'>
                                <div className='float-left'>
                                    {this.displayFeeTime(licence, isShowFeeTime)}

                                </div>
                                <div className='float-right'>
                                    {this.displayDetailsButton(licence.Id, onClick, isShowDetails)}
                                    {licence.IsExternalLicence ?
                                        (
                                            <>
                                                <div className='search-licence-link link-margin-left' onClick={(e) => this.externalLink(e, licence.Url)}>
                                                    {getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_AGENCYWEBSITE)}</div>
                                            </>
                                        ) :
                                        ([
                                            this.displayCartButton(licence, isShowAddToCart, updateCart, currentCart),
                                            this.displayApplyButton(licence, getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY), isShowApply, updateCart, currentCart, MODAL_NAMES.EXCLUSIVECART)
                                        ])}
                                </div>
                            </div>
                        </div>
                        <SearchExculsiveLicenceModal
                            isOpen={this.state.modalState === MODAL_NAMES.EXCLUSIVECART}
                            closeCallBack={() => this.toggleModal(MODAL_NAMES.EXCLUSIVECART)}
                            contentHeader={getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_EXCLUSIVESERVICES)}
                            exclusiveCart={this.state.exclusiveCart}
                        />
                    </>
                )}
            </LicenceCartContext.Consumer>
        );
    }
}

LicenceItemResult.defaultProps = {
    customUpdateCartCallback: () => { /*default empty function */ }
};

export default withTranslation()(LicenceItemResult);
