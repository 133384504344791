//App Name
export const APP_NAME = "DSP";
export const APP_NAME_LICENCE_CONFIG = process.env.REACT_APP_APP_NAME_LICENCE_CONFIG;

const STAGING_ENV = 'staging';

// Const: Authentication configuration (isCorporateLogin or publicLogin)
const GET_AUTHORITY_CONFIG = (isCorporateLogin) => {
    return (
        {
            "authentication_settings": {
                "authority": process.env.REACT_APP_AUTHORITY_URL,
                "client_id": process.env.REACT_APP_CLIENT_ID,
                "redirect_uri": process.env.REACT_APP_AUTHORITY_REDIRECT_URL,
                "response_type": "id_token token",
                "scope": "openid profile role email userguid admin-web-core profile-web-core",
                "post_logout_redirect_uri": process.env.REACT_APP_AUTHORITY_POST_LOGOUT_REDIRECT_URL,
                "silent_redirect_uri": process.env.REACT_APP_AUTHORITY_RENEW_URL,
                "automaticSilentRenew": true,
                "revokeAccessTokenOnSignout": true,
                "extraQueryParams": { IsCorporateLogin: isCorporateLogin }
            },
            "authorisation_endpoints": {
                "administration": {
                    "base_url": process.env.REACT_APP_AUTHORITY_ADMINISTRATION_BASE_URL,
                    "permissions": "/Permission/GetPermissionsByRoleGuids?{0}"
                }
            }
        }
    )
}

// Const: Authentication configuration
export const AUTHORITY_CONFIG = {
    "openid_client_configuration": GET_AUTHORITY_CONFIG(false),
    "corppass_client_configuration": GET_AUTHORITY_CONFIG(true)
};


// Set IS_DEMO
export const IS_DEMO = false;

// Set true or false to check if is staging environment
// if true display everything else remove chatbot, changeID from the website
export const GET_APP_ENV = process.env.REACT_APP_ENV !== STAGING_ENV ? true : false;

// Server URL
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const HOME_URL = process.env.REACT_APP_HOME_URL;

// JSON URLs
const INBOX_JSON = `data/inbox.json`;
const ENQUIRY_JSON = `data/enquiry.json`;
const ENQUIRY_STATUS_FORMTYPE_JSON = `data/enquiry_statusformtypes.json`;
const NEW_JSON = `/data/request_form_details.json`;
const DRAFT_JSON = `/data/response_draft.json`;
const SUCCESS_JSON = `../../../data/response_create.json`;
const DELETE_JSON = `/data/request_delete.json`;
const LICENCE_LIST_JSON = 'data/licence_list.json';

// Page URLs
export const HOME_PATH = '/home';
export const ENQUIRY_PATH = '/enquiry';
export const INBOX_PATH = '/inbox';
export const FAQ_PATH = '/faq';
export const LOGIN_REDIRECT_PATH = '/inbox'; // this page needs to be a authentication required page
export const LICENCE_CART_PATH = '/licenceCart';
export const DASHBOARD_PATH = '/dashboard';
export const SERVICES_PATH = '/services';
export const AMEND_LICENCE_PATH = '/amendLicence';
export const CANCEL_LICENCE_PATH = '/cancelLicence';
export const APPLICATION_DETAILS_PATH = '/applicationDetails';
export const SEARCH_ALL_AGENCY_PATH = '/searchAllAgency';
export const SEARCH_ALL_INDUSTRY_PATH = '/searchAllIndustry';
export const SEARCH_ALL_LICENCE_PATH = '/searchAllLicence';
export const SEARCH_INTENT_PATH = '/searchIntent';
export const LOGIN_PATH = '/login';
export const BUSINESS_ADVISOR_PATH = '/businessAdvisor';
export const MAKE_PAYMENT_PATH = '/makePayment';
export const PAYMENT_SUCCESS_PATH = '/paymentSuccess';
export const OFFLINE_SUCCESS_PATH = '/services/devform/applySuccess';
export const APPLY_FORM_PATH = '/services/devform/start';
export const BUSINESS_DETAILS_PATH = '/businessDetails';
export const CITIZEN_DETAILS_PATH = '/citizenDetails';


// Commands URLs
export const INBOX_LIST_URL = (IS_DEMO) ? INBOX_JSON : `${SERVER_URL}Workflow/WorkflowInbox`;
export const ENQUIRY_LIST_URL = (IS_DEMO) ? ENQUIRY_JSON : `${SERVER_URL}SForm/FormList?`;
export const ENQUIRY_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}SForm/FormTypesAndStatus/`;
export const ENQUIRY_DELETE_URL = (IS_DEMO) ? DELETE_JSON : `${SERVER_URL}SForm/Form/`;
export const FORM_HISTORY_URL = `${SERVER_URL}SForm/FormHistoryList/`;
export const CREATE_FORM_URL = (IS_DEMO) ? SUCCESS_JSON : `${SERVER_URL}DynaForm/Form`;
export const DRAFT_SERVER_URL = (IS_DEMO) ? DRAFT_JSON : `${SERVER_URL}DynaForm/DraftForm`;
export const GET_FORM_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}DynaForm/DynaFormJson?`;
export const GET_DRAFT_FORM_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}DynaForm/DraftForm/`;
export const GET_FILE_URL = `${SERVER_URL}SFormDocument/File?`;
export const GET_LICENCE_DOCUMENT_FILE_URL = `${SERVER_URL}File/LicenceDocumentFile?`;
export const DELETE_FILE_URL = `${SERVER_URL}SFormDocument/File/`;
export const LICENCE_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}FormsEnquiry/LicenceList?`;
export const ALL_LICENCE_AGENCY_INDUSTRY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/SearchByAllCategory?`;
export const SEARCH_ALL_AGENCY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/SearchAgency?`;
export const AGENCY_LIST_BY_ID_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/AgencyLicence?`;
export const SEARCH_ALL_LICENCE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/SearchAllLicence?`;
export const SEARCH_LICENCE_BY_ID_LIST = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/SearchLicenceByIdList?`;
export const GET_ALL_AGENCY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/AllAgency?`;
export const GET_ALL_DOCUMENTS_LICENCES_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/GetAllDocuments?`;
export const GET_FEES_LICENCES_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Licence/GetLicenceFees?`;
export const GET_LICENCE_INBOX_ALL_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Licence/LicenceInboxAll/`;
export const GET_LICENCE_INBOX_RENEWAL_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Licence/LicenceInboxRenewal/`;
export const GET_APPLICATION_RFA_DETAILS_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}DynaForm/ApplicationRfa/`;
export const GET_APPLICATION_RFA_HISTORY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}DynaForm/RFAHistory/`;
export const GET_APPLICATION_AGENCY_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}DynaForm/ApplicationAgency/`;
export const GET_APPLICATION_FORM_DATA_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}DynaForm/ApplicationFormData/`;
export const GET_LICENCE_DETAILS_URL = `${SERVER_URL}Licence/LicenceDetails/`;
export const GET_LICENCE_DETAILS_FROM_SFORM_ID_URL = `${SERVER_URL}Licence/LicenceDetailsSformId/`;
export const GET_LICENCE_RENEWAL_DETAILS_URL = `${SERVER_URL}Licence/LicenceRenewalDetails/`;
export const GET_LICENCE_APPLICATION_HISTORY_URL = `${SERVER_URL}Licence/LicenceApplicationHistory/`;
export const GET_ADDITIONAL_DOCS_URL = `${SERVER_URL}DynaForm/RequiredFileUpload/`;
export const SUBMIT_RFA_RESPONSE_URL = `${SERVER_URL}DynaForm/SubmitRfa/`;
export const GET_PAYMENT_DETAILS_URL = `${SERVER_URL}Payment/PaymentDetails/`;
export const GET_SFORM_FORM_DATA_FROM_APP_TYPE_URL = `${SERVER_URL}DynaForm/GetSformFormDataFromAppType?`;
export const GET_RFA_SFORM_FORM_DATA_URL = `${SERVER_URL}DynaForm/GetRfaSformFormData/`;
export const AMEND_APPLICATION_URL = `${SERVER_URL}Licence/AmendApplication`;
export const AMEND_LICENCE_URL = `${SERVER_URL}Licence/AmendLicence`;
export const RENEW_AMEND_LICENCE_URL = `${SERVER_URL}Licence/RenewAmendLicence`;
export const GET_LICENCE_GI_DATA_URL = (IS_DEMO) ? SUCCESS_JSON : `${SERVER_URL}Licence/LicenceGeneralInfo`;
export const GET_APPLICATION_PENDING_PAYMENT_DATA_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}DynaForm/GetPendingPayment/`;
export const GET_CORRESPONDENCES_DETAILS_URL = `${SERVER_URL}Correspondence/CorrespondenceDetails/`;
export const GET_CANCELLATION_DATE_URL = `${SERVER_URL}Licence/MinimumCancellationDate/`;
export const CANCEL_LICENCE_URL = `${SERVER_URL}Licence/CancelLicence/`;

export const GET_APPLICATION_INBOX_SERVER_SIDE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}DynaForm/DynaFormInboxSearch`;
export const GET_APPLICATION_DRAFT_INBOX_SERVER_SIDE_URL = `${SERVER_URL}DynaForm/DraftFormInboxSearch`;
export const GET_APPLICATION_INBOX_COUNT_URL = `${SERVER_URL}DynaForm/DynaFormInboxCount`;

export const GET_LICENCE_INBOX_ALL_SERVER_SIDE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Licence/LicenceInboxAllSearch/`;
export const GET_LICENCE_INBOX_RENEWAL_ALL_SERVER_SIDE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Licence/LicenceInboxRenewalSearch/`;
export const GET_LICENCE_INBOX_COUNT_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Licence/LicenceInboxCount/`;

export const GET_ALL_INDUSTRY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/AllIndustry?`;
export const SEARCH_ALL_INDUSTRY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/SearchIndustry?`;
export const GET_SUB_INDUSTRY_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/SubIndustry?`;
export const GET_PAYMENT_REQUEST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/PaymentHub?`;
export const GET_PAYMENT_SUCCESS_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/PaymentSuccess?`;
export const GET_PAYMENT_RECEIPT_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/PaymentReceipt?`;
export const GET_MAKE_PAYMENT_DETAIL_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/MakePaymentSummary?`;
export const CHECK_PAYMENT_ORDERS_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/CheckOrdersAndPayment?`;

export const GET_BUSINESS_INTENT_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Enquiry/GetBusinessIntent?`;
export const GET_BUSINESS_INTENT_JSON_URL = `${SERVER_URL}Enquiry/GetBusinessIntentJson?intent=`;

export const PROFILE_URL = process.env.REACT_APP_SMART_ME_PROFILE_CLIENT_URL;
export const PROFILE_SERVER_URL = process.env.REACT_APP_SMART_ME_PROFILE_SERVER_URL;
export const GET_PROFILE_URL = `${PROFILE_SERVER_URL}User/GetUserByUserGuid/`;

export const DRAFT_ENQUIRY_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}SearchEnquiry/ApplicationDraftFilterParams/`;
export const APPLICATION_ENQUIRY_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}SearchEnquiry/ApplicationFilterParams/`;
export const LICENCE_ENQUIRY_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}SearchEnquiry/LicenceFilterParams/`;
export const CORRESPONDENCE_ENQUIRY_PARAMS_URL = `${SERVER_URL}SearchEnquiry/CorrespondenceFilterParams/`;
export const GET_CORRESPONDENCE_INBOX_SERVER_SIDE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Correspondence/CorrespondenceInboxSearch`;
export const GET_CORRESPONDENCE_INBOX_COUNT_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Correspondence/CorrespondenceInboxCount`;

//FAQ URL, (FaqGuid has to be hardcoded due to SMART core)
export const GET_FAQ_URL = `${SERVER_URL}Faqapi/Api/Faq?Application=${APP_NAME}&FaqGuid=750F9DE0-08DB-407A-BEB6-06D978DC61B1`;

//Document Generation URLs
export const DOC_BIRTH_URL = `${SERVER_URL}MuniDocument/GetBirthPDFDocument/`;
export const DOC_DEATH_URL = `${SERVER_URL}MuniDocument/GetDeathPDFDocument/`;
export const DOC_MARRIAGE_URL = `${SERVER_URL}MuniDocument/GetMarriagePDFDocument/`;

export const GET_OFFLINE_SUCCESS_URL = `${SERVER_URL}DynaForm/applySuccess?`;
export const UPDATE_ORDERS_PAYMENT_URL = `${SERVER_URL}Payment/UpdateFormOfflinePayment?`;

//Language Translation URLs
export const GET_TRANSLATION_URL = `${SERVER_URL}BlsLocaleApi/GetTranslation?language={{lng}}`;
export const GET_SUPPORTED_LANGUAGE_URL = `${SERVER_URL}BlsLocaleApi/GetSupportedLanguage`;

// Print licence
export const PRINT_LICENCE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Print/PrintLicence/`;

// Recommendation Engine
export const GET_LICENCE_RECOMMENDATION_URL = `${SERVER_URL}Recommendation/Licence?`;

//ChatBot Tokens
export const CHAT_BOT_SECRET = process.env.REACT_APP_CHAT_BOT_SECRET;
export const CHAT_BOT_SPEECH_KEY = process.env.REACT_APP_CHAT_BOT_SPEECH_KEY;
export const CHAT_BOT_SPEECH_REGION = process.env.REACT_APP_CHAT_BOT_SPEECH_REGION;
export const CHAT_BOT_TOKEN_END_POINT = `https://${CHAT_BOT_SPEECH_REGION}.api.cognitive.microsoft.com/sts/v1.0/issuetoken`;
// Const: Default timeout for all loader implementations
export const TIMEOUT_LOADER = 60000;

// Const: Display format for date
export const DATE_DISPLAY_FORMAT = {
    DATE_TIME: "DD-MMM-YYYY HH:mm A",
    TIME: "hh:mm A",
    DATE: "DD-MMM-YYYY"
}

// Const: Default period ago for Enquiry page
export const DEFAULT_ENQUIRY_MONTHS_AGO = 1;

// Const: Status Badge Color configuration
export const STATUS_BADGE_COLOR = {
    /* green */ success: ["Approved", "Application Accepted", "Completed"],
    /* amber */ warning: ["Review", "Processing", "Pending Approval Officer", "Pending Verification Officer", "Pending Review Officer", "Pending User Action"],
    /* red   */ danger: ["Rejected", "Application Rejected"],
    /* grey  */ normal: ["Draft"]
}

// Const: Regular Expressions for Validation
export const REGEX = {
    EMAIL: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,})$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    TELEPHONE: /^(\+[0-9]{1,3})?(\s)?([0-9 ]*)$/,
    NUMBER: /^[1-9]\d*(\.\d+)?$/,
    DATE: /^(([0-9])|([0-2][0-9])|([3][0-1]))-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/
};

// Const: Regular Expressions for Masked inputs
// Format: Array of Regex for each character
export const MASK = {
    TELEPHONE: ['+', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
};

// Const: Actions in React-Grid / Tables
export const ROW_ACTIONS = {
    VIEW: "VIEW",
    EDIT: "EDIT",
    DELETE: "DELETE"
}

// Const: Form Type for Birth Certificate forms
export const FORM_TYPE = {
    NEW: 'New',
    PREVIEW: 'Preview',
    ENQUIRY_DETAILS: 'Enquiry Details'
}

// Const: Layout Type for each page
export const LAYOUT_TYPE = {
    NAVBAR_DESKTOP: 2,      // 1 = vertical, 2 = horizontal
    NAVBAR_MOBILE: 2,       // 1 = hamburger, 2 = bottom buttons
    FORM: 1,                // 1 = full page, 2 = floating window
    FORM_CONTENT: 3       // 1 = tab, 2 = accordion, 3 = wizard
}

// Const: SMART Me URL
export const SMART_ME_PROFILE_SERVER_URL = process.env.REACT_APP_SMART_ME_PROFILE_SERVER_URL;
export const SMART_ME_PROFILE_CLIENT_URL = process.env.REACT_APP_SMART_ME_PROFILE_CLIENT_URL;
export const SMART_ME_IDENTITY_CLIENT_URL = process.env.REACT_APP_SMART_ME_IDENTITY_CLIENT_URL;
export const CHANGE_USERNAME_URL = SMART_ME_IDENTITY_CLIENT_URL + "Account/ChangeUsername";
export const CHANGE_PASSWORD_URL = SMART_ME_IDENTITY_CLIENT_URL + "Account/ChangePassword";
export const CHANGE_SECURITY_INFO_URL = SMART_ME_IDENTITY_CLIENT_URL + "Account/ChangeSecurityInfo";


// General Information Constants
export const DEFAULT_HOME_COUNTRY = 'Singapore';
export const DEFAULT_HOME_COUNTRY_ID_TYPE = 'NRIC';
export const DEFAULT_OVERSEAS_COUNTRY_ID_TYPE = 'Passport';
// Const: Address Details
export const ADDRESS_TYPE = {
    LOCAL: 'Local',
    OVERSEAS: 'Overseas'
}

const ADDRESS_FIELDS = {
    LINE1: {
        AddressLine1: '',
        BlockNo: '',
        StreetName: ''
    },
    LINE2: {
        AddressLine2: '',
        LevelNo: '',
        UnitNo: '',
        BuildingName: ''
    }
}

export const DEFAULT_ADDRESS_TYPE = ADDRESS_TYPE.LOCAL;

export const ADDRESS = {
    ADDRESS_TYPE: ADDRESS_TYPE,
    ADDRESS_FIELDS: ADDRESS_FIELDS
}
export const PAYMENT_OPTIONS = {
    ONLINE: "Online",
    OFFLINE: "Offline"
}

// Code Values
export const GET_CODE_BY_CODE_CATEGORY = `${SERVER_URL}Code/GetCodes`;

// Application Configuration Values
export const GET_APPLICATION_CONFIGURATION_BY_CATEGORY = `${SERVER_URL}ApplicationConfiguration/ListByCategory`;

//Default Payment Option
export const DEFAULT_PAYMENT_OPTION = "Online";

// General Information Form Type
export const GENERAL_INFORMATION_VALIDFORMTYPE = "GeneralInformation";
export const GENERAL_INFORMATION_FORMTYPE = {
    INDIVIDUAL: "GeneralInformation",
    CORPORATE: "GeneralInformation_Corporate"
}

// Business Advisor Question Categories
export const BIZ_ADVISOR_CAT = {
    "Premise": {
        CSS: "premise"
    },
    "Business services": {
        CSS: "business-serv"
    },
    "Business operations": {
        CSS: "business-op"
    }
};

// Const: Table Layout
export const TABLE_LAYOUT = {
    COLUMNS: 3
};

export const SESSIONSTORAGE_IS_LOGIN = 'sessionStorage-isLogin.' + process.env.REACT_APP_CLIENT_ID + '.' + process.env.REACT_APP_HOME_URL;
export const LOCALSTORAGE_IS_LOGIN = 'localStorage-isLogin.' + process.env.REACT_APP_CLIENT_ID + '.' + process.env.REACT_APP_HOME_URL;


//Static Site Content
export const SITE_ABBREVIATION = 'BL';
export const SITE_NAME = 'CrimsonLogic - Coherence Digital Services Platform (Public Site)';
export const SITE_FAVICON =  process.env.PUBLIC_URL+'/favicon.ico';
export const SITE_LOGO = process.env.PUBLIC_URL + '/images/logo.png';
export const SITE_LOGO_ALT_TEXT = `${SITE_NAME}`;
export const FOOTER_LOGO = process.env.PUBLIC_URL+ '/images/logo-footer.png';
export const FOOTER_LOGO_ALT_TEXT = '';
export const CURRENT_YEAR = (new Date()).getFullYear();
export const COPYRIGHT_SYMBOL = "\u00A9";
export const COPYRIGHT_TEXT = `${COPYRIGHT_SYMBOL}${CURRENT_YEAR} Coherence Digital Services Platform`;
export const COPYRIGHT_TEXT_V2 = `${COPYRIGHT_SYMBOL}${CURRENT_YEAR} CrimsonLogic Pte Ltd. All Rights Reserved`;

//Formio form
export const FORMIO_DEFAULT = 'http://localhost:3050/default1'; 
export const FORMIO_LOCAL_EDU = 'http://localhost:3050/registrationofprivateeducation';
export const FORMIO_EDU = 'https://uivzixschhxgbph.form.io/registrationofprivateeducation';