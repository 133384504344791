import React from 'react';
import { Button } from 'reactstrap';
import { ADDRESS, DATE_DISPLAY_FORMAT, DEFAULT_HOME_COUNTRY, DEFAULT_OVERSEAS_COUNTRY_ID_TYPE } from '../../../App/AppSettings';
import { FIELD_SIZE, OPTION_DATA_CODE_CATEGORY } from '../../../Shared/Constants';
import { AccordionContainer, Text } from '../../../Shared/Forms';
import DateInput from '../../../Shared/Forms/DateInput';
import Radio from '../../../Shared/Forms/Radio';
import SelectList from '../../../Shared/Forms/SelectList';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';


const SECTION_NAMES = {
    APPLICANT_PARTICULARS: { title: "Applicant's Particulars", status: true },
    APPLICANT_ADDRESS: { title: "Applicant's Address", status: true },
    MAILING_ADDRESS: { title: 'Mailing Address', status: true }
}

class IndividualGI extends React.Component {

    //used to copy Individial GI address to mailing address
    copyAddressToMailingAddress = (onChangeField, values) => {
        const vgi = values.GeneralInformation;
        onChangeField('GeneralInformation.GI_MailingAddressAddressType', vgi.GI_AddressAddressType);
        onChangeField('GeneralInformation.GI_MailingAddressTypeOfPremises', vgi.GI_AddressTypeOfPremises);
        onChangeField('GeneralInformation.GI_MailingAddressPostalCode', vgi.GI_AddressPostalCode);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1BlockNo', vgi.GI_AddressAddressLine1BlockNo);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1StreetName', vgi.GI_AddressAddressLine1StreetName);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2LevelNo', vgi.GI_AddressAddressLine2LevelNo);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2UnitNo', vgi.GI_AddressAddressLine2UnitNo);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2BuildingName', vgi.GI_AddressAddressLine2BuildingName);
        onChangeField('GeneralInformation.GI_MailingAddressCountry', vgi.GI_AddressCountry);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1AddressLine1', vgi.GI_AddressAddressLine1AddressLine1);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2AddressLine2', vgi.GI_AddressAddressLine2AddressLine2);
        onChangeField('GeneralInformation.GI_MailingAddressCity', vgi.GI_AddressCity);
        onChangeField('GeneralInformation.GI_MailingAddressState', vgi.GI_AddressState);
    };

    render() {
        const { values, onChange, onChangeField, errors, toggleSection, toggleAllSections } = this.props.smartFormValues;
        const { state, t } = this.props;
        
        return (
            <>
                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}</div>
                <div className="collapse-expand">
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(SECTION_NAMES, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(SECTION_NAMES, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                </div>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantParticulars'))}
                    active={SECTION_NAMES.APPLICANT_PARTICULARS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <SelectList
                            name="GeneralInformation.GI_Salutation"
                            value={values.GeneralInformation.GI_Salutation}
                            options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.SALUTATION]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Salutation'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Salutation'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Salutation}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_Name"
                            value={values.GeneralInformation.GI_Name}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Name'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Name'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Name}
                            required
                            readOnly
                        />
                        <SelectList
                            name="GeneralInformation.GI_IDtype"
                            value={values.GeneralInformation.GI_IDtype}
                            options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.ID_TYPE]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_IDtype'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDtype'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_IDtype}
                            required
                            isDisabled
                        />

                        <Text
                            name="GeneralInformation.GI_IDnumber"
                            value={values.GeneralInformation.GI_IDnumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_IDnumber'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDnumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_IDnumber}
                            required
                            readOnly
                        />
                        {values.GeneralInformation.GI_IDtype === DEFAULT_OVERSEAS_COUNTRY_ID_TYPE &&
                            <SelectList
                                name="GeneralInformation.GI_Passportissuingcountry"
                                value={values.GeneralInformation.GI_Passportissuingcountry}
                                options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY].filter(function (value) {
                                    return value.value !== DEFAULT_HOME_COUNTRY;
                                })}
                                onChangeField={onChangeField}
                                placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Passportissuingcountry'))}
                                isMulti={false}
                                labelSize={FIELD_SIZE.MEDIUM}
                                inputSize={FIELD_SIZE.MEDIUM}
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Passportissuingcountry'))}
                                error={errors.GeneralInformation && errors.GeneralInformation.GI_Passportissuingcountry}
                                required
                                isDisabled
                            />
                        }
                        <SelectList
                            name="GeneralInformation.GI_Nationality"
                            value={values.GeneralInformation.GI_Nationality}
                            options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Nationality'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Nationality'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Nationality}
                            required
                            isDisabled
                        />
                        <Radio
                            name="GeneralInformation.GI_Gender"
                            value={values.GeneralInformation.GI_Gender}
                            options={[
                                { label: "Male", value: "Male", key: "Male" },
                                { label: "Female", value: "Female", key: "Female" }
                            ]}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Gender'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Gender}
                            required
                            disabled
                        />
                        <DateInput
                            name="GeneralInformation.GI_DateOfBirth"
                            value={values.GeneralInformation.GI_DateOfBirth}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_DateOfBirth'))}
                            onChangeField={onChangeField}
                            time={false}
                            date={true}
                            dropUp={true}
                            min="1900-01-01"
                            max={state.todayDate}
                            format={DATE_DISPLAY_FORMAT.DATE}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_DateOfBirth'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_DateOfBirth}
                            required
                            disabled
                        />
                        <Radio
                            name="GeneralInformation.GI_PrimaryContact"
                            value={values.GeneralInformation.GI_PrimaryContact}
                            options={[
                                { label: "Mobile Number", value: "Mobile" },
                                { label: "Office Number", value: "Office" },
                                { label: "Home Number", value: "Home" }]}
                            onChange={onChange}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_PrimaryContact'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_PrimaryContact'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_PrimaryContact}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_MobileNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MobileNumber'))}
                            value={values.GeneralInformation.GI_MobileNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MobileNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MobileNumber}
                            required={values.GeneralInformation.GI_PrimaryContact === 'Mobile'}
                        />
                        <Text
                            name="GeneralInformation.GI_OfficeNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_OfficeNumber'))}
                            value={values.GeneralInformation.GI_OfficeNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_OfficeNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_OfficeNumber}
                            required={values.GeneralInformation.GI_PrimaryContact === 'Office'}
                        />
                        <Text
                            name="GeneralInformation.GI_HomeNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_HomeNumber'))}
                            value={values.GeneralInformation.GI_HomeNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_HomeNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_HomeNumber}
                            required={values.GeneralInformation.GI_PrimaryContact === 'Home'}
                        />
                        <Text
                            name="GeneralInformation.GI_FaxNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_FaxNumber'))}
                            value={values.GeneralInformation.GI_FaxNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_FaxNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_FaxNumber}
                        />
                        <SelectList
                            name="GeneralInformation.GI_Designation"
                            value={values.GeneralInformation.GI_Designation}
                            options={[
                                { label: "Managing Director", value: "Managing Director" },
                                { label: "Manager", value: "Manager" },
                                { label: "Officer", value: "Officer" },
                                { label: "Secretary", value: "Secretary" },
                                { label: "Director", value: "Director" },
                                { label: "General Manager", value: "General Manager" },
                                { label: "Shareholder", value: "Shareholder" },
                                { label: "Director and shareholder", value: "Director and shareholder" },
                                { label: "Auditor", value: "Auditor" },
                                { label: "Agent", value: "Agent" },
                                { label: "Others", value: "Others" }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Designation'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Designation'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Designation}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_Email"
                            value={values.GeneralInformation.GI_Email}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Email'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Email'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Email}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_AltEmailAddress"
                            value={values.GeneralInformation.GI_AltEmailAddress}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AltEmailAddress'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AltEmailAddress'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AltEmailAddress}
                        />
                    </div>
                </AccordionContainer>

                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantAddress'))}
                    active={SECTION_NAMES.APPLICANT_ADDRESS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <Radio
                            name="GeneralInformation.GI_AddressAddressType"
                            value={values.GeneralInformation.GI_AddressAddressType}
                            options={state.addressTypeOptions}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressType'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressType}
                            required
                        />
                        <SelectList
                            name="GeneralInformation.GI_AddressTypeOfPremises"
                            value={values.GeneralInformation.GI_AddressTypeOfPremises}
                            options={[
                                { label: "Residential", value: "Residential" },
                                { label: "Office", value: "Office" },
                                { label: "Others", value: "Others" }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressTypeOfPremises'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressTypeOfPremises'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressTypeOfPremises}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_AddressPostalCode"
                            value={values.GeneralInformation.GI_AddressPostalCode}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressPostalCode'))}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressPostalCode'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressPostalCode}
                            required
                        />
                        {values.GeneralInformation.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                            <>
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine1BlockNo"
                                    value={values.GeneralInformation.GI_AddressAddressLine1BlockNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine1BlockNo}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine1StreetName"
                                    value={values.GeneralInformation.GI_AddressAddressLine1StreetName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine1StreetName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1StreetName'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine1StreetName}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2LevelNo"
                                    value={values.GeneralInformation.GI_AddressAddressLine2LevelNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                    />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2UnitNo"
                                    value={values.GeneralInformation.GI_AddressAddressLine2UnitNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2BuildingName"
                                    value={values.GeneralInformation.GI_AddressAddressLine2BuildingName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                />
                            </>
                        }
                        {values.GeneralInformation.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                            <>
                                <SelectList
                                    name="GeneralInformation.GI_AddressCountry"
                                    value={values.GeneralInformation.GI_AddressCountry}
                                    options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY].filter(country => {
                                        return country.value !== DEFAULT_HOME_COUNTRY
                                    })}
                                    onChangeField={onChangeField}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressCountry'))}
                                    isMulti={false}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCountry'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressCountry}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine1AddressLine1"
                                    value={values.GeneralInformation.GI_AddressAddressLine1AddressLine1}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                    // helpLabel="Address Line 1"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine1AddressLine1}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2AddressLine2"
                                    value={values.GeneralInformation.GI_AddressAddressLine2AddressLine2}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                    // helpLabel="Address Line 2"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine2AddressLine2}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressCity"
                                    value={values.GeneralInformation.GI_AddressCity}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressCity'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCity'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressCity}
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressState"
                                    value={values.GeneralInformation.GI_AddressState}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressState'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressState'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressState}
                                    required
                                />
                            </>
                        }
                    </div>
                </AccordionContainer>

                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_MailingAddress'))}
                    active={SECTION_NAMES.MAILING_ADDRESS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <div className="button-mail">
                            <Button  color="mailing" size="sm" type="button" onClick={() => this.copyAddressToMailingAddress(onChangeField, values)}>Same as above</Button>
                        </div>
                        <Radio
                            name="GeneralInformation.GI_MailingAddressAddressType"
                            value={values.GeneralInformation.GI_MailingAddressAddressType}
                            options={state.addressTypeOptions}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressType'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressType}
                            required
                        />
                        <SelectList
                            name="GeneralInformation.GI_MailingAddressTypeOfPremises"
                            value={values.GeneralInformation.GI_MailingAddressTypeOfPremises}
                            options={[
                                { label: "Residential", value: "Residential" },
                                { label: "Office", value: "Office" },
                                { label: "Retail outlet", value: "Retail outlet" },
                                { label: "Show room", value: "Show room" },
                                { label: "Warehouse", value: "Warehouse" },
                                { label: "Others", value: "Others" }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressTypeOfPremises'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressTypeOfPremises'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressTypeOfPremises}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_MailingAddressPostalCode"
                            value={values.GeneralInformation.GI_MailingAddressPostalCode}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressPostalCode'))}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressPostalCode'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressPostalCode}
                            required
                        />
                        {values.GeneralInformation.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                            <>
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine1BlockNo"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine1BlockNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine1BlockNo}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine1StreetName"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine1StreetName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine1StreetName}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2LevelNo"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2LevelNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                    />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2UnitNo"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2UnitNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2BuildingName"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2BuildingName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                />
                            </>
                        }
                        {values.GeneralInformation.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                            <>
                                <SelectList
                                    name="GeneralInformation.GI_MailingAddressCountry"
                                    value={values.GeneralInformation.GI_MailingAddressCountry}
                                    options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY].filter(country => {
                                        return country.value !== DEFAULT_HOME_COUNTRY
                                    })}
                                    onChangeField={onChangeField}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressCountry'))}
                                    isMulti={false}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCountry'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressCountry}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine1AddressLine1"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine1AddressLine1}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                    // helpLabel="Address Line 1"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine1AddressLine1}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2AddressLine2"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2AddressLine2}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                    // helpLabel="Address Line 2"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine2AddressLine2}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressCity"
                                    value={values.GeneralInformation.GI_MailingAddressCity}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressCity'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCity'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressCity}
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressState"
                                    value={values.GeneralInformation.GI_MailingAddressState}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressState'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressState'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressState}
                                    required
                                />
                            </>
                        }
                    </div>
                </AccordionContainer>
            </>
        );
    }
}

export default withTranslation()(IndividualGI);
