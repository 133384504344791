import { ADDRESS, DEFAULT_ADDRESS_TYPE } from '../../../App/AppSettings';

export const getAddressTypeOptions = () => {
    const addressTypes = ADDRESS.ADDRESS_TYPE;
    const addressTypesOptions = [];

    Object.keys(addressTypes).forEach(function (key) {
        addressTypesOptions.push({
            value: addressTypes[key],
            label: addressTypes[key]
        })
    });

    return addressTypesOptions;
}

export const setGeneralInfoValues = (Data, isCorporateProfile, corporateUEN) => {
    const profileData = Data.Profile;
    const middleName = profileData.MiddleName;
    const idNumbers = JSON.parse(profileData.IdNumbers);
    let generalInformation = {
        GI_Email: Data.Email,
        GI_Name: profileData.FirstName + ((middleName === '' || middleName === 'null' || middleName === null) ? '' : ` ${middleName} ${profileData.LastName}`),
        GI_DateOfBirth: profileData.DateOfBirth,
        GI_Gender: profileData.Gender,
        GI_Salutation: profileData.Salutation === null ? '' : profileData.Salutation,
        GI_Nationality: profileData.Nationality,
        GI_Designation: '',
        //assume only 1 ID
        GI_IDtype: idNumbers[0].IdType,
        //assume only 1 ID
        GI_IDnumber: idNumbers[0].IdNumber,
        //assume only 1 ID
        GI_Passportissuingcountry: idNumbers[0].IdCountry,
        GI_AltEmailAddress: profileData.AltEmailAddress === null ? '' : profileData.AltEmailAddress,
        GI_PrimaryContact: profileData.PrimaryContact,
        GI_MobileNumber: profileData.MobileNumber ?
            (profileData.MobileNumber.CountryCode === null ? '' : profileData.MobileNumber.CountryCode +
                profileData.MobileNumber.Number === null ? '' : profileData.MobileNumber.Number) : '',
        GI_OfficeNumber: profileData.OfficeNumber ?
            (profileData.OfficeNumber.CountryCode === null ? '' : profileData.OfficeNumber.CountryCode +
                profileData.OfficeNumber.Number === null ? '' : profileData.OfficeNumber.Number) : '',
        GI_HomeNumber: profileData.HomeNumber ?
            (profileData.HomeNumber.CountryCode === null ? '' : profileData.HomeNumber.CountryCode +
                profileData.HomeNumber.Number === null ? '' : profileData.HomeNumber.Number) : '',
        GI_FaxNumber: profileData.FaxNumber ?
            (profileData.FaxNumber.CountryCode === null ? '' : profileData.FaxNumber.CountryCode +
                profileData.FaxNumber.Number === null ? '' : profileData.FaxNumber.Number) : '',
        GI_AddressAddressType: profileData.Address && profileData.Address.AddressType ? profileData.Address.AddressType : '',
        GI_AddressTypeOfPremises: '',
        GI_AddressPostalCode: profileData.Address && profileData.Address.PostalCode ? profileData.Address.PostalCode : '',
        // Only applicable to Address Type: Overseas
        GI_AddressCountry: profileData.Address && profileData.Address.Country ? profileData.Address.Country : '',
        GI_AddressCity: profileData.Address && profileData.Address.City ? profileData.Address.City : '',
        GI_AddressState: profileData.Address && profileData.Address.State ? profileData.Address.State : '',
        GI_AddressAddressLine1AddressLine1: profileData.Address && profileData.Address.AddressLine1 && profileData.Address.AddressLine1.AddressLine1 ?
            profileData.Address.AddressLine1.AddressLine1 : '',
        GI_AddressAddressLine1BlockNo: profileData.Address && profileData.Address.AddressLine1 && profileData.Address.AddressLine1.BlockNo ?
            profileData.Address.AddressLine1.BlockNo : '',
        GI_AddressAddressLine1StreetName: profileData.Address && profileData.Address.AddressLine1 && profileData.Address.AddressLine1.StreetName ?
            profileData.Address.AddressLine1.StreetName : '',
        GI_AddressAddressLine2AddressLine2: profileData.Address && profileData.Address.AddressLine2 && profileData.Address.AddressLine2.AddressLine2 ?
            profileData.Address.AddressLine2.AddressLine2 : '',
        GI_AddressAddressLine2LevelNo: profileData.Address && profileData.Address.AddressLine2 && profileData.Address.AddressLine2.LevelNo ?
            profileData.Address.AddressLine2.LevelNo : '',
        GI_AddressAddressLine2UnitNo: profileData.Address && profileData.Address.AddressLine2 && profileData.Address.AddressLine2.UnitNo ?
            profileData.Address.AddressLine2.UnitNo : '',
        GI_AddressAddressLine2BuildingName: profileData.Address && profileData.Address.AddressLine2 && profileData.Address.AddressLine2.BuildingName ?
            profileData.Address.AddressLine2.BuildingName : '',
        GI_MailingAddressAddressType: DEFAULT_ADDRESS_TYPE,
        GI_MailingAddressPostalCode: '',
        GI_MailingAddressTypeOfPremises: '',
        // Only applicable to Address Type: Overseas
        GI_MailingAddressCountry: '',
        GI_MailingAddressCity: '',
        GI_MailingAddressState: '',
        GI_MailingAddressAddressLine1AddressLine1: '',
        GI_MailingAddressAddressLine1BlockNo: '',
        GI_MailingAddressAddressLine1StreetName: '',
        GI_MailingAddressAddressLine2AddressLine2: '',
        GI_MailingAddressAddressLine2LevelNo: '',
        GI_MailingAddressAddressLine2UnitNo: '',
        GI_MailingAddressAddressLine2BuildingName: '',
    }
    if (isCorporateProfile) {
        generalInformation = {
            ...generalInformation,
            // TODO: not sure is uen will come from profile or token, to change accordingly. this is the only additional autofill field *CorporateGI_TODO*
            GI_EntitydetailsUEN: corporateUEN === null ? '' : corporateUEN,
            GI_EntitydetailsEntityname: '',
            GI_EntitydetailsOrganisationType: '',
            GI_EntityregisteredaddressTypeofpremise: '',
            GI_EntityregisteredaddressPostalcode: '',
            GI_EntityregisteredaddressBlockHousenumber: '',
            GI_EntityregisteredaddressStreetname: '',
            GI_EntityregisteredaddressLevel: '',
            GI_EntityregisteredaddressUnitNumber: '',
            GI_EntityregisteredaddressBuildingName: '',
            GI_EntityoperatingaddressTypeofpremise: '',
            GI_EntityoperatingaddressPostalcode: '',
            GI_EntityoperatingaddressBlockHousenumber: '',
            GI_EntityoperatingaddressStreetname: '',
            GI_EntityoperatingaddressLevel: '',
            GI_EntityoperatingaddressUnitNumber: '',
            GI_EntityoperatingaddressBuildingName: '',
            GI_EntitycontactdetailsTelephone: '',
            GI_EntitycontactdetailsFaxnumber: '',
            GI_EntitycontactdetailsBusinessemail: ''
        }
    }
    return generalInformation;
}