import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Input } from 'reactstrap';
import { withBaseControl } from './BaseControl';


class Badges extends React.Component {

    render() {
        const multiselectArray = this.mapValueToArray();
        var badgesComponent = "-";
        if (!(multiselectArray === null || multiselectArray.constructor !== Array || multiselectArray.length < 1)) {
            badgesComponent = (multiselectArray.map((item) => <Badge key={item}>{ this.props.isUpper ? item.toUpperCase() : item }</Badge>))
        }
        badgesComponent = <Input plaintext className="plaintext">{badgesComponent}</Input>

        return badgesComponent              
    }

    mapValueToArray = () => {
        let { value, delimiter } = this.props;

        if(delimiter && !Array.isArray(value)) {
            return value.split(delimiter);
        }
        return value;
    }
}

// PropTypes: For prop validation
Badges.propTypes = {
    label: PropTypes.string.isRequired,
    isFile: PropTypes.bool,
    isUpper: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    delimiter: PropTypes.string
};

// PropTypes: Defaulting value for optional props
Badges.defaultProps = {
    isFile: false,
    value: []
};


export default withBaseControl(Badges);