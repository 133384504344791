import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../../Content/DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../Constants/LanguageKeys';

class SearchExculsiveLicenceModal extends React.Component {

    render() {
        const { t, className, closeCallBack, contentHeader, exclusiveCart, isOpen } = this.props;

        return (
            <div>
                <Modal size='lg' isOpen={isOpen} className={`modal-content ${className}`}>
                    <ModalHeader toggle={closeCallBack} charCode='&#x1F5D9;'>{contentHeader}</ModalHeader>
                    <ModalBody>
                        <div className="div-modal-body">
                            <p>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_EXCLUSIVESERVICEMESSAGE)}</p>
                            <p className="label-error">
                                {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_PLEASE)} <u>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_REMOVEINLOWERCASE)}</u> {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_REMOVESERVICEMESSAGE)}
                            </p>
                        </div>
                        {exclusiveCart.length > 0 && exclusiveCart.map((ec, index) => {
                            return (
                                <div key={index}>
                                    <p><u>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_EXCLUSIVELICENCESSET)}</u></p>
                                    <div key={index} className="div-exclusive-licence-set">
                                        {ec[index].map((licence, eindex) => {
                                            const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);
                                            return (
                                                <div key={eindex}>
                                                    {
                                                        // Add only the licence name if is the first licence
                                                        eindex % 2 === 0 &&
                                                        <h5>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                                                    }
                                                    {
                                                        // Check if it is the second licence of the set.
                                                        // Add div in the middle to seperate the first licence and second licence
                                                        eindex % 2 === 1 &&
                                                        <>
                                                            <div className="div-exclusive-licence-divider">
                                                                <span className="div-exclusive-licence-centre-box">
                                                                    {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_MANDATORY_CANNOTBEAPPLIEDTOGETHER)}
                                                                </span>
                                                            </div>
                                                            <h5>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                                                        </>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='primary'
                            onClick={closeCallBack}>
                            {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_CLOSE)}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }
}

// PropTypes: For prop validation
SearchExculsiveLicenceModal.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    //contentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeCallBack: PropTypes.func,
    addToCartCallBack: PropTypes.func,
    applyCallBack: PropTypes.func,
    isShowAddCart: PropTypes.bool,
    isShowApply: PropTypes.bool,
    errorMessage: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
SearchExculsiveLicenceModal.defaultProps = {
    isOpen: false
};


export default withTranslation()(withRouter(SearchExculsiveLicenceModal));
