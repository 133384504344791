import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, getIn } from 'formik';
import React from 'react';
import { Button, Label, Table } from 'reactstrap';
import { SMARTFormContext, SMARTSectionContext } from '../../../Shared/Forms/SMARTContext';
import RenderTableFieldComponent from '../RenderTableFieldComponent';

const withSMARTContext = WrappedComponent => {
    class SMARTContext extends React.Component {
        render() {
            return (
                <SMARTFormContext.Consumer>
                    {updateControlList => (
                        <SMARTSectionContext.Consumer>
                            {sectionName => (
                                <WrappedComponent
                                    context={{
                                        updateControlList,
                                        sectionName
                                    }}
                                    {...this.props}
                                />
                            )}
                        </SMARTSectionContext.Consumer>
                    )}
                </SMARTFormContext.Consumer>
            );
        }
    }

    return SMARTContext;
};

class SmallTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    // Lifecycle: Called once when component is mounted
    componentDidMount() {
        // Get context
        const { updateControlList, sectionName } = this.props.context;
        const { formType, formSection } = this.props;

        // Control calls callback to inform its parent SMARTForm their existence
        updateControlList && updateControlList(`${formType}.${formSection.Name}`, sectionName);
    }

    // Set state to true if Maximum Row reached
    maximumErrorMessage = maximumRows => {
        this.setState({
            showMaximumRows: maximumRows
        });
    }

    render() {
        const { values, onChangeField, onChange, errors } = this.props.smartFormValues;
        const { formType, formSection, optionDataList } = this.props;
        const newRow = {};

        return (
            <FieldArray
                name={`${formType}.${formSection.Name}`}
                render={arrayHelpers => (
                    <div>
                        <div className="table-add-row">
                            <Button
                                color="neutral" size="md"
                                type="button"
                                className="generic-table-add"
                                onClick={
                                    // Check table row, if table maximum row not reached, user can continue to add row
                                    (values[formType][formSection.Name] && values[formType][formSection.Name].length !== formSection.Table.MaximumTableRows)
                                        || formSection.Table.MaximumTableRows === undefined
                                        || formSection.Table.MaximumTableRows === 0
                                        ? () => arrayHelpers.push(newRow)
                                        : () => this.maximumErrorMessage(true)
                                }
                                disabled={this.state.showMaximumRows ? true : false}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add
                            </Button>
                        </div>
                        <div className="table-requirement-label">
                            {/* Display label for min & max row */}
                            <Label>
                                {formSection.Table.MinimumTableRows ?
                                    <p>Require at least {formSection.Table.MinimumTableRows} record(s)</p>
                                    : null
                                }
                                {formSection.Table.MaximumTableRows ?
                                    <p>Maximum of {formSection.Table.MaximumTableRows} record(s)</p>
                                    : null
                                }
                            </Label>
                            <br />
                            {
                                !Array.isArray(getIn(errors, `${formType}.${formSection.Name}`))
                                && getIn(errors, `${formType}.${formSection.Name}`) !== ''
                                && getIn(errors, `${formType}.${formSection.Name}`) !== undefined
                                && <Label className="required label-error">
                                    {
                                        getIn(errors, `${formType}.${formSection.Name}`)
                                    }
                                </Label>
                            }
                            {
                                this.state.showMaximumRows ?
                                    <Label className="required label-error">
                                        Maximum quota hit: Maximum {formSection.Table.MaximumTableRows} record(s)
                                                                </Label>
                                    : null
                            }
                        </div>
                        <Table className="griddle-table" >
                            <thead className="griddle-table-heading">
                                <tr>
                                    {
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            newRow[field.Name] = '';
                                            if (field.Validation.Required === true) {
                                                return (
                                                    <th key={findex} className="griddle-table-heading-cell required">
                                                        <span>{field.DisplayName}</span>
                                                    </th>
                                                );
                                            }
                                            else {
                                                return (
                                                    <th key={findex} className="griddle-table-heading-cell">
                                                        <span>{field.DisplayName}</span>
                                                    </th>
                                                );
                                            }
                                        })
                                    }
                                    <th className="griddle-table-heading-cell">Action</th>
                                </tr>
                            </thead>
                            <tbody className="griddle-table-body">
                                {values[formType][formSection.Name] && values[formType][formSection.Name].map((row, rindex) => {
                                    return (
                                        <tr key={rindex} className="griddle-row">
                                            {
                                                formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                                    return (
                                                        <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                            <RenderTableFieldComponent
                                                                optionDataList={optionDataList}
                                                                sectionName={formSection.Name}
                                                                rowIndex={rindex}
                                                                name={field.Name}
                                                                field={field}
                                                                formType={formType}
                                                                values={values}
                                                                onChange={onChange}
                                                                onChangeField={onChangeField}
                                                                errors={errors}
                                                                helpLabel={field.HelpText}
                                                            />
                                                        </td>
                                                    );
                                                })
                                            }
                                            <td className="griddle-cell table-griddle-cell">
                                                <div className="action-button-group table-button">
                                                    <Button
                                                        className="action-btn"
                                                        color="hazard"
                                                        type="button"
                                                        onClick={() => {
                                                            arrayHelpers.remove(rindex);
                                                            this.maximumErrorMessage(false);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                )}
            />
        );
    }
}
export default withSMARTContext(SmallTable);
