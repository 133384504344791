import React from "react";
import { withRouter } from "react-router-dom";
import {
  HOME_PATH,
  LOGIN_PATH,
  SITE_LOGO,
  SITE_LOGO_ALT_TEXT,
} from "../App/AppSettings";
import LicenceCartModal from "../Content/LicenceCart/LicenceCartModal";
import { navigateTo } from "../Shared/Actions";
import ItemResult from "../Shared/SearchLicence/LicenceItemResult";
import { LANGUAGE_KEYS } from "../Shared/Constants/LanguageKeys";
import { withTranslation } from "react-i18next";
import { AuthConsumer } from "../Shared/Authentication/AuthContext";
import ProfileDropdown from "./ProfileDropdown";

const MODAL_NAMES = { CART: "Cart" };

class NavLayout2M extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navDropdown: "",
      showProfileDetails: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.cartLicences !== prevProps.cartLicences) {
      if (this.props.cartLicences.length <= 0) {
        this.props.modal.forceCloseModal(MODAL_NAMES.CART);
      }
    }
  };

  setNavDropdown = (input) => {
    this.setState({ navDropdown: input });
  };

  handleCartClick(modal) {
    modal.toggleModal(MODAL_NAMES.CART);
  }

  toggleProfileDisplay = () => {
    this.setState((prevState) => {
      return {
        showProfileDetails: !prevState.showProfileDetails,
      };
    });
  };

  render() {
    const { modal, cartLicences, t } = this.props;

    return (
      <React.Fragment>
        <AuthConsumer>
          {({ isAuthenticated, profile, logout }) => (
            <>
              <div className="mobile-navbar-container">
                <nav className="navbar">
                  <div className="container">
                    <div>
                      <button
                        className="navbar-brand"
                        onClick={() => navigateTo(HOME_PATH)}
                      >
                        <img src={SITE_LOGO} alt={SITE_LOGO_ALT_TEXT}></img>
                      </button>
                    </div>
                    <ul className="nav navbar-nav navbar-right">
                      {profile && isAuthenticated ? (
                        <>
                          <li className="loggedin">
                            <i
                              className="fal fa-user-circle"
                              onClick={this.toggleProfileDisplay}
                            >
                              {" "}
                              <span
                                className={`${
                                  this.state.showProfileDetails
                                    ? "show"
                                    : "hide"
                                }`}
                              ></span>
                            </i>
                          </li>
                        </>
                      ) : (
                        <li className="login">
                          <button
                            onClick={() => navigateTo(LOGIN_PATH)}
                            className="btn btn-orange"
                          >
                            <i className="far fa-lock-alt"></i>
                            <span>
                              {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_LOGIN)}
                            </span>
                          </button>
                        </li>
                      )}

                      {!this.props.location.pathname.includes(
                        "/services/devform/"
                      ) && (
                        <li className="cart">
                          {cartLicences.length > 0 ? (
                            <button onClick={() => this.handleCartClick(modal)}>
                              <i className="fas fa-shopping-cart"></i>
                              <span>{cartLicences.length}</span>
                            </button>
                          ) : (
                            <button className="empty-cart">
                              {" "}
                              <i className="fas fa-shopping-cart"></i>
                              <span>{cartLicences.length}</span>
                            </button>
                          )}
                        </li>
                      )}
                    </ul>
                    <div
                      className="navbar-toggle"
                      onClick={this.props.sidebarToggleClickHandler}
                    >
                      <div className="menu-icon">
                        <div className="menu-bar1"></div>
                        <div className="menu-bar2"></div>
                        <div className="menu-bar3"></div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              {this.state.showProfileDetails && (
                <ProfileDropdown
                  profile={profile}
                  toggleProfileDisplay={this.toggleProfileDisplay}
                  logout={logout}
                />
              )}

              <LicenceCartModal
                isOpen={modal.modalState === MODAL_NAMES.CART}
                closeCallBack={() => modal.toggleModal(MODAL_NAMES.CART)}
                contentHeader={t(
                  LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SELECTEDSERVICES
                )}
              >
                <div>
                  {cartLicences.map((licence, i) => {
                    return (
                      <ItemResult
                        key={i}
                        licence={licence}
                        isTdNavigation={false}
                        tdUrl={null}
                        inLineStyle={null}
                        isShowDescription={true}
                        isShowFeeTime={true}
                        isShowAddToCart={true}
                      />
                    );
                  })}
                </div>
              </LicenceCartModal>
            </>
          )}
        </AuthConsumer>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(NavLayout2M));
