import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from '../../../Shared/Constants';
import LicenceItem from './LicenceItem';
import QuestionItem from './QuestionItem';

class AnswerCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isDisabled, exclusiveLicenceCart, AnswerGrp, updateBizIntentCart, bizIntentCart } = this.props;

        const licence = AnswerGrp[0]?.licence;
        const isDisabledProp = nextProps.isDisabled !== isDisabled && nextProps.isDisabled === true;
        const isExclusiveProp = nextProps.exclusiveLicenceCart !== exclusiveLicenceCart && (licence && nextProps.exclusiveLicenceCart.includes(licence.Id));

        const isDisabledChanged = nextProps.isDisabled !== isDisabled;
        const exclusivePropChanged = nextProps.exclusiveLicenceCart !== exclusiveLicenceCart;
        const bizIntentCartChanged = nextProps.bizIntentCart !== bizIntentCart;
        const stateChanged = nextState !== this.state;

        if (isDisabledProp || isExclusiveProp) {
            if (licence) {
                updateBizIntentCart(licence, CART_ACTION_REMOVE,
                    updateBizIntentCart(this.getPreReqLicences(licence), CART_ACTION_REMOVE)
                );
            }
            this.setState({
                checked: false
            });
        }

        return isDisabledChanged || exclusivePropChanged || bizIntentCartChanged || stateChanged;
    }

    handleOnClick = () => {
        const { updateBizIntentCart } = this.props;

        const ansGrp = this.props.AnswerGrp;
        this.setState(prevState => {
            if (ansGrp !== undefined && ansGrp.length > 0 && ansGrp[0].licence) {
                const licence = ansGrp[0].licence;
                updateBizIntentCart(
                    licence,
                    //if previously was not checked, means its going to be checked, so we add the licence
                    prevState.checked ? CART_ACTION_REMOVE : CART_ACTION_ADD,
                    updateBizIntentCart(this.getPreReqLicences(licence), prevState.checked ? CART_ACTION_REMOVE : CART_ACTION_ADD)
                );
            }
            return {
                checked: !prevState.checked
            };
        });
    };

    getPreReqLicences = licence => {
        const { preReqLicences } = this.props;
        let currentPreReqLicences = [];

        //get prereq from master prereq list
        if (licence.PreRequisite.length > 0 && preReqLicences.length > 0) {
            const preReqIds = licence.PreRequisite.map(lic => lic.LicenceTypeId);
            currentPreReqLicences = preReqLicences.filter(lic => {
                return preReqIds.includes(lic.Id);
            });
        }

        return currentPreReqLicences;
    };

    render() {
        const { Description, AnswerGrp, updateBizIntentCart, 
            bizIntentCart, ShowOnLoad, isDisabled, exclusiveLicenceCart, preReqLicences,
            currLang, Subquestion, SubAnsGrp } = this.props;
        const { checked } = this.state;

        const answerSet = AnswerGrp[0];
        const licence = answerSet?.licence;
        const isAnswerDisabled = isDisabled || (licence && exclusiveLicenceCart.includes(licence.Id));
        const questionAnswerSet = {
            question: Subquestion,
            question_answer_group: SubAnsGrp,
            subquestion: null,
            subquestion_answer_group: null
        };

        return (
            <div className={ShowOnLoad ? '' : 'box-hidden'}>
                <Row className={`bordered-box${checked ? ' selected' : ''}${isAnswerDisabled ? ' disabled' : ''}`} >
                    <Col>
                        <div className={`box-selection`}>
                            <label className='check-control'>
                                <input
                                    className='checkbox'
                                    name='select-check'
                                    checked={checked}
                                    type='checkbox'
                                    onChange={() => { /*used only to remove warnings*/ }}
                                    onClick={this.handleOnClick}
                                    disabled={isAnswerDisabled}
                                >
                                </input>
                                <span className={`checkbox-overlay${isAnswerDisabled ? ' disabled' : ''}`}></span><strong>{Description}</strong>
                            </label>
                        </div>
                        {checked && licence &&
                            <LicenceItem
                                licence={licence}
                                preReqLicences={this.getPreReqLicences(licence)}
                            />
                        }
                        {checked && Subquestion &&
                            <QuestionItem
                                key={'SubQuestion' + Subquestion.id}
                                questionAnswerSet={questionAnswerSet}
                                updateBizIntentCart={updateBizIntentCart}
                                bizIntentCart={bizIntentCart}
                                exclusiveLicenceCart={exclusiveLicenceCart}
                                preReqLicences={preReqLicences}
                                currLang={currLang}
                                isSubquestion={true}
                            />
                        }
                    </Col>
                </Row>
            </div >
        );
    }
}

AnswerCheckbox.propTypes = {
    Description: PropTypes.string.isRequired,
    AnswerGrp: PropTypes.array.isRequired,
    updateBizIntentCart: PropTypes.func.isRequired,
    ShowOnLoad: PropTypes.bool.isRequired
};

export default AnswerCheckbox;
