import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { withBaseControl } from './BaseControl';


class ToggleSwitch extends React.Component {

    // Lifecycle Fn
    componentDidMount() {

        // If value is neither onText nor offText
        if ((this.props.value !== this.props.onText) && (this.props.value !== this.props.offText)) {

            // We then check whether defaultOn prop is given
            const newValue = (this.props.defaultOn) ? (this.props.onText) : (this.props.offText);

            // Update new value in parent state
            this.props.onChangeField(this.props.name, newValue);
        }
    }


    // Handle switch toggle/untoggle
    handleToggleChange = ({ onChangeField, name, value, onText, offText }) => {

        // Toggle the text based on currentValue
        const newValue = (value === onText) ? offText : onText;

        // Update text to parent state
        onChangeField(name, newValue);
    };

    // Component: Toggle Text
    ToggleText = (showToggleText) => {
        return (showToggleText) ?
            (<Label className="toggle-switch-label">{this.props.value}</Label>)
            :
            (null);
    };

    render() {
        return (
            <FormGroup className="toggle-switch-group" check inline>
                {/* Hide original Checkbox/ToggleSwitch UI, but all react behavior still happens here */}
                <Input
                    className="toggle-switch-control"
                    type="checkbox"
                    id={this.props.name}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={() => this.handleToggleChange(this.props)}
                    checked={this.props.value === this.props.onText}
                />

                {/* Re-styled ToggleSwitch UI for cross-browser compatibility */}
                <Label for={this.props.name} className="toggle-switch-display"></Label>

                {/* ToggleSwitch Text */}
                {this.ToggleText(this.props.showToggleText)}
            </FormGroup>
        );
    }
}

// PropTypes: For prop validation
ToggleSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeField: PropTypes.func.isRequired,
    onText: PropTypes.string,
    offText: PropTypes.string,
    showToggleText: PropTypes.bool,
    defaultOn: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
ToggleSwitch.defaultProps = {
    value: '',
    onText: 'Yes',
    offText: 'No',
    showToggleText: true,
    defaultOn: undefined
};


export default withBaseControl(ToggleSwitch);




