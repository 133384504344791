import { getIn } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DATE_DISPLAY_FORMAT, MASK } from '../../App/AppSettings';
import { DISPLAY_RICH_TEXT, getLangKey, getTranslation } from '../../Content/DisplayComponents/DisplayUtils';
import MultiFileUpload from '../../Content/FormComponents/MultiFileUpload';
import { DELETE_FILES_ARRAY, FIELD_SIZE } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { Paragraph, Text } from '../../Shared/Forms';
import Checkbox from '../../Shared/Forms/Checkbox';
import DateInput from '../../Shared/Forms/DateInput';
import MaskInput from '../../Shared/Forms/MaskedInput';
import NumberInput from '../../Shared/Forms/NumberInput';
import Radio from '../../Shared/Forms/Radio';
import SelectList from '../../Shared/Forms/SelectList';

class RenderFieldComponent extends React.Component {

    constructor(props) {
        super(props);

        const { field, optionDataList } = this.props;
        const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
        const isLocal = field.Data && (field.DataSource === undefined || field.DataSource === 'Local');
        let newOptionsList = [];

        if (isRemote) {
            newOptionsList = optionDataList[field.DataParameters.Category];
        } else if (isLocal) {
            newOptionsList = this.getLocalList(field);
        } else {
            newOptionsList = [];
        }

        this.state = {
            optionDataList: newOptionsList
        };
    }

    getLocalList = field => field.Data.map(({ Value }) => ({ label: Value, value: Value, key: Value }));

    render() {

        // De-structure props to be used
        const { t } = this.props;
        const { formType, field, values, onChange, onChangeField, errors } = this.props;
        const { optionDataList } = this.state;
        let isFieldDisabled = false;

        if (field.InputDisabled === 'Yes') {
            isFieldDisabled = true;
        }

        switch (field.InputType) {
            case 'Text':
            case 'Email':
                return <Text
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    inputSize={FIELD_SIZE.MEDIUM}
                    minLength={field.Validation.MinimumLength}
                    maxLength={field.Validation.MaximumLength}
                    onChange={onChange}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    labelSize={FIELD_SIZE.MEDIUM}
                    readOnly={isFieldDisabled}
                >
                </Text>;

            case 'Radio':
                return <Radio
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    options={optionDataList}
                    onChange={onChange}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    readOnly={isFieldDisabled}
                >
                </Radio>;

            case 'Date':
                return <DateInput
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    onChangeField={onChangeField}
                    time={false}
                    date={true}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue === "Current Date" ? (new Date()).setHours(0, 0, 0, 0) : field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.DATE}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'Time':
                return <DateInput
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'DateTime':
                return <DateInput
                    name={`${formType}.${field.Name}`}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    onChangeField={onChangeField}
                    time={true}
                    date={true}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue === 'Current Date' ? (new Date()).setHours(0, 0, 0, 0) : field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.DATE_TIME}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'DropDownList':
                return <SelectList
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    isMulti={false}
                    isClearable={true}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </SelectList>;

            case 'Number':
                return <NumberInput
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    onChangeField={onChangeField}
                    unitOfMeasurement=""
                    step={1.0}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                >
                </NumberInput>;

            case 'Paragraph':
                return <Paragraph
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    onChange={onChange}
                    rows={3}
                    minLength={field.Validation.MinimumLength}
                    maxLength={field.Validation.MaximumLength}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    readOnly={isFieldDisabled}
                >
                </Paragraph>;

            case 'Checkbox':
                return <Checkbox
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation ? (field.Validation.Required === true) : false}>
                </Checkbox>;

            case 'Multiselect':
                return <SelectList
                    name={`${formType}.${field.Name}`}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    isMulti={true}
                    isClearable={true}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    required={field.Validation ? (field.Validation.Required === true) : false}
                    isDisabled={isFieldDisabled}
                >
                </SelectList>;

            case 'MaskedInput':
                return <MaskInput
                    name={`${formType}.${field.Name}`}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    onChange={onChange}
                    mask={MASK.TELEPHONE}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    required={field.Validation ? field.Validation.Required === true : false}
                >
                </MaskInput>;

            case 'FileUpload':
                return <MultiFileUpload
                    name={`${formType}.${field.Name}`}
                    label={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, field.Name))}
                    description={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, field.Name), DISPLAY_RICH_TEXT)}
                    fileList={values[formType][field.Name]}
                    onChangeField={onChangeField}
                    key={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, field.Name))}
                    error={getIn(errors, `${formType}.${field.Name}`)}
                    deleteArray={values[DELETE_FILES_ARRAY]}
                    required={field.Validation.Required === true}
                />;

            default:
                return null;
        }
    }
}

export default withTranslation()(RenderFieldComponent);
