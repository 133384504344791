import { faBell, faSearch } from '@fortawesome/free-solid-svg-icons';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { DASHBOARD_PATH, ENQUIRY_PATH, FAQ_PATH, HOME_PATH, LOGIN_PATH, SEARCH_INTENT_PATH, SITE_LOGO, SITE_LOGO_ALT_TEXT } from '../App/AppSettings';
import LicenceCartModal from '../Content/LicenceCart/LicenceCartModal';
import NavLink from '../Navbar/NavLink';
import { navigateTo } from '../Shared/Actions';
import { AuthConsumer } from '../Shared/Authentication/AuthContext';
import { DirectionContextConsumer } from '../Shared/DirectionContext';
import { withModalWrapper } from '../Shared/Modal/ModalWrapper';
import ItemResult from '../Shared/SearchLicence/LicenceItemResult';
import ProfileDropdown from './ProfileDropdown';
import { LANGUAGE_KEYS } from '../Shared/Constants/LanguageKeys';
import { getLogo, getTransl } from '../../directus';

const PUBLIC_URL = process.env.PUBLIC_URL;
const MODAL_NAMES = { CART: 'Cart' };

class NavLayout2D extends React.Component {

    constructor(props) {
        super(props);
        const { supportedLangResponse, currLang } = this.props.context;
        const { pathname } = this.props.location;
        const langArray = supportedLangResponse ? supportedLangResponse.body.Data : [];
        const isMirroredPage = pathname.toLowerCase().includes('mirrored');

        this.state = {
            navDropdown: '',
            showProfileDetails: false,
            dropdownOpen: false,
            lang: langArray,
            currLang: currLang,
            logo: '',
            isMirrored: isMirroredPage
        };
    }

    async componentDidMount() {
        const { isMirrored } = this.state;
        const slug = isMirrored ? '/Mirrored' : '/Home';

        this.setState({ logo: await getLogo(slug)});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.cartLicences !== prevProps.cartLicences) {
            if (this.props.cartLicences.length <= 0) {
                this.props.modal.forceCloseModal(MODAL_NAMES.CART);
            }
        }
        if (prevProps.context.supportedLangResponse !== this.props.context.supportedLangResponse) {
            this.setState({
                lang: this.props.context.supportedLangResponse.body.Data
            })
        }
    }

    setNavDropdown = (input) => {
        this.setState({ navDropdown: input });
    }

    toggleNavDisplay = () => {
        if (this.props.showNavDisplay) {
            return 'd-flex';
        }
        else {
            return 'd-none';
        }
    }

    adjustNavDisplay = () => {
        return this.props.showNavDisplay ? '' : 'no-navdisplay-layout';
    }

    handleCartClick(modal) {
        modal.toggleModal(MODAL_NAMES.CART);
    };

    toggleProfileDisplay = () => {
        this.setState((prevState) => {
            return {
                showProfileDetails: !prevState.showProfileDetails
            };
        });
    }

    toggle = () => this.setState(prevState => !prevState);

    render() {
        const { i18n, t, modal, cartLicences } = this.props;
        const { isMirrored, logo, currLang } = this.state;
        const { changeLanguage } = this.props.context;

        const logoTitle = getTransl(logo, currLang)?.title;

        return (
            <AuthConsumer>
                {({ isAuthenticated, profile, logout }) => (
                    <>
                        <div className={`smart-navbar layout-2d ${this.adjustNavDisplay()}`}>
                            <div className='nav-container'>
                                <Row className='top-navbar'>
                                    <Container className='bls-top-navbar'>
                                        <div className='navbar-collapse'>
                                            <ul className='nav navbar-nav navbar-left'>
                                                <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to Home'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_HOME)}</button></li>
                                                <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to About Us'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_ABOUTUS)}</button></li>
                                                <li><button onClick={() => navigateTo(FAQ_PATH)} title='Go to FAQ'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_FAQ)}</button></li>
                                                <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to Contact Us'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CONTACTUS)}</button></li>
                                            </ul>
                                            <ul className='nav navbar-nav'>
                                                <UncontrolledDropdown nav>
                                                    <DropdownToggle nav caret>
                                                    {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_LANGUAGE)}
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        {
                                                            this.state.lang.map((lang, i) =>
                                                                <DropdownItem
                                                                    key={i}
                                                                    onClick={() => { i18n.changeLanguage(lang.LanguageCodeValue); changeLanguage(lang.LanguageCodeValue); }}>
                                                                    <bdi>{`${lang.Description} (${lang.LanguageCodeValue})`}</bdi>
                                                                </DropdownItem>
                                                            )
                                                        }
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <li><span className='site-search'><NavLink icon={faSearch} to={ENQUIRY_PATH} className='site-search'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SITESEARCH)}</NavLink></span></li>
                                                <li><span className='notices'><NavLink icon={faBell} to={ENQUIRY_PATH} className='site-search'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_NOTICES)}</NavLink></span></li>
                                            </ul>
                                        </div>
                                    </Container>
                                    <nav className='bls-btm-navbar navbar'>
                                        <Sticky stickyClassName={'sticky-bar'}>
                                            <Container>
                                                <Row className='stick-row'>
                                                    <Col sm={4} className='sticky-left'>
                                                        {logo && <button className='navbar-brand' onClick={() => navigateTo(logo.url)}>
                                                            <img src={logo.image} alt={logoTitle}></img>
                                                        </button>}
                                                    </Col>
                                                    {
                                                        !isMirrored &&
                                                        <Col className='sticky-right'>
                                                        <ul className='nav navbar-nav'>
                                                            {(profile && isAuthenticated) && <li className='hidden-mobile'><div className='dashboard-click' onClick={() => navigateTo(DASHBOARD_PATH)} title={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_PLACEHOLDER_GOTODASHBOARD)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</div></li>}
                                                            <UncontrolledDropdown nav>
                                                                <DropdownToggle nav caret>
                                                                    {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SERVICEAPPLICATION)}
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    <DropdownItem>
                                                                        <div onClick={() => navigateTo(SEARCH_INTENT_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_APPLYFORNEWSERVICEAPPLICATION)}</div>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <div onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_RENEWSERVICEAPPLICATION)}</div>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <div onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CANCELSERVICEAPPLICATION)}</div>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <div onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_AMENDSERVICEAPPLICATION)}</div>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            {profile && isAuthenticated ?
                                                                <>
                                                                    <li className='loggedin'>
                                                                        <i className='fal fa-user-circle'
                                                                            onClick={this.toggleProfileDisplay}>  <span className={`${this.state.showProfileDetails ? 'show' : 'hide'}`}></span></i>
                                                                    </li>
                                                                </>
                                                                :
                                                                <li className='login'>
                                                                    <button
                                                                        className='btn-orange'
                                                                        onClick={() => navigateTo(LOGIN_PATH)}>
                                                                        <i className='far fa-lock-alt'></i><span>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_LOGIN)}</span></button></li>

                                                            }
                                                            {!this.props.location.pathname.includes('/services/devform/new') && <li className='cart'>
                                                                {cartLicences.length > 0 ?
                                                                    <button onClick={() => this.handleCartClick(modal)}>
                                                                        <i className='fas fa-shopping-cart'></i><span>{cartLicences.length}</span></button>
                                                                    : <button className='empty-cart'> <i className='fas fa-shopping-cart'></i><span>{cartLicences.length}</span></button>
                                                                }
                                                            </li>}
                                                        </ul>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Container>

                                            {
                                                !isMirrored &&
                                                this.state.showProfileDetails &&
                                                <ProfileDropdown
                                                    profile={profile}
                                                    toggleProfileDisplay={this.toggleProfileDisplay}
                                                    logout={logout}
                                                />
                                            }
                                        </Sticky>
                                    </nav>
                                </Row>
                            </div>
                        </div>
                        {
                            !isMirrored &&
                            <LicenceCartModal
                            isOpen={modal.modalState === MODAL_NAMES.CART}
                            closeCallBack={() => modal.toggleModal(MODAL_NAMES.CART)}
                            contentHeader={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SELECTEDSERVICES)}
                        >
                            <div>
                                {cartLicences.map((licence, i) => {
                                    return (
                                        <ItemResult
                                            key={i}
                                            licence={licence}
                                            isTdNavigation={false}
                                            tdUrl={null}
                                            inLineStyle={null}
                                            isShowDescription={true}
                                            isShowFeeTime={true}
                                            isShowAddToCart={true}
                                        />
                                    );
                                })}
                            </div>
                            </LicenceCartModal>
                        }
                    </>
                )}
            </AuthConsumer>
        );
    }
}

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ changeLanguage, supportedLangResponse, currLang }) => (
                        <WrappedComponent
                            context={{ changeLanguage, supportedLangResponse, currLang }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};

export default withTranslation()(withModalWrapper((withRouter(withDirectionContext(NavLayout2D)))));