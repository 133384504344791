import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from "react-select";
import { withBaseControl } from './BaseControl';

class SelectList extends React.Component {

    clearField = ({ onChangeField, name }) => {
        this.setState({
            value: null
        })
        onChangeField(name, '');
    }

    // Fn: Handle changes in checkbox group 
    handleSelectChange = (event, { onChangeField, name, isMulti, onSelectListChange }) => {
        if (event !== null) {
            // Take in an event, and convert it into
            let selections = (isMulti) ? event : [event];

            // Only takes in 'value' prop for each object
            let value = _.map(selections, (selection) => selection.value);

            if (!isMulti) {
                value = value[0];
            }

            // Update changes in Formik handleChange event
            onChangeField(name, value);

            // Execute other SelectList onChange
            if (onSelectListChange) {
                onSelectListChange({ [name]: value });
            }
        } else {
            this.setState({ value: '' });
            onChangeField(name, '');
        }
    };

    matchObjectValue = (valueArray, key, option) => {
        if (key === option.value) {
            return valueArray.push(option)
        }
    }

    // Fn: Retrieve value and convert it to react-select readable label-value pair
    mapValuesToOptions = ({ options, value }) => {
        // Only takes in 'value' prop for each object
        let valueArr = [];
        if (value !== "") {
            if (Array.isArray(value)) {
                value.forEach(element => {
                    options.forEach(option => {
                        if (element === option.value) {
                            valueArr.push(option);
                        }
                    });
                })
            }
            else if (typeof value === "object") {
                Object.keys(value).map(key =>
                    options.forEach(option => {
                        this.matchObjectValue(valueArr, value[key], option)
                    })
                );
            }
            else {
                options.forEach(option => {
                    if (value === option.value) {
                        valueArr.push(option);
                    }
                });
            }
            return valueArr;
        }
        return valueArr;
    };

    componentDidUpdate(prevProps) {
        let { name, value, isMulti, onChangeField } = this.props;
        if (this.props.value !== prevProps.value) {        // Map & update value to array
            if (isMulti && value !== undefined && value !== "" && !Array.isArray(value)) {
                onChangeField(name, value.split(","));
            } else if (!isMulti && value !== undefined && value !== "") {
                onChangeField(name, value);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Select
                    name={this.props.name}
                    value={this.mapValuesToOptions(this.props)}
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.handleSelectChange(e, this.props)}
                    options={this.props.options}
                    isMulti={this.props.isMulti}
                    isDisabled={this.props.isDisabled}
                    isClearable={this.props.isClearable}
                    maxMenuHeight={150}
                    menuPlacement={(this.props.menuPlacement ? this.props.menuPlacement : "auto")}
                    // menuPortalTarget={document.body}
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                    }}
                    className={this.props.className + " select-list"} //  class for css
                />
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
SelectList.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
    placeholder: PropTypes.string,
    onChangeField: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    isMulti: PropTypes.bool.isRequired
};


export default withBaseControl(SelectList);