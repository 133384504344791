import { default as React } from "react";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { compose } from "recompose";
import {
  GET_BUSINESS_INTENT_JSON_URL,
  IS_DEMO,
  LAYOUT_TYPE,
  LICENCE_CART_PATH,
  SEARCH_INTENT_PATH,
  SEARCH_LICENCE_BY_ID_LIST,
} from "../../../App/AppSettings";
import Layout from "../../../Layout";
import { fetchRequest, getParams, navigateTo } from "../../../Shared/Actions";
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from "../../../Shared/Constants";
import { LANGUAGE_KEYS } from "../../../Shared/Constants/LanguageKeys";
import history from "../../../Shared/History";
import { LicenceCartContext } from "../../../Shared/LicenceCartContext";
import { withLoader } from "../../../Shared/Loading";
import { getLangKey } from "../../../Content/DisplayComponents/DisplayUtils";
import LicenceCard from "./LicenceCard";
import QuestionContainer from "./QuestionContainer";
import { withRouter } from "react-router-dom";
import { getBusinessIntentById, getTransl } from "../../../../directus";
import { DirectionContextConsumer } from '../../../Shared/DirectionContext';

const withLicenceContext = (WrappedComponent) => {
  class LicenceContext extends React.Component {
    render() {
      return (
        <LicenceCartContext.Consumer>
          {({ updateCart, currentCart, clearCart }) => (
            <WrappedComponent
              context={{
                currentCart,
                updateCart,
                clearCart,
              }}
              {...this.props}
            />
          )}
        </LicenceCartContext.Consumer>
      );
    }
  }

  return LicenceContext;
};

class BusinessAdvisor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bizIntentData: null,
      bizIntentCart: [],
      categoryOrder: [],
      categorizedQuestions: [],
      exclusiveLicenceCart: [],
      defaultLicences: [],
      preReqLicences: [],
      currLang: null,
    };
  }

  async componentDidMount() {
    const { state } = history.location;
    const { loader } = this.props;
    let isPreReqExist = false;
    const defaultPreReqIds = [];
    let preReqLicences = [];
    let RETRIEVE_PRE_REQ_URL = SEARCH_LICENCE_BY_ID_LIST;
    let RETRIEVE_LICENCE_URL = SEARCH_LICENCE_BY_ID_LIST;
    const { currLang } = this.props.context;

    if (state && state.intentId) {
      loader.start();

      // retrieved business intent data, default licences, categorized questions data from Directus
      const directusIntentData = await getBusinessIntentById(state.intentId);
      const defaultLicences = directusIntentData?.default_licence;
      const intentComp = directusIntentData?.business_intent_composition;
      const categorizedQuestions = intentComp[0]?.categorized_questions ?? [];
      const categoryOrder = directusIntentData?.category_order.map(category => category.question_category);

      if (directusIntentData) {
        const licIdList = [];

        // get licence data by id
        Array.isArray(defaultLicences) && defaultLicences.forEach((licence) => {
          let currLicId = licence?.dsp_ref_id;
          currLicId && !licIdList.includes(currLicId) && licIdList.push(currLicId);
        });
        Array.isArray(categorizedQuestions) && categorizedQuestions.forEach((qSet) => {
          let qAnsGrp = qSet.question_answer_group;
          let subQAnsGrp = qSet.subquestion_answer_group;
          let ansGrp = qAnsGrp ?
            (subQAnsGrp ? [...qAnsGrp, ...subQAnsGrp] : [...qAnsGrp]) : // subquestion does not have any answer/licence
            (subQAnsGrp ? [...subQAnsGrp] : []); // question does not have any answer/licence
          ansGrp.forEach((aSet) => {
            let currLic = aSet.licence;
            currLic && !licIdList.includes(currLic.dsp_ref_id) && licIdList.push(currLic.dsp_ref_id);
          })
        });
        RETRIEVE_LICENCE_URL += Array.isArray(licIdList)
                                  && licIdList.map(id => `idList=${id}`).join('&');
        const licResponse = await fetchRequest(
          RETRIEVE_LICENCE_URL,
          getParams(),
          IS_DEMO
        );

        if (licResponse.success && licResponse.body.IsSuccess) {
          let licenceList = licResponse.body.Data;
          // map licences to data
          Array.isArray(defaultLicences) && defaultLicences.forEach((defLic, i) =>
            defaultLicences[i] = licenceList.find(lic => lic.Id === defLic.dsp_ref_id));
          Array.isArray(categorizedQuestions) && categorizedQuestions.forEach(qSet => {
            let qAnsGrp = qSet.question_answer_group;
            let subQAnsGrp = qSet.subquestion_answer_group;
            let ansGrp = qAnsGrp ?
              (subQAnsGrp ? [...qAnsGrp, ...subQAnsGrp] : [...qAnsGrp]) :
              (subQAnsGrp ? [...subQAnsGrp] : []);
            ansGrp.forEach(aSet => {
              const currLicence = aSet.licence;
              aSet.licence = currLicence && licenceList.find(lic => lic.Id === currLicence.dsp_ref_id);
            })
          });

          //get pre req licence for default licences
          if (Array.isArray(defaultLicences) && defaultLicences.length > 0) {
            for (let licence of defaultLicences) {
              if (licence.PreRequisite.length > 0) {
                isPreReqExist = true;
                const idList = licence.PreRequisite.map((required) => {
                  defaultPreReqIds.push(required.LicenceTypeId);
                  return `idList=${required.LicenceTypeId}`;
                });
                RETRIEVE_PRE_REQ_URL += idList.join("&") + "&";
              }
            }
          }
          
          //get pre req licence for all other licences
          Array.isArray(categorizedQuestions) && categorizedQuestions.forEach(qSet => {
            const qAnsGrp = qSet.question_answer_group;
            const subQAnsGrp = qSet.subquestion_answer_group;
            const ansGrp = qAnsGrp ?
              (subQAnsGrp ? [...qAnsGrp, ...subQAnsGrp] : [...qAnsGrp]) : // subquestion does not have any answer/licence
              (subQAnsGrp ? [...subQAnsGrp] : []); // question does not have any answer/licence
            ansGrp.forEach(aSet => {
              if (aSet.licence && aSet.licence.PreRequisite.length > 0) {
                isPreReqExist = true;
                const idList = aSet.licence.PreRequisite.map((required) => {
                  return `idList=${required.LicenceTypeId}`;
                });
                RETRIEVE_PRE_REQ_URL += idList.join("&") + "&";
              }
            });
          });

          if (isPreReqExist) {
            const preReqResponse = await fetchRequest(
              RETRIEVE_PRE_REQ_URL,
              getParams(),
              IS_DEMO
            );
            if (preReqResponse.success && preReqResponse.body.IsSuccess) {
              preReqLicences = preReqResponse.body.Data;
              if (defaultPreReqIds.length > 0) {
                defaultLicences = defaultLicences.concat(
                  preReqLicences.filter((lic) => {
                    return defaultPreReqIds.includes(lic.Id);
                  })
                );
              }
            }
          }

          this.setState({
            bizIntentData: directusIntentData,
            bizIntentCart: defaultLicences,
            categoryOrder: categoryOrder,
            categorizedQuestions: categorizedQuestions,
            //JSON.parse & JSON.stringify used to close the default licences and not just pass a object reference, else the functions will be editing default licences
            defaultLicences: JSON.parse(JSON.stringify(defaultLicences)),
            preReqLicences,
            currLang: currLang
          });
          loader.done();
        } else {
          loader.error();
        }
      } else {
        navigateTo(SEARCH_INTENT_PATH);
      }
    }
  }

  updateBizIntentCart = (licence, cartAction, callback) => {
    this.setState((prevState) => {
      const cartLicences = prevState.bizIntentCart;

      let currentLicences = [];
      let updatedLicences = [];
      let updatedExclusiveLicenceCart = [];
      let isLicenceInCart = false;

      if (cartLicences !== null) {
        currentLicences = cartLicences;
        isLicenceInCart = currentLicences.some(cartLicence => cartLicence.Id === licence.Id)
      }

      //check if update is multiple or single licences
      if (Array.isArray(licence)) {
        if (cartAction === CART_ACTION_REMOVE) {
          const licenceFiltered = licence.filter(
            (li) => li !== null && li !== undefined
          );
          //remove licences by id
          updatedLicences = currentLicences.filter(
            (currentLicence) =>
              !licenceFiltered.map((lf) => lf.Id).includes(currentLicence.Id)
          );
        } else if (cartAction === CART_ACTION_ADD) {
          //get new licences that are not in currentLicences
          const uniqueLicences = licence.filter(
            (li) =>
              !currentLicences
                .map((currentLicence) => currentLicence.Id)
                .includes(li.Id)
          );
          //add new licences that are not in currentLicences
          updatedLicences = currentLicences.concat(uniqueLicences);
        }
      } else {
        if (cartAction === CART_ACTION_REMOVE) {
          updatedLicences = currentLicences.filter(
            (currentLicence) => currentLicence.Id !== licence.Id
          );
        } else if (cartAction === CART_ACTION_ADD && !isLicenceInCart) {
          currentLicences.push(licence);
          updatedLicences = currentLicences;
        } else {
          updatedLicences = currentLicences;
        }
      }

      //update exclusive licence list based on current intent cart
      updatedExclusiveLicenceCart = updatedLicences
        .map((lic) => lic.Exclusive)
        .flat(Infinity)
        .map((lic) => lic.LicenceTypeId);
      return {
        bizIntentCart: updatedLicences,
        exclusiveLicenceCart: updatedExclusiveLicenceCart,
      };
    }, callback);
  };

  render() {
    const {
      bizIntentData,
      bizIntentCart,
      categoryOrder,
      categorizedQuestions,
      exclusiveLicenceCart,
      defaultLicences,
      preReqLicences,
    } = this.state;
    const { currLang } = this.props.context;
    const intentId = bizIntentData === null ? "" : bizIntentData.id;
    const { t } = this.props;
    console.log(bizIntentData);
    console.log(bizIntentCart);
    console.log(exclusiveLicenceCart);

    return (
      <>
        <Layout type={LAYOUT_TYPE.FORM} footer>
          <div className="main-content">
            <nav aria-label="breadcrumb" className="heading-big">
              <Container>
                <h2>
                  {bizIntentData && getTransl(bizIntentData, currLang).name}
                </h2>
                <p>
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_SELECTBESTOPTION
                  )}
                </p>
              </Container>
            </nav>
            <Container className="bls-biz-advisor-container">
              {bizIntentData && (
                <Row className="questionnaire">
                  <Col>
                    {Array.isArray(defaultLicences) &&
                      defaultLicences.length > 0 && (
                        <>
                          <Row>
                            <Col>
                              <div className="heading-icon pre-request">
                                {t(
                                  LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_PREREQUISITELICENCES
                                )}
                              </div>
                              <Row className="questionnaire-content" noGutters>
                                {defaultLicences.map((licence) => {
                                  return (
                                    <Col
                                      key={"pre-request" + licence.Id}
                                      md={4}
                                    >
                                      <LicenceCard
                                        licence={licence}
                                      ></LicenceCard>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          </Row>
                          <div className="separator"></div>
                        </>
                      )}
                    <Row>
                      <Col>
                        <div className="questionnaire">
                          <QuestionContainer
                            bizIntentCart={bizIntentCart}
                            exclusiveLicenceCart={exclusiveLicenceCart}
                            updateBizIntentCart={this.updateBizIntentCart}
                            categoryOrder={categoryOrder}
                            categorizedQuestions={categorizedQuestions}
                            preReqLicences={preReqLicences}
                            currLang={currLang}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <div className="button-center">
                <button
                  className="btn"
                  onClick={() => navigateTo(SEARCH_INTENT_PATH)}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_BACKTOSEARCH
                  )}
                </button>
                <button
                  className="btn btn-blue"
                  onClick={() => {
                    this.props.context.updateCart(
                      bizIntentCart.filter(
                        (item) => item.IsExternalLicence === false
                      ),
                      CART_ACTION_ADD
                    );
                    sessionStorage.removeItem("externalServices");
                    sessionStorage.setItem(
                      "externalServices",
                      JSON.stringify(
                        bizIntentCart.filter(
                          (item) => item.IsExternalLicence === true
                        )
                      )
                    );
                    navigateTo(LICENCE_CART_PATH);
                  }}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_APPLYNOW
                  )}
                </button>
              </div>
            </Container>
          </div>
        </Layout>
      </>
    );
  }
}

const withDirectionContext = WrappedComponent => {
  class DirectionOnPageContext extends React.Component {
    render() {
      return (
        <DirectionContextConsumer>
          {({ currLang }) => (
            <WrappedComponent
              context={{ currLang }}
              {...this.props}
            />
          )}
        </DirectionContextConsumer>
      );
    }
  }

  return DirectionOnPageContext;
};

export default withTranslation()(
  compose(withLicenceContext, withLoader, withRouter)(withDirectionContext(BusinessAdvisor))
);
