import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { clearEditorContent } from 'draftjs-utils';
import htmlToDraft from 'html-to-draftjs';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { withBaseControl } from './BaseControl';

// const decorator = new CompositeDecorator([
//   ... decorator
//   ]);

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props)

    let initialUpdate = true;
    let editorState = EditorState.createEmpty();

    if (props.value !== undefined && props.value !== "") {
      const blocksFromHtml = htmlToDraft(props.value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

      editorState = EditorState.createWithContent(contentState);
      initialUpdate = false;
    }

    this.state = {
      editorState,
      initialUpdate
    }
  }
  
  componentDidUpdate(nextProps) {
    if (this.state.initialUpdate) {
      if (this.props.value !== nextProps.value && this.props.value !== undefined) {
        const { value } = this.props;
        const blocksFromHtml = htmlToDraft(value);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const newEditorState = EditorState.createWithContent(contentState);

        this.setState({
          editorState: newEditorState,
          initialUpdate: false
        });
      }
    }
    else {
      if(this.props.value === "") {
         //reset editor when value is set to ''
         this.setState({
          editorState: clearEditorContent(this.state.editorState),
          initialUpdate: true
        });
      }
    }
  }

  onEditorStateChange = (editorState) => {
    const { name, onChangeField, savePlaintext, plaintextName } = this.props;

    if (editorState.getCurrentContent().getPlainText() === "") {
      onChangeField(name, "");
    } else {
      onChangeField(name, draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    if (savePlaintext && plaintextName !== undefined) {
      onChangeField(plaintextName, editorState.getCurrentContent().getPlainText());
    }

    this.setState({
      editorState,
      initialUpdate: false
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <React.Fragment>
        <Editor
          editorState={editorState}
          wrapperClassName={"demo-wrapper " + (this.props.wrapperClassName ? this.props.wrapperClassName : "")}
          editorClassName={"demo-editor " + (this.props.editorClassName ? this.props.editorClassName : "")}
          onEditorStateChange={this.onEditorStateChange}
          readOnly={this.props.readOnly}
          toolbarClassName={(this.props.toolbarClassName ? this.props.toolbarClassName : "") + ((this.props.toolbarHidden || this.props.readOnly) && " d-none")}
          toolbarHidden={this.props.toolbarHidden || this.props.readOnly}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: this.props.suggestions
          }}
        />

        {this.props.showHtml &&
          <React.Fragment>
            HTML:
            <textarea
              className="demo-textarea"
              disabled
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}


RichTextEditor.defaultProps = {
  showHtml: false,
  savePlaintext: false,
  suggestions: []
}

export default withBaseControl(RichTextEditor);