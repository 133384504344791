import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
// import _ from 'lodash';


class ErrorLabel extends React.PureComponent {

    // Fn: Render errorMessages shown
    ErrorMessage = ({ message, formatter }) => {
        // return _.template(message)(formatter);
        return message.replace("<%= label %>", formatter.label);
    };

    render() {
        return (
            <div>
                <Label className="label-feedback">
                    {this.ErrorMessage(this.props)}
                </Label>
            </div>
        );
    }
};

// PropTypes: For prop validation
ErrorLabel.propTypes = {
    message: PropTypes.string.isRequired,
    formatter: PropTypes.object
};

// PropTypes: Defaulting value for optional props
ErrorLabel.defaultProps = {
    formatter: {}
};


export default ErrorLabel;