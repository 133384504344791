import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { navigateTo } from '../../Shared/Actions';
import { LICENCE_CART_PATH } from '../../App/AppSettings';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

class LicenceCartModal extends React.Component {
    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }

    render() {
        const { t } = this.props;
        return (
            <div>
            <Modal size='lg' isOpen={this.props.isOpen} toggle={this.props.closeCallBack} className={'modal-content ' + this.props.className}>
                <ModalHeader toggle={this.props.closeCallBack} charCode='&#x1F5D9;'>{this.props.contentHeader}</ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='primary'
                        onClick={() => navigateTo(LICENCE_CART_PATH)}>
                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY)}
                    </Button>
                </ModalFooter>
            </Modal>
            </div>
        );
    }
}

// PropTypes: For prop validation
LicenceCartModal.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    //contentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeCallBack: PropTypes.func,
    addToCartCallBack: PropTypes.func,
    applyCallBack: PropTypes.func,
    isShowAddCart: PropTypes.bool,
    isShowApply: PropTypes.bool,
};

// PropTypes: Defaulting value for optional props
LicenceCartModal.defaultProps = {
    isOpen: false,
};


export default withTranslation()(withRouter(LicenceCartModal));