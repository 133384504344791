import React from 'react';
import { withModalWrapper } from '../../../Shared/Modal/ModalWrapper';
import SearchResultNotFound from '../../../Shared/SearchResultNotFound';
import QuestionItem from './QuestionItem';
import PropTypes from 'prop-types';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys'
import { withTranslation } from 'react-i18next';
import { getTransl } from '../../../../directus';

class QuestionContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            publishedQuestionArray: [],
            sortedCatQuestions: {},
        };
    }

    componentDidMount() {
        const { categorizedQuestions } = this.props;
        this.setState({
            sortedCatQuestions: this.sortQuestions(categorizedQuestions),
            pubQuestionArr: categorizedQuestions.map(questionSet => {
                if (questionSet.status === 'published') {
                    return questionSet.question.id;
                }
                else {
                    return undefined;
                }
            }).filter(a => a !== undefined)
        });
    }

    sortQuestions = (qGrp) => {
        qGrp.sort(function (a, b) { return a.question_order - b.question_order; });
        return qGrp;
    };

    renderCategoryImage = (imageUrl) => <img src={`${imageUrl}`} width="auto" height="50px"/>

    render() {
        const { updateBizIntentCart, bizIntentCart, categoryOrder, exclusiveLicenceCart, preReqLicences, t, currLang } = this.props;
        const { sortedCatQuestions, pubQuestionArr } = this.state;
        const hasQuestion = sortedCatQuestions && sortedCatQuestions.length > 0;
        return (
            <>
                {!hasQuestion ?
                    <SearchResultNotFound recommendBanner={false} />
                    :
                    categoryOrder
                        .map((category, i) => {
                            //check that category is not empty (there is at least one question that is shown), if true then show category
                            if (sortedCatQuestions.filter(qSet => qSet.question_category.id === category.id)
                                                .map(qSet => qSet.question.id)
                                                .some(qId => pubQuestionArr.includes(qId))) {
                                return (
                                    <div key={category.id}>
                                        <div className="heading-icon d-flex flex-row align-items-center p-0">
                                            {category.image && this.renderCategoryImage(category.image)}
                                            <div className="px-4">{getTransl(category, currLang).title}</div>
                                        </div>
                                        <div className='questionnaire-content'>
                                            {sortedCatQuestions.filter(qSet => qSet.question_category.id === category.id).map((qSet, i) => {
                                                return (
                                                    pubQuestionArr.includes(qSet.question.id) &&
                                                    <QuestionItem
                                                        key={qSet.question.id}
                                                        questionAnswerSet={qSet}
                                                        updateBizIntentCart={updateBizIntentCart}
                                                        bizIntentCart={bizIntentCart}
                                                        exclusiveLicenceCart={exclusiveLicenceCart}
                                                        preReqLicences={preReqLicences}
                                                        currLang={currLang}
                                                        isSubquestion={false}
                                                    />
                                                );
                                            })}
                                        </div>
                                        {/* if not last category, add separator */}
                                        {categoryOrder.length !== i + 1 && <div className='separator'></div>}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })

                }
            </>
        );
    }
}

QuestionContainer.propTypes = {
    updateBizIntentCart: PropTypes.func.isRequired,
    categoryOrder: PropTypes.array.isRequired,
    categorizedQuestions: PropTypes.array.isRequired
};

export default withTranslation()(withModalWrapper(QuestionContainer));
