import React from 'react';
import { compose } from 'recompose';
import { withPageNotFound } from '../Error';
import { withLoader } from '../Loading';
import { withModalWrapper } from '../Modal/ModalWrapper';

export const withSMARTWrapper = (FormComponent) => {

    class SMARTWrapper extends React.Component {

        render() {
            return (
                <FormComponent
                    {...this.props}
                />
            );
        }
    };

    return compose(withModalWrapper, withPageNotFound, withLoader)(SMARTWrapper);
};