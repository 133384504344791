const initialState = {};

function rootReducer(state = initialState, action) {
    
    if (action.type === "NAVBAR_TOGGLE") {
    }
    
    return state;
};

export default rootReducer;