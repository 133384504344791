import { Response } from '../Constants';
import history from '../History';
import AuthHelper from '../Authentication/AuthHelper';

let mgr = AuthHelper.getManager();

// Const: Fetch Method Type
export const METHOD_TYPE = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

// Const: Fetch Content Type
export const CONTENT_TYPE = {
  JSON: 'Json',
  FORM_DATA: 'FormData'
}

//Form necessary params for SMARTForm usage
export const formParams = (method = METHOD_TYPE.POST, contentType = CONTENT_TYPE.FORM_DATA, queryString = {}) => {
  return {
    method: method,
    contentType: contentType,
    queryString: queryString
  }
}

// Fn: Send GET, POST, PUT or DELETE request to/from server-side
export const fetchRequest = async (serverURL, params, isDummy) => {
  if (params.method === METHOD_TYPE.GET) {
    return getRequest(serverURL, params, isDummy)
  }
  if (params.method === METHOD_TYPE.POST) {
    return postRequest(serverURL, params, isDummy)
  }
  if (params.method === METHOD_TYPE.PUT) {
    return putRequest(serverURL, params, isDummy)
  }
  if (params.method === METHOD_TYPE.DELETE) {
    return deleteRequest(serverURL, params, isDummy)
  }
}

//Form necessary params for GET usage
export const getParams = (queryString = {}) => {
  return {
    method: METHOD_TYPE.GET,
    queryString: queryString
  }
}

// Fn: Get data from server-side 
const getRequest = async (serverURL, params, isDummy) => {
  let getCall;

  // Retrieve token
  var token = await getAuthToken();

  // Set authorization header
  let headers = { 'Authorization': 'Bearer ' + token };

  if (isDummy) {
    getCall = fetch(serverURL, { headers: headers });
  }
  else {
    // Process Query String
    let queryString = params.queryString;

    for (let i in queryString) {
      if (queryString[i] !== "") {
        serverURL = serverURL.concat(i + "=" + queryString[i] + "&");
      }
    }
    getCall =
      fetch(serverURL, {
        method: METHOD_TYPE.GET,
        headers: headers
      });
  }

  return getCall
    .then((response) => {
      if (!response.ok) {
        throw response;
      }

      return response.json();
    })

    // Return response when result is successful
    .then((result) => Response(result, true, 200))
    // Process response when error is caught
    .catch((error) => Response(error, false, 500));
}

//Form necessary params for POST usage
export const postParams = (data = '', queryString = {}, contentType = CONTENT_TYPE.FORM_DATA) => {
  return {
    method: METHOD_TYPE.POST,
    data: (data === null || data === '') ? '' : data,
    contentType: contentType,
    queryString: queryString
  }
}


// Fn: Send data to server-side to create or process data
const postRequest = async (serverURL, params, isDummy) => {
  
  let postCall;

  // Retrieve token
  var token = await getAuthToken();

  // Set authorization header
  let headers = { 'Authorization': 'Bearer ' + token };

  if (isDummy) {
    postCall = fetch(serverURL, { headers: headers });
  }
  else {
    // Process Query String
    let queryString = params.queryString;
    for (let i in queryString) {
      if (queryString[i] !== "") {
        serverURL = serverURL.concat(i + "=" + queryString[i] + "&");
      }
    }

    // Append Content Type for JSON
    if (params.contentType === CONTENT_TYPE.JSON) {
      headers['Content-Type'] = 'application/json'
    }

    // Send POST Request to given url
    postCall =
      fetch(serverURL, {
        method: METHOD_TYPE.POST,
        headers: headers,
        body: params.data
      });
  }

  // In fetch API, you must tell response how to route in the FIRST .then()
  return postCall
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })

    // Return response when result is successful
    .then((result) => Response(result, true, 200))
    // Process response when error is caught
    .catch((error) => Response(error, false, 500));
};


//Form necessary params for PUT usage
export const putParams = (data = '', queryString = {}, contentType = CONTENT_TYPE.FORM_DATA) => {
  return {
    method: METHOD_TYPE.PUT,
    data: (data === null || data === '') ? '' : data,
    contentType: contentType,
    queryString: queryString
  }
}

// Fn: Send data to server-side to update data
const putRequest = async (serverURL, params, isDummy) => {
  let putCall;

  // Retrieve token
  var token = await getAuthToken();

  // Set authorization header
  let headers = { 'Authorization': 'Bearer ' + token };

  if (isDummy) {
    putCall = fetch(serverURL, { headers: headers });
  }
  else {
    // Process Query String
    let queryString = params.queryString;
    for (let i in queryString) {
      if (queryString[i] !== "") {
        serverURL = serverURL.concat(i + "=" + queryString[i] + "&");
      }
    }

    // Append Content Type for JSON
    if (params.contentType === CONTENT_TYPE.JSON) {
      headers['Content-Type'] = 'application/json'
    }

    // Send POST Request to given url
    putCall =
      fetch(serverURL, {
        method: METHOD_TYPE.PUT,
        headers: headers,
        body: params.data
      });
  }

  // In fetch API, you must tell response how to route in the FIRST .then()
  return putCall
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })

    // Return response when result is successful
    .then((result) => Response(result, true, 200))
    // Process response when error is caught
    .catch((error) => Response(error, false, 500));
};

//Form necessary params for DELETE usage
export const deleteParams = (data = '', queryString = {}, contentType = CONTENT_TYPE.FORM_DATA) => {
  return {
    method: METHOD_TYPE.DELETE,
    data: (data === null || data === '') ? '' : data,
    contentType: contentType,
    queryString: queryString
  }
}

// Fn: Send data to server-side to delete data
const deleteRequest = async (serverURL, params, isDummy) => {
  let deleteCall;

  // Retrieve token
  var token = await getAuthToken();

  // Set authorization header
  let headers = { 'Authorization': 'Bearer ' + token };

  if (isDummy) {
    deleteCall = fetch(serverURL, { headers: headers });
  }
  else {
    // Process Query String
    let queryString = params.queryString;
    for (let i in queryString) {
      if (queryString[i] !== "") {
        serverURL = serverURL.concat(i + "=" + queryString[i] + "&");
      }
    }

    // Append Content Type for JSON
    if (params.contentType === CONTENT_TYPE.JSON) {
      headers['Content-Type'] = 'application/json'
    }

    // Send POST Request to given url
    deleteCall =
      fetch(serverURL, {
        method: METHOD_TYPE.DELETE,
        headers: headers,
        body: params.data
      });
  }

  // In fetch API, you must tell response how to route in the FIRST .then()
  return deleteCall
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })

    // Return response when result is successful
    .then((result) => Response(result, true, 200))
    // Process response when error is caught
    .catch((error) => Response(error, false, 500));
};

// Fn: Navigate to specific pages
export const navigateTo = (nextURL, values) => {

  // Ensure scroll to top of page on navigation
  window.scrollTo(0, 0);

  // Perform navigation
  history.push({
    pathname: nextURL,
    state: {
      from: history.location.pathname,
      ...values
    }
  });
};

// Fn: Navigate to specific pages with Form Data
export const navigateToPageWithFormValues = (nextURL, values) => {

  // Ensure scroll to top of page on navigation
  window.scrollTo(0, 0);

  // Perform navigation
  history.push({
    pathname: nextURL,
    state: {
      from: history.location.pathname,
      FormValues: values
    }
  });
};

const getAuthToken = async () => {
  //Get JWT Token
  var user = await mgr.getUser();
  var token = "";

  if (user != null) {
    token = user.access_token;
  }
  
  return token;
};

export const getFileRequest = async(serverURL, isDummy, documentName) => {
  var token=await getAuthToken();

  // Send GET Request to given url
  let getCall= (isDummy) ?
  fetch(serverURL) :
  fetch(serverURL, {
    method:'GET',
    headers: { 'Authorization':'Bearer '+token }
  });

  // In fetch API, you must tell response how to route in the FIRST .then()
  return getCall
  .then((response) => {
    if (!response.ok) {
      throw response;
    }
    return response.blob();
  })
  // Return response when result is successful
  .then((blob) => {
    let url=window.URL.createObjectURL(blob);
    let a=document.createElement('a');
    a.href=url;
    a.download=documentName;
    a.click();
 })

// Process response when error is caught

 .catch((error) =>Response(error, false, 500));

};


export const isValidForm = (validationSchema, values) => {
  return validationSchema.isValidSync(values);
}
