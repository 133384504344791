import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IS_DEMO, SEARCH_LICENCE_BY_ID_LIST } from '../../App/AppSettings';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { CART_ACTION_ADD } from '../../Shared/Constants';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../../Content/DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withTranslation } from 'react-i18next';

class PreRequisiteModal extends React.Component {
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    navigateToNext = async (prerequisiteList, currentCart, updateCart, displayExclusiveModal) => {
        let closeModal = true;
        let RETRIEVE_URL = SEARCH_LICENCE_BY_ID_LIST;
        // Append id of licences to retrieve
        prerequisiteList.forEach(id => {
            RETRIEVE_URL += `idList=${id}&`;
        });

        // Retrieve licence details
        const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data } = response.body;
        if (response.success && IsSuccess) {
            const licenceList = Data;

            // For each licence retrieved, check if it has prerequisite and also check if it is already in the cart
            licenceList.forEach(async licence => {
                // If there are pre-requisite in the added licences that are not in the cart "continue" button will not close popup
                if (licence.PreRequisite.length !== 0 && !currentCart.some(existing => licence.PreRequisite.some(prereq => prereq.LicenceTypeId === existing.Id))) {
                    closeModal = false;
                }
                await updateCart(licence, CART_ACTION_ADD);
            });
        }

        // If closeModal is true, "continue" button will close pop up and call displayExclusiveModal to check for exclusive again
        if (closeModal) {
            this.props.closeCallBack();
            displayExclusiveModal();
        }
    };

    getPrerequisite = (currentCart, updateCart, displayExclusiveModal) => {
        const checkCart = [];

        // For each licence in cart, check if they have a prerequisite
        currentCart.forEach(cc => {
            if (cc.PreRequisite && cc.PreRequisite.length > 0) {
                // for each prerequisite, check if it exists in current cart, if it does not exist, add to checkCart
                cc.PreRequisite.forEach(required => {
                    if (!currentCart.some(existing => existing.Id === required.LicenceTypeId)) {
                        checkCart.push(required.LicenceTypeId);
                    }
                });
            }
        });
        this.navigateToNext(checkCart, currentCart, updateCart, displayExclusiveModal);
    };

    render() {
        const { className, closeCallBack, contentHeader, isOpen, displayExclusiveModal, t } = this.props;

        return (
            <LicenceCartContext.Consumer>
                {({ updateCart, currentCart }) => (
                    <div>
                        <Modal size='lg' isOpen={isOpen} className={`modal-content  ${className}`}>
                            <ModalHeader toggle={closeCallBack} charCode='&#x1F5D9;' className="hasIcon">{contentHeader}</ModalHeader>
                            <ModalBody className="div-modal-body">
                                <p>The following license(s) require pre-requisite licence(s):</p>
                                {
                                    // For each licence in current cart, check if it has prerequisite
                                    currentCart.map((cc, index) => {
                                        if (cc.PreRequisite && cc.PreRequisite.length > 0) {
                                            const title = '';
                                            return (
                                                <div className={`pre-req-licence-container`} key={index} hidden={false}>
                                                    {cc.PreRequisite.map((required, rindex) => {
                                                        // Check if prerequisite is in current cart
                                                        if (!currentCart.some(existing => existing.Id === required.LicenceTypeId) && title === '') {
                                                            const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(cc.Title);
                                                            return (
                                                                <p key={rindex} className="pre-req-display">{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, cc.Id))} requires:</p>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                    }
                                                    <ul className='licence-list'>
                                                        {
                                                            cc.PreRequisite.map((required, rindex) => {
                                                                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(required.LicenceName);
                                                                return (
                                                                    !currentCart.some(existing => existing.Id === required.LicenceTypeId) &&
                                                                    <li key={rindex}> {t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, required.LicenceTypeId))} </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                                <p className="pre-req-display label-error">
                                    The missing pre-requisite licence(s) will be added to your cart upon proceeding with your application
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color='primary'
                                    onClick={closeCallBack}>
                                    Back to cart summary
                                </Button>
                                <Button
                                    color='primary'
                                    onClick={() => this.getPrerequisite(currentCart, updateCart, displayExclusiveModal)}
                                >
                                    Continue
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                )}</LicenceCartContext.Consumer>
        );
    }
}

// PropTypes: For prop validation
PreRequisiteModal.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    //contentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeCallBack: PropTypes.func,
    addToCartCallBack: PropTypes.func,
    applyCallBack: PropTypes.func,
    isShowAddCart: PropTypes.bool,
    isShowApply: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
PreRequisiteModal.defaultProps = {
    isOpen: false
};


export default withTranslation()(withRouter(PreRequisiteModal));