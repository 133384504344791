import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import { LAYOUT_TYPE, SEARCH_ALL_LICENCE_PATH } from "../../App/AppSettings";
import Layout from '../../Layout';
import { SMARTForm } from '../../Shared/Forms';
import { LANGUAGE_KEYS } from '../Constants/LanguageKeys';
import { DirectionContextConsumer } from '../DirectionContext';

const FORM_INITIAL_VALUES = {
    title: '',
};

class DashboardSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            REDIRECT_URL: "",
            title: FORM_INITIAL_VALUES
        }
    }

    componentDidMount() {
    }

    searchValue = (values) => {
        const { currLang } = this.props.context;
        this.props.history.replace({ pathname: SEARCH_ALL_LICENCE_PATH, search: '?title=' + values.title + "&lang=" + currLang });
    }

    inputKeyDownHandler = (e, values) => {
        const { currLang } = this.props.context;
        if (e.key === 'Enter') {
            this.props.history.replace({ pathname: SEARCH_ALL_LICENCE_PATH, search: '?title=' + values.title + "&lang=" + currLang });

        }
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM}>
                    <SMARTForm formValues={this.state.title}>
                        {({ values, onChange }) => (
                            <React.Fragment>
                                <div className="input-group">
                                    <Row noGutters className="searchbar-container">
                                        <Col>
                                            <Input
                                                className="searchbar-input no-gutters"
                                                type="text"
                                                value={values.title}
                                                name="title"
                                                placeholder={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_SEARCHBOX)}
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={140}
                                                onKeyDown={(e) => this.inputKeyDownHandler(e, values)}
                                                innerRef={this.props.innerRef}
                                            />
                                            <span className="input-group-btn">
                                                <button className="btn btn-orange" type="button"
                                                    onClick={() => this.searchValue(values)}>
                                                    <i className="fal fa-search"></i>
                                                </button>
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ currLang }) => (
                        <WrappedComponent
                            context={{ currLang }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};
export default withTranslation()(withRouter(withDirectionContext(DashboardSearch)));
