import ReactHtmlParser from 'react-html-parser';

export const getLicenceTypeNameTranslationKey = (inputName) => {

    let licenceTypeName = inputName;
    if (inputName) {
        licenceTypeName = licenceTypeName.replace(/ /g, '_');
        licenceTypeName = licenceTypeName.replace(/[^0-9a-zA-Z_ ]/g, '');
    }
    return licenceTypeName;
};


export function getLangKey(translationKey, ...replacementKeys) {
    //spread operator for the keys that need to be replaced in
    const arr = [...replacementKeys];
    let count = 0;

    //assign each matched substring to argument array index on FCFS basis
    function replaceMatchedSubstring() {
        const str = arr[count];
        count++;
        return str;
    }

    return translationKey.replace(/%[0-9a-zA-Z]*%/g, replaceMatchedSubstring);
}

export const getTranslation = (t, string, isDisplayRichText = false) =>  {
    if (!isDisplayRichText) {
        return t(string);
    } else {
        return ReactHtmlParser(t(string));
    }
}

export const DISPLAY_RICH_TEXT = true;
