import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withModalWrapper } from '../../../Shared/Modal/ModalWrapper';
import LicenceDetailsModalContent from '../../DisplayComponents/LicenceDetailsModalContent';
import { DISPLAY_RICH_TEXT, getLicenceTypeNameTranslationKey, getTranslation } from '../../DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../DisplayComponents/DisplayUtils'; 

const MODAL_NAMES = { DETAILS: 'Details' };

const EmptyLicence = {
    Description: '',
    DocumentDesc: '',
    FaqDesc: '',
    FeeDesc: '',
    Id: '',
    TimeDesc: '',
    Title: '',
    Url: '',
    DocumentDict: {}
};

class LicenceCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stateLicence: this.props.licence ? this.props.licence : EmptyLicence
        };
    }

    // For the 'detail' button to open modal
    handleDetailClick = () => {
        this.props.modal.toggleModal(MODAL_NAMES.DETAILS);
    };

    render() {
        const { t, licence, modal } = this.props;
        const { stateLicence } = this.state;

        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(stateLicence.Title);
        return (
            <>
                <div className='required-licence'>
                    <h4>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h4>
                    <p>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId), DISPLAY_RICH_TEXT)}</p>
                    <div className='btn-licence-card' data-toggle='modal' data-target='#detailsModal' onClick={this.handleDetailClick}>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_MOREINFO)}</div>
                </div>
                {stateLicence && stateLicence !== EmptyLicence &&
                    <LicenceDetailsModalContent
                        stateLicence={stateLicence}
                        modal={modal}
                    />
                }
            </>
        );
    }
}

LicenceCard.propTypes = {
    licence: PropTypes.object.isRequired
};

export default withTranslation()(withModalWrapper(LicenceCard));
