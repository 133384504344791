import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { withBaseControl } from './BaseControl';


class Currency extends React.Component {

    render() {
        return (
            <InputGroup>
                <InputGroupAddon addonType="prepend">{this.props.symbol}</InputGroupAddon>
                <Input
                    type="number"
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    step={this.props.step}
                    min={this.props.minValue}
                    max={this.props.maxValue}
                />
            </InputGroup>
        );
    }
}

// PropTypes: For prop validation
Currency.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    step: PropTypes.number,
    minValue: PropTypes.number,
    symbol: PropTypes.string
};

// PropTypes: Defaulting value for optional props
Currency.defaultProps = {
    step: 0.01,
    minValue: 0,
    symbol: '$'
};


export default withBaseControl(Currency);