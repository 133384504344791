import React from 'react';
import { navigateTo } from '../../Shared/Actions';
import { SEARCH_INTENT_PATH } from '../../App/AppSettings';
import { withTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

class RecommendBanner extends React.Component {

  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
          <div className="recommend">
            <div className="recommend-blog">
                <h4>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_BUTTON_NEWTODS)}</h4>
                <p><strong>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_INFORMATION_IMAGEMESSAGE)}</strong></p>
                <h5>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_INFORMATION_IMAGEMESSAGE2)}</h5>
                <button onClick={() => navigateTo(SEARCH_INTENT_PATH)} className="btn-orange">{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_GETSTARTED)}</button>
            </div>

		    </div>
      </React.Fragment>
    );
  }
}


export default withTranslation()(RecommendBanner);