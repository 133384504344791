import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import AuthHelper from './AuthHelper.js';

class Logout extends Component {
  constructor() {
    super();
    this.mgr = AuthHelper.getManager();

    this.state = {
      path: 'Error/Page404'
    }
   
  }

  componentDidMount() {
    this.setState({
      isAuthenticated: false,
      profile: null
    });
    this.mgr.signoutRedirect();
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default withRouter(Logout);