import React from 'react';
import { Button } from 'reactstrap';
import { AccordionContainer } from '../../../Shared/Forms';
import RenderFieldComponent from '../RenderFieldComponent';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

class CommonInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionNames: undefined
        };
    }

    componentDidUpdate() {
        const { form } = this.props;

        const sectionNames = {};
        form.forEach(formSection => {
            sectionNames[formSection.Name] = { title: formSection.DisplayName, status: true };
        });

        this.setState(prevState => {
            if (prevState.sectionNames === undefined) {
                return {
                    sectionNames
                };
            } else { return null; }
        });
    }

    render() {
        const { values, onChange, onChangeField, errors, toggleSection, toggleAllSections } = this.props.smartFormValues;
        const { sectionNames } = this.state;
        const { form, optionDataList, t } = this.props;

        return (
            <>
                <div className='heading-bg'>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_COMMONINFORMATION)}</div>
                <div className='collapse-expand'>
                    <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(sectionNames, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                    <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(sectionNames, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                </div>
                {form.map((formSection, i) => {
                    return (
                        <React.Fragment key={formSection.DisplayName + i}>
                            {sectionNames &&
                                <AccordionContainer
                                    className='accordion'
                                    title={sectionNames && sectionNames[formSection.Name].title}
                                    active={sectionNames && sectionNames[formSection.Name].status}
                                    onClick={toggleSection}
                                    isIndividual={true}
                                    sections={sectionNames}
                                >
                                    <div className='card-body'>
                                        {formSection.Fields.map((field, index) => {
                                            return (<RenderFieldComponent
                                                key={index}
                                                formType={'CommonInformation'}
                                                field={field}
                                                values={values}
                                                onChange={onChange}
                                                onChangeField={onChangeField}
                                                errors={errors}
                                                optionDataList={optionDataList}
                                            />);
                                        })}
                                    </div>
                                </AccordionContainer>
                            }
                        </React.Fragment>
                    );
                })}
            </>
        );

    }
}

export default withTranslation()(CommonInformation);