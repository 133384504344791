import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ModalConfirm } from '../Modal';


class ActionButton extends React.Component {
    constructor(props) {
        super(props);

        // Merge state with props.modal object
        this.state = {
            isOpen: false,
            hasModal: false,
            modalHeader: "Confirm Action?",
            modalContent: "Action to Confirm",
            confirmText: "Yes",
            cancelText: "No",
            confirmCallback: this.props.onClick,
            cancelCallback: () => { },
            ...this.props.modal
        };
    }

    // Fn: Toggles state of Modal show/hide based on given modalName
    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    // Fn: Handles onClick event
    handleOnClick = () => {
        // If modal exist, toggle Modal
        // otherwise just launch onClick event
        (this.state.hasModal) ?
            (this.toggleModal()) :
            (this.props.onClick());
    };

    render() {
        return (
            <React.Fragment>
                {/* ActionButton component */}
                <Button
                    className={this.props.className + (this.props.disabled?" disabled":"")}
                    onClick={this.handleOnClick}
                    title={this.props.tooltip}
                    color={this.props.color}
                    disabled={this.props.disabled}
                >
                    {this.props.children}
                </Button>

                {/* Modal component */}
                <ModalConfirm
                    isOpen={this.state.isOpen}
                    contentHeader={this.state.modalHeader}
                    contentBody={this.state.modalContent}
                    confirmText={this.state.confirmText}
                    cancelText={this.state.cancelText}
                    confirmCallback={() => { this.state.confirmCallback(); this.toggleModal(); }}
                    cancelCallback={() => { this.state.cancelCallback(); this.toggleModal(); }}
                />
            </React.Fragment>
        );
    }
}

//PropTypes: For prop validation
ActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    tooltip: PropTypes.string,
    modal: PropTypes.shape({
        modalHeader: PropTypes.string,
        modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        confirmText: PropTypes.string,
        cancelText: PropTypes.string,
        confirmCallback: PropTypes.func,
        cancelCallback: PropTypes.func
    })
};

// PropTypes: Defaulting value for optional props
ActionButton.defaultProps = {
    className: 'action-btn',
    tooltip: ''
};


export default ActionButton;