import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import GoogleMapComponent from "./GoogleMapComponent";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 */
const CustomMapComponent = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
		};
	}

	setValue = (newValue) => {
		this.setState({ value: newValue }, () =>
			this.props.onChange(this.state.value)
		);
	};

	render() {
		return (
			<GoogleMapComponent
				value={this.state.value}
				onChange={(newValue) => this.setValue(newValue)}
			></GoogleMapComponent>
		);
	}
};

export default class CustomMap extends ReactComponent {
	/**
	 * This function tells the form builder about your component. It's name, icon and what group it should be in.
	 *
	 * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
	 */
	static get builderInfo() {
		return {
			title: "Map",
			group: "basic",
			weight: 70,
			schema: CustomMap.schema(),
		};
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema() {
		return ReactComponent.schema({
			type: "customMap",
		});
	}

	/**
	 * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
	 *
	 * @param DOMElement
	 * #returns ReactInstance
	 */
	attachReact(element) {
		return ReactDOM.render(
			<CustomMapComponent
				component={this.component} // These are the component settings if you want to use them to render the component.
				value={this.dataValue} // The starting value of the component.
				onChange={this.updateValue} // The onChange event to call when the value changes.
			/>,
			element
		);
	}

	/**
	 * Automatically detach any react components.
	 *
	 * @param element
	 */
	detachReact(element) {
		if (element) {
			ReactDOM.unmountComponentAtNode(element);
		}
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		this.dataValue = value;
		if (this.reactInstance) this.attachReact(this.reactInstance);
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		flags = flags || {};
		const newValue =
			value === undefined || value === null ? this.getValue() : value;
		const changed =
			newValue !== undefined
				? this.hasChanged(newValue, this.dataValue)
				: false;
		this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

		this.updateOnChange(flags, changed);
		return changed;
	};

	/**
	 * Get the current value of the component. Should return the value set in the react component.
	 *
	 * @returns {*}
	 */
	getValue() {
		if (this.dataValue) {
			return this.dataValue;
		}
		return this.defaultValue;
	}
}
