import React from 'react';
import { Row } from 'reactstrap';


class PageFooter extends React.Component {

  renderFooter(param) {
    switch (param) {
        case 1:
        default:
            return (
              <Row>
              <div className={"page-footer col"}>
                {this.props.children}
              </div>
            </Row>
            );
        case 2:
            return (
              <Row>
                <div className={"page-footer col page-card-footer"}>
                  {this.props.children}
                </div>
              </Row>
            );
    }
}
  
  render() {
    return (
      <React.Fragment>
        {this.renderFooter(this.props.type)}
      </React.Fragment>
    );
  }
}


export default PageFooter;