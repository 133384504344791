import React, { Suspense } from 'react';
import Spinner from './components/Shared/Loading/Spinner';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import registerServiceWorker from './registerServiceWorker';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import './i18n';
import Site from './components/Site';

const store = createStore(rootReducer, composeWithDevTools());

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner visible={true} />}>
            <Site />
            <App />
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();