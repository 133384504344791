import { faHospital, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Fade } from 'reactstrap';
import { CHANGE_PASSWORD_URL, CHANGE_SECURITY_INFO_URL, CHANGE_USERNAME_URL, GET_APP_ENV,
    INBOX_PATH, SMART_ME_PROFILE_CLIENT_URL } from '../App/AppSettings';
import { navigateTo } from '../Shared/Actions';
import { AuthConsumer } from '../Shared/Authentication/AuthContext';
import { withLoader } from '../Shared/Loading';
import { redirect } from '../Shared/SmartMe/Actions';

// Const: Title of Dropdown titles resided in NavHeader
const NAVHEADER_DROPDOWN_TITLE = {
    MY_PROFILE: 'My Profile',
    CHANGE_USERNAME: 'Change Username',
    CHANGE_PASSWORD: 'Change Password',
    CHANGE_SECURITY_INFO: 'Change Security Info',
    LOGOUT: 'Log Out'
};

class Profile extends React.Component {
    constructor(props) {
        super(props);

        const { loader } = this.props;

        this.state = {
            loader: loader,
            isActive: false
        };
    }

    // Fn: Toggle between active state
    toggleActiveState = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    toggleProfileDisplay = () => {
        if (this.props.profileDisplay) {
            return "d-block"
        }
        else {
            return "d-none"
        }
    }

    render() {
        return (
            <AuthConsumer>
                {({ isAuthenticated, profile, isSecurityQuestionsPresent, userAccountSettings, logout, login }) => (((!isAuthenticated) && (
                    <Col className={"col-auto profile " + this.toggleProfileDisplay()}>
                        <Button color="neutral" onClick={() => navigateTo(INBOX_PATH)}>
                            Login
                        <FontAwesomeIcon icon={faHospital} className="login-button" fixedWidth={true} />
                        </Button>
                    </Col>
                )) || ((profile && isAuthenticated) && (
                    <Col className={"col-auto profile " + this.toggleProfileDisplay()}>
                        <Dropdown isOpen={this.state.isActive} toggle={this.toggleActiveState} className="profile-dropdown">
                            <DropdownToggle tag="div" data-toggle="dropdown" data-boundary="viewport" aria-expanded="false">
                                <div className="btn align-self-center" >
                                    <i className="fa-2x align-middle"><FontAwesomeIcon icon={faUserCircle} /></i>
                                    <span className="align-middle profile-text dropdown-toggle p-1">{profile.preferred_username}</span>
                                </div>
                            </DropdownToggle>
                            <Fade in={this.state.isActive} timeout={100}>
                                <DropdownMenu right className="navbar-header-dropdown">
                                    <div onClick={(e) => { e.preventDefault(); redirect(SMART_ME_PROFILE_CLIENT_URL) }}>
                                        <DropdownItem>{NAVHEADER_DROPDOWN_TITLE.MY_PROFILE}</DropdownItem>
                                    </div>
                                    <DropdownItem divider />
                                    {userAccountSettings && userAccountSettings.ChangeUsernameEnabled && GET_APP_ENV &&
                                        <React.Fragment>
                                            <div onClick={(e) => { e.preventDefault(); redirect(CHANGE_USERNAME_URL) }}>
                                                <DropdownItem>{NAVHEADER_DROPDOWN_TITLE.CHANGE_USERNAME}</DropdownItem>
                                            </div>
                                            <DropdownItem divider />
                                        </React.Fragment>
                                    }
                                    <div onClick={(e) => { e.preventDefault(); redirect(CHANGE_PASSWORD_URL) }}>
                                        <DropdownItem>{NAVHEADER_DROPDOWN_TITLE.CHANGE_PASSWORD}</DropdownItem>
                                    </div>
                                    <DropdownItem divider />
                                    {isSecurityQuestionsPresent &&
                                        <React.Fragment>
                                            <DropdownItem divider />
                                            <div onClick={(e) => { e.preventDefault(); redirect(CHANGE_SECURITY_INFO_URL) }}>
                                                <DropdownItem>{NAVHEADER_DROPDOWN_TITLE.CHANGE_SECURITY_INFO}</DropdownItem>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <div
                                            href="#"
                                            onClick={logout}>
                                            {NAVHEADER_DROPDOWN_TITLE.LOGOUT}
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Fade>
                        </Dropdown>
                    </Col>
                )))}
            </AuthConsumer>
        );
    }
}

export default withLoader(Profile);