import { default as React } from 'react';
import { withRouter } from 'react-router-dom';
import { Input } from 'reactstrap';
import { GET_ALL_INDUSTRY_LIST_URL, GET_SUB_INDUSTRY_LIST_URL, IS_DEMO, LAYOUT_TYPE, SEARCH_ALL_INDUSTRY_LIST_URL, SEARCH_ALL_INDUSTRY_PATH, SEARCH_ALL_LICENCE_PATH } from '../../App/AppSettings';
import Layout from '../../Layout';
import { getParams, fetchRequest } from '../../Shared/Actions';
import { withSMARTWrapper } from '../../Shared/Forms';
import DetailContainer from './DetailContainer';
import ResultContainer from './ResultContainer';
import { DIRECTIONS } from '../../Shared/Constants';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DirectionContextConsumer } from '../../Shared/DirectionContext';
import qs from 'query-string';

const GET_ALL_INDUSTRY = GET_ALL_INDUSTRY_LIST_URL;
const SEARCH_ALL_INDUSTRY = SEARCH_ALL_INDUSTRY_LIST_URL;
const GET_SUB_INDUSTRY_LIST = GET_SUB_INDUSTRY_LIST_URL;

const INITIAL_VALUES = '*'; //will use this to take it that i will need to load all
const BLANK_VALUE = '';
const FORM_INITIAL_VALUES = {
    title: '',
};

class SearchAllIndustry extends React.Component {
    constructor(props) {
        super(props);
        let searchTerm;
        const parsedStr = qs.parse(this.props.history.location.search);
        if (parsedStr.title) {
            searchTerm = parsedStr.title;
        }

        this.state = {
            values: '*',
            selectedId: null,
            isResultDisplayed: false,
            industries: [],
            subIndustries: [],
            displayValue: BLANK_VALUE,
            searchTerm
        };
    }

    componentDidMount() {
        this.getIndustry();
    }

    getIndustry = async () => {
        const { loader } = this.props;
        const { currLang } = this.props.context;
        const parsedStr = qs.parse(this.props.history.location.search);
        const queryString = Object.assign({}, FORM_INITIAL_VALUES, { title: this.state.searchTerm, lang: currLang });

        // Start loading
        loader.start();

        let response;
        if (!this.state.searchTerm) {
            if (parsedStr.lang !== currLang) {
                this.props.history.replace({ pathname: SEARCH_ALL_INDUSTRY_PATH, search: `lang=${currLang}` });
            }
            response = await fetchRequest(GET_ALL_INDUSTRY, getParams(), IS_DEMO);
        }
        else {
            if (parsedStr.lang !== currLang || parsedStr.title !== this.state.searchTerm ) {
                this.props.history.replace({ pathname: SEARCH_ALL_INDUSTRY_PATH, search: `?title=${this.state.searchTerm}&lang=${currLang}` });
            }

            response = await fetchRequest(SEARCH_ALL_INDUSTRY, getParams(queryString), IS_DEMO);
        }
        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {
                this.state.displayValue = this.state.values;
                this.setState(() => {
                    return {
                        industries: Data,
                        selectedId: null,
                        subIndustries: [],
                        industryTitle: BLANK_VALUE,
                        industryDesc: BLANK_VALUE,
                    };
                });
            } else {
                this.setState(() => {
                    return {
                        industries: [],
                        selectedId: null,
                        subIndustries: [],
                        industryTitle: BLANK_VALUE,
                        industryDesc: BLANK_VALUE,
                    };
                });
            }
        }

        // End loading
        loader.done();
    };

    displayIndustryResultContainer = () => {
        return (
            <ResultContainer
                industries={this.state.industries}
                criteria={this.state.displayValue}
                handleItemClick={this.handleIndustryClick}
                selectedId={this.state.selectedId}
                isResultDisplayed={this.state.isResultDisplayed}
            />
        );
    };

    //clicking the industry will load the related subindustry
    handleIndustryClick = async (id, title, description, codeName) => {
        //execute some function, set the Title and Desc
        const { loader } = this.props;
        this.setState(() => {
            return {
                selectedId: id
            };
        });

        const queryString = Object.assign({}, { id });

        // Start loading
        loader.start();

        const response = await fetchRequest(GET_SUB_INDUSTRY_LIST, getParams(queryString), IS_DEMO);//todo
        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {
                this.state.displayValue = this.state.values;
                this.setState(() => {
                    return {
                        subIndustries: Data,
                        industryTitle: title,
                        industryDesc: description,
                        industryCodeName: codeName
                    };
                });
            }
            else {
                this.setState(() => {
                    return {
                        subIndustries: [],
                        industryTitle: BLANK_VALUE,
                        industryDesc: BLANK_VALUE,
                        industryCodeName: BLANK_VALUE
                    };
                });
            }

        }

        // End loading
        loader.done();
    };


    displaySubIndustryDetailContainer = () => {

        return (
            <DetailContainer
                subIndustries={this.state.subIndustries}
                value={this.state.displayValue}
                industryCodeName={this.state.industryCodeName}
            />
        );
    };

    inputOnChangeHandler = (e) => {
        if (e.target.value === undefined || e.target.value === '') {
            this.setState({
                values: INITIAL_VALUES,
                searchTerm: ''
            });
        }
        else {
            this.setState({
                searchTerm: e.target.value
            });
        }
    };

    searchValue = () => {
        const values = this.state.values;
        if (values === undefined || values === '') {
            this.setState({
                values: INITIAL_VALUES
            });
        }
        this.getIndustry();
    };

    inputKeyDownHandler = e => {
        if (e.key === 'Enter') {
            const values = this.state.values;
            if (values === undefined || values === '') {
                this.setState({
                    values: INITIAL_VALUES
                });
            }
            this.getIndustry();
        }
    };

    toggleViewOfAgencyResults = () => {
        this.setState(prevState => {
            return {
                isResultDisplayed: !prevState.isResultDisplayed
            };
        });
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className="main-content">
                        <nav aria-label="breadcrumb" className="breadcrumb-main">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={process.env.PUBLIC_URL+SEARCH_ALL_LICENCE_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCH)}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_TITLE_INDUSTRY)}</li>
                                </ol>
                            </div>
                        </nav>
                        <div className="container">
                            <div className="browse-by-agency">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="find-apply">
                                            <div className="licvence-serach">
                                                <div className="input-group">
                                                    <Input
                                                        dir={DIRECTIONS.AUTO}
                                                        className=""
                                                        type="text"
                                                        value={this.state.searchTerm}
                                                        name="title"
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_PLACEHOLDER_SEARCHINDUSTRY)}
                                                        onChange={this.inputOnChangeHandler}
                                                        minLength={0}
                                                        maxLength={140}
                                                        onKeyDown={this.inputKeyDownHandler}
                                                    />                                                    <span className="input-group-btn">
                                                        <button className="btn btn-orange" type="button" onClick={this.searchValue}><i className="fal fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mobile-sub-nav" onClick={this.toggleViewOfAgencyResults}>Industries</h4>
                                        {this.displayIndustryResultContainer()}

                                    </div>
                                    <div className="col-lg-8">
                                        {this.displaySubIndustryDetailContainer()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>

        );
    }
}

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ isStartSearchWithChangedLang, currLang, finishSearchWithChangedLang }) => (
                        <WrappedComponent
                            context={{ isStartSearchWithChangedLang, currLang, finishSearchWithChangedLang }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};

export default withTranslation()(withRouter(withSMARTWrapper(withDirectionContext(SearchAllIndustry))));

