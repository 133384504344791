import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { DASHBOARD_PATH, GET_OFFLINE_SUCCESS_URL, IS_DEMO, LAYOUT_TYPE } from '../../App/AppSettings';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import { getLangKey, getLicenceTypeNameTranslationKey, getTranslation } from '../DisplayComponents/DisplayUtils';

const INITIAL_VALUES = 0;
const GET_SUCCESS = GET_OFFLINE_SUCCESS_URL;

const EmptyOrderDetails = [{
    LicenceName: '',
    PaymentType: ''
}];

class OfflineSuccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderNumber: INITIAL_VALUES,
            applicationList: EmptyOrderDetails
        };
    }

    componentDidMount() {
        this.getSuccess();
    }

    getSuccess = async () => {

        // Start loading
        const { loader, t } = this.props;
        loader.start();

        let paramValue = INITIAL_VALUES;


        if (history.location.state !== undefined) {
            paramValue = history.location.state.Data.OrderList;
        }

        const queryString = Object.assign({}, { orderList: paramValue });

        const response = await fetchRequest(GET_SUCCESS, getParams(queryString), IS_DEMO);

        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {

                this.setState(() => {
                    return {
                        submissionNumber: Data.SubmissionNumber,
                        applicationList: Data.ApplicationList
                    };
                });

            }
            else {
                this.setState(() => {
                    return { applicationList: EmptyOrderDetails };
                });
            }

        }
        else {
            //show error page
            this.setState(() => {
                return { applicationList: EmptyOrderDetails };
            });
            alert(t(ERROR.ERROR));
        }

        // End loading
        loader.done();
    }




    render() {
        const { t } = this.props;
        return (

            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className="main-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="success-block">

                                        <i className="fas fa-check-circle"></i>
                                        <h4>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSUBMISSIONCOMPLETE)}</h4>
                                        <h5> {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_NOTE_SUBMISSIONNUM)} <b>{this.state.submissionNumber}</b></h5>
                                        <h5>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_NOTE_OFFLINEPAYMENTTEXT)}</h5>

                                        <div className="data-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME)}</th>
                                                            <th>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_APPLICATIONNO)}</th>
                                                            <th>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_MODEPAYMENT)}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.applicationList.map((item, i) => {

                                                            const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(item.LicenceName);

                                                            return (
                                                                <tr key={i}>
                                                                    <td>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, item.LicenceTypeId))}</td>
                                                                    <td>{item.ReferenceNumber}</td>
                                                                    <td>{item.PaymentType}</td>
                                                                </tr>
                                                            );
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="button-center">
                                        <button className="btn" onClick={() => navigateTo(DASHBOARD_PATH)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </Layout>


            </React.Fragment>



        );



    }
}

export default withTranslation()(withRouter(withSMARTWrapper(OfflineSuccess)));

