import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import './Faq.css';

class AccordionTopicSubTopic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            active: false,
            collapseIcon: faAngleDown
        };

    }

    Caret = () => {
        return (this.props.active) ? faAngleUp : faAngleDown;
    };

    toggleCollapse = () => {
        this.setState((prevState) => {
            return {
                active: (!prevState.active),
                collapseIcon: ((prevState.active) ? (faAngleDown) : (faAngleUp))
            };
        });
    };

    render() {
        const { topic, handleOnClickSubTopic, selectedSubTopic } = this.props;
        return (
            <>
                <Card className='card-container'>
                    <CardBody className={this.state.active ? 'key-card-body active' : 'key-card-body closed'}>
                        <Row>
                            <Col className='accordion-topic'>
                                <div>{topic.TopicName}</div>
                            </Col>
                            <Col className='accordion-topic-caret'>
                                <FontAwesomeIcon className='key-card-action-button action-button-group'
                                    fixedWidth
                                    icon={this.state.collapseIcon}
                                    onClick={() => this.toggleCollapse()}
                                />
                            </Col>
                        </Row>

                        <Collapse isOpen={this.state.active} className='subtopic-collapse'>
                            {topic.SubTopics.map((subTopic, subTopicIndex) => (

                                <Row key={'subTopic' + subTopicIndex}>
                                    <Col
                                        className={subTopic === selectedSubTopic
                                            ? 'accordion-sub-topic active'
                                            : 'accordion-sub-topic closed'}
                                        onClick={() => handleOnClickSubTopic(subTopic.QuestionSets, topic, subTopic)}
                                    >
                                        {subTopic.SubTopicName}
                                    </Col>
                                </Row>
                            ))}
                        </Collapse>
                    </CardBody>
                </Card>

            </>
        );
    }
}

export default AccordionTopicSubTopic;
