import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { ModalConfirm } from '../../Shared/Modal';
import { TABLE_LAYOUT } from '../../App/AppSettings';
import { AccordionContainer, Plaintext } from '../../Shared/Forms';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import LicenceSummaryModal from '../FormComponents/LicenceSummary/LicenceSummaryModal';
import LicenceOverviewContents from '../LicenceDetails/LicenceOverviewContents';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';

const MODAL_NAME_REVIEW = 'REVIEW_LICENCES';
const MODAL_NAMES = { SUBMIT: 'Submit', TablePopUp: 'TablePopUp' };
const LABEL_SIZE = 6;
const INPUT_SIZE = 6;
const IS_UPPER = false;

class CancelLicenceReviewModalContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //Check whether webpage is loaded
            isLoaded: false,
            sectionNames: undefined
        };
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    componentDidMount() {
        const { formDefinition } = this.props;
        const sectionNames = {};

        formDefinition.Sections.forEach(formSection => {
            sectionNames[formSection.Name] = { title: formSection.DisplayName, status: true };
        });

        this.setState(prevState => {
            if (prevState.sectionNames === undefined) {
                return {
                    sectionNames,
                    isLoaded: true
                };
            }
            else {
                return null;
            }
        });
    }

    handleReviewClick(modal) {
        modal.toggleModal(MODAL_NAME_REVIEW);
    };

    render() {
        const { t, modal, formName, toggleSection, licence, formType, values, displayDocuments, documentList, formDefinition } = this.props;
        const { isLoaded, sectionNames } = this.state;

        const SECTION_NAMES = {
            CANCELLATION_INFORMATION: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_CANCELLATIONINFORMATION), status: true },
            LICENCE_OVERVIEW: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SERVICEOVERVIEW), status: true }
        };

        return (
            isLoaded && <React.Fragment>
                <div className="review-submit-blog">
                    <div className="review-header">
                        <h4>{formName}</h4>
                        <div className="review-action">
                            {/*note: cannot use <button> for some reason so color has to be passed in to get rid of the default secondary color */}
                            <Button className="btn-bordered-small-review-info" color="grey"
                                onClick={() => this.handleReviewClick(modal)}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_REVIEWINFORMATION)}
                            </Button>

                            {/* <button className="btn btn-bordered-small">Edit</button> */}
                        </div>
                    </div>
                    {displayDocuments && documentList.length > 0 ?
                        <div className="review-details">
                            <h5>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_DOCUMENTSSUBMITTED)}</h5>
                            {documentList.map((doc, i) =>
                                <p
                                    key={i} className="file-pdf icons">{doc}
                                </p>
                            )}
                        </div>
                        : null
                    }

                </div>

                <LicenceSummaryModal
                    isOpen={modal.modalState === MODAL_NAME_REVIEW}
                    closeCallBack={() => modal.toggleModal(MODAL_NAME_REVIEW)}
                    contentHeader={this.props.formName}
                >
                    <AccordionContainer
                        title={SECTION_NAMES.CANCELLATION_INFORMATION.title}
                        active={SECTION_NAMES.CANCELLATION_INFORMATION.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_REMARKS)}
                            name={'Remarks'}
                            value={values[formType]['Remarks']}
                            isUpper={IS_UPPER}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_EFFECTIVEENDDATE)}
                            name={'EndDate'}
                            value={values[formType]['EndDate']}
                            isUpper={IS_UPPER}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                    </AccordionContainer>
                    <AccordionContainer
                        title={SECTION_NAMES.LICENCE_OVERVIEW.title}
                        active={SECTION_NAMES.LICENCE_OVERVIEW.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        <>
                            <LicenceOverviewContents
                                formType={formType}
                                values={values}
                                licence={licence}
                            />
                        </>
                    </AccordionContainer>
                    {
                        formDefinition.Sections.map((section, index) => {
                            return (
                                <>
                                    <AccordionContainer
                                        key={index}
                                        title={sectionNames && sectionNames[section.Name].title}
                                        active={sectionNames && sectionNames[section.Name].status}
                                        onClick={toggleSection}
                                        isIndividual={true}
                                        sections={sectionNames}
                                    >
                                        <div className="card-body">
                                            <div className="form-body form-preview">
                                                <div key={index} className="multi-row-form">
                                                    <h3>{section.DisplayName}</h3>
                                                    {
                                                        section.Fields && section.Fields.map((field, findex) => {
                                                            return (
                                                                <Plaintext
                                                                    key={findex}
                                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                    name={field.Name}
                                                                    value={values[formType][field.Name]}
                                                                    isUpper={IS_UPPER}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            );
                                                        })
                                                    }
                                                    {
                                                        section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                                        <Table className="griddle-table">
                                                            <thead className="griddle-table-heading">
                                                                <tr>
                                                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                                        return (
                                                                            <th key={findex} className="griddle-table-heading-cell">{field.DisplayName}</th>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="griddle-table-body">
                                                                {values[formType][section.Name] && values[formType][section.Name].map((row, rindex) => {
                                                                    return (
                                                                        <tr key={rindex} className="griddle-row">
                                                                            {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                                                return (
                                                                                    <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                                                        {
                                                                                            field.InputType !== 'FileUpload' && <Plaintext
                                                                                                className="modal-plaintext"
                                                                                                key={findex + rindex}
                                                                                                name={field.Name}
                                                                                                value={row[field.Name]}
                                                                                                inputSize={INPUT_SIZE}
                                                                                                labelSize={null}
                                                                                                label={undefined}
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    }
                                                    {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                                        <Table className="griddle-table">
                                                            <thead className="griddle-table-heading">
                                                                <tr>
                                                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                                        return (
                                                                            <th key={findex} className="griddle-table-heading-cell">{field.DisplayName}</th>
                                                                        );
                                                                    })}
                                                                    <th className="griddle-table-heading-cell">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="griddle-table-body">
                                                                {values[formType][section.Name] && values[formType][section.Name].map((row, rindex) => {
                                                                    return (
                                                                        <tr key={rindex} className="griddle-row">
                                                                            {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                                                return (
                                                                                    <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                                                        {
                                                                                            field.InputType !== 'FileUpload' && <Plaintext
                                                                                                className="modal-plaintext"
                                                                                                key={findex + rindex}
                                                                                                name={field.Name}
                                                                                                value={row[field.Name]}
                                                                                                inputSize={INPUT_SIZE}
                                                                                                labelSize={null}
                                                                                                label={undefined}
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                            <td className="griddle-cell table-griddle-cell">
                                                                                <div className="action-button-group table-button">
                                                                                    <Button
                                                                                        className="action-btn"
                                                                                        color="forward"
                                                                                        type="button"
                                                                                        onClick={() => this.toggleModal(MODAL_NAMES.TablePopUp, rindex)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faEye} />
                                                                                    </Button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                <ModalConfirm
                                                                    className="modal-table"
                                                                    isOpen={this.state.modalState === MODAL_NAMES.TablePopUp}
                                                                    contentHeader={`View ${section.DisplayName}`}
                                                                    contentBody={
                                                                        values[formType][section.Name] && values[formType][section.Name][this.state.modalID] &&
                                                                        section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                                                            return (
                                                                                <div key={findex + this.state.modalID}>
                                                                                    {
                                                                                        field.InputType !== 'FileUpload' && <Plaintext
                                                                                            key={findex + this.state.modalID}
                                                                                            className="modal-plaintext"
                                                                                            name={field.Name}
                                                                                            label={field.DisplayName}
                                                                                            value={values[formType][section.Name][this.state.modalID][field.Name]}
                                                                                            labelSize={LABEL_SIZE}
                                                                                            inputSize={INPUT_SIZE}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                    confirmText="Close"
                                                                    confirmCallback={() => { this.toggleModal(MODAL_NAMES.TablePopUp, this.state.modalID); }}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionContainer>
                                </>
                            );
                        })
                    }
                </LicenceSummaryModal>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withModalWrapper(CancelLicenceReviewModalContent));