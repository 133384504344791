import { Button, Dropdown, Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

  class DropdownButton extends React.Component {
      
  menu = ({menuItems, onChange}) => {    
    return (
    <Menu onClick={onChange}>
      {menuItems.map((obj) => {
        return (
            <Menu.Item key={obj.value}>{obj.label}</Menu.Item>
        );
      })}
    </Menu>
    )
  }


  render(){
      return(
        <Dropdown disabled={this.props.disabled} className={this.props.className} overlay={this.menu(this.props)} placement={this.props.placement}>
            <Button disabled={this.props.disabled}>
            {this.props.buttonLabel} <Icon type="down"/>
            </Button>
        </Dropdown>
    )
  }
}

// PropTypes: For prop validation
DropdownButton.propTypes = {
  disabled: PropTypes.bool
};


export default DropdownButton;