import i18n from "i18next";
import { GET_CODE_BY_CODE_CATEGORY } from "../../App/AppSettings";
import { fetchRequest, getParams } from "../../Shared/Actions";
import {
  ERROR,
  LANGUAGE_KEYS,
  WIZARD_TAB_NAMES,
} from "../../Shared/Constants/LanguageKeys";
import { toastError } from "../../Shared/Forms/Toaster";
import { getLangKey } from "../DisplayComponents/DisplayUtils";

export const getFormNames = (formName, documentList, paymentRequired) => {
  // Have to add in order
  const result = {};
  // First add General Information
  result[i18n.t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)] = i18n.t(
    WIZARD_TAB_NAMES.GENERAL_INFORMATION
  );

  result[formName] = formName;
  if (documentList.length > 0) {
    result[i18n.t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)] = i18n.t(
      WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS
    );
  }
  result[i18n.t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)] = i18n.t(
    WIZARD_TAB_NAMES.REVIEW_INFORMATION
  );

  if (paymentRequired) {
    result[i18n.t(WIZARD_TAB_NAMES.PAYMENTS)] = i18n.t(
      WIZARD_TAB_NAMES.PAYMENTS
    );
  }
  return result;
};

//get the options required and store them in a dictionary in the label,value,key format that is use by the form inputs
export const getRemoteList = async (t, fields) => {
  let CODE_URL = `${GET_CODE_BY_CODE_CATEGORY}?`;
  fields.forEach((field) => {
    CODE_URL += `categories=${field}&`;
  });

  const response = await fetchRequest(CODE_URL, getParams());
  const { IsSuccess, Data, Messages } = response.body;
  const optionObj = {};

  if (response.success && IsSuccess) {
    const codes = Data;
    fields.forEach((field) => {
      const options = [];

      codes.forEach((code) => {
        if (code.CodeCategory === field) {
          const label = i18n.exists(
            getLangKey(
              LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY,
              code.CodeCategory,
              code.CodeName
            )
          )
            ? i18n.t(
                getLangKey(
                  LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY,
                  code.CodeCategory,
                  code.CodeName
                )
              )
            : code.CodeTitle;

          options.push({
            label,
            value: code.CodeTitle,
            key: code.CodeName,
          });
        }
      });
      optionObj[field] = options;
    });
  } else {
    toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
  }
  return optionObj;
};

//get the options required and store them in a dictionary in the label,value,key format that is use by the form inputs
export const getGeneralRemoteList = async (t, fields) => {
  let CODE_URL = `${GET_CODE_BY_CODE_CATEGORY}?`;
  fields.forEach((field) => {
    CODE_URL += `categories=${field}&`;
  });

  const response = await fetchRequest(CODE_URL, getParams());
  const { IsSuccess, Data, Messages } = response.body;
  const optionObj = {};

  if (response.success && IsSuccess) {
    const codes = Data;
    fields.forEach((field) => {
      const options = [];

      codes.forEach((code) => {
        if (code.CodeCategory === field) {
          options.push({
            label: code.CodeTitle,
            value: code.CodeTitle,
            key: code.CodeTitle,
          });
        }
      });
      optionObj[field] = options;
    });
  } else {
    toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
  }
  return optionObj;
};

export const premiseInformation = [
  "PremiseInformation_BlockHouseNo",
  "PremiseInformation_Buildingname",
  "PremiseInformation_Level",
  "PremiseInformation_PostalCode",
  "PremiseInformation_Streetname",
  "PremiseInformation_Unitnumber",
];

export const getTableListValues = (tableValueKeys, sectionName) => {
  const listOfTable = [];
  let tableIndex = 0;
  const sectionObjTable = {};
  tableValueKeys &&
    tableValueKeys.forEach((tv) => {
      if (
        `${tv.split(".")[0]}` === sectionName &&
        `${tv.split(".")[0]}.${tv.split(".")[1]}` ===
          `${sectionName}.${tableIndex}` &&
        listOfTable.indexOf(`${tv.split(".")[0]}.${tv.split(".")[1]}`) < 0
      ) {
        listOfTable.push(`${tv.split(".")[0]}.${tv.split(".")[1]}`);
        tableIndex++;
      }
    });
  if (listOfTable.length !== 0) {
    sectionObjTable[sectionName] = listOfTable;
    return sectionObjTable;
  } else {
    return null;
  }
};

export const getPremiseOperatingAddress = (LicenceInformation) => {
  let {
    PremiseInformation_BlockHouseNo,
    PremiseInformation_Buildingname,
    PremiseInformation_Level,
    PremiseInformation_PostalCode,
    PremiseInformation_Streetname,
    PremiseInformation_Unitnumber,
  } = LicenceInformation;

  let Address = "-";

  PremiseInformation_BlockHouseNo = PremiseInformation_BlockHouseNo
    ? PremiseInformation_BlockHouseNo
    : "";
  PremiseInformation_Buildingname = PremiseInformation_Buildingname
    ? PremiseInformation_Buildingname
    : "";
  PremiseInformation_Level = PremiseInformation_Level
    ? PremiseInformation_Level
    : "";
  PremiseInformation_PostalCode = PremiseInformation_PostalCode
    ? PremiseInformation_PostalCode
    : "";
  PremiseInformation_Streetname = PremiseInformation_Streetname
    ? PremiseInformation_Streetname
    : "";
  PremiseInformation_Unitnumber = PremiseInformation_Unitnumber
    ? PremiseInformation_Unitnumber
    : "";

  const Space = "\u00A0";

  const BlockNo = `${
    PremiseInformation_BlockHouseNo &&
    `${PremiseInformation_BlockHouseNo},${Space}`
  }`;
  const StreetName = `${
    PremiseInformation_Streetname && `${PremiseInformation_Streetname},${Space}`
  }`;
  const LevelUnitNumber = `${
    PremiseInformation_Level && `#${PremiseInformation_Level}`
  }${PremiseInformation_Unitnumber && `-${PremiseInformation_Unitnumber}`}${
    (PremiseInformation_Level || PremiseInformation_Unitnumber) && `,${Space}`
  }`;
  const BuildingName = `${
    PremiseInformation_Buildingname &&
    `${PremiseInformation_Buildingname},${Space}`
  }`;
  const PostalCode = `${PremiseInformation_PostalCode}`;

  Address = `${BlockNo}${StreetName}${LevelUnitNumber}${BuildingName}${PostalCode}`;

  return Address;
};
