import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { SMARTView } from '../../Shared/Forms';
import ItemDetailModal from '../../Shared/Modal/ItemDetailModal';
import { getLicenceTypeNameTranslationKey, getLangKey, DISPLAY_RICH_TEXT, getTranslation } from './DisplayUtils';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

export default function LicenceDetailsModalContent(props) {

    const { t } = useTranslation();

    const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.ACCORDION_MODAL;
    const MODAL_NAMES = { DETAILS: 'Details' };
    const SECTION_NAME = {
        DOCUMENTS: getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_SUPPORTINGDOC),
        QUESTIONS: getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_FAQ)
    };

    const EmptyLicence = {
        Description: '',
        DocumentDesc: '',
        FaqDesc: '',
        FeeDesc: '',
        Id: '',
        TimeDesc: '',
        Title: '',
        Url: '',
        DocumentDict: {}
    };

    const [licence, setLicence] = useState(EmptyLicence);
    const [licenceTypeNameTranslationKey, setLicenceTypeNameTranslationKey] = useState('');

    useEffect(() => {
        // Prep Licence Name into Translation Key Format
        const licenceTypeName = getLicenceTypeNameTranslationKey(props.stateLicence.Title);
        setLicenceTypeNameTranslationKey(licenceTypeName);
        setLicence(props.stateLicence);
    }, [t, props.stateLicence, props.modal]);


    const displayDocuments = docs => {
        const documentsDisplay = [];
        if (props.document) {
            docs && docs.forEach((document, i) => {
                if (document.UsedByFormType.some(item => licence.Title.toLowerCase() === item.toLowerCase())) {
                    documentsDisplay.push(
                        <div className='jumbotron' key={i}>
                            <h5>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, document.Name))}</h5>
                            <p>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, document.Name), DISPLAY_RICH_TEXT)}</p>
                        </div>
                    );
                }
            });
        } else {
            docs && docs.forEach((document, i) => {
                documentsDisplay.push(
                    <div className='jumbotron' key={i}>
                        <h5>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, document.Name))}</h5>
                        <p>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, document.Name), DISPLAY_RICH_TEXT)}</p>
                    </div>
                );
            });
        }
        return (
            documentsDisplay
        );
    };

    let documents = {};

    if (props.documents) {
        documents = props.documents;
    } else {
        documents = props.stateLicence.Documents;
    }

    return (
        licence !== EmptyLicence &&
        <ItemDetailModal
            isOpen={props.modal.modalState === MODAL_NAMES.DETAILS}
            contentHeader={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}
            closeText='Close'
            closeCallBack={() => props.modal.toggleModal('submit')}
            applyCallBack={() => props.modal.toggleModal('submit')}
            isShowAddCart={licence.IsExternalLicence === true ? false : true}
            isShowApply={licence.IsExternalLicence === true ? false : true}
            licence={licence}
        >
            <div className='result-block'>
                <div className='result-details'>
                    <p>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId), DISPLAY_RICH_TEXT)}</p>
                </div>
                <div className='result-footer'>
                    <div className='float-left'>
                        <p className="fee">{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_FEE)} : 
                            <strong><span>$</span> {getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_FEEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId))}</strong>
                        </p>
                        <p className='time'>
                            {getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_PROCESSINGTIME)} : 
                            <strong>{licence.TimeDesc} <span>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_WORKINGDAYS)}</span></strong>
                        </p>
                    </div>
                </div>
            </div>
            <SMARTView>
                {({ toggleSection, sectionState }) => (
                    <SectionWrapper
                        type={SECTION_LAYOUT_TYPE}
                        tabs={SECTION_NAME}
                        sectionState={sectionState}
                        toggleSection={toggleSection}
                    >
                        <Section
                            type={SECTION_LAYOUT_TYPE}
                            sectionState={sectionState}
                            sectionName={SECTION_NAME.DOCUMENTS}
                            toggleSection={toggleSection}
                        >
                            {Object.keys(licence.DocumentDict).length === 0 &&
                                <p>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NODOCSTOBESUBMITTED)}</p>
                            }
                            {
                                displayDocuments(documents)
                            }
                        </Section>

                        <Section
                            type={SECTION_LAYOUT_TYPE}
                            sectionState={sectionState}
                            sectionName={SECTION_NAME.QUESTIONS}
                            toggleSection={toggleSection}
                        >
                            {licence.FAQList.length !== 0 ?
                                <div className='align-text-left'>
                                    {licence.FAQList.sort(function (a, b) { return a.DisplayRank - b.DisplayRank; }).map((faq, index) => {
                                        return (
                                            <React.Fragment key={faq.Question + index}>
                                                <p>
                                                    <b>{index + 1}) {getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY, licenceTypeNameTranslationKey, faq.Id), DISPLAY_RICH_TEXT)}</b>
                                                    <br />
                                                    {getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY, licenceTypeNameTranslationKey, faq.Id), DISPLAY_RICH_TEXT)}
                                                </p>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                :
                                <p>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NOFAQAVAILABLE)}</p>
                            }
                        </Section>
                    </SectionWrapper>
                )}
            </SMARTView>
        </ItemDetailModal>
    );
}