import React from 'react';
import { withTranslation } from 'react-i18next';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import LicenceItemResult from '../../Shared/SearchLicence/LicenceItemResult';
import LicenceDetailsModalContent from '../DisplayComponents/LicenceDetailsModalContent';
import PreRequisiteLicenceItemResult from '../../Shared/SearchLicence/PreRequisiteLicenceItemResult';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

const MODAL_NAMES = { DETAILS: 'Details' };

const EmptyLicence = {
    Description: '',
    DocumentDesc: '',
    FaqDesc: '',
    FeeDesc: '',
    Id: '',
    TimeDesc: '',
    Title: '',
    Url: '',
    DocumentDict: {}
};

class DetailContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLicence: EmptyLicence
        };
    }

    // For the 'detail' button to open modal
    handleDetailClick = (licence, modal) => {
        modal.toggleModal(MODAL_NAMES.DETAILS);
        this.setState({ stateLicence: licence });
    };

    render() {

        const { licences, modal, agencyTitle, agencyDesc, t } = this.props;
        const { stateLicence } = this.state;
        let isSuccessNull;
        const message = t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_TITLE_SUBTITLEINDUSTRY);

        if (licences.length === 0) {
            isSuccessNull = true;
        }

        return (
            <React.Fragment>
                {isSuccessNull ? (
                    <React.Fragment>
                        <p></p>
                        <p className='error-message'>{message}</p>
                        <p className='error-message'></p>
                    </React.Fragment>
                ) : (
                        <>
                            <div className='search-result'>
                                <h4>{agencyTitle}<span>{agencyDesc}</span></h4>
                                {licences.map((licence, i) => {
                                    if (licence.PreRequisite.length <= 0) {
                                        return (
                                            <LicenceItemResult
                                                key={i}
                                                licence={licence}
                                                onClick={() => this.handleDetailClick(licence, modal)}
                                                isTdNavigation={false}
                                                inLineStyle={null}
                                                isShowDescription={true}
                                                isShowFeeTime={true}
                                                isShowAddToCart={true}
                                                isShowApply={true}
                                                isShowDetails={true}
                                                isShowPreReq={true}
                                                toggleSection={true}
                                            />
                                        );
                                    } else {
                                        return (
                                            <PreRequisiteLicenceItemResult
                                                key={i}
                                                licence={licence}
                                                onClick={() => this.handleDetailClick(licence, modal)}
                                                isTdNavigation={false}
                                                inLineStyle={null}
                                                isShowDescription={true}
                                                isShowFeeTime={true}
                                                isShowAddToCart={true}
                                                isShowApply={true}
                                                isShowDetails={true}
                                                isShowPreReq={true}
                                                toggleSection={true}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </>
                    )}

                {stateLicence && stateLicence !== EmptyLicence &&
                    <LicenceDetailsModalContent
                        stateLicence={stateLicence}
                        modal={modal}
                    />
                }
            </React.Fragment >
        );
    }
}

export default withTranslation()(withModalWrapper(DetailContainer));
