import React from 'react';
import { navigateTo } from '../../Shared/Actions';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { CART_ACTION_REMOVE, CART_ACTION_ADD } from '../../Shared/Constants';
import { LICENCE_CART_PATH } from '../../App/AppSettings';
import { Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { DISPLAY_RICH_TEXT, getLicenceTypeNameTranslationKey, getTranslation } from '../../Content/DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../DisplayComponents/DisplayUtils';

class RecommendedItemResult extends React.Component {

    displayDescription = (desc, isShow) => {
        if (isShow) {
            return (
                <div dangerouslySetInnerHTML={{ __html: desc }} />
            );
        }
        else {
            return null;
        }
    }

    displayCartButton = (licence, isShow, updateCart, currentCart) => {
        const {t} = this.props;
        if (isShow) {
            //licence already in the cart
            if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length > 0) {
                return (
                    <button className='btn btn-add-to-cart added' key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE) + licence.Id} onClick={() => updateCart(licence, CART_ACTION_REMOVE)}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE)}</button>
                );
            } else {
                return (
                    <button className='btn btn-add-to-cart' key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART) + licence.Id} onClick={() => updateCart(licence, CART_ACTION_ADD)}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART)}</button>
                );
            }
        }
    };

    displayApplyButton = (licence, displayText, isShow, updateCart, currentCart) => {
        const {t} = this.props;
        if (isShow) {
            return (
                <button className='btn btn-apply' key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY) + licence.Id} onClick={() => this.pressApplyButton(licence, updateCart, currentCart)}>{displayText}</button>
            );
        }
    };

    pressApplyButton = (licence, updateCart, currentCart) => {
        if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length === 0) {
            updateCart(licence, CART_ACTION_ADD);
        }
        navigateTo(LICENCE_CART_PATH);
    }

    displayDetailsButton = (onClick, isShow) => {
        const {t} = this.props;
        if (isShow) {
            return (
                <button className='btn btn-details' onClick={onClick} >{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_DETAILS)}</button>
            );
        }
        else {
            return null;
        }
    }

    externalLink = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank');
    }

    render() {
        const { t, licence, isShowDescription, onClick, isShowDetails, isShowAddToCart } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        return (
            <LicenceCartContext.Consumer>
                {({ updateCart, currentCart }) => (
                    <>
                        <Col md='4'>
                            <div className='result-recommend'>
                                <div className='result-details'>
                                    <h5>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                                    {this.displayDescription(
                                        getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY, licenceTypeNameTranslationKey, licence.LicenceDefinitionId), DISPLAY_RICH_TEXT),
                                        isShowDescription
                                    )}
                                </div>
                                <div className='result-footer'>
                                    <div className='float-left'>

                                    </div>
                                    {this.displayDetailsButton(onClick, isShowDetails)}
                                    {licence.IsExternalLicence ?
                                        (
                                            <>
                                                <div className='search-licence-link link-margin-left' onClick={(e) =>
                                                    this.externalLink(e, licence.Url)}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_AGENCYWEBSITE)}</div>
                                            </>
                                        ) :
                                        (
                                            this.displayCartButton(licence, isShowAddToCart, updateCart, currentCart)
                                        )}
                                </div>
                            </div>
                        </Col>
                    </>
                )}
            </LicenceCartContext.Consumer>

        );
    }
}
export default withTranslation()(RecommendedItemResult);