import { createDirectus } from '@directus/sdk';
import { rest, readItems } from '@directus/sdk/dist/rest';

const DIRECTUS_URL = process.env.REACT_APP_CMS_SERVER_URL;
const DIRECTUS_ASSETS_URL = `${DIRECTUS_URL}/assets`;
const DIRECTUS_DEFAULT_LANGCODE = 'en';

const client = createDirectus(DIRECTUS_URL).with(rest());

const mapImageUrl = (id) => {
  if (id !== null && id !== undefined)
    return `${DIRECTUS_ASSETS_URL}/${id}`;
}

const withActiveStatus = (licence) => {
  let effStartDate = new Date(licence.effective_start_date);
  let effEndDate = licence.effective_end_date == null? null: new Date(licence.effective_end_date);
  let isDeleted = licence.is_deleted;
  let utcTimeNow = new Date();
  let utcMinDate = new Date("1899-12-31T16:00:00");

  if (isDeleted) {
    return false;
  }
  else if (effStartDate === utcMinDate && effEndDate === null) {
    return false;
  }
  else if (effStartDate === utcMinDate && effEndDate === utcMinDate) {
    return false;
  }
  else if (effEndDate !== null && effStartDate <= utcTimeNow && effEndDate > utcTimeNow ||
    effEndDate === null && effStartDate <= utcTimeNow ||
    effEndDate === utcMinDate && effStartDate <= utcTimeNow) {
    return true;
  }
  else {
    return false;
  }
}

export const getLogo = async(slug) => {
  let logos = await client.request(readItems('logo', {
    filter: {
      '_and': [
        {
          'status': {
            '_eq': 'published'
          }
        }, {
          'slug': {
            '_eq': slug
          }
        }
      ]
    },
    fields: [
      'id', 'status', 'url', 'image', {
        translations: ['*']
      }
    ]
  }));

  logos.forEach(item => { 
      item.image = mapImageUrl(item.image)
  });
  return logos[0];
}

export const getLandingPage = async(slug) => {
  let pages =  await client.request(readItems('landing_page', {
    filter: {
      "_and": [
        {
          'status': {
            '_eq': 'published'
          }
        }, {
          'slug': {
            '_eq': slug
          }
        }
      ]
    },
    fields: [
      'id','status','background_image', 'slug', {
        translations: ['*']
      }
    ]
  }))

  pages.forEach(item => item.background_image = mapImageUrl(item.background_image));
  return pages[0];
}

export const getCarousel = async(slug) => {
  let carousel =  await client.request(readItems('carousel', {
    filter: {
      "_and": [
        {
          'status': {
            '_eq': 'published'
          }
        }, {
          'slug': {
            '_eq': slug
          }
        }
      ]
    },
    fields: [
      'id', 'status', 'sort', 'image', {
        translations: ['*']
      }
    ]
  }))
  carousel.forEach(item => item.image = mapImageUrl(item.image));

  return carousel;
}

export const getWhatsNew = async(slug) => {
  let whatsNew =  await client.request(readItems('whats_new', {
    filter: {
      "_and": [
        {
          'status': {
            '_eq': 'published'
          }
        }, {
          'slug': {
            '_eq': slug
          }
        }
      ]
    },
    deep: {
      "whats_new_list": {
        "_filter": {
          "status": {
            "_eq": "published"
          }
        }
      }
    },
    fields: [
      'id', 'status', 'sort', {
        translations: ['*']
      }, {
        whats_new_list: [
          'id', 'status', 'sort', 'category_id', {
            translations: ['*']
          }
        ]
      }
    ]
  }))

  return whatsNew[0];
}

export const getWhatsNewCategories = async(slug) => {
  let whatsNewCats =  await client.request(readItems('whats_new_category', {
    filter: {
      "_and": [
        {
          'status': {
            '_eq': 'published'
          }
        }, {
          'slug': {
            '_eq': slug
          }
        }
      ]
    },
    fields: [
      'id', 'status', {
        translations: ['*']
      }
    ]
  }))

  return whatsNewCats;
}

export const getBusinessIntentById = async(bizIntentId) => {
  // Use array find to ensure single item returned
  const bizIntent = (await client.request(readItems('business_intent', {
    limit: 1,
    filter: {
      "_and": [
        {
          'status': {
            '_eq': 'published'
          },
          'dsp_ref_id': {
            '_eq': bizIntentId
          }
        }
      ]
    },
    fields: [
      'id', 'status', 'dsp_ref_id', {
        default_licence: [
          {
            licence_type_id: [
              'id', 'status', 'effective_start_date', 'effective_end_date', 'is_deleted', 'dsp_ref_id'
            ]
          }
        ]
      }, {
        category_order: [
          'id', 'status', 'order', {
            question_category: [
              'id', 'status', 'image', {
                translations: ['*']
              }
            ]
          }
        ]
      }, {
        business_intent_composition: [
          {
            categorized_questions: [
              'id', 'status', 'question_order', {
                question_category: [
                  'id', 'status', 'image', {
                    translations: ['*']
                  }
                ]
              }, {
                question: [
                  'id', 'status', {
                    translations: ['*']
                  }, {
                    question_type: [
                      'id', 'status', {
                        translations: ['*']
                      }
                    ]
                  }
                ]
              }, {
                question_answer_group: [
                  'id', 'status', 'answer_order', {
                    answer: [
                      'id', 'status', {
                        translations: ['*']
                      }
                    ]
                  }, {
                    licence: [
                      'id', 'status', 'effective_start_date', 'effective_end_date', 'is_deleted', 'dsp_ref_id'
                    ]
                  }
                ]
              }, {
                subquestion: [
                  'id', 'status', {
                    translations: ['*']
                  }, {
                    question_type: [
                      'id', 'status', {
                        translations: ['*']
                      }
                    ]
                  }
                ]
              }, {
                subquestion_answer_group: [
                  'id', 'status', {
                    answer: [
                      'id', 'status', {
                        translations: ['*']
                      }
                    ]
                  }, {
                    licence: [
                      'id', 'status', 'effective_start_date', 'effective_end_date', 'is_deleted', 'dsp_ref_id'
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        translations: ['*']
      }
    ]
  }))).find(intent => intent.dsp_ref_id === bizIntentId);

  const defaultLicences = bizIntent?.default_licence;
  const catOrder = bizIntent?.category_order;
  const intentComp = bizIntent?.business_intent_composition;
  const catQuestions = intentComp[0]?.categorized_questions;

  // data restructrue
  if (bizIntent && Array.isArray(defaultLicences)) {
    bizIntent.default_licence = defaultLicences.map(currLicence => currLicence?.licence_type_id);
  }

  // map image url
  Array.isArray(catOrder) &&
    catOrder.forEach(cat => cat.question_category.image = mapImageUrl(cat?.question_category?.image));
  Array.isArray(catQuestions) &&
    catQuestions.forEach(q => q.question_category.image = mapImageUrl(q?.question_category?.image));
  
  Array.isArray(defaultLicences) && defaultLicences.forEach(currLicence => {
    currLicence && !withActiveStatus(currLicence)
      && defaultLicences.pop(licence => licence?.id === currLicence?.id)
  });
  Array.isArray(catQuestions) && catQuestions.forEach(qSet => {
    let qAnsGrp = qSet.question_answer_group;
    let subQAnsGrp = qSet.subquestion_answer_group;
    let ansGrp = qAnsGrp ?
      (subQAnsGrp ? [...qAnsGrp, ...subQAnsGrp] : [...qAnsGrp]) :
      (subQAnsGrp ? [...subQAnsGrp] : []);
    ansGrp.forEach(aSet => {
      const currLicence = aSet?.licence;
      aSet.licence = (currLicence && !withActiveStatus(currLicence)) ? null : currLicence;
    })
  });
  
  return bizIntent;
}

export const getTransl = (object, lang) => {
  let currTransl = object?.translations?.find(t => t.languages_code === lang);
  let defaultTransl = object?.translations?.find(t => t.languages_code === DIRECTUS_DEFAULT_LANGCODE);
  let isTranslAvail = currTransl !== undefined && currTransl !== null;
  let transl = isTranslAvail ? currTransl : defaultTransl;
  return transl;
}