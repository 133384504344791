import React from 'react'
import { Prompt } from 'react-router-dom'
import { navigateTo } from '../Actions'
import { ModalConfirm } from '../Modal'

// adapted from https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
export class NavigationPrompt extends React.Component {
    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false,
    }

    showModal = (location) => this.setState({
        modalVisible: true,
        lastLocation: location,
    })

    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    blockNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state
        if (!confirmedNavigation) {
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const { lastLocation } = this.state
        if (lastLocation) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                // Navigate to the previous blocked location with your navigate function     
                navigateTo(lastLocation.pathname)
            })
        }
    })

    handleCancelNavigationClick = () => this.closeModal(() => { })

    render() {
        const { when } = this.props
        const { modalVisible } = this.state
        return (
            <>
                <Prompt
                    when={when}
                    message={this.blockNavigation} />
                <ModalConfirm
                    isOpen={modalVisible}
                    contentHeader={this.props.contentHeader}
                    contentBody={this.props.contentBody}
                    confirmText={this.props.confirmText}
                    cancelText={this.props.cancelText}
                    confirmCallback={this.handleConfirmNavigationClick}
                    cancelCallback={this.handleCancelNavigationClick}
                />
            </>
        )
    }
}
export default NavigationPrompt;