import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { APPLICATION_DETAILS_PATH, DASHBOARD_PATH, GET_ADDITIONAL_DOCS_URL, LAYOUT_TYPE, SUBMIT_RFA_RESPONSE_URL } from '../../App/AppSettings';
import MultiFileUpload from '../../Content/FormComponents/MultiFileUpload';
import Layout from '../../Layout';
import { toastError } from '../../Shared//Forms';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { Paragraph, SMARTForm } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';

const FORM_CONTEXT = 'RFCResponse';
const SUCCESS_URL = '/submissionSuccess';

class RFAClarification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalDocs: [],
            values: {
                AppRefNum: props.application.ApplicationRefNumber,
                Clarification: '',
                AdditionalDocs: []
            }
        };
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();
        this.fetchDataForClarification(loader);
    }

    async fetchDataForClarification(loader) {
        const { t } = this.props;
        const response = await fetchRequest(GET_ADDITIONAL_DOCS_URL + this.props.application.ApplicationRefNumber, getParams(), false);
        const { IsSuccess, Data } = response.body;
        if (IsSuccess) {
            const additionalDocs = {};
            const additionalDocsDescription = {};

            Data.forEach(data => {
                additionalDocs[t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, data.Name.toLowerCase()))] = [];
                additionalDocsDescription[t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, data.Name.toLowerCase()))] = data.DisplayName;
            });

            this.setState(prevState => {
                return {
                    values: Object.assign(prevState.values, {
                        AdditionalDocs: additionalDocs,
                        AdditionalDocsDescription: additionalDocsDescription
                    }),
                    additionalDocs: Data,
                    validationSchema: this.createValidationSchema(Data)
                };
            });
            loader.done();
        } else {
            this.setState({
                additionalDocs: [],
                applicationRfaLoaded: true
            });
            loader.done();
            toastError(t(ERROR.RFA_DETAILS_NOT_FETCHED));
        }
    }

    createValidationSchema = additionalDocs => {
        const { t } = this.props;
        const validation = {};
        additionalDocs.forEach(doc => {
            validation[t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, doc.Name.toLowerCase()))] = Yup.array()
            .required(t(ERROR.LABEL) + t(ERROR.REQUIRED)).min(1,(ERROR.LABEL) + t(ERROR.REQUIRED));
        });

        return Yup.object().shape({
            'AdditionalDocs': Yup.object().shape(validation),
            'Clarification': Yup.string().required(t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_CLARIFICATION) + ' ' + t(ERROR.REQUIRED))
        });
    }
    render() {
        const { applicationRfa, application, t } = this.props;
        const { additionalDocs } = this.state;
        return (
            <>
                <div className="main-content">
                    <nav aria-label="breadcrumb" className="breadcrumb-main">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</Link></li>
                                <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_RESPONDTORFA)}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{application.LicenceName}</li>
                            </ol>
                            <div className="back-btn">
                                <Button
                                    color="header"
                                    className="btn-header"
                                    onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                            </div>
                        </div>
                    </nav>
                    <div className="container">
                        <div className="detailed-box db-app-details">
                            <div className="db-details-head">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_REQUESTFORCLARIFICATION)}</div>
                            <div className="details-box">
                                <h5>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_APPLICATIONBEINGPROCESSED)} {application.AgencyDisplayName} {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_MESSAGESENT)} </h5>
                                <div className="details-body">
                                    <p>{applicationRfa.InternalRemarks}</p>
                                </div>
                                <div className="details-footer">
                                    <p>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_RESPONDBY)}  <strong>{applicationRfa.DueDate}</strong></p>
                                </div>
                            </div>
                        </div>
                        <Layout type={LAYOUT_TYPE.FORM}>
                            <SMARTForm
                                formContext={FORM_CONTEXT}
                                formValues={this.state.values}
                                nextURL={SUCCESS_URL}
                                serverURL={SUBMIT_RFA_RESPONSE_URL}
                                validationSchema={this.state.validationSchema}
                            >
                                {({ values, errors, onChange, submitForm, onChangeField }) => (
                                    <>
                                        <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_CLARIFICATION)}</div>

                                        <div className="clarification">
                                            <div className="form-group">
                                                <Paragraph
                                                    name="Clarification"
                                                    value={values.Clarification}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER_TYPEHERE)}
                                                    onChange={onChange}
                                                    rows={5}
                                                    minLength={0}
                                                    inputSize={12}
                                                    labelSize={FIELD_SIZE.NONE}
                                                    error={errors.Clarification}
                                                />
                                            </div>
                                        </div>
                                        {additionalDocs.length > 0 &&
                                            <>
                                                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADDITIONALSUPPORTINGDOCREQ)}</div>
                                                <div className="additional-supporting-documents">
                                                    {additionalDocs && additionalDocs.map(doc => 
                                                        <MultiFileUpload
                                                            key={doc.Name}
                                                            name={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, doc.Name.toLowerCase()))}
                                                            label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, doc.Name.toLowerCase()))}
                                                            description={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, doc.Name.toLowerCase()))}
                                                            fileList={values.AdditionalDocs[t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, doc.Name.toLowerCase()))]}
                                                            onChangeField={onChangeField}
                                                            error={errors.AdditionalDocs && errors.AdditionalDocs[t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, doc.Name.toLowerCase()))]}
                                                            required />
                                                    )}
                                                </div>
                                            </>
                                        }
                                        <div className="button-center">
                                            <Button onClick={() => navigateTo(APPLICATION_DETAILS_PATH, { application })}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_BACKTORFA)}</Button>
                                            <Button onClick={submitForm}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_SUBMIT)}</Button>
                                        </div>
                                    </>
                                )}

                            </SMARTForm>
                        </Layout>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(compose(withLoader)(RFAClarification));