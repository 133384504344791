import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { withTranslation } from 'react-i18next';
import { Input, Row, Button } from 'reactstrap';
import { SEARCH_ALL_INDUSTRY_PATH, SEARCH_ALL_AGENCY_PATH, LAYOUT_TYPE, 
        SEARCH_INTENT_PATH, GET_APP_ENV
    } from '../../App/AppSettings';
import { FooterContentExtension } from '../../Extensions/FooterContentExtension';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import CustomCarousel from '../../Shared/CustomCarousel/CustomCarousel';
import DashboardSearch from '../../Shared/DashboardSearch';
import { ScrollToTop } from '../../Shared/Forms';
import ModalBasic from '../../Shared/Modal/ModalBasic';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withRouter } from 'react-router-dom';
import { DirectionContextConsumer } from '../../Shared/DirectionContext';
import { getLandingPage, getCarousel, getWhatsNew, getWhatsNewCategories, getTransl } from '../../../directus';

const PUBLIC_URL = process.env.PUBLIC_URL;
const MODAL_NAMES = { BUSINESS: 'searchintent' };
const MODAL_DASHBOARD_SEARCH = { BUSINESS: 'dashboardsearch' };

const BLANK_VALUES = '';

class Home extends React.Component {
    constructor(props) {
        super(props);
        const { currLang } = this.props.context;

        this.state = {
            intentSearchValue: BLANK_VALUES,
            currLang: currLang,
            landingPage: '',
            carousel: '',
            whatsNew: '',
            whatsNewCategories: [],
        }
        this.intentInput = React.createRef();
        this.licenceInput = React.createRef();
    }

    async componentDidMount() {
        const { loader, location } = this.props;
        const slug = location.pathname;

        loader.start();
        const landingPageData = await getLandingPage(slug);
        const carouselData = await getCarousel(slug);
        const whatsNewData = await getWhatsNew(slug);
        const whatsNewCategoriesData = await getWhatsNewCategories(slug);
        
        if (landingPageData || carouselData || whatsNewData || whatsNewCategoriesData) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    landingPage: landingPageData,
                    carousel: carouselData,
                    whatsNew: whatsNewData,
                    whatsNewCategories: whatsNewCategoriesData
                }
            })
            loader.done();
        } else {
            loader.error();
        }
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    /// SEARCH INTENT METHODS: START ///

    inputOnChangeHandler = (e) => {
        if (e.target.value === undefined || e.target.value === '') {
            this.setState({
                intentSearchValue: BLANK_VALUES
            });
        }
        else {
            this.setState({
                intentSearchValue: e.target.value
            });
        }
    }


    inputKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            this.props.history.replace({ pathname: SEARCH_INTENT_PATH, search: '?title=' + this.state.intentSearchValue });
        }
    }

    searchIntentValue = () => {
        if (this.state.intentSearchValue === undefined || this.state.intentSearchValue === '') {
            navigateTo(SEARCH_INTENT_PATH)
        }
        else {
            this.props.history.replace({ pathname: SEARCH_INTENT_PATH, search: '?title=' + this.state.intentSearchValue });
        }

    }

    /// SEARCH INTENT METHODS: END ///

    renderBanner = () => {
        let bgImageUrl = `url(${this.state.landingPage.background_image})`;
        return (
            <div className='banner' style={{ backgroundImage: bgImageUrl }}></div>
        )
    }

    renderLandingPageContent = () => {
        let modal = this.props.modal;
        let content = this.state.landingPage;
        let currTransl = getTransl(content, this.state.currLang);
        let heading = currTransl.heading;
        let subheading = currTransl.subheading;
        let buttons = {
            first: currTransl.buttons[0],
            second: currTransl.buttons[1]
        };

        return (
            <>
                <div className='banner-background'>
                    {this.renderBanner()}
                </div>
                <div className='homepage-tagline'>
                    <h3>{heading}</h3>
                    <h4>{subheading}</h4>
                    <div className='button-center'>
                        {
                            GET_APP_ENV &&
                            <button
                                className='btn btn-blue'
                                onClick={async () => {
                                    await modal.toggleModal(MODAL_NAMES.BUSINESS);
                                    this.intentInput.current.focus()
                                }}
                            >
                                {buttons.first.label}
                            </button>
                        }
                        <button
                            className='btn btn-green'
                            onClick={async () => {
                                await modal.toggleModal(MODAL_DASHBOARD_SEARCH.BUSINESS);
                                this.licenceInput.current.focus()
                            }}
                        >
                            {buttons.second.label}
                        </button>
                    </div>
                </div>
            </>
        )
    }

    getCarouselItems = () => {
        let carouselItems = this.state.carousel;

        return carouselItems
            .sort((item1, item2) => {
                if (item1.sort < item2.sort) return -1;
                else if (item1.sort > item2.sort) return 1;
                else return 0;
            })
            .map((item, idx) => {
                let currTransl = getTransl(item, this.state.currLang);
                let heading = currTransl.heading;
                let subheading = currTransl.subheading;
                let buttons = currTransl.buttons;
                return {
                    src: item.image,
                    altText: `Slide ${idx + 1}`,
                    header: heading,
                    caption: <>
                        <span>{subheading}</span>
                        <br></br>
                        <span className='d-flex justify-content-around flex-wrap'>
                            {buttons.map((button, idx) => {
                                return <Button key={idx}>
                                    <a href={button.url} style={{ color: 'black' }} target='_blank'>{button.label}</a>
                                </Button>})}
                        </span>
                    </>
                }
            })
    }

    renderWhatsNew = () => {
        let whatsNew = this.state.whatsNew;
        let currTransl = getTransl(whatsNew, this.state.currLang);
        let title = currTransl.title;

        return <>
            <h1>{title}</h1>
            <div className='row'>
                {this.renderWhatsNewCategories(whatsNew.whats_new_list)}
            </div>
        </>
    }

    renderWhatsNewCategories = (whatsNewList) => {
        let categories = this.state.whatsNewCategories;

        return categories
            .sort((item1, item2) => {
                if (item1.sort < item2.sort) return -1;
                else if (item1.sort > item2.sort) return 1;
                else return 0;
            })
            .map((category, idx) => {
                let currTransl = getTransl(category, this.state.currLang);
                let title = currTransl.title;
                let buttons = currTransl.buttons;
                let categoryId = category.id;

                return (
                    <div key={idx} className='col-lg-4'>
                        <div className='wats-new-blog-main'>
                            <div className={`wats-new-blog ${categoryId === 5 || categoryId === 1 ? 'news' : categoryId === 6 || categoryId === 2 ? 'events' : 'notice'}-blog`}>
                                <h3>{title}</h3>
                                {this.renderWhatsNewItemsByCategoryId(whatsNewList, categoryId)}
                                <div className='read-more'>
                                    {buttons.map((button, idx) => {
                                        return <span key={idx}>
                                            <a href={button.url} style={{ color: 'white'}} target='_blank'>{button.label}</a>
                                            </span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
    }

    renderWhatsNewItemsByCategoryId = (whatsNewList, id) => {
        let items = whatsNewList.filter(item => item.category_id === id);
        
        return (
            <ul>
                {
                    items
                        .sort((item1, item2) => {
                            if (item1.sort < item2.sort) return -1;
                            else if (item1.sort > item2.sort) return 1;
                            else return 0;
                        })
                        .map((item, idx) => {
                            let currTransl = getTransl(item, this.state.currLang);
                            let title = currTransl.title;

                            return <li key={idx}>{title}</li>
                        })
                }
            </ul>
        )
    }

    renderFooterExtension = () => {
        return <FooterContentExtension />;
    };

    render() {
        const { t, modal } = this.props;
        return (
            <React.Fragment>
                <Layout
                    type={LAYOUT_TYPE.FORM}
                    footer={true}
                    footerExtension={this.renderFooterExtension()}
                >
                    <ScrollToTop />

                    <div className='main-content'>
                        { this.state.landingPage && this.renderLandingPageContent() }
                        <div className='container'>
                            <div className='home-carousel'>
                            {
                                this.state.carousel &&
                                <CustomCarousel items={this.getCarouselItems()}/>
                            }
                            </div>
                        </div>
                        <div className='whats-new'>
                            <div className='container'>
                                { this.state.whatsNew && this.renderWhatsNew() }
                            </div>
                        </div>
                    </div>
                    <ModalBasic
                        isOpen={modal.modalState === MODAL_NAMES.BUSINESS}
                        closeCallBack={() => modal.toggleModal(MODAL_NAMES.BUSINESS)}
                    >
                        <div className='inner-serach'>
                            <div className='tagline'>
                                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_CONTENT_TITLE_TELLUSABOUT)}<strong>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_CONTENT_TITLE_YOURBIZ)}</strong>
                            </div>
                            <div className='licence-serach'>
                                <div className='input-group'>
                                    <Input
                                        className='form-control'
                                        type='text'
                                        value={this.state.intentSearchValue}
                                        name='title'
                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICHOME_CONTENT_MODAL_ABOUTBIZ)}
                                        onChange={this.inputOnChangeHandler}
                                        minLength={0}
                                        maxLength={140}
                                        onKeyDown={this.inputKeyDownHandler}
                                        innerRef={this.intentInput}
                                    />
                                    <span className='input-group-btn'><button className='btn btn-orange' type='button' onClick={this.searchIntentValue}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_BUTTON_ENTER)}</button></span>

                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </ModalBasic>
                    <ModalBasic
                        isOpen={modal.modalState === MODAL_DASHBOARD_SEARCH.BUSINESS}
                        closeCallBack={() => modal.toggleModal(MODAL_DASHBOARD_SEARCH.BUSINESS)}
                    >
                        <div className='heading text-center'>
                            <span>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCHTITLE)}</span>
                        </div>
                        <div className='inner-serach'>
                            <div className='licence-serach'>
                                <DashboardSearch getLicencesFromLandingPage={true} innerRef={this.licenceInput} />
                            </div>

                            <div className='or'></div>

                            <div className='browse-by'>
                                <Row noGutters>
                                    <button
                                        className='by-gov-agency'
                                        onClick={() => navigateTo(SEARCH_ALL_AGENCY_PATH)}
                                    >
                                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBYGOVAGENCY)}
                                    </button>
                                    <button
                                        className='by-business-intent'
                                        onClick={() => navigateTo(SEARCH_ALL_INDUSTRY_PATH)}
                                    >
                                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBYINDUSTRY)}
                                    </button>
                                </Row>
                            </div>
                            <br />
                            <br />
                        </div>
                    </ModalBasic>
                </Layout>
            </React.Fragment >
        );
    }
}

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
      render() {
        return (
          <DirectionContextConsumer>
            {({ currLang }) => (
              <WrappedComponent
                context={{ currLang }}
                {...this.props}
              />
            )}
          </DirectionContextConsumer>
        );
      }
    }
  
    return DirectionOnPageContext;
  };

export default withTranslation()(withRouter(withModalWrapper(withDirectionContext(Home))));
