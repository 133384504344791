import React from 'react';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { Plaintext, AccordionContainer } from '../../Shared/Forms';
import { getPremiseOperatingAddress } from '../FormComponents/FormUtils';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

class LicenceOverview extends React.Component {

    render() {
        const { values, toggleSection, sectionNames, t } = this.props;
        const { LicenceDetails } = values;

        const LicenceData = JSON.parse(LicenceDetails.LicenceData);
        const { GeneralInformation, LicenceInformation } = LicenceData ? LicenceData : '';
        const Entity = GeneralInformation ? GeneralInformation.GI_EntitydetailsEntityname : null;
        return (
            <>
                {LicenceInformation && GeneralInformation &&
                    <>
                        <AccordionContainer
                            title={sectionNames.LICENCE_OVERVIEW.title}
                            active={sectionNames.LICENCE_OVERVIEW.status}
                            onClick={toggleSection}
                            isIndividual={true}
                            sections={sectionNames}
                        >
                            <div className="card-body">
                                <div className="form-body form-preview">
                                    <div className="multi-row-form">

                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENAME)}
                                            name="LicenceName"
                                            value={LicenceDetails.LicenceName}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENUMBER)}
                                            name="LicenceNumber"
                                            value={LicenceDetails.LicenceNumber}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_APPLICANT)}
                                            name="Licensee"
                                            value={GeneralInformation.GI_Name}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICESTATUS)}
                                            name="LicenceStatus"
                                            value={LicenceDetails.LicenceStatus}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_AGENCY)}
                                            name="Agency"
                                            value={LicenceDetails.Agency}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        {Entity &&
                                            <Plaintext
                                                label="Entity"
                                                name="Entity"
                                                value={Entity}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                            />
                                        }
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_ISSUEDDATE)}
                                            name="IssuedDate"
                                            value={LicenceDetails.IssuedDate}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_EXPIRYDATE)}
                                            name="ExpiryDate"
                                            value={LicenceDetails.ExpiryDate}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_PREMISEOPERATINGADDR)}
                                            name="PremiseOperatingAddress"
                                            value={getPremiseOperatingAddress(LicenceInformation)}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    </div>
                                </div>
                            </div>
                        </AccordionContainer>
                    </>
                }
            </>
        );
    }
}

export default withTranslation()(LicenceOverview);