import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { AccordionContainer } from '../../../Shared/Forms';
import RenderFieldComponent from '../RenderFieldComponent';


const SECTION_NAMES = {
    MANDATORY_DOCUMENTS: { title: "MANDATORY DOCUMENTS", status: true },
    OPTIONAL_DOCUMENTS: { title: "OPTIONAL DOCUMENTS", status: true }
};

class SupportingDocuments extends React.Component {
    render() {
        const { values, onChangeField, onChange, errors, toggleAllSections, toggleSection } = this.props.smartFormValues;
        const { files, t } = this.props;

        return (
            <>
                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SUPPORTINGDOCUMENTS)}</div>
                <>
                    <div className="collapse-expand">
                        <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(SECTION_NAMES, true)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                        <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(SECTION_NAMES, false)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                    </div>
                    <AccordionContainer
                        className="accordion"
                        title={SECTION_NAMES.MANDATORY_DOCUMENTS.title}
                        active={SECTION_NAMES.MANDATORY_DOCUMENTS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        <div className="card-body">
                            {files.map(file => {
                                return (file.Validation && file.Validation.Required === true)
                                    && <RenderFieldComponent
                                        key={file.Name}
                                        formType={'SupportingDocument'}
                                        field={file}
                                        values={values}
                                        onChange={onChange}
                                        onChangeField={onChangeField}
                                        errors={errors}
                                    />;
                            })}
                        </div>
                    </AccordionContainer>
                    <AccordionContainer
                        className="accordion"
                        title={SECTION_NAMES.OPTIONAL_DOCUMENTS.title}
                        active={SECTION_NAMES.OPTIONAL_DOCUMENTS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        <div className="card-body">
                            {files.map(file => {
                                return (!file.Validation || file.Validation.Required === false)
                                    && <RenderFieldComponent
                                        key={file.Name}
                                        formType={"SupportingDocument"}
                                        field={file}
                                        values={values}
                                        onChange={onChange}
                                        onChangeField={onChangeField}
                                        errors={errors}
                                    />;
                            })}
                        </div>
                    </AccordionContainer>
                </>
            </>
        );
    }
}
export default withTranslation()(SupportingDocuments);
