import { default as React } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { compose } from "recompose";
import {
  APPLY_FORM_PATH,
  GET_ALL_DOCUMENTS_LICENCES_URL,
  GET_APP_ENV,
  GET_LICENCE_RECOMMENDATION_URL,
  HOME_PATH,
  LAYOUT_TYPE,
  SEARCH_ALL_LICENCE_PATH,
} from "../../App/AppSettings";
import Layout from "../../Layout";
import { fetchRequest, getParams, navigateTo } from "../../Shared/Actions";
import { ERROR, LANGUAGE_KEYS } from "../../Shared/Constants/LanguageKeys";
import { toastError } from "../../Shared/Forms/Toaster";
import { LicenceCartContext } from "../../Shared/LicenceCartContext";
import { withLoader } from "../../Shared/Loading";
import {
  getLangKey,
  getLicenceTypeNameTranslationKey,
  getTranslation,
} from "../DisplayComponents/DisplayUtils";
import ResultContainer from "../SearchLicence/ResultContainer";
import ExclusiveModal from "./ExclusiveModal";
import PreRequisiteModal from "./PreRequisiteModal";
import RecommendationResultContainer from "./RecommendationResultContainer";

const RECOMMENDED_LICENCES_URL = GET_LICENCE_RECOMMENDATION_URL;
const MODAL_NAMES = { CART: "Cart", EXCLUSIVECART: "Exclusive-Cart" };

const withLicenceContext = (WrappedComponent) => {
  class LicenceContext extends React.Component {
    render() {
      return (
        <LicenceCartContext.Consumer>
          {({ currentCart, externalServices }) => (
            <WrappedComponent
              context={{
                currentCart,
                externalServices,
              }}
              {...this.props}
            />
          )}
        </LicenceCartContext.Consumer>
      );
    }
  }

  return LicenceContext;
};

class LicenceCart extends React.Component {
  constructor(props) {
    const { loader } = props;
    super(props);
    this.state = {
      loader,
      documents: [],
      currentCart: this.props.context.currentCart,
      noDocs: false,
      isRecommendedListFetched: true,
      isDocFetched: false,
      recommendedList: [],
      exclusiveCart: [],
      buttonDisabled: true,
    };
  }

  async componentDidMount() {
    this.state.loader.start();

    const listOfLicenceTitles = this.props.context.currentCart.map(
      (licence) => licence.Id
    );
    this.getDocuments(listOfLicenceTitles);
    this.getRecommendedLicences(listOfLicenceTitles);
  }

  componentDidUpdate(prevProps) {
    //if cart is empty, redirect to searchLicence page
    if (
      this.props.context.currentCart.length === 0 &&
      this.props.context.externalServices.length === 0
    ) {
      navigateTo(SEARCH_ALL_LICENCE_PATH);
    }
    if (this.props.context.currentCart !== prevProps.context.currentCart) {
      this.state.loader.start();
      const listOfLicenceTitles = this.props.context.currentCart.map(
        (licence) => licence.Id
      );
      this.setState({
        isDocFetched: false,
      });
      this.getDocuments(listOfLicenceTitles);
    }
  }

  getRecommendedLicences = async (listOfLicenceTitles) => {
    let REQUEST_URL = RECOMMENDED_LICENCES_URL;
    listOfLicenceTitles.forEach((element) => {
      REQUEST_URL += `licenceId=${element}&`;
    });

    const response = await fetchRequest(REQUEST_URL, getParams());
    const { Data, IsSuccess } = response.body;

    if (IsSuccess) {
      this.setState({
        recommendedList: Data,
      });
    }
    this.setState(
      {
        isRecommendedListFetched: true,
      },
      () => this.stopLoader(this.state.loader)
    );
  };

  async getDocuments(listOfLicenceTitles) {
    const { t } = this.props;
    let REQUEST_URL = GET_ALL_DOCUMENTS_LICENCES_URL;
    listOfLicenceTitles.forEach((element) => {
      REQUEST_URL += `licenceIds=${element}&`;
    });

    const response = await fetchRequest(REQUEST_URL, getParams(), false);
    const { IsSuccess, Messages, Data } = response.body;

    if (IsSuccess) {
      let sortedData;
      if (Data.length > 0) {
        sortedData = this.sortData(Data);
      }
      this.setState(() => {
        return {
          documents: Data.length > 0 ? sortedData : [],
          noDocs: Data.length > 0 ? false : true,
        };
      });
    } else {
      toastError(
        t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)),
        Messages
      );
      this.setState(() => {
        return { documents: [], noDocs: false };
      });
    }
    this.setState(
      {
        isDocFetched: true,
      },
      () => this.stopLoader(this.state.loader)
    );
  }

  // Loader done
  stopLoader = async (loader) => {
    const { isDocFetched, isRecommendedListFetched } = this.state;

    if (isDocFetched && isRecommendedListFetched) {
      loader.done();
    }
  };

  //sort data with files that most forms require be at the top
  sortData = (data) => {
    return data.sort(function (a, b) {
      const usedByFormTypesFieldA = a.UsedByFormType.length;
      const usedByFormTypesFieldB = b.UsedByFormType.length;
      if (usedByFormTypesFieldA < usedByFormTypesFieldB) {
        return 1;
      } else if (usedByFormTypesFieldA > usedByFormTypesFieldB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  displayDocuments = (documents, t) => {
    const documentsDisplay = [];
    documents.forEach((document, i) => {
      documentsDisplay.push(
        <p key={i}>
          <strong>
            {t(
              getLangKey(
                LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY,
                document.Name
              )
            )}
          </strong>
          {this.displayDocumentUsedByLicenceTypeId(
            t,
            document.UsedByFormType,
            document.UsedByLicenceTypeId
          )}
        </p>
      );
    });
    return documentsDisplay;
  };

  displayDocumentUsedByFormType = (usedByFormType) => {
    let string = "";
    usedByFormType.forEach((form, i) => {
      if (i === 0) {
        string += form;
      } else {
        string += `, ${form}`;
      }
    });
    return string;
  };

  displayDocumentUsedByLicenceTypeId = (
    t,
    usedByFormType,
    usedByLicenceTypeId
  ) => {
    let string = "";
    let licenceTypeNameTranslationKey = "";
    usedByLicenceTypeId &&
      usedByLicenceTypeId.forEach((id, i) => {
        if (i === 0) {
          licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(
            usedByFormType[i]
          );
          string += getTranslation(
            t,
            getLangKey(
              LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY,
              licenceTypeNameTranslationKey,
              id
            )
          );
        } else {
          licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(
            usedByFormType[i]
          );
          string += `, ${getTranslation(
            t,
            getLangKey(
              LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY,
              licenceTypeNameTranslationKey,
              id
            )
          )}`;
        }
      });
    return string;
  };

  displayPrerequisiteModal() {
    const checkCart = [];
    const { currentCart } = this.props.context;
    // For each licence in current cart, check if it has prerequisite
    currentCart.forEach((licence) => {
      if (licence.PreRequisite && licence.PreRequisite.length > 0) {
        // For each prerequisite, check if it already exists in currentCart, if not, add to checkCart
        licence.PreRequisite.forEach((required) => {
          if (
            !currentCart.some(
              (existing) => existing.Id === required.LicenceTypeId
            )
          ) {
            checkCart.push(required);
            this.setState({
              modalState: MODAL_NAMES.CART,
            });
          }
        });
      }
    });
    if (checkCart.length === 0) {
      navigateTo(APPLY_FORM_PATH, { applicationType: "APPLY" });
    }
  }

  displayExclusiveModal() {
    const exclusiveLicenceCart = [];
    const { currentCart } = this.props.context;
    // Check if currentCart is not empty & loop currentCart
    currentCart.length > 0 &&
      currentCart.forEach((licence) => {
        // Check if current licence have any exclusive licence & loop Exclusive Array
        licence.Exclusive &&
          licence.Exclusive.length > 0 &&
          licence.Exclusive.forEach((e) => {
            const cartObj = {};
            const cartArray = [];
            // Check if exclusiveLicenceCart already have the combination of Licence 1 and Licence 2
            if (
              !exclusiveLicenceCart.some(
                (exclusiveLicence, index) =>
                  exclusiveLicence[index].some((c) => c.Id === licence.Id) &&
                  exclusiveLicence[index].some((c) => c.Id === e.LicenceTypeId)
              ) &&
              // Check if exclusive Licence exist in currentCart
              currentCart.some((c) => c.Id === e.LicenceTypeId)
            ) {
              cartArray.push(currentCart.find((c) => c.Id === e.LicenceTypeId));
              cartArray.push(licence);
            }

            if (cartArray.length > 0) {
              cartObj[exclusiveLicenceCart.length] = cartArray;
              exclusiveLicenceCart.push(cartObj);
            }
          });
      });

    if (exclusiveLicenceCart.length === 0) {
      this.setState({
        modalState: undefined,
      });
      this.displayPrerequisiteModal(currentCart);
    } else {
      this.setState({
        exclusiveCart: exclusiveLicenceCart,
        modalState: MODAL_NAMES.EXCLUSIVECART,
        buttonDisabled: true,
      });
    }
  }

  toggleModal = (modalName) => {
    this.setState({
      modalState: this.state.modalState !== modalName ? modalName : undefined,
      errorMessage: false,
    });
  };

  // Check exclusive modal 'Continue' button whether to enable or disable it
  checkExclusiveCondition = (buttonDisabled) => {
    if (buttonDisabled !== undefined) {
      this.setState({
        buttonDisabled,
      });
    } else {
      this.setState((prevState) => ({
        buttonDisabled: !prevState.buttonDisabled,
      }));
    }
  };

  render() {
    const { t } = this.props;
    let licenceList = [];
    const { currentCart, externalServices } = this.props.context;
    const { documents, noDocs } = this.state;
    // combine it with the previous when recommendedList is restored
    const { recommendedList } = this.state;
    const filteredRecommendedList = recommendedList.filter(
      (r) => !currentCart.some((lic) => lic.Id === r.Id)
    );
    console.log(
      "external services is loaded in licence cart: ",
      externalServices
    );
    let hasExternalLicence = externalServices.length > 0;
    if (hasExternalLicence) {
      licenceList = currentCart.concat(externalServices);
    } else licenceList = currentCart;
    let showApplyBtn = currentCart.length > 0;

    return (
      <>
        <Layout type={LAYOUT_TYPE.FORM} footer>
          <div className="main-content">
            <nav aria-label="breadcrumb" className="breadcrumb-main">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={HOME_PATH}>
                      {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_HOME)}
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t(
                      LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_APPLYFORNEWSERVICEAPPLICATION
                    )}
                  </li>
                </ol>
              </div>
            </nav>
            <div className="container">
              <div className="row licence-cart-div">
                <div className="col-lg-8">
                  <div className="search-result">
                    <ResultContainer
                      licences={licenceList}
                      fromCart={true}
                      documents={documents}
                    />
                  </div>
                  {showApplyBtn ? (
                    <div className="button-center">
                      <Button
                        color="primary"
                        className="btn"
                        onClick={() => this.displayExclusiveModal()}
                      >
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY
                        )}
                      </Button>
                      <ExclusiveModal
                        isOpen={
                          this.state.modalState === MODAL_NAMES.EXCLUSIVECART
                        }
                        closeCallBack={() =>
                          this.toggleModal(MODAL_NAMES.EXCLUSIVECART)
                        }
                        contentHeader={t(
                          LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_EXCLUSIVELICENCES
                        )}
                        exclusiveCart={this.state.exclusiveCart}
                        checkExclusiveCondition={this.checkExclusiveCondition}
                        buttonDisabled={this.state.buttonDisabled}
                        displayExclusiveModal={() =>
                          this.displayExclusiveModal()
                        }
                      />
                      <PreRequisiteModal
                        isOpen={this.state.modalState === MODAL_NAMES.CART}
                        closeCallBack={() => this.toggleModal(MODAL_NAMES.CART)}
                        contentHeader={t(
                          LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_PREREQUISITELICENCES
                        )}
                        displayExclusiveModal={() =>
                          this.displayExclusiveModal()
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {licenceList.length > 0 ? (
                  <div className="col-lg-4">
                    <div className="documents-to-be-submitted">
                      <h4>
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_SUPPORTINGDOC
                        )}
                      </h4>
                      <div className="documents">
                        {noDocs ? (
                          <p>
                            {t(
                              LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_NOSUPPORTINGDOC
                            )}
                          </p>
                        ) : (
                          this.displayDocuments(documents, t)
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {GET_APP_ENV && (
                  <RecommendationResultContainer
                    licences={filteredRecommendedList}
                  />
                )}
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTranslation()(
  compose(withLoader, withRouter)(withLicenceContext(LicenceCart))
);
