import React from 'react';
import { withTranslation } from 'react-i18next';
import { CHANGE_PASSWORD_URL, CHANGE_USERNAME_URL, GET_APP_ENV, PROFILE_URL } from '../App/AppSettings';
import { LANGUAGE_KEYS } from '../Shared/Constants/LanguageKeys';


class ProfileDropdown extends React.Component {
    render() {
        const { profile, toggleProfileDisplay, logout, t } = this.props;
        return (
            <>
                <div className="user-profile-overlay"></div>
                <div className="user-profile">
                    <div className="container">
                        <div className="user-profile-close" onClick={toggleProfileDisplay}><i className="fal fa-times-circle"></i></div>
                        <h5 className="user-name">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_TITLE_HI)} {profile.given_name}</h5>
                        <p className="loggedin-time"></p>
                        <div className="profile-options">
                            <ul>
                                <li><span onClick={() => window.open(PROFILE_URL, '_blank')}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_UPDATEPROFILE)}</span></li>
                                {GET_APP_ENV && <li><span onClick={() => window.open(CHANGE_USERNAME_URL, '_blank')}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_CHANGELOGINID)}</span></li>}
                                <li><span onClick={() => window.open(CHANGE_PASSWORD_URL, '_blank')}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_CHANGEPASSWORD)}</span></li>
                                <li><span onClick={logout}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_LOGOUT)}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ProfileDropdown);

