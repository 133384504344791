import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { withBaseControl } from './BaseControl';


class ToggleSwitchBoolean extends React.Component {

    // Lifecycle Fn
    componentDidMount() {

        // If value is neither true nor false
        if ((this.props.value !== true) && (this.props.value !== false)) {

            // We then check whether defaultOn prop is given
            const newValue = (this.props.defaultOn) ? true : false;

            // Update new value in parent state
            this.props.onChangeField(this.props.name, newValue);
        }
    }


    // Handle switch toggle/untoggle
    handleToggleChange = ({ onChangeField, name, value }) => {

        // Toggle the text based on currentValue
        const newValue = (value === true) ? false : true;

        // Update text to parent state
        onChangeField(name, newValue);
    };

    // Component: Toggle Text
    ToggleText = ({showToggleText, value, trueText, falseText}) => {

        const displayText = (value === true ? trueText : falseText )

        return (showToggleText) ?
            (<Label className="toggle-switch-label">{displayText}</Label>)
            :
            (null);
    };

    render() {
        return (
            <FormGroup className="toggle-switch-group" check inline>
                {/* Hide original Checkbox/ToggleSwitchBoolean UI, but all react behavior still happens here */}
                <Input
                    className="toggle-switch-control"
                    type="checkbox"
                    id={this.props.name}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={() => this.handleToggleChange(this.props)}
                    checked={this.props.value === true}
                />

                {/* Re-styled ToggleSwitchBoolean UI for cross-browser compatibility */}
                <Label for={this.props.name} className="toggle-switch-display"></Label>

                {/* ToggleSwitchBoolean Text */}
                {this.ToggleText(this.props)}
            </FormGroup>
        );
    }
}

// PropTypes: For prop validation
ToggleSwitchBoolean.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool,PropTypes.string]),
    onChangeField: PropTypes.func.isRequired,
    trueText: PropTypes.string,
    falseText: PropTypes.string,
    showToggleText: PropTypes.bool,
    defaultOn: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
ToggleSwitchBoolean.defaultProps = {
    value: true,
    trueText: 'True',
    falseText: 'False',
    showToggleText: true,
    defaultOn: undefined
};


export default withBaseControl(ToggleSwitchBoolean);




