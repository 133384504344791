import { default as React } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import { CREATE_FORM_URL, DASHBOARD_PATH, GET_MAKE_PAYMENT_DETAIL_URL, IS_DEMO, LAYOUT_TYPE, PAYMENT_OPTIONS, CHECK_PAYMENT_ORDERS_URL, MAKE_PAYMENT_PATH } from '../../../App/AppSettings';
import Layout from '../../../Layout';
import Section from '../../../Section';
import SectionWrapper from '../../../SectionWrapper';
import { getParams, fetchRequest, navigateTo, postParams } from '../../../Shared/Actions';
import { SECTION_WRAPPER_TYPES, BUTTON_TYPE } from '../../../Shared/Constants';
import { ScrollToTop, SMARTForm, withSMARTWrapper } from '../../../Shared/Forms';
import history from '../../../Shared/History';
import PageFooter from '../../../Shared/PageFooter';
import PaymentSummary from '../../FormComponents/PaymentSummary';
import { toastError } from '../../../Shared/Forms/Toaster';
import { withTranslation } from 'react-i18next';
import { ERROR, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.ACCORDION;
const SECTION_LAYOUT_TYPE_WIZARD = SECTION_WRAPPER_TYPES.WIZARD;

const INITIAL_VALUES = 0;
const GET_PAYMENT_REQUEST = GET_MAKE_PAYMENT_DETAIL_URL;
const CANCEL_URL = DASHBOARD_PATH;
const MAKE_PAYMENT = MAKE_PAYMENT_PATH;
const CHECK_PAYMENT = CHECK_PAYMENT_ORDERS_URL;

const PARAM_INITIAL_VALUES = {
    submissionNumber: INITIAL_VALUES,
};

const SECTION_NAME_PAYMENT = "Payments"

class MakePaymentSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formNames: {
                tab: ""
            },
            onlineBtn: BUTTON_TYPE.ACTIVE,
            offlineBtn: BUTTON_TYPE.INACTIVE,
            paymentOption: PAYMENT_OPTIONS.ONLINE
        }
    }
    async componentDidMount() {

        this.getFees();
    }

    choosePaymentOption = (paymentOption) => {
        if (paymentOption === PAYMENT_OPTIONS.ONLINE) {
            this.setState({
                onlineBtn: BUTTON_TYPE.ACTIVE,
                offlineBtn: BUTTON_TYPE.INACTIVE,
                paymentOption: paymentOption
            });
        } else {
            this.setState({
                onlineBtn: BUTTON_TYPE.INACTIVE,
                offlineBtn: BUTTON_TYPE.ACTIVE,
                paymentOption: paymentOption
            });
        }
    }

    getFees = async () => {
        // Start loading
        const { loader, t } = this.props;
        loader.start();

        let paramValue = INITIAL_VALUES;

        //get the params
        if (history.location.state !== undefined) {
            paramValue = history.location.state.Data;
        }       
        else {
            //show error page
            loader.done();
            alert(t(ERROR.ERROR));
            return;
        }

        let queryString = Object.assign({}, PARAM_INITIAL_VALUES, { submissionNumber: paramValue });
        let response = await fetchRequest(GET_PAYMENT_REQUEST, getParams(queryString), IS_DEMO);

        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {

            this.setState(() => {
                return {
                    licenceFees: Data.LicenceFeeItemList,
                    totalLicenceFee: Data.TotalLicenceFee,
                    submissionNumber: paramValue
                };
            });

            loader.done();
        }
        else {
            //show error page
            loader.done();
            alert(t(ERROR.PAYMENT_DETAILS_NOT_FETCHED));
        }

        // End loading

    }

    checkOrders = async (submissionNumber, paymentOption) => {
        //change the payment type for the orders
        const { t } = this.props;
        let queryString = Object.assign({}, { submissionNumber });

        let response = await fetchRequest(CHECK_PAYMENT, postParams('',queryString), IS_DEMO);
        const { IsSuccess, Messages } = response.body;

        if (IsSuccess) {
            navigateTo(MAKE_PAYMENT, { Data: { SubmissionNumber: submissionNumber, PaymentOption: paymentOption } });
        }
        else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }

    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment >
                <div className="main-content">
                    <ScrollToTop />
                    <Layout type={LAYOUT_TYPE.FORM} footer>
                        <SMARTForm
                            className="container margin-only-40"
                            //formContext={FORM_CONTEXT}
                            sectionNames={this.state.formNames}
                            defaultSection={SECTION_NAME_PAYMENT}
                            formValues={this.state.values}
                            validateOnSubmit={false}
                            serverURL={CREATE_FORM_URL}

                        >
                            {({ toggleSection, sectionState, values }) => {

                                return (
                                    <React.Fragment>
                                        <Col>
                                            <SectionWrapper
                                                type={SECTION_LAYOUT_TYPE}
                                                tabs={this.state.formNames}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}>

                                                {/* Payment Section */}
                                                <Section
                                                    type={SECTION_LAYOUT_TYPE_WIZARD}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={SECTION_NAME_PAYMENT}
                                                    key="Payments">
                                                    {this.state.licenceFees &&
                                                        <PaymentSummary
                                                            licenceFees={this.state.licenceFees}
                                                            totalLicenceFee={this.state.totalLicenceFee}
                                                            choosePaymentOption={(option) => this.choosePaymentOption(option)}
                                                            onlineBtn={this.state.onlineBtn}
                                                            offlineBtn={this.state.offlineBtn}
                                                        />
                                                    }
                                                </Section>
                                            </SectionWrapper>
                                        </Col>

                                        <PageFooter type={LAYOUT_TYPE.FORM}>
                                            <div className="button-center">

                                                {/* Cancel Button */}
                                                <Button color="backward" size="sm" type="button" onClick={() => navigateTo(CANCEL_URL)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_BUTTON_CANCEL)}</Button>


                                                <Button color="forward" size="sm" type="button"
                                                        onClick={() => this.checkOrders(this.state.submissionNumber, this.state.paymentOption)}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_PAY)}
                                            </Button>

                                            </div>
                                        </PageFooter>
                                    </React.Fragment>
                                )
                            }}
                        </SMARTForm>
                    </Layout>
                </div>
            </React.Fragment >
        );
    }
}
export default withTranslation()(withRouter(withSMARTWrapper(MakePaymentSummary)));

