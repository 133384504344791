import React from 'react';
// import { withSMARTLayout } from '../Layout/SMARTLayout';
// import { Route, Redirect  } from 'react-router-dom';


class RouterExtension extends React.Component {

    render() {
        return(
            <React.Fragment>


            {/* Paste generated path below to add on */}
			
			
            </React.Fragment>
        )
    }
}


export { RouterExtension }