import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'reactstrap';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class ErrorPage extends React.Component {

    // Component: Render Back Button
    BackButton = () => {
        return (this.props.backCallback) &&
            (
                <Button color="backward" className="error-btn-back" onClick={this.props.backCallback}>
                    {this.props.backButtonText}
                </Button>
            );
    };

    // Component: Render Reload Button
    ReloadButton = () => {
        return (this.props.reloadCallback) &&
            (
                <Button color="neutral" className="error-btn-reload" onClick={this.props.reloadCallback}>
                    {this.props.reloadButtonText}
                </Button>
            );
    };

    // Component: Render ErrorID Message
    ErrorIdMessage = () => {
        return (this.props.errorId) &&
            (
                <Label className="error-id-message">
                    Error: {this.props.errorId}
                </Label>
            );
    };

    // Lifecycle Fn: To be called during unmount
    componentWillUnmount = () => {
        // Clear all timeout before unmounting / exiting page
        this.props.clearTimeout && this.props.clearTimeout();
    };


    render() {
        return (
            <div className={this.props.className}>
                <FontAwesomeIcon className={this.props.iconStyle} icon={this.props.icon} />
                <Label className="error-header">{this.props.errorHeader}</Label>
                <Label className="error-content">{this.props.errorContent}</Label>
                <div className="error-btn-group">
                    {this.BackButton()}
                    {this.ReloadButton()}
                </div>
                {this.ErrorIdMessage()}
            </div>
        );
    }
}

ErrorPage.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.object,
    iconStyle: PropTypes.string,
    errorHeader: PropTypes.string,
    errorContent: PropTypes.string,
    backCallback: PropTypes.func,       // If there is no backCallback, back button will not be loaded
    backButtonText: PropTypes.string,
    reloadCallback: PropTypes.func,     // If there is no reloadCallback, reload button will not be loaded
    reloadButtonText: PropTypes.string,
    errorId: PropTypes.string,          // If there is no errorId, errorIdMessage will not be loaded
    clearTimeout: PropTypes.func        // Used to clear all Timeout events as ErrorPage usually shown 
};

ErrorPage.defaultProps = {
    className: "error-container",
    icon: faTimesCircle,
    iconStyle: "error-icon",
    errorHeader: "An error has occurred!",
    errorContent: "The server has encountered an error. Please try again.",
    backButtonText: "Back",
    reloadButtonText: "Refresh"
};


export default ErrorPage;