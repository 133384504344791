import 'core-js/es6/object';
import 'core-js/es7/object'; // ES7 Object needed
import React from 'react';
import { Router } from 'react-router-dom';
import Content from '../Content';
import { AuthProvider } from '../Shared/Authentication/AuthContext';
import history from '../Shared/History';
import Css from '../Css';
// import MinimizableWebChat from '../Chatbot/MinimizableWebChat';
// import { GET_APP_ENV } from './AppSettings.js';

class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <AuthProvider>
                     {/*US#275350 to hide the chatbot settings*/
                    /* <MinimizableWebChat /> */}
                    <Css />
                    <Content />
                </AuthProvider >
            </Router >
        );
    }
}

export default App;