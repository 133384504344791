import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalState: undefined
        };
    }

    // Fn: Toggles state of Modal (show/hide) based on given modalName
    toggleModal = (modalName) => {
        // Default state: Allow opened modal to hide
        // Otherwise, open new modal and hide others
        let newModalState = (this.state.modalState !== modalName) ? modalName : undefined;

        // Update new modalState
        this.setState({ modalState: newModalState });
    };

    forceCloseModal = (modalName) => {
        let newModalState = (this.state.modalState === modalName) ? undefined : this.state.modalState;
        this.setState({ modalState: newModalState });
    }

    render() {
        return (
            <>
                {this.props.render({
                    modal: {
                        modalState: this.state.modalState,
                        toggleModal: this.toggleModal,
                        forceCloseModal: this.forceCloseModal
                    }
                })}
            </>
        );
    }
}

export default Modal;