import React from "react";
import NavContainer from "./NavContainer";
import NavLink from "./NavLink";
import { withTranslation } from "react-i18next";
import { DirectionContextConsumer } from "../Shared/DirectionContext";
import { withModalWrapper } from "../Shared/Modal/ModalWrapper";
import { AuthConsumer } from "../Shared/Authentication/AuthContext";
import { withRouter } from "react-router-dom";
import {
  DASHBOARD_PATH,
  ENQUIRY_PATH,
  FAQ_PATH,
  HOME_PATH,
  LOGIN_PATH,
  SEARCH_INTENT_PATH,
  SITE_LOGO,
  SITE_LOGO_ALT_TEXT,
} from "../App/AppSettings";
import { LANGUAGE_KEYS } from "../Shared/Constants/LanguageKeys";

const NAVITEM = {
  Home: "Home",
  INBOX: "Inbox",
  ENQUIRY: "Enquiry",
  SERVICES: "Services",
  ADMIN: "Admin",
  CONTACTUS: "Contact Us",
  FAQS: "FAQ",
};

class BLSMobileNavbar extends React.Component {
  render() {
    const { i18n, t } = this.props;
    const { changeLanguage, supportedLangResponse } = this.props.context;
    const langArray = supportedLangResponse
      ? supportedLangResponse.body.Data
      : [];
    return (
      <AuthConsumer>
        {({ isAuthenticated, profile }) => (
          <div className={this.props.sideNavbarClassname}>
            <ul className="nav navbar-nav navbar-left">
              <NavLink
                onClick={this.props.onClick}
                navbarLayout={"1M"}
                to={HOME_PATH}
                dropdownlevel="1"
                navPath={NAVITEM.Home}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_HOME)}
              </NavLink>

              {profile && isAuthenticated && (
                <NavLink
                  onClick={this.props.onClick}
                  navbarLayout={"1M"}
                  to={DASHBOARD_PATH}
                  dropdownlevel="1"
                  navPath={NAVITEM.CONTACTUS}
                >
                  Workspace
                </NavLink>
              )}

              <NavContainer
                className="dropdown-toggle"
                navbarLayout={"1M"}
                path="/services/deathregistration/"
                dropdownlevel="1"
                name={t(
                  LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SERVICEAPPLICATION
                )}
                navPath={NAVITEM.ADMIN}
              >
                <NavLink
                  onClick={this.props.onClick}
                  navbarLayout={"1M"}
                  to={SEARCH_INTENT_PATH}
                  dropdownlevel="2"
                  navPath={NAVITEM.ADMIN}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_APPLYFORNEWSERVICEAPPLICATION
                  )}
                </NavLink>
                <NavLink
                  onClick={this.props.onClick}
                  navbarLayout={"1M"}
                  to={DASHBOARD_PATH}
                  dropdownlevel="2"
                  navPath={NAVITEM.ADMIN}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_RENEWSERVICEAPPLICATION
                  )}
                </NavLink>
                <NavLink
                  onClick={this.props.onClick}
                  navbarLayout={"1M"}
                  to={DASHBOARD_PATH}
                  dropdownlevel="2"
                  navPath={NAVITEM.ADMIN}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CANCELSERVICEAPPLICATION
                  )}
                </NavLink>
                <NavLink
                  onClick={this.props.onClick}
                  navbarLayout={"1M"}
                  to={DASHBOARD_PATH}
                  dropdownlevel="2"
                  navPath={NAVITEM.ADMIN}
                >
                  {t(
                    LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_AMENDSERVICEAPPLICATION
                  )}
                </NavLink>
              </NavContainer>

              <NavLink
                onClick={this.props.onClick}
                navbarLayout={"1M"}
                to={ENQUIRY_PATH}
                dropdownlevel="1"
                navPath={NAVITEM.CONTACTUS}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SITESEARCH)}
              </NavLink>
              <NavLink
                onClick={this.props.onClick}
                navbarLayout={"1M"}
                to={ENQUIRY_PATH}
                dropdownlevel="1"
                navPath={NAVITEM.CONTACTUS}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_NOTICES)}
              </NavLink>
              <NavContainer
                className="dropdown-toggle"
                navbarLayout={"1M"}
                path="/services/deathregistration/"
                dropdownlevel="1"
                name={t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_LANGUAGE)}
                navPath={NAVITEM.ADMIN}
              >
                {langArray.map((lang, i) => (
                  <NavLink
                    key={i}
                    dropdownlevel="2"
                    navPath={NAVITEM.CONTACTUS}
                    navbarLayout={"1M"}
                    to="#top"
                    onClick={() => {
                      i18n.changeLanguage(lang.LanguageCodeValue);
                      changeLanguage(lang.LanguageCodeValue);
                    }}
                  >
                    {`${lang.Description} (${lang.LanguageCodeValue})`}
                  </NavLink>
                ))}
              </NavContainer>
              <NavLink
                onClick={this.props.onClick}
                navbarLayout={"1M"}
                to={HOME_PATH}
                dropdownlevel="1"
                navPath={NAVITEM.CONTACTUS}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_ABOUTUS)}
              </NavLink>
              <NavLink
                onClick={this.props.onClick}
                navbarLayout={"1M"}
                to={FAQ_PATH}
                dropdownlevel="1"
                navPath={NAVITEM.CONTACTUS}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_FAQ)}
              </NavLink>
              <NavLink
                onClick={this.props.onClick}
                navbarLayout={"1M"}
                to={HOME_PATH}
                dropdownlevel="1"
                navPath={NAVITEM.CONTACTUS}
              >
                {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CONTACTUS)}
              </NavLink>
            </ul>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

const withDirectionContext = (WrappedComponent) => {
  class DirectionOnPageContext extends React.Component {
    render() {
      return (
        <DirectionContextConsumer>
          {({ changeLanguage, supportedLangResponse }) => (
            <WrappedComponent
              context={{ changeLanguage, supportedLangResponse }}
              {...this.props}
            />
          )}
        </DirectionContextConsumer>
      );
    }
  }

  return DirectionOnPageContext;
};

export default withTranslation()(
  withModalWrapper(withRouter(withDirectionContext(BLSMobileNavbar)))
);
