import React from 'react';
import { FAQ_PATH, HOME_PATH, DASHBOARD_PATH, SEARCH_INTENT_PATH, FOOTER_LOGO, FOOTER_LOGO_ALT_TEXT, COPYRIGHT_TEXT, COPYRIGHT_TEXT_V2 } from '../App/AppSettings';
import { navigateTo } from '../Shared/Actions';
import { withTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from '../Shared/Constants/LanguageKeys';

class Footer extends React.Component {

    render() {
        const {t} = this.props;
        const copyrightText = this.props.isMirroredFooter ? COPYRIGHT_TEXT_V2 : COPYRIGHT_TEXT;
        return (
            // <div className={"navbar-footer"}>
            <React.Fragment>

                {this.props.footerExtension}

                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-top">

                            <div className="row">
                            <div className="col-sm-4 col-md-4 col-lg-3"><a href="#top"><img src={ FOOTER_LOGO } alt={ FOOTER_LOGO_ALT_TEXT } /></a></div>
                                <div className="col-sm-4  col-md-4 col-lg-3">
                                    <ul className="footer-links">
                                        <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to Home'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_HOME)}</button></li>
                                        <li><button onClick={() => navigateTo(FAQ_PATH)} title='Go to FAQ'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_FAQ)}</button></li>
                                        <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to Home'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_NEWS)}</button></li>
                                        <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to Home'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_EVENTS)}</button></li>
                                        <li><button onClick={() => navigateTo(HOME_PATH)} title='Go to Home'>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CONTACTUS)}</button></li>
                                    </ul>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-3">
                                    <ul className="footer-links">
                                        <li><div className="footerheader">{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SERVICEAPPLICATION)}</div></li>                                                                                           
                                        <li><div className="LicenceApp" onClick={()=>navigateTo (SEARCH_INTENT_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_APPLYFORNEWSERVICEAPPLICATION)}</div></li>
                                        <li><div className="LicenceApp" onClick={()=>navigateTo (DASHBOARD_PATH)}> {t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_RENEWSERVICEAPPLICATION)}</div></li>
                                        <li><div className="LicenceApp" onClick={()=>navigateTo (DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CANCELSERVICEAPPLICATION)}</div></li>
                                        <li><div className="LicenceApp" onClick={()=>navigateTo (DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_AMENDSERVICEAPPLICATION)}</div></li>
                                    </ul>


                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-3">
                                    <div className="social-link-block">
                                        <a href="https://www.facebook.com/" className="social-link facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                        <a href="https://www.linkedin.com/" className="social-link linkedin" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                        <a href="https://www.twitter.com/" className="social-link twitter" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                        <a href="https://www.instagram.com/" className="social-link instagram" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="footer-copy">
                            <p className="pull-left">{ copyrightText }</p>
                            <p className="pull-right"><a href="#top">{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_PRIVACYSTATEMENT)} </a>      <a href="#top">{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_TERMSOFUSE)}</a></p>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withTranslation()(Footer);