import { default as React } from 'react';
import { withRouter } from 'react-router-dom';
import { Input } from 'reactstrap';
import { AGENCY_LIST_BY_ID_URL, GET_ALL_AGENCY_LIST_URL, IS_DEMO, LAYOUT_TYPE, SEARCH_ALL_AGENCY_LIST_URL, SEARCH_ALL_LICENCE_PATH } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { DIRECTIONS } from '../../Shared/Constants';
import { withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import DetailContainer from './DetailContainer';
import ResultContainer from './ResultContainer';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

const SEARCH_ALL_AGENCY = SEARCH_ALL_AGENCY_LIST_URL;
const GET_ALL_AGENCY = GET_ALL_AGENCY_LIST_URL;
const GET_LICENCE_BY_AGENCY_ID = AGENCY_LIST_BY_ID_URL;

const INITIAL_VALUES = '*';
const BLANK_VALUE = '';
const FORM_INITIAL_VALUES = {
    title: ''
};

class SearchAllAgency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: undefined,
            selectedId: null,
            isResultDisplayed: false,
        };

        if (history.location.state) {
            this.state = { values: history.location.state.title };
        }

        if (this.props.location.search) {
            //Passes search query when 'Enter' button is clicked
            const params = new URLSearchParams(this.props.location.search);
            const title = params.get('title');
            this.state = { values: title !== '' ? title : INITIAL_VALUES };
        }

        const values = this.state.values;
        if (values === undefined || values === '') {
            this.state = {
                values: INITIAL_VALUES
            };
        }

        this.state = {
            values: this.state.values,
            licences: [],
            title: FORM_INITIAL_VALUES,
            agencyTitle: BLANK_VALUE,
            agencyDesc: BLANK_VALUE,
            displayValue: BLANK_VALUE
        };
    }

    componentDidMount() {
        this.getAgency();
    }

    getAgency = async () => {
        const { loader } = this.props;

        const queryString = Object.assign({}, FORM_INITIAL_VALUES, { title: this.state.values });

        // Start loading
        loader.start();

        let response;
        if (this.state.values === INITIAL_VALUES) {
            response = await fetchRequest(GET_ALL_AGENCY, getParams(queryString), IS_DEMO);
        }
        else {
            response = await fetchRequest(SEARCH_ALL_AGENCY, getParams(queryString), IS_DEMO);
        }
        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {
                this.setState(() => {
                    return {
                        agencies: Data,
                        licences: [],
                        selectedId: null,
                        agencyTitle: BLANK_VALUE,
                        agencyDesc: BLANK_VALUE,
                        displayValue: this.state.values
                    };
                });
            }
            else {
                this.setState(() => {
                    return { agencies: [] };
                });
            }

        }

        // End loading
        loader.done();
    };

    displayAgencyResultContainer = () => {
        return (
            <ResultContainer
                agencies={this.state.agencies}
                criteria={this.state.displayValue}
                handleAgencyClick={this.handleAgencyClick}
                selectedId={this.state.selectedId}
                isResultDisplayed={this.state.isResultDisplayed}
            />
        );
    };

    //clicking the agency will load the related licence
    handleAgencyClick = async (id, title, description) => {
        //execute some function, set the agencyTitle and agencyDesc
        const { loader } = this.props;
        this.setState(() => {
            return {
                selectedId: id
            };
        });

        const queryString = { id: id };

        // Start loading
        loader.start();

        const response = await fetchRequest(GET_LICENCE_BY_AGENCY_ID, getParams(queryString), IS_DEMO);
        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {
                this.state.displayValue = this.state.values;
                this.setState(() => {
                    return {
                        licences: Data,
                        agencyTitle: title,
                        agencyDesc: description
                    };
                });
            }
            else {
                this.setState(() => {
                    return {
                        licences: [],
                        agencyTitle: BLANK_VALUE,
                        agencyDesc: BLANK_VALUE
                    };
                });
            }

        }

        // End loading
        loader.done();
    };


    displayLicenceDetailContainer = () => {
        return (
            <DetailContainer
                licences={this.state.licences}
                value={this.state.displayValue}
                agencyTitle={this.state.agencyTitle}
                agencyDesc={this.state.agencyDesc}
            />
        );
    };

    inputOnChangeHandler = e => {
        if (e.target.value === undefined || e.target.value === '') {
            this.setState({
                title: {
                    title: FORM_INITIAL_VALUES.title
                },
                values: INITIAL_VALUES
            });
        }
        else {

            this.setState({
                title: {
                    title: e.target.value
                },
                values: e.target.value
            });
        }
    };

    searchValue = () => {
        const values = this.state.values;
        if (values === undefined || values === '') {
            this.setState({
                values: INITIAL_VALUES
            });
        }
        this.getAgency();
    };

    inputKeyDownHandler = e => {
        if (e.key === 'Enter') {
            const values = this.state.values;
            if (values === undefined || values === '') {
                this.setState({
                    values: INITIAL_VALUES
                });
            }
            this.getAgency();
        }
    };

    toggleViewOfAgencyResults = () => {
        this.setState(prevState => {
            return {
                isResultDisplayed: !prevState.isResultDisplayed
            };
        });
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className="main-content">
                        <nav aria-label="breadcrumb" className="breadcrumb-main">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={process.env.PUBLIC_URL+SEARCH_ALL_LICENCE_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCH)}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYAGENCY_CONTENT_TITLE_AGENCY)}</li>
                                </ol>
                            </div>
                        </nav>
                        <div className="container">
                            <div className="browse-by-agency">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="find-apply">
                                            <div className="licvence-serach">
                                                <div className="input-group">
                                                    <Input
                                                        dir={DIRECTIONS.AUTO}
                                                        className=""
                                                        type="text"
                                                        value={this.state.title.title}
                                                        name="title"
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYAGENCY_CONTENT_PLACEHOLDER_SEARCHAGENCY)}
                                                        onChange={this.inputOnChangeHandler}
                                                        minLength={0}
                                                        maxLength={140}
                                                        onKeyDown={this.inputKeyDownHandler}
                                                    />                                                    <span className="input-group-btn">
                                                        <button className="btn btn-orange" type="button" onClick={this.searchValue}><i className="fal fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mobile-sub-nav" onClick={this.toggleViewOfAgencyResults}>Agencies</h4>
                                        {this.displayAgencyResultContainer()}
                                    </div>
                                    <div className="col-lg-8">
                                        {this.displayLicenceDetailContainer()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>

        );
    }
}
export default withTranslation()(withRouter(withSMARTWrapper(SearchAllAgency)));

