import React, { useState, useEffect, useCallback } from 'react';
import { GET_SUPPORTED_LANGUAGE_URL } from '../../App/AppSettings';
import { fetchRequest, getParams } from '../Actions';
import { withTranslation } from 'react-i18next';
import { DIRECTIONS } from '../Constants';
export const DirectionContext = React.createContext();

function DirectionProviderComponent(props) {
    const { i18n } = props;

    const [direction, setDirection] = useState(DIRECTIONS.LTR);
    const [response, setResponse] = useState("");
    const [currLang, setcurrLang] = useState(i18n.options.fallbackLng[0]);

    const fetchData = useCallback((langValue, getDefaultLang) => {
        const { IsSuccess, Data } = response.body;
        if (response.success && IsSuccess) {
            let idx;
            if (getDefaultLang) {
                idx = Data.findIndex(l => l.IsDefault);
                if (langValue !== Data[idx].LanguageCodeValue) {
                    i18n.changeLanguage(Data[idx].LanguageCodeValue);
                }
            } else {
                idx = Data.findIndex(l => l.LanguageCodeValue === langValue);
            }

            setcurrLang(Data[idx].LanguageCodeValue);
            const directionToSet = Data[idx].IsAlignRight ? DIRECTIONS.RTL : DIRECTIONS.LTR;
            //set dir=rtl or dir=ltr in html tag
            document.documentElement.dir = directionToSet;
            setDirection(directionToSet);
        }
    }, [response, i18n])

    //fetch supported languages data
    useEffect(() => {
        const url = GET_SUPPORTED_LANGUAGE_URL;
        async function fetch() {
            const result = await fetchRequest(url, getParams());
            setResponse(result);
        }
        if (!response) {
            fetch();
        }
        const currLangValue = i18n.options.fallbackLng[0];
        if (response) {
            fetchData(currLangValue, true);
        }
    }, [response, i18n, fetchData]);

    const changeLanguage = (langValue) => {
        fetchData(langValue, false);
    }

    return (
        <DirectionContext.Provider
            value={{
                direction,
                changeLanguage,
                supportedLangResponse: response,
                currLang
            }}
        >
            {props.children}
        </DirectionContext.Provider>
    );
}

const DirectionContextConsumer = DirectionContext.Consumer;
const DirectionContextProvider = withTranslation()(DirectionProviderComponent);
export { DirectionContextConsumer, DirectionContextProvider };
