import { APP_NAME_LICENCE_CONFIG } from '../../App/AppSettings';

export const APP_NAME = "DSP";

const SCOPE_CAT_SUBCAT_STRINGS = {
    //SORT BY HOME PAGE
    PUBLICHOME_CONTENT_TITLE: 'PublicHome.Content.Title',
    PUBLICHOME_CONTENT_MODAL: 'PublicHome.Content.Modal',
    PUBLICHOME_CONTENT_NOTE: 'PublicHome.Content.Note',
    PUBLICHOME_CONTENT_BUTTON: 'PublicHome.Content.Button',
    PUBLICHOME_NAVIGATION_BUTTON: 'PublicHome.Navigation.Button',
    PUBLICHOME_NAVIGATION_MENU: 'PublicHome.Navigation.Menu',
    //SORT BY LICENCESEARCH
    PUBLICLICENCESEARCH_CONTENT_MODAL: 'PublicLicenceSearch.Content.Modal',
    PUBLICLICENCESEARCH_CONTENT_TITLE: 'PublicLicenceSearch.Content.Title',
    PUBLICLICENCESEARCH_CONTENT_BUTTON: 'PublicLicenceSearch.Content.Button',
    PUBLICLICENCESEARCH_CONTENT_FIELD: 'PublicLicenceSearch.Content.Field',
    PUBLICLICENCESEARCH_CONTENT_LINK: 'PublicLicenceSearch.Content.Link',
    PUBLICLICENCESEARCH_CONTENT_INFORMATION: 'PublicLicenceSearch.Content.Information',
    PUBLICLICENCESEARCH_NAVIGATION_BUTTON: 'PublicLicenceSearch.Navigation.Button',
    PUBLICLICENCESEARCH_MESSAGE_NOTE: 'PublicLicenceSearch.Message.Note',
    PUBLICLICENCESEARCH_MESSAGE_TITLE: 'PublicLicenceSearch.Message.Title',
    PUBLICLICENCESEARCH_MESSAGE_MANDATORY: 'PublicLicenceSearch.Message.Mandatory',
    //SORT BY LOGIN
    PUBLICLOGIN_CONTENT_BUTTON: 'PublicLogin.Content.Button',
    PUBLICLOGIN_NAVIGATION_MENU: 'PublicLogin.Navigation.Menu',
    //BROWEBYAGENGY
    PUBLICBROSEBYAGENCY_CONTENT_TITLE: 'PublicBrowseByAgency.Content.Title',
    PUBLICBROWSEBYAGENCY_CONTENT_PLACEHOLDER: 'PublicBrowseByAgency.Content.Placeholder',
    PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER: 'PublicBrowseByAgency.Message.Placeholder',
    //BROWEBYINDUSTRY
    PUBLICBROSEBYINDUSTRY_CONTENT_TITLE: 'PublicBrowseByIndustry.Content.Title',
    PUBLICBROWSEBYINDUSTRY_CONTENT_PLACEHOLDER: 'PublicBrowseByIndustry.Content.Placeholder',
    PUBLICBROWSEBYINDUSTRY_MESSAGE_PLACEHOLDER: 'PublicBrowseByIndustry.Message.Placeholder',
    //PUBLICQUESTIONNAIRE
    PUBLICQUESTIONNAIRE_CONTENT_TITLE: 'PublicQuestionnaire.Content.Title',
    PUBLICQUESTIONNAIRE_CONTENT_INFORMATION: 'PublicQuestionnaire.Content.Information',
    PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON: 'PublicQuestionnaire.Navigation.Button',
    //WORKSPACE
    PUBLICWORKSPACE_CONTENT_TITLE: 'PublicWorkspace.Content.Title',
    PUBLICWORKSPACE_CONTENT_MENU: 'PublicWorkspace.Content.Menu',
    PUBLICWORKSPACE_NAVIGATION_BUTTON: 'PublicWorkspace.Navigation.Button',
    PUBLICWORKSPACE_NAVIGATION_MENU: 'PublicWorkspace.Navigation.Menu',
    PUBLICWORKSPACE_NAVIGATION_PLACEHOLDER: 'PublicWorkspace.Navigation.Placeholder',
    //WORKSPACE APPLICATION
    PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE: 'PublicWorkspaceApplication.Content.Title',
    PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION: 'PublicWorkspaceApplication.Content.Section',
    PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD: 'PublicWorkspaceApplication.Content.Field',
    PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE: 'PublicWorkspaceApplication.Content.Note',
    PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON: 'PublicWorkspaceApplication.Content.Button',
    PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER: 'PublicWorkspaceApplication.Content.Placeholder',
    PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION: 'PublicWorkspaceApplication.Content.Information',
    PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL: 'PublicWorkspaceApplication.Content.Modal',
    PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON: 'PublicWorkspaceApplication.Navigation.Button',
    PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU: 'PublicWorkspaceApplication.Navigation.Menu',
    PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION: 'PublicWorkspaceApplication.Message.Information',
    PUBLICWORKSPACEAPPLICATION_TABLE_TITLE: 'PublicWorkspaceApplication.Table.Title',
    //WORKSPACE LICENCE
    PUBLICWORKSPACELICENCE_CONTENT_TITLE: 'PublicWorkspaceLicence.Content.Title',
    PUBLICWORKSPACELICENCE_CONTENT_FIELD: 'PublicWorkspaceLicence.Content.Field',
    PUBLICWORKSPACELICENCE_CONTENT_SECTION: 'PublicWorkspaceLicence.Content.Section',
    PUBLICWORKSPACELICENCE_CONTENT_MENU: 'PublicWorkspaceLicence.Content.Menu',
    PUBLICWORKSPACELICENCE_CONTENT_PLACEHOLDER: 'PublicWorkspaceLicence.Content.Placeholder',
    PUBLICWORKSPACELICENCE_CONTENT_BUTTON: 'PublicWorkspaceLicence.Content.Button',
    PUBLICWORKSPACELICENCE_CONTENT_INFORMATION: 'PublicWorkspaceLicence.Content.Information',
    PUBLICWORKSPACELICENCE_MESSAGE_NOTE: 'PublicWorkspaceLicence.Message.Note',
    PUBLICWORKSPACELICENCE_TABLE_TITLE: 'PublicWorkspaceLicence.Table.Title',
    PUBLICWORKSPACELICENCE_NAVIGATION_MENU: 'PublicWorkspaceLicence.Navigation.Menu',
    LICENCEDETAILS_LICENCESTATUS_TITLE: 'LicenceDetails.LicenceStatus.Title',
    CODE_LICENCEAPPLICATIONTYPE_TITLE: 'Code.LicenceApplicationType.Title',
    CODE_CORRESPONDENCETYPE_TITLE: 'Code.CorrespondenceType.Title',
    CODE_LICENCESTATUS_TITLE: 'Code.LicenceStatus.Title',
    DEFAULT_CODE_LICENCEAPPLICATIONTYPE: 'Default.Code.LicenceApplicationType',
    DEFAULT_CODE_CORRESPONDENCETYPE: 'Default.Code.CorrespondenceType',
    //WORKSPACE CORRESPONDENCE
    PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD: 'PublicWorkspaceCorrespondence.Content.Field',
    PUBLICWORKSPACECORRESPONDENCE_CONTENT_TITLE: 'PublicWorkspaceCorrespondence.Content.Title',
    PUBLICWORKSPACECORRESPONDENCE_CONTENT_PLACEHOLDER: 'PublicWorkspaceCorrespondence.Content.Placeholder',
    PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU: 'PublicWorkspaceCorrespondence.Content.Menu',
    PUBLICWORKSPACECORRESPONDENCE_CONTENT_BUTTON: 'PublicWorkspaceCorrespondence.Content.Button',
    PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU: 'PublicWorkspaceCorrespondence.Navigation.Menu',
    PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE: 'PublicWorkspaceCorrespondence.Table.Title',
    PUBLICWORKSPACECORRESPONDENCE_MESSAGE_NOTE: 'PublicWorkspaceCorrespondence.Message.Note',
    //APPLICATION
    PUBLICAPPLICATION_CONTENT_TITLE: 'PublicApplication.Content.Title',
    PUBLICAPPLICATION_CONTENT_NOTE: 'PublicApplication.Content.Note',
    PUBLICAPPLICATION_MESSAGE_NOTE: 'PublicApplication.Message.Note',
    PUBLICAPPLICATION_CONTENT_BUTTON: 'PublicApplication.Content.Button',
    PUBLICAPPLICATION_NAVIGATION_BUTTON: 'PublicApplication.Navigation.Button',
    PUBLICAPPLICATION_TABLE_TITLE: 'PublicApplication.Table.Title',
    //SORT BY LICENCE
    LICENCEDEF_FEEDESC: 'LicenceDefinition.FeeDescription',
    LICENCEDEF_LICENCEDESC: 'LicenceDefinition.LicenceDescription',
    LICENCETYPE_LICENCETYPENAME: 'LicenceType.LicenceTypeName',
    LICENCEDEFINITIONFAQ_QUESTION: 'LicenceDefinitionFAQ.Question',
    LICENCEDEFINITIONFAQ_ANSWER: 'LicenceDefinitionFAQ.Answer',
    LICENCETYPEDECLARATION_DECLARATIONDESC: 'LicenceTypeDeclaration.DeclarationDescription',
    LICENCEAPPLICATIONFEE_APPLICATIONFEE: 'LicenceApplicationFee.ApplicationFee',
    //SORT BY COMMONINFO
    COMMONINFO_FORM_FIELD: 'CommonInformation.Form.Field',
    COMMONINFO_FORM_PLACEHOLDERTEXT: 'CommonInformation.Form.PlaceholderText',
    COMMONINFO_FORM_HELPTEXT: 'CommonInformation.Form.HelpText',
    COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME: 'CommonInformation.GlobalSupportingDocument.DocumentName',
    COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC: 'CommonInformation.GlobalSupportingDocument.DocumentDescription',
    COMMONINFO_FORM_SECTION: 'CommonInformation.Form.Section',
    //GENERAL
    GENERALINFORMATIONDECLARATION_LICENCETYPEDECLARATION_DECLARATIONDESC: 'GeneralInformationDeclaration.LicenceTypeDeclaration.DeclarationDescription',
    PUBLICGENERAL_CONTENT_ERROR: 'PublicGeneral.Content.Error',
    PUBLICGENERAL_CONTENT_HELPTEXT: 'PublicGeneral.Content.HelpText',
    PUBLICGENERAL_CONTENT_BUTTON: 'PublicGeneral.Content.Button',
    //PAYMENT TYPE
    DEFAULT_PAYMENTTYPE_PAYMENTOPTION: 'Default.PaymentType.PaymentOption',
    //SORT BY GENERALINFO
    GENERALINFORMATION_FORM_SECTION: 'GeneralInformation.Form.Section',
    GENERALINFORMATION_FORM_FIELD: 'GeneralInformation.Form.Field',
    GENERALINFORMATION_FORM_HELPTEXT: 'GeneralInformation.Form.HelpText',
    GENERALINFORMATION_FORM_PLACEHOLDERTEXT: 'GeneralInformation.Form.PlaceholderText',
    //QUESTIONNAIRE
    QUESTIONNAIRE_BUSINESSINTENT_NAME: 'Questionnaire.BusinessIntent.Name',
    QUESTIONNAIRE_BUSINESSINTENT_DESCRIPTION: 'Questionnaire.BusinessIntent.Description',
    QUESTIONNAIRE_QUESTION_DESCRIPTION: 'Questionnaire.Question.Description',
    QUESTIONNAIRE_ANSWER_DESCRIPTION: 'Questionnaire.Answer.Description',
    //Code Table DropDown List
    CODE: 'Code',
    TITLE: 'Title',
    //SEARCH BY INDUSTRY
    CODE_INDUSTRY_TITLE: 'Code.Industry.Title',
    CODE_INDUSTRY_DESCRIPTION: 'Code.Industry.Description',
    CODE_SUBINDUSTRY_TITLE: 'Code.SubIndustry.Title',
    //TERMS AND CONDITIONS
    PUBLICTERMSANDCONDITIONS_CONTENT_SECTION: 'PublicTermsAndConditions.Content.Section',
    PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION: 'PublicTermsAndConditions.Content.SubSection'
}

const KEY_STRINGS = {
    WHATNEW: 'WhatNew',
    MAIN: 'MainPhrase',
    SUB: 'SubPhrase',
    CAROUSEL1: 'Carousel1Title',
    CAROUSEL1DESC: 'Carousel1Description',
    CAROUSEL2: 'Carousel2Title',
    CAROUSEL2DESC: 'Carousel2Description',
    CAROUSEL3: 'Carousel3Title',
    CAROUSEL3DESC: 'Carousel3Description',
    SEARCHTITLE: 'SearchTitle',
    NEWTODS: 'NewtoDS',
    KNOWSERVICE: 'KnowService',
    KNOWMORE: 'KnowMore',
    BROWSEBYGOVAGENCY: 'BrowseByGovAgency',
    BROWSEBYINDUSTRY: 'BrowseByIndustry',
    BROWSEBY: 'BrowseBy',
    INDUSTRY: 'Industry',
    GOVAGENCY: 'GovAgency',
    SERVICE: 'Service',
    DETAILS: 'Details',
    FEE: 'Fee',
    GETSTARTED: 'GetStarted',
    PROCESSINGTIME: 'ProcessingTime',
    SUPPORTINGDOC: 'SupportingDoc',
    AGENCYWEBSITE: 'Agencywebsite',
    NEWS: 'News',
    HOME: 'Home',
    PRIVACYSTATEMENT: 'PrivacyStatement',
    TERMOFUSE: 'TermOfUse',
    EVENTS: 'Events',
    CONTACTUS: 'ContactUs',
    ABOUTUS: 'AboutUS',
    FAQ: 'FAQ',
    SERVICEAPPLICATION: 'ServiceApplication',
    APPLYFORNEWSERVICEAPPLICATION: 'ApplyForNewServiceApplication',
    RENEWSERVICEAPPLICATION: 'RenewServiceApplication',
    CANCELSERVICEAPPLICATION: 'CancelServiceApplication',
    AMENDSERVICEAPPLICATION: 'AmendServiceApplication',
    SITESEARCH: 'SiteSearch',
    NOTICES: 'Notices',
    DASHBOARD: 'Dashboard',
    CORPORATELOGIN: 'CorporateLogin',
    BUSINESSIDLOGIN: 'BusinessIDLogin',
    INDIVIDUALLOGIN: 'IndividualLogin',
    IMAGEHEADER: 'Imageheader',
    IMAGEMESSAGE: 'ImageMessage',
    IMAGEMESSAGE2: 'ImageMessage2',
    MAINTENANCENOTICE: 'MaintenanceNotice',
    LANGUAGE: 'Language',
    LOGIN: 'Login',
    SEARCHRESULTS: 'SearchResults',
    RESULTSMESSAGE: 'ResultsMessage',
    SEARCHBOX: 'SearchBox',
    ABOUTBIZ: 'AboutBiz',
    TELLUSABOUT: 'TellUsAbout',
    YOURBIZ: 'YourBiz',
    READMORE: 'ReadMore',
    ENTER: 'Enter',
    ADDTOCART: 'AddtoCart',
    APPLY: 'Apply',
    REMOVE: 'Remove',
    SELECTEDSERVICES: 'SelectedServices',
    ALLFIELDSARECOMMON: 'AllFieldsAreCommon',
    NODOCSTOBESUBMITTED: 'NoDocsToBeSubmitted',
    WORKINGDAYS: 'WorkingDays',
    NOTE: 'Note',
    SUBTITLE: 'SubTitle',
    SEARCHAGENCY: 'SearchAgency',
    NOFAQAVAILABLE: 'NoFAQAvailable',
    NORESULTS: 'NoResults',
    REMOVEINLOWERCASE: 'RemoveInLowerCase',
    PLEASE: 'Please',
    REMOVESERVICEMESSAGE: 'RemoveServiceMessage',
    EXCLUSIVESERVICES: 'ExclusiveServices',
    EXCLUSIVESERVICEMESSAGE: 'ExclusiveServiceMessage',
    NOTEINFO: 'NoteInfo',
    SHOWNOTEINFO: 'ShowNoteInfo',
    HIDENOTEINFO: 'HideNoteInfo',
    EXCLUSIVELICENCESET: 'ExclusiveLicenceSet',
    SEARCH: 'Search',
    CANNOTBEAPPLIEDTOGETHER: 'CannotBeAppliedTogether',
    AGENCY: 'Agency',
    SUBTITLEAGENCY: 'SubTitleAgency',
    SUBTITLEINDUSTRY: 'SubTitleIndustry',
    SEARCHINDUSTRY: 'SearchIndustry',
    BESTMATCH: 'BestMatch',
    CORRECTBUSINSS: 'CorrectBusiness',
    SOMETHINGELSE: 'SomethingElse',
    CLICKBUSINESS: 'ClickBusiness',
    REFINEBUSINESS: 'RefineBusiness',
    SEARCHTIPS: 'SearchTips',
    CHECKSPELLING: 'CheckSpelling',
    TRYDIFFSEARCHTERM: 'TryDiffSearchTerm',
    BACKTOSEARCH: 'BackToSearch',
    APPLYNOW: 'ApplyNow',
    SELECTBESTOPTION: 'SelectBestOption',
    PREREQUISITELICENCE: 'PreRequisiteLicence',
    PREREQUISITELICENCES: 'PreRequisiteLicences',
    NOSUPPORTINGDOC: 'NoSupportingDoc',
    WORKSPACE: 'Workspace',
    WORKSPACE2: 'Workspace2',
    APPLICATIONS: 'Applications',
    //CERTIFICATES: 'Certificates',
    SERVICES: "Services",
    APPROVALS: "Approvals",
    ISSUEANCE: "Issuance",
    CORRESPONDENCES: 'Correspondences',
    UPDATEPROFILE: 'UpdateProfile',
    CHANGELOGINID: 'ChangeLoginID',
    CHANGEPASSWORD: 'ChangePassword',
    LOGOUT: 'Logout',
    HI: 'Hi',
    ATTENTIONREQUIRED: 'AttentionRequired',
    ALL: 'All',
    APPLICATIONREFNUMBER: 'ApplicationRefNumber',
    DRAFTS: 'Drafts',
    SUBMISSIONNUMBER: 'SubmissionNumber',
    SUBMISSIONDATE: 'SubmissionDate',
    APPLICATIONTYPE: 'ApplicationType',
    SERVICENAME: 'ServiceName',
    APPLICATIONSTATUS: 'ApplicationStatus',
    ACTION: 'Action',
    ACTIONS: 'Actions',
    ADVANCEDSEARCH: 'AdvancedSearch',
    STATUS: 'Status',
    REFERENCENUMBER: 'ReferenceNumber',
    FROMDATE: 'FromDate',
    TODATE: 'ToDate',
    WORKSPACESEARCHBOX: 'WorkspaceSearchBox',
    NO: 'No',
    DRAFTDATE: 'DraftDate',
    NODRAFTSPENDING: 'NoDraftsPending',
    SUBMITPAYMENT: 'SubmitPayment',
    RESPONDTOACTION: 'RespondToAction',
    VIEWAPPLICATIONDETAILS: 'ViewApplicationDetails',
    BACKTOWORKSPACE: 'BackToWorkspace',
    RESPONDTORFA: 'RespondToRFA',
    WITHDRAWAPPLICATION: 'WithdrawApplication',
    SUBMIT: 'Submit',
    BACKTORFA: 'BackToRFA',
    INFORMATIONOFAPPLICATION: 'InformationofApplication',
    GENERALINFORMATION: 'GeneralInformation',
    SERVICEOVERVIEW: 'ServiceOverview',
    SERVICEAPPLICATIONDETAILS: 'ServiceApplicationDetails',
    CANCELLATIONINFORMATION: 'CancellationInformation',
    SUPPORTINGDOCUMENTS: 'SupportingDocuments',
    APPLICATIONDOCUMENTS: 'ApplicationDocuments',
    PAYMENTRECEIPT: 'PaymentReceipt',
    CORRESPONDENCE: 'Correspondence',
    REQUESTFORACTIONHISTORY: 'RequestForActionHistory',
    REMARKS: 'Remarks',
    COLLAPSEALL: 'CollapseAll',
    EXPANDALL: 'ExpandAll',
    MAKEPAYMENT: 'MakePayment',
    APPLICATIONBEINGPROCESSED: 'ApplicationBeingProcessed',
    MESSAGESENT: 'MessageSent',
    RESPONDBY: 'RespondBy',
    RESPOND: 'Respond',
    ADDITIONALPAYMENTREQUIRED: 'AdditionalPaymentRequired',
    PAYMENTREQUIRED: 'PaymentRequired',
    REQUESTFORAMENDMENT: 'RequestForAmendment',
    REQUESTFORCLARIFICATION: 'RequestForClarification',
    CLARIFICATION: 'Clarification',
    ADDITIONALSUPPORTINGDOCREQ: 'AdditionalSupportingDocReq',
    APPLICATIONSUBMISSIONCOMPLETE: 'ApplicationSubmissionComplete',
    ADDITIONALPAYMENTINFO: 'AdditionalPaymentInfo',
    SUBMITSECONDPAYMENT: 'SubmitSecondPayment',
    APPLICATIONSUBMITTEDMESSAGE: 'ApplicationSubmittedMessage',
    PAYMENTDUEDATE: 'PaymentDueDate',
    ADDITIONALPAYMENTFEE: 'AdditionalPaymentFee',
    CURRENCYSYMBOL: 'CurrencySymbol',
    PAYMENTREQUIREDINFO: 'PaymentRequiredInfo',
    NOPAYMENTREQUIRED: 'NoPaymentRequired',
    NOAPPLICATIONDOCUMENTSFOUND: 'NoApplicationDocumentsFound',
    NOLICENCEDOCUMENTSFOUND: 'NoLicenceDocumentsFound',
    DOCUMENTNAME: "DocumentName",
    NOREQFORACTIONHISTORY: 'NoReqForActionHistory',
    PAYMENTREFNUMBER: 'PaymentRefNumber',
    DATEOFPAYMENT: 'DateOfPayment',
    AMOUNTPAID: 'AmountPaid',
    MODEPAYMENT: 'ModePayment',
    TYPEOFREQUEST: 'TypeOfRequest',
    REQUESTDATE: 'RequestDate',
    DEADLINE: 'Deadline',
    RESPONSEDATE: 'ResponseDate',
    OFFICERREMARKSRESPONSE: 'OfficerRemarksResponse',
    OFFICERREMARKS: 'OfficerRemarks',
    RESPONSE: 'Response',
    VIEW: 'View',
    UPLOAD: 'Upload',
    FILTER: 'Filter',
    DUEFORRENEWAL: 'DueForRenewal',
    SERVICESEARCHBOX: 'ServiceSearchBox',
    SERVICENUMBER: 'ServiceNumber',
    SERVICESTATUS: 'ServiceStatus',
    STARTDATE: 'StartDate',
    EXPIRYDATE: 'ExpiryDate',
    NOSERVICEFOUND: 'NoServiceFound',
    AGENCYNAME: 'AgencyName',
    VIEWSERVICEDETAILS: 'ViewServiceDetails',
    APPLICANT: 'Applicant',
    ISSUEDDATE: 'IssuedDate',
    PREMISEOPERATINGADDR: 'PremiseOperatingAddr',
    SERVICEDOCUMENT: 'ServiceDocument',
    SERVICEAPPLICATIONHISTORY: 'ServiceApplicationHistory',
    LICENCECERTIFICATE: 'LicenceCertificate',
    DOWNLOADLICENCE: 'DownloadLicence',
    CORRESPONDENCEDETAILS: 'CorrespondenceDetails',
    TO: 'To',
    FROM: 'From',
    SENTDATE: 'SentDate',
    TITLE: 'Title',
    NOAPPLICATIONSFOUND: 'NoApplicationsFound',
    DELIVERYCHANNEL: 'DeliveryChannel',
    NOSERVICEAPPHISTORYFOUND: 'NoServiceAppHistoryFound',
    CORRESPONDENCETITLE: 'CorrespondenceTitle',
    UNREAD: 'Unread',
    NOCORRESPONDENCEFOUND: 'NoCorrespondenceFound',
    CORRESPONDENCESEARCHBOX: 'CorrespondenceSearchBox',
    DELIVERYDATE: 'DeliveryDate',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    APPLYFORSERVICE: 'ApplyForService',
    COMMONINFORMATION: 'CommonInformation',
    PAYMENTS: 'Payments',
    REVIEWANDSUBMIT: 'ReviewAndSubmit',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    PAY: 'Pay',
    AGREE: 'Agree',
    AGREEMENT: 'Agreement',
    TERMSANDCONDITIONS: 'TermsAndConditions',
    NEWSTEXT1: 'NewsText1',
    NEWSTEXT2: 'NewsText2',
    FAQNOTE1: 'FAQNote1',
    FAQNOTE2: 'FAQNote2',
    FAQNOTE3: 'FAQNote3',
    MAINTENANCENOTE1: 'MaintenanceNote1',
    SAVEASDRAFT: 'SaveAsDraft',
    APPLICATIONNO: 'ApplicationNo',
    LICENCEDUERENEWALTEXT1: 'LicenceDueRenewalText1',
    LICENCEDUERENEWALTEXT2: 'LicenceDueRenewalText2',
    LICENCEEXPIRYDATE: 'LicenceExpiryDate',
    LICENCERENEWALFEE: 'LicenceRenewalFee',
    DUEFORRENEWAL2: 'DueForRenewal2',
    RENEWLICENCE: 'RenewLicence',
    NOMATCHESFOUND: 'NoMatchesFound',
    DECLARATION: 'Declaration',
    PAYMENTTEXT1: 'PaymentText1',
    PAYMENTTEXT2: 'PaymentText2',
    CHOOSEPAYMENTMODE: 'ChoosePaymentMode',
    CLICKANYBELOW: 'ClickAnyBelow',
    FEES: 'Fees',
    TAX: 'Tax',
    SUBTOTAL: 'SubTotal',
    GRANDTOTAL: 'GrandTotal',
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    DECLARATIONINSTRUCTIONS: 'DeclarationInstructions',
    ACKNOWLEDGEMENTTEXT: 'AcknowledgementText',
    DOCUMENTSSUBMITTED: 'DocumentsSubmitted',
    MANDATORYDOCUMENTS: 'MandatoryDocuments',
    OPTIONALDOCUMENTS: 'OptionalDocuments',
    REVIEWINFORMATION: 'ReviewInformation',
    EFFECTIVEENDDATE: 'EffectiveEndDate',
    SUBMISSIONNUM: 'SubmissionNum',
    OFFLINNEPAYMENTTEXT: 'OfflinePaymentText',
    RETRIEVINGPAYMENTDETAILS: 'RetrievingPaymentDetails',
    ORDERID: 'OrderID',
    PAYMENTSUCCESSFULLYPERFORMED: 'PaymentSuccessfullyPerformed',
    REDIRECTTOPAYMENT: 'RedirectToPayment',
    PRINTRECEIPT: 'PrintReceipt',
    PAYMENTSUCCESSTEXT1: 'PaymentSuccessText1',
    PAYMENTSUCCESSTEXT2: 'PaymentSuccessText2',
    PAYMENTSUCCESSTEXT3: 'PaymentSuccessText3',
    GENERALDECLARATIONTITLE: 'GeneralDeclarationTitle',
    GOTODASHBOARD: 'GoToDashboard',
    SEARCHTERMTOOSHORT: 'SearchTermTooShort',
    ENTERVALIDKEYWORD: 'EnterValidKeyword',
    MOREINFO: 'MoreInfo',
    LICENCEREQUIREDMESSAGE: 'LicenceRequiredMessage',
    ASKATLAS: 'AskAtlas',
    DOWNLOAD_FILE: 'DownloadFile',
    LABEL_REQUIRED: 'LabelRequired',
    MIN_VALUE: 'MinValue',
    MAX_VALUE: 'MaxValue',
    TICK_REQUIRED: 'TickRequired',
    FILE_LIMIT_REACHED: 'FileLimitReached',
    FILE_REQUIRED: 'FileRequired',
    FILE_DESC_REQUIRED: 'FileDescRequired',
    FORM_VALIDATION_FAIL: 'FormValidationFail',
    FORM_DEFAULT_FAIL: 'FormDefaultFailed',
    PASSWORD_REGEX_UNMATCH: 'PasswordRegexUnmatch',
    EMAIL_INVALID: 'EmailInvalid',
    MIN_LENGTH_LIMIT: 'MinLengthLimit',
    MAX_LENGTH_LIMIT: 'MaxLengthLimit',
    TELEPHONE_INVALID: 'TelephoneInvalid',
    NUMBER_INVALID: 'NumberInvalid',
    APPLICATION_DELETE_FAIL: 'ApplicationDeleteFail',
    LIST_RETRIEVAL_FAIL: 'ListRetrievalFail',
    DRAFT_SAVED_FAIL: 'DraftSavedFail',
    DRAFT_EMPTY_FIELD: 'DraftEmptyField',
    DATE_INVALID: 'DateInvalid',
    FORM_DEFAULT_SUCCESS: 'FormDefaultSuccess',
    APPLICATION_DELETE_SUCCESS: 'ApplicationDeleteSuccess',
    FORM_DATA_NOT_FETCHED: 'FormDataNotFetched',
    LICENCE_DATA_NOT_FETCHED: 'LicenceDataNotFetched',
    SERVER_UNREACHABLE: 'ServerUnreachable',
    PREPARE_PAYMENT_FAIL: 'PreparePaymentFail',
    PROCESS_PAYMENT_FAIL: 'ProcessPaymentFail',
    RFA_DETAILS_NOT_FETCHED: 'RFADetailsNotFetched',
    RFA_DETAILS_HISTORY_NOT_FETCHED: 'RFADetailsHistoryNotFetched',
    PAYMENT_DETAILS_NOT_FETCHED: 'PaymentDetailsNotFetched',
    PAYMENT_STATUS_NOT_FETCHED: 'PaymentStatusNotFetched',
    PAYMENT_REDIRECT_FAIL: 'PaymentRedirectFail',
    TRANSLATION_LOAD_ERROR: 'TranslationLoadError',
    PAYMENT_ERROR: 'PaymentError',
    CANNOT_REACH_SERVER: 'CannotReachServer',
    DRAFT_RETRIEVAL_FAIL: 'DraftRetrievalFail',
    ERROR_MESSAGE: 'Error',
    FILE_FORMAT_ERROR: 'FileFormatError',
    FILE_SIZE_ERROR: 'FileSizeError',
    PAYMENT_FAIL: 'PaymentFail',
    INVALID: 'Invalid',
    UPDATE: 'Update',
    ADD: 'Add',
    PREREQUISITELICENCESREQUIREDMESSAGE: 'PreRequisiteLicencesRequiredMessage',
    CHARACTERSREMAINING: 'CharactersRemaining',
    TYPEHERE: 'TypeHere',
    PREMISE: 'Premise',
    BUSINESSSERVICES: 'BusinessServices',
    BUSINESSOPERATIONS: 'BusinessOperations',
    RECOMMENDEDSERVICESFORYOU: 'RecommendedServicesForYou',
    ALLOWEDFILEFORMAT: 'AllowedFileFormat',
    MAXIMUMFILESIZE: 'MaximumFileSize',
    UNABLETOGENARATEDOCUMENT: 'UnableToGenarateDocument',
    CONTACTAGENCYFORSUPPORT: 'ContactAgencyForSupport',
    SECTION_1: '1',
    SECTION_2: '2',
    SECTION_3: '3',
    SECTION_4: '4',
    SECTION_5: '5',
    SECTION_6: '6',
    SECTION_7: '7',
    SECTION_8: '8',
    SECTION_9: '9',
    SECTION_10: '10',
    SECTION_11: '11',
    SUBSECTION_1A: '1a',
    SUBSECTION_1B: '1b',
    SUBSECTION_1C: '1c',
    SUBSECTION_1D: '1d',
    SUBSECTION_1E: '1e',
    SUBSECTION_1F: '1f',
    SUBSECTION_1G: '1g',
    SUBSECTION_1H: '1h',
    SUBSECTION_1I: '1i',
    SUBSECTION_1J: '1j',
    SUBSECTION_2A: '2a',
    SUBSECTION_2B: '2b',
    SUBSECTION_2C: '2c',
    SUBSECTION_2D: '2d',
    SUBSECTION_2E: '2e',
    SUBSECTION_2F: '2f',
    SUBSECTION_2G: '2g',
    SUBSECTION_2H: '2h',
    SUBSECTION_2I: '2i',
    SUBSECTION_2J: '2j',
    SUBSECTION_3A: '3a',
    SUBSECTION_3B: '3b',
    SUBSECTION_3C: '3c',
    SUBSECTION_3D: '3d',
    SUBSECTION_3E: '3e',
    SUBSECTION_3F: '3f',
    SUBSECTION_3G: '3g',
    SUBSECTION_3H: '3h',
    SUBSECTION_3I: '3i',
    SUBSECTION_3J: '3j',  
    SUBSECTION_4A: '4a',
    SUBSECTION_4B: '4b',
    SUBSECTION_4C: '4c',
    SUBSECTION_4D: '4d',
    SUBSECTION_4E: '4e',
    SUBSECTION_4F: '4f',
    SUBSECTION_4G: '4g',
    SUBSECTION_4H: '4h',
    SUBSECTION_4I: '4i',
    SUBSECTION_4J: '4j',
    SUBSECTION_5A: '5a',
    SUBSECTION_5B: '5b',
    SUBSECTION_5C: '5c',
    SUBSECTION_5D: '5d',
    SUBSECTION_5E: '5e',
    SUBSECTION_5F: '5f',
    SUBSECTION_5G: '5g',
    SUBSECTION_5H: '5h',
    SUBSECTION_5I: '5i',
    SUBSECTION_5J: '5j',
    SUBSECTION_6A: '6a',
    SUBSECTION_6B: '6b',
    SUBSECTION_6C: '6c',
    SUBSECTION_6D: '6d',
    SUBSECTION_6E: '6e',
    SUBSECTION_6F: '6f',
    SUBSECTION_6G: '6g',
    SUBSECTION_6H: '6h',
    SUBSECTION_6I: '6i',
    SUBSECTION_6J: '6j',
    SUBSECTION_7A: '7a',
    SUBSECTION_7B: '7b',
    SUBSECTION_7C: '7c',
    SUBSECTION_7D: '7d',
    SUBSECTION_7E: '7e',
    SUBSECTION_7F: '7f',
    SUBSECTION_7G: '7g',
    SUBSECTION_7H: '7h',
    SUBSECTION_7I: '7i',
    SUBSECTION_7J: '7j',
    SUBSECTION_8A: '8a',
    SUBSECTION_8B: '8b',
    SUBSECTION_8C: '8c',
    SUBSECTION_8D: '8d',
    SUBSECTION_8E: '8e',
    SUBSECTION_8F: '8f',
    SUBSECTION_8G: '8g',
    SUBSECTION_8H: '8h',
    SUBSECTION_8I: '8i',
    SUBSECTION_8J: '8j',
    SUBSECTION_9A: '9a',
    SUBSECTION_9B: '9b',
    SUBSECTION_9C: '9c',
    SUBSECTION_9D: '9d',
    SUBSECTION_9E: '9e',
    SUBSECTION_9F: '9f',
    SUBSECTION_9G: '9g',
    SUBSECTION_9H: '9h',
    SUBSECTION_9I: '9i',
    SUBSECTION_9J: '9j',
    SUBSECTION_10A: '10a',
    SUBSECTION_10B: '10b',
    SUBSECTION_10C: '10c',
    SUBSECTION_10D: '10d',
    SUBSECTION_10E: '10e',
    SUBSECTION_10F: '10f',
    SUBSECTION_10G: '10g',
    SUBSECTION_10H: '10h',
    SUBSECTION_10I: '10i',
    SUBSECTION_10J: '10j',
    SUBSECTION_11A: '11a',
    SUBSECTION_11B: '11b',
    SUBSECTION_11C: '11c',
    SUBSECTION_11D: '11d',
    SUBSECTION_11E: '11e',
    SUBSECTION_11F: '11f',
    SUBSECTION_11G: '11g',
    SUBSECTION_11H: '11h',
    SUBSECTION_11I: '11i',
    SUBSECTION_11J: '11j',
}

export const LANGUAGE_KEYS = {
    //SORT BY HOME PAGE
    BLS_PUBLICHOME_CONTENT_TITLE_MAIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.MAIN}`,
    BLS_PUBLICHOME_CONTENT_TITLE_SUB: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.SUB}`,
    BLS_PUBLICHOME_NAV_BUTTON_NEWTODS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_BUTTON}.${KEY_STRINGS.NEWTODS}`,
    BLS_PUBLICHOME_NAV_BUTTON_ENTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_BUTTON}.${KEY_STRINGS.ENTER}`,
    BLS_PUBLICHOME_NAV_BUTTON_READMORE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_BUTTON}.${KEY_STRINGS.READMORE}`,
    BLS_PUBLICHOME_NAV_BUTTON_KNOWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_BUTTON}.${KEY_STRINGS.KNOWSERVICE}`,
    BLS_PUBLICHOME_NAV_BUTTON_CANCEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_PUBLICHOME_CONTENT_TITLE_CAROUSEL1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.CAROUSEL1}`,
    BLS_PUBLICHOME_CONTENT_TITLE_CAROUSEL1DESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.CAROUSEL1DESC}`,
    BLS_PUBLICHOME_CONTENT_TITLE_CAROUSEL2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.CAROUSEL2}`,
    BLS_PUBLICHOME_CONTENT_TITLE_CAROUSEL2DESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.CAROUSEL2DESC}`,
    BLS_PUBLICHOME_CONTENT_TITLE_CAROUSEL3: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.CAROUSEL3}`,
    BLS_PUBLICHOME_CONTENT_TITLE_CAROUSEL3DESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.CAROUSEL3DESC}`,
    BLS_PUBLICHOME_CONTENT_TITLE_WHATNEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.WHATNEW}`,
    BLS_PUBLICHOME_CONTENT_TITLE_TELLUSABOUT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.TELLUSABOUT}`,
    BLS_PUBLICHOME_CONTENT_TITLE_YOURBIZ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_TITLE}.${KEY_STRINGS.YOURBIZ}`,
    BLS_PUBLICHOME_CONTENT_MODAL_ABOUTBIZ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_MODAL}.${KEY_STRINGS.ABOUTBIZ}`,
    BLS_PUBLICHOME_CONTENT_NOTE_NEWSTEXT1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_NOTE}.${KEY_STRINGS.NEWSTEXT1}`,
    BLS_PUBLICHOME_CONTENT_NOTE_NEWSTEXT2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_NOTE}.${KEY_STRINGS.NEWSTEXT2}`,
    BLS_PUBLICHOME_CONTENT_NOTE_FAQNOTE1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_NOTE}.${KEY_STRINGS.FAQNOTE1}`,
    BLS_PUBLICHOME_CONTENT_NOTE_FAQNOTE2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_NOTE}.${KEY_STRINGS.FAQNOTE2}`,
    BLS_PUBLICHOME_CONTENT_NOTE_FAQNOTE3: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_NOTE}.${KEY_STRINGS.FAQNOTE3}`,
    BLS_PUBLICHOME_CONTENT_NOTE_MAINTENANCENOTE1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_CONTENT_NOTE}.${KEY_STRINGS.MAINTENANCENOTE1}`,
    BLS_PUBLICHOME_NAV_MENU_NEWS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.NEWS}`,
    BLS_PUBLICHOME_NAV_MENU_MAINTENANCENOTICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.MAINTENANCENOTICE}`,
    BLS_PUBLICHOME_NAV_MENU_HOME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.HOME}`,
    BLS_PUBLICHOME_NAV_MENU_ABOUTUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.ABOUTUS}`,
    BLS_PUBLICHOME_NAV_MENU_FAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.FAQ}`,
    BLS_PUBLICHOME_NAV_MENU_EVENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.EVENTS}`,
    BLS_PUBLICHOME_NAV_MENU_CONTACTUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.CONTACTUS}`,
    BLS_PUBLICHOME_NAV_MENU_SERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.SERVICEAPPLICATION}`,
    BLS_PUBLICHOME_NAV_MENU_APPLYFORNEWSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.APPLYFORNEWSERVICEAPPLICATION}`,
    BLS_PUBLICHOME_NAV_MENU_APPLYFORSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.APPLYFORSERVICE}`,
    BLS_PUBLICHOME_NAV_MENU_AMENDSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.AMENDSERVICEAPPLICATION}`,
    BLS_PUBLICHOME_NAV_MENU_CANCELSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.CANCELSERVICEAPPLICATION}`,
    BLS_PUBLICHOME_NAV_MENU_RENEWSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.RENEWSERVICEAPPLICATION}`,
    BLS_PUBLICHOME_NAV_MENU_PRIVACYSTATEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.PRIVACYSTATEMENT}`,
    BLS_PUBLICHOME_NAV_MENU_TERMSOFUSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.TERMOFUSE}`,
    BLS_PUBLICHOME_NAV_MENU_SITESEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.SITESEARCH}`,
    BLS_PUBLICHOME_NAV_MENU_NOTICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.NOTICES}`,
    BLS_PUBLICHOME_NAV_MENU_LANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.LANGUAGE}`,
    BLS_PUBLICHOME_NAV_MENU_LOGIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.LOGIN}`,
    BLS_PUBLICHOME_NAV_MENU_ASKATLAS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICHOME_NAVIGATION_MENU}.${KEY_STRINGS.ASKATLAS}`,
    //SORT BY BLS_PUBLICLICENCESEARCH
    BLS_PUBLICLICENCESEARCH_NAV_BUTTON_KNOWMORE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_NAVIGATION_BUTTON}.${KEY_STRINGS.KNOWMORE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_FAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_MODAL}.${KEY_STRINGS.FAQ}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_SUPPORTINGDOC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_MODAL}.${KEY_STRINGS.SUPPORTINGDOC}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_NOSUPPORTINGDOC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_MODAL}.${KEY_STRINGS.NOSUPPORTINGDOC}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_SEARCHBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_MODAL}.${KEY_STRINGS.SEARCHBOX}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_CLOSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_MODAL}.${KEY_STRINGS.CLOSE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCHTITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.SEARCHTITLE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCHRESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.SEARCHRESULTS}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.SEARCH}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_RESULTSMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.RESULTSMESSAGE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.SERVICE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SELECTEDSERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.SELECTEDSERVICES}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_EXCLUSIVESERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.EXCLUSIVESERVICES}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_PREREQUISITELICENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.PREREQUISITELICENCE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_PREREQUISITELICENCES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.PREREQUISITELICENCES}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_RECOMMENDEDSERVICESFORYOU: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.RECOMMENDEDSERVICESFORYOU}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.BROWSEBY}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_GOVAGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.GOVAGENCY}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_INDUSTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.INDUSTRY}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBYGOVAGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.BROWSEBYGOVAGENCY}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBYINDUSTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.BROWSEBYINDUSTRY}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_DETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.DETAILS}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.ADDTOCART}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.APPLY}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.REMOVE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_GETSTARTED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_BUTTON}.${KEY_STRINGS.GETSTARTED}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_FEE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_FIELD}.${KEY_STRINGS.FEE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_PROCESSINGTIME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_FIELD}.${KEY_STRINGS.PROCESSINGTIME}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_WORKINGDAYS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_FIELD}.${KEY_STRINGS.WORKINGDAYS}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_LINK_AGENCYWEBSITE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_LINK}.${KEY_STRINGS.AGENCYWEBSITE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_LINK_EXCLUSIVELICENCESSET: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_LINK}.${KEY_STRINGS.EXCLUSIVELICENCESET}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_INFORMATION_IMAGEMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_INFORMATION}.${KEY_STRINGS.IMAGEMESSAGE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_INFORMATION_IMAGEMESSAGE2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_INFORMATION}.${KEY_STRINGS.IMAGEMESSAGE2}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NODOCSTOBESUBMITTED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.NODOCSTOBESUBMITTED}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NOFAQAVAILABLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.NOFAQAVAILABLE}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_EXCLUSIVESERVICEMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.EXCLUSIVESERVICEMESSAGE}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_PLEASE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.PLEASE}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_REMOVEINLOWERCASE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.REMOVEINLOWERCASE}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_REMOVESERVICEMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.REMOVESERVICEMESSAGE}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NOTEINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.NOTEINFO}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_SHOWNOTEINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.SHOWNOTEINFO}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_HIDENOTEINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.HIDENOTEINFO}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NORESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_NOTE}.${KEY_STRINGS.NORESULTS}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_MANDATORY_CANNOTBEAPPLIEDTOGETHER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_MANDATORY}.${KEY_STRINGS.CANNOTBEAPPLIEDTOGETHER}`,
    BLS_PUBLICLICENCESEARCH_MESSAGE_TITLE_NOTE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_MESSAGE_TITLE}.${KEY_STRINGS.NOTE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_PREMISE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.PREMISE}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_BUSINESSSERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.BUSINESSSERVICES}`,
    BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_BUSINESSOPERATIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLICENCESEARCH_CONTENT_TITLE}.${KEY_STRINGS.BUSINESSOPERATIONS}`,
    //SORT BY PUBLICLOGIN
    BLS_PUBLICLOGIN_CONTENT_BUTTON_CORPORATELOGIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLOGIN_CONTENT_BUTTON}.${KEY_STRINGS.CORPORATELOGIN}`,
    BLS_PUBLICLOGIN_CONTENT_BUTTON_INDIVIDUALLOGIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLOGIN_CONTENT_BUTTON}.${KEY_STRINGS.INDIVIDUALLOGIN}`,
    BLS_PUBLICLOGIN_CONTENT_BUTTON_BUSINESSIDLOGIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICLOGIN_CONTENT_BUTTON}.${KEY_STRINGS.BUSINESSIDLOGIN}`,
    //SORT BY SCOPE AND KEY
    BLS_SCOPE_LICENCEDEF_FEEDESC_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEF_FEEDESC}.%Key%`,
    BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEF_LICENCEDESC}.%Key%`,
    BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCETYPE_LICENCETYPENAME}.%Key%`,
    BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEFINITIONFAQ_QUESTION}.%Key%`,
    BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEFINITIONFAQ_ANSWER}.%Key%`,
    BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCETYPEDECLARATION_DECLARATIONDESC}.%Key%`,
    //GENERAL INFORMATION DECLARATION
    BLS_GENERALINFORMATIONDECLARATION_LICENCETYPEDECLARATION_DECLARATIONDESC_GENERALDECLARATIONTITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATIONDECLARATION_LICENCETYPEDECLARATION_DECLARATIONDESC}.${KEY_STRINGS.GENERALDECLARATIONTITLE}`,
    //SORT BY BLS_COMMONINFO
    BLS_COMMONINFO_FORM_FIELD_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_FIELD}.%Key%`,
    BLS_COMMONINFO_FORM_HELPTEXT_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_HELPTEXT}.%Key%`,
    BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_PLACEHOLDERTEXT}.%Key%`,
    BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME}.%Key%`,
    BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC}.%Key%`,
    BLS_COMMONINFO_FORM_SECTION_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_SECTION}.%Key%`,
    BLS_COMMONINFO_FORM_SECTION_ALLFIELDSARECOMMON: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_SECTION}.${KEY_STRINGS.ALLFIELDSARECOMMON}`,
    //BROWSEBYAGENCY
    BLS_PUBLICBROWSEBYAGENCY_CONTENT_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROSEBYAGENCY_CONTENT_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_PUBLICBROWSEBYAGENCY_CONTENT_TITLE_SUBTITLEAGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROSEBYAGENCY_CONTENT_TITLE}.${KEY_STRINGS.SUBTITLEAGENCY}`,
    BLS_PUBLICBROWSEBYAGENCY_CONTENT_PLACEHOLDER_SEARCHAGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROWSEBYAGENCY_CONTENT_PLACEHOLDER}.${KEY_STRINGS.SEARCHAGENCY}`,
    BLS_PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER_ENTERVALIDKEYWORD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.ENTERVALIDKEYWORD}`,
    BLS_PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER_NORESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROWSEBYAGENCY_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NORESULTS}`,
    //BROWSEBYINDUSTRY
    BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_TITLE_INDUSTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROSEBYINDUSTRY_CONTENT_TITLE}.${KEY_STRINGS.INDUSTRY}`,
    BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_TITLE_SUBTITLEINDUSTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROSEBYINDUSTRY_CONTENT_TITLE}.${KEY_STRINGS.SUBTITLEINDUSTRY}`,
    BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_PLACEHOLDER_SEARCHINDUSTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROWSEBYINDUSTRY_CONTENT_PLACEHOLDER}.${KEY_STRINGS.SEARCHINDUSTRY}`,
    BLS_PUBLICBROWSEBYINDUSTRY_MESSAGE_PLACEHOLDER_ENTERVALIDKEYWORD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROWSEBYINDUSTRY_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.ENTERVALIDKEYWORD}`,
    BLS_PUBLICBROWSEBYINDUSTRY_MESSAGE_PLACEHOLDER_NORESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICBROWSEBYINDUSTRY_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NORESULTS}`,
    //PUBLICQUESTIONNAIRE
    BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SUBTITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_TITLE}.${KEY_STRINGS.SUBTITLE}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_CLICKBUSINESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_TITLE}.${KEY_STRINGS.CLICKBUSINESS}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_REFINEBUSINESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_TITLE}.${KEY_STRINGS.REFINEBUSINESS}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SEARCHTIPS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_TITLE}.${KEY_STRINGS.SEARCHTIPS}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_NOMATCHESFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_TITLE}.${KEY_STRINGS.NOMATCHESFOUND}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SEARCHTERMTOOSHORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_TITLE}.${KEY_STRINGS.SEARCHTERMTOOSHORT}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_BESTMATCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_INFORMATION}.${KEY_STRINGS.BESTMATCH}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_CHECKSPELLING: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_INFORMATION}.${KEY_STRINGS.CHECKSPELLING}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_TRYDIFFSEARCHTERM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_INFORMATION}.${KEY_STRINGS.TRYDIFFSEARCHTERM}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_SELECTBESTOPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_INFORMATION}.${KEY_STRINGS.SELECTBESTOPTION}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_MOREINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_INFORMATION}.${KEY_STRINGS.MOREINFO}`,
    BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_LICENCEREQUIREDMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_CONTENT_INFORMATION}.${KEY_STRINGS.LICENCEREQUIREDMESSAGE}`,
    BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_CORRECTBUSINESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON}.${KEY_STRINGS.CORRECTBUSINSS}`,
    BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_SOMETHINGELSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON}.${KEY_STRINGS.SOMETHINGELSE}`,
    BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_BACKTOSEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON}.${KEY_STRINGS.BACKTOSEARCH}`,
    BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_APPLYNOW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON}.${KEY_STRINGS.APPLYNOW}`,
    //WORKSPACE
    BLS_PUBLICWORKSPACE_CONTENT_TITLE_WORKSPACE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_CONTENT_TITLE}.${KEY_STRINGS.WORKSPACE}`,
    BLS_PUBLICWORKSPACE_CONTENT_TITLE_HI: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_CONTENT_TITLE}.${KEY_STRINGS.HI}`,
    BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_NAVIGATION_MENU}.${KEY_STRINGS.WORKSPACE2}`,

    BLS_PUBLICWORKSPACE_CONTENT_MENU_APPLICATIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_CONTENT_MENU}.${KEY_STRINGS.APPLICATIONS}`,
    BLS_PUBLICWORKSPACE_CONTENT_MENU_ISSUANCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_CONTENT_MENU}.${KEY_STRINGS.ISSUEANCE}`,
    BLS_PUBLICWORKSPACE_CONTENT_MENU_CORRESPONDENCES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_CONTENT_MENU}.${KEY_STRINGS.CORRESPONDENCES}`,
    BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_UPDATEPROFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_NAVIGATION_BUTTON}.${KEY_STRINGS.UPDATEPROFILE}`,
    BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_CHANGELOGINID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_NAVIGATION_BUTTON}.${KEY_STRINGS.CHANGELOGINID}`,
    BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_CHANGEPASSWORD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_NAVIGATION_BUTTON}.${KEY_STRINGS.CHANGEPASSWORD}`,
    BLS_PUBLICWORKSPACE_NAVIGATION_BUTTON_LOGOUT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_NAVIGATION_BUTTON}.${KEY_STRINGS.LOGOUT}`,
    BLS_PUBLICWORKSPACE_NAVIGATION_PLACEHOLDER_GOTODASHBOARD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACE_NAVIGATION_PLACEHOLDER}.${KEY_STRINGS.GOTODASHBOARD}`,
    //WORKSPACE APPLICATION
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER_WORKSPACESEARCHBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER}.${KEY_STRINGS.WORKSPACESEARCHBOX}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER_TYPEHERE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER}.${KEY_STRINGS.TYPEHERE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER_FILTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER}.${KEY_STRINGS.FILTER}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_ALL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.ALL}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_DRAFTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.DRAFTS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_ATTENTIONREQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.ATTENTIONREQUIRED}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_SUBMITPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.SUBMITPAYMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_RESPONDTOACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.RESPONDTOACTION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.SEARCH}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.COLLAPSEALL}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.EXPANDALL}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_MAKEPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.MAKEPAYMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_VIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.VIEW}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_UPLOAD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.UPLOAD}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.APPLICATIONREFNUMBER}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONNUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.SUBMISSIONNUMBER}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.SUBMISSIONDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.APPLICATIONTYPE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.SERVICENAME}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.APPLICATIONSTATUS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADVANCEDSEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.ADVANCEDSEARCH}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.ACTION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.ACTIONS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_REFNUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.REFERENCENUMBER}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_FROMDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.FROMDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_TODATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.TODATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_NO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.NO}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.SERVICES}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_DRAFTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.DRAFTDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADDITIONALPAYMENTREQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.ADDITIONALPAYMENTREQUIRED}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_PAYMENTREQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.PAYMENTREQUIRED}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_REQUESTFORAMENDMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.REQUESTFORAMENDMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_REQUESTFORCLARIFICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.REQUESTFORCLARIFICATION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_CLARIFICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.CLARIFICATION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADDITIONALSUPPORTINGDOCREQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.ADDITIONALSUPPORTINGDOCREQ}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSUBMISSIONCOMPLETE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.APPLICATIONSUBMISSIONCOMPLETE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_PRINTRECEIPT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.PRINTRECEIPT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NODRAFTSPENDING: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.NODRAFTSPENDING}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_APPLICATIONBEINGPROCESSED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.APPLICATIONBEINGPROCESSED}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_ADDITIONALPAYMENTINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.ADDITIONALPAYMENTINFO}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_SUBMITSECONDPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.SUBMITSECONDPAYMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_APPLICATIONSUBMITTEDMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.APPLICATIONSUBMITTEDMESSAGE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTREQUIREDINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.PAYMENTREQUIREDINFO}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOPAYMENTREQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.NOPAYMENTREQUIRED}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOAPPLICATIONDOCUMENTSFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.NOAPPLICATIONDOCUMENTSFOUND}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOREQFORACTIONHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.NOREQFORACTIONHISTORY}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOAPPLICATIONSFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.NOAPPLICATIONSFOUND}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_RETRIEVINGPAYMENTDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.RETRIEVINGPAYMENTDETAILS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSFULLYPERFORMED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.PAYMENTSUCCESSFULLYPERFORMED}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_REDIRECTTOPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.REDIRECTTOPAYMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSTEXT1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.PAYMENTSUCCESSTEXT1}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSTEXT2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.PAYMENTSUCCESSTEXT2}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSTEXT3: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.PAYMENTSUCCESSTEXT3}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_INFORMATIONOFAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.INFORMATIONOFAPPLICATION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_GENERALINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.GENERALINFORMATION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SERVICEOVERVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.SERVICEOVERVIEW}`, 
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SERVICEAPPLICATIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.SERVICEAPPLICATIONDETAILS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_CANCELLATIONINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.CANCELLATIONINFORMATION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SUPPORTINGDOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.SUPPORTINGDOCUMENTS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_APPLICATIONDOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.APPLICATIONDOCUMENTS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_PAYMENTRECEIPT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.PAYMENTRECEIPT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_CORRESPONDENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.CORRESPONDENCE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_REQUESTFORACTIONHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION}.${KEY_STRINGS.REQUESTFORACTIONHISTORY}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_REMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD}.${KEY_STRINGS.REMARKS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_EFFECTIVEENDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD}.${KEY_STRINGS.EFFECTIVEENDDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_PAYMENTDUEDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION}.${KEY_STRINGS.PAYMENTDUEDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_ADDITIONALPAYMENTFEE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION}.${KEY_STRINGS.ADDITIONALPAYMENTFEE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION}.${KEY_STRINGS.CURRENCYSYMBOL}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_ORDERID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION}.${KEY_STRINGS.ORDERID}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL_OFFICERREMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL}.${KEY_STRINGS.OFFICERREMARKS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL_RESPONSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL}.${KEY_STRINGS.RESPONSE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_MESSAGESENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION}.${KEY_STRINGS.MESSAGESENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_RESPONDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION}.${KEY_STRINGS.RESPONDBY}`,
    BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_RESPOND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION}.${KEY_STRINGS.RESPOND}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_PAYMENTREFNUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.PAYMENTREFNUMBER}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DOCUMENTNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.DOCUMENTNAME}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.ISSUEDDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DATEOFPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.DATEOFPAYMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_AMOUNTPAID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.AMOUNTPAID}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_MODEPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.MODEPAYMENT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_TYPEOFREQUEST: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.TYPEOFREQUEST}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_REQUESTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.REQUESTDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DEADLINE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.DEADLINE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_RESPONSEDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.RESPONSEDATE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_OFFICERREMARKSRESPONSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.OFFICERREMARKSRESPONSE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_APPLICATIONNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.APPLICATIONNO}`,
    BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.SERVICE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_WITHDRAWAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON}.${KEY_STRINGS.WITHDRAWAPPLICATION}`,
    BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_SUBMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON}.${KEY_STRINGS.SUBMIT}`,
    BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_BACKTORFA: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON}.${KEY_STRINGS.BACKTORFA}`,
    BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_VIEWAPPLICATIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU}.${KEY_STRINGS.VIEWAPPLICATIONDETAILS}`,
    BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU}.${KEY_STRINGS.BACKTOWORKSPACE}`,
    BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_RESPONDTORFA: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU}.${KEY_STRINGS.RESPONDTORFA}`,
    //WORKSPACE LICENCE
    BLS_PUBLICWORKSPACELICENCE_CONTENT_MENU_DUEFORRENEWAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_MENU}.${KEY_STRINGS.DUEFORRENEWAL}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_MENU_ALL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_MENU}.${KEY_STRINGS.ALL}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_ADVANCEDSEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_TITLE}.${KEY_STRINGS.ADVANCEDSEARCH}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_AGENCYNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_TITLE}.${KEY_STRINGS.AGENCYNAME}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_DUEFORRENEWAL2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_TITLE}.${KEY_STRINGS.DUEFORRENEWAL2}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_PLACEHOLDER_SERVICESEARCHBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_PLACEHOLDER}.${KEY_STRINGS.SERVICESEARCHBOX}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_BUTTON_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_BUTTON}.${KEY_STRINGS.SEARCH}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_BUTTON_CANCELLICENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_BUTTON}.${KEY_STRINGS.CANCELLICENCE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_BUTTON_RENEWLICENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_BUTTON}.${KEY_STRINGS.RENEWLICENCE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_APPLICANT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.APPLICANT}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.ISSUEDDATE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_PREMISEOPERATINGADDR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.PREMISEOPERATINGADDR}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_LICENCECERTIFICATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.LICENCECERTIFICATE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_DOWNLOADLICENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.DOWNLOADLICENCE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_DOWNLOADFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.DOWNLOAD_FILE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.SERVICENUMBER}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.SERVICENAME}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICESTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.SERVICESTATUS}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_FIELD}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_SECTION_SERVICEOVERVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_SECTION}.${KEY_STRINGS.SERVICEOVERVIEW}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_SECTION_SERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_SECTION}.${KEY_STRINGS.SERVICEDOCUMENT}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_SECTION_SERVICEAPPLICATIONHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_SECTION}.${KEY_STRINGS.SERVICEAPPLICATIONHISTORY}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_INFORMATION_LICENCEEXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_INFORMATION}.${KEY_STRINGS.LICENCEEXPIRYDATE}`,
    BLS_PUBLICWORKSPACELICENCE_CONTENT_INFORMATION_LICENCERENEWALFEE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_CONTENT_INFORMATION}.${KEY_STRINGS.LICENCERENEWALFEE}`,
    BLS_PUBLICWORKSPACELICENCE_NAVIGATION_MENU_VIEWSERVICEDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_NAVIGATION_MENU}.${KEY_STRINGS.VIEWSERVICEDETAILS}`,
    BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOSERVICEFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_MESSAGE_NOTE}.${KEY_STRINGS.NOSERVICEFOUND}`,
    BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOSERVICEAPPHISTORYFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_MESSAGE_NOTE}.${KEY_STRINGS.NOSERVICEAPPHISTORYFOUND}`,
    BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_LICENCEDUERENEWALTEXT1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_MESSAGE_NOTE}.${KEY_STRINGS.LICENCEDUERENEWALTEXT1}`,
    BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_LICENCEDUERENEWALTEXT2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_MESSAGE_NOTE}.${KEY_STRINGS.LICENCEDUERENEWALTEXT2}`,
    BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCEDOCUMENTSFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_MESSAGE_NOTE}.${KEY_STRINGS.NOLICENCEDOCUMENTSFOUND}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.SERVICENUMBER}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.SERVICENAME}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICESTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.SERVICESTATUS}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_STARTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.STARTDATE}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACELICENCE_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_LICENCEAPPLICATIONTYPE_TITLE}.%Key%`,
    BLS_CODE_LICENCESTATUS_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_LICENCESTATUS_TITLE}.%Key%`,
    //WORKSPACE CORRESPONDENCE
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_TO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.TO}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_FROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.FROM}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_SENTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.SENTDATE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_TITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.TITLE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_DELIVERYCHANNEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.DELIVERYCHANNEL}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_TODATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.TODATE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_FROMDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD}.${KEY_STRINGS.FROMDATE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_TITLE_ADVANCEDSEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_TITLE}.${KEY_STRINGS.ADVANCEDSEARCH}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_BUTTON_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_BUTTON}.${KEY_STRINGS.SEARCH}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU_CORRESPONDENCEDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU}.${KEY_STRINGS.CORRESPONDENCEDETAILS}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU_CORRESPONDENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU}.${KEY_STRINGS.CORRESPONDENCE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYCHANNEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE}.${KEY_STRINGS.DELIVERYCHANNEL}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE}.${KEY_STRINGS.DELIVERYDATE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE}.${KEY_STRINGS.SERVICE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_CORRESPONDENCETITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE}.${KEY_STRINGS.CORRESPONDENCETITLE}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_PLACEHOLDER_CORRESPONDENCESEARCHBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_PLACEHOLDER}.${KEY_STRINGS.CORRESPONDENCESEARCHBOX}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_MESSAGE_NOTE_NOCORRESPONDENCEFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_MESSAGE_NOTE}.${KEY_STRINGS.NOCORRESPONDENCEFOUND}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU_ALL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU}.${KEY_STRINGS.ALL}`,
    BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU_UNREAD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU}.${KEY_STRINGS.UNREAD}`,
    BLS_CODE_CORRESPONDENCETYPE_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_CORRESPONDENCETYPE_TITLE}.%Key%`,
    //APPLICATION
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_PAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.PAY}`,
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_AGREE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.AGREE}`,
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_UPLOAD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.UPLOAD}`,
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_REVIEWINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.REVIEWINFORMATION}`,
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_SAVEASDRAFT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.SAVEASDRAFT}`,
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_ONLINE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.ONLINE}`,
    BLS_PUBLICAPPLICATION_CONTENT_BUTTON_OFFLINE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_BUTTON}.${KEY_STRINGS.OFFLINE}`,
    BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_NEXT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_NAVIGATION_BUTTON}.${KEY_STRINGS.NEXT}`,
    BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_PREVIOUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_NAVIGATION_BUTTON}.${KEY_STRINGS.PREVIOUS}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_COMMONINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.COMMONINFORMATION}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_PAYMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.PAYMENTS}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.GENERALINFORMATION}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_REVIEWANDSUBMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.REVIEWANDSUBMIT}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_COMMONFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.COMMONINFORMATION}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_OPTIONALDOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.OPTIONALDOCUMENTS}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_MANDATORYDOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.MANDATORYDOCUMENTS}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.DECLARATION}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_TERMSANDCONDITONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.TERMSANDCONDITIONS}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_AGREEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.AGREEMENT}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_DOCUMENTSSUBMITTED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.DOCUMENTSSUBMITTED}`,
    BLS_PUBLICAPPLICATION_CONTENT_TITLE_GRANDTOTAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.GRANDTOTAL}`,
    BLS_PUBLICAPPLICATION_MESSAGE_NOTE_PAYMENTTEXT1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_MESSAGE_NOTE}.${KEY_STRINGS.PAYMENTTEXT1}`,
    BLS_PUBLICAPPLICATION_MESSAGE_NOTE_PAYMENTTEXT2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_MESSAGE_NOTE}.${KEY_STRINGS.PAYMENTTEXT2}`,
    BLS_PUBLICAPPLICATION_CONTENT_NOTE_CHOOSEPAYMENTMODE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.CHOOSEPAYMENTMODE}`,
    BLS_PUBLICAPPLICATION_CONTENT_NOTE_CLICKANYBELOW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.CLICKANYBELOW}`,
    BLS_PUBLICAPPLICATION_CONTENT_NOTE_DECLARATIONINSTRUCTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.DECLARATIONINSTRUCTIONS}`,
    BLS_PUBLICAPPLICATION_CONTENT_NOTE_ACKNOWLEDGEMENTTEXT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.ACKNOWLEDGEMENTTEXT}`,
    BLS_PUBLICAPPLICATION_CONTENT_NOTE_SUBMISSIONNUM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.SUBMISSIONNUM}`,
    BLS_PUBLICAPPLICATION_CONTENT_NOTE_OFFLINEPAYMENTTEXT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_NOTE}.${KEY_STRINGS.OFFLINNEPAYMENTTEXT}`,
    BLS_PUBLICAPPLICATION_TABLE_TITLE_SERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.SERVICES}`,
    BLS_PUBLICAPPLICATION_TABLE_TITLE_FEES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.FEES}`,
    BLS_PUBLICAPPLICATION_TABLE_TITLE_TAX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.TAX}`,
    BLS_PUBLICAPPLICATION_TABLE_TITLE_SUBTOTAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_TABLE_TITLE}.${KEY_STRINGS.SUBTOTAL}`,
    //APPLICATION DETAILS
    BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.DEFAULT_PAYMENTTYPE_PAYMENTOPTION}.%Key%`,
    // GENERAL INFORMATION
    BLS_GENERALINFORMATION_FORM_SECTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_SECTION}.%Key%`,
    BLS_GENERALINFORMATION_FORM_FIELD_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_FIELD}.%Key%`,
    BLS_GENERALINFORMATION_FORM_HELPTEXT_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_HELPTEXT}.%Key%`,
    BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_PLACEHOLDERTEXT}.%Key%`,
    // LICENCE APPLICATION FEES
    BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEAPPLICATIONFEE_APPLICATIONFEE}.%Key%`,
    // QUESTIONNAIRE
    BLS_QUESTIONNAIRE_BUSINESSINTENT_NAME_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.QUESTIONNAIRE_BUSINESSINTENT_NAME}.%Key%`,
    BLS_QUESTIONNAIRE_BUSINESSINTENT_DESCRIPTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.QUESTIONNAIRE_BUSINESSINTENT_DESCRIPTION}.%Key%`,
    BLS_QUESTIONNAIRE_QUESTION_DESCRIPTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.QUESTIONNAIRE_QUESTION_DESCRIPTION}.%Key%`,
    BLS_QUESTIONNAIRE_ANSWER_DESCRIPTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.QUESTIONNAIRE_ANSWER_DESCRIPTION}.%Key%`,
    //Code Table DropDown List
    BLS_CODE_CATEGORY_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE}.%Category%.${SCOPE_CAT_SUBCAT_STRINGS.TITLE}.%Key%`,
    //SEARCH BY INDUSTRY
    BLS_CODE_INDUSTRY_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_INDUSTRY_TITLE}.%Key%`,
    BLS_CODE_INDUSTRY_DESCRIPTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_INDUSTRY_DESCRIPTION}.%Key%`,
    BLS_CODE_SUBINDUSTRY_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_SUBINDUSTRY_TITLE}.%Key%`,
    //GENERAL
    BLS_PUBLICGENERAL_CONTENT_HELPTEXT_CHARARACTERSREMAINING: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.CHARACTERSREMAINING}`,
    BLS_PUBLICGENERAL_CONTENT_HELPTEXT_MAXIMUMFILESIZE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MAXIMUMFILESIZE}`,
    BLS_PUBLICGENERAL_CONTENT_HELPTEXT_ALLOWEDFILEFORMAT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.ALLOWEDFILEFORMAT}`,
    //TERMS ADN CONDITIONS
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_1: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_1}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_2}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_3: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_3}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_4: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_4}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_5: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_5}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_6: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_6}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_7: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_7}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_8: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_8}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_9: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_9}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_10: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_10}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_11: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SECTION}.${KEY_STRINGS.SECTION_11}`,
    //section 1
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_1J}`,
    //section 2
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_2J}`,
    //section 3
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_3J}`,
    //section 4
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_4J}`,
    //section 5
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_5J}`,
    //section 6
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_6J}`,
    //section 7
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_7J}`,
    //section 8
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_8J}`,
    //section 9
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_9J}`,
    //section 10
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_10J}`,
    //section 11
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11A: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11A}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11B: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11B}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11C: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11C}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11D: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11D}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11E: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11E}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11F: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11F}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11G: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11G}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11H: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11H}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11I: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11I}`,
    BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11J: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION}.${KEY_STRINGS.SUBSECTION_11J}`,
    //FormIO
    BLS_FORMIO_FIELD: `${APP_NAME_LICENCE_CONFIG}.%Scope%.Form.Field.%Key%`,
    
};

export const WIZARD_TAB_NAMES = {
    GENERAL_INFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.GENERALINFORMATION}`,
    COMMON_INFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.COMMONINFORMATION}`,
    SUPPORTING_DOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.SUPPORTINGDOCUMENTS}`,
    REVIEW_INFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.REVIEWANDSUBMIT}`,
    PAYMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICAPPLICATION_CONTENT_TITLE}.${KEY_STRINGS.PAYMENTS}`,
    FORMIO: "FormIO"
};

// Const: Error Messages
export const ERROR = {
    REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.LABEL_REQUIRED}`,
    INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.INVALID}`,
    LABEL: '<%= label %> ',
    TICK_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TICK_REQUIRED}`,
    MIN_VALUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MIN_VALUE}`,
    MIN_VALUE_LABEL: ' <%= minValue %>.',
    MAX_VALUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MAX_VALUE}`,
    MAX_VALUE_LABEL: ' <%= maxValue %>.',
    FILE_LIMIT_REACHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FILE_LIMIT_REACHED}`,
    LIMIT_LABEL: ' <%= limit %>.',
    FILE_ITEM_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FILE_REQUIRED}`,
    FILE_DESC_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FILE_DESC_REQUIRED}`,
    FORM_VALIDATION_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FORM_VALIDATION_FAIL}`,
    FORM_DEFAULT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FORM_DEFAULT_FAIL}`,
    PASSWORD_REGEX_UNMATCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.PASSWORD_REGEX_UNMATCH}`,
    EMAIL_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.EMAIL_INVALID}`,
    MIN_LENGTH_LIMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MIN_LENGTH_LIMIT}`,
    MIN_LENGTH_LABEL: ' <%= minLength %>.',
    MAX_LENGTH_LIMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MAX_LENGTH_LIMIT}`,
    MAX_LENGTH_LABEL: ' <%= maxLength %>.',
    TELEPHONE_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TELEPHONE_INVALID}`,
    NUMBER_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.NUMBER_INVALID}`,
    APPLICATION_DELETE_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.APPLICATION_DELETE_FAIL}`,
    LIST_RETRIEVAL_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.LIST_RETRIEVAL_FAIL}`,
    DRAFT_SAVED_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DRAFT_SAVED_FAIL}`,
    DRAFT_EMPTY_FIELD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DRAFT_EMPTY_FIELD}`,
    DATE_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DATE_INVALID}`,
    LICENCE_DATA_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.LICENCE_DATA_NOT_FETCHED}`,
    FORM_DATA_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FORM_DATA_NOT_FETCHED}`,
    PREPARE_PAYMENT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PREPARE_PAYMENT_FAIL}`,
    PROCESS_PAYMENT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PROCESS_PAYMENT_FAIL}`,
    PAYMENT_REDIRECT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_REDIRECT_FAIL}`,
    RFA_DETAILS_HISTORY_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.RFA_DETAILS_HISTORY_NOT_FETCHED}`,
    RFA_DETAILS_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.RFA_DETAILS_NOT_FETCHED}`,
    PAYMENT_STATUS_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_STATUS_NOT_FETCHED}`,
    PAYMENT_DETAILS_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_DETAILS_NOT_FETCHED}`,
    TRANSLATION_LOAD_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.TRANSLATION_LOAD_ERROR}`,
    SERVER_UNREACHABLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.SERVER_UNREACHABLE}`,
    PAYMENT_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_ERROR}`,
    CANNOT_REACH_SERVER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.CANNOT_REACH_SERVER}`,
    DRAFT_RETRIEVAL_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.DRAFT_RETRIEVAL_FAIL}`,
    ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.ERROR_MESSAGE}`,
    FILE_FORMAT_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FILE_FORMAT_ERROR}`,
    FILE_SIZE_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FILE_SIZE_ERROR}`,
    PAYMENT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_FAIL}`,   
    ALLOWEDFILEFORMAT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.ALLOWEDFILEFORMAT}`,
    MAXIMUMFILESIZE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MAXIMUMFILESIZE}`,
    UNABLETOGENARATEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_ERROR}.${KEY_STRINGS.UNABLETOGENARATEDOCUMENT}`,
    CONTACTAGENCYFORSUPPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.CONTACTAGENCYFORSUPPORT}`,
    //Key to get error messages from backend
    BACKEND_ERROR_MESSAGE: `${APP_NAME}.%Key%`
}

// Const: Success Messages
export const SUCCESS = {
    FORM_DEFAULT_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FORM_DEFAULT_SUCCESS}`,
    APPLICATION_DELETE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.APPLICATION_DELETE_SUCCESS}`,
    //Key to get success messages from backend
    BACKEND_SUCCESS_MESSAGE: `${APP_NAME}.%Key%`
}

//Const: Button Labels
export const BUTTON = {
    ADD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.ADD}`,
    UPDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.PUBLICGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.UPDATE}`
}

export const DEFAULT_APPLICATION_NAME = "BLS";

export const DEFAULT_SCOPE = {
    APPCONFIGSTATIC: 'AppConfigStatic',
    CODESTATIC: 'CodeStatic',
    MENU: 'Menu',
    DEFAULTSTATIC: 'DefaultStatic',
    FAQSTATIC: 'FaqStatic',
    LOCALESTATIC: 'LocaleStatic',
    PUBLICHOLIDAYSTATIC: 'PublicHolidayStatic',
    NOTITEMPLATESTATIC: 'NotiTemplateStatic'
}

export const DEFAULT_CATEGORY_SUBCATEGORY = {
    CONTENT_TITLE: 'Content.Title',
    CONTENT_DROPDOWN: 'Content.Dropdown',
    NAVIGATION_BUTTON: 'Navigation.Button',
    CONTENT_PLACEHOLDER: 'Content.Placeholder',
    CONTENT_TABLE: 'Content.Table',
    FORM_TITLE: 'Form.Title',
    FORM_VALUE: 'Form.Value',
    FORM_HELPTEXT: 'Form.HelpText',
    FORM_PLACEHOLDER: 'Form.Placeholder',
    FORM_FIELD: 'Form.Field',
    MODAL_TITLE: 'Modal.Title',
    MODAL_TEXT: 'Modal.Text',
    TABLE_PLACEHOLDER: 'Table.Placeholder',
    TABLE_TOOLTIP: 'Table.Tooltip',
    TABLE_TEXT: 'Table.Text',
    SUCCESS_TITLE: 'Success.Title',
    ERROR_TITLE: 'Error.Title',
    TREE_TITLE: 'Tree.Title',
    BADGE_TEXT: 'Badge.Text',
    CONTENT_TEXT: 'Content.Text',
    FORM_DIVIDER: 'Form.Divider'
}

export const DEFAULT_KEY = {
    APPLICATIONCONFIGURATIONMANAGEMENT: 'ApplicationConfigurationManagement',
    NEWCONFIGURATION: 'NewConfiguration',
    ALLCATEGORIES: 'AllCategories',
    FILTER: 'Filter',
    NO: 'No',
    ACTIONS: 'Actions',
    CATEGORY: 'Category',
    NAME: 'Name',
    VALUETYPE: 'ValueType',
    VALUE: 'Value',
    EFFECTIVESTARTDATE: 'EffectiveStartDate',
    EFFECTIVEENDDATE: 'EffectiveEndDate',
    UPDATECONFIGURATION: 'UpdateConfiguration',
    LOCALESETTINGS: 'LocaleSettings',
    ISDEFAULTGLOBALAPPENABLED: 'IsDefaultGlobalAppEnabled',
    GENERAL: 'General',
    CURRENCY: 'Currency',
    DATE: 'Date',
    EMAIL: 'Email',
    NUMBER: 'Number',
    DECIMAL: 'Decimal',
    BACK: 'Back',
    VIEWCONFIGURATION: 'ViewConfiguration',
    CREATEDBY: 'CreatedBy',
    CREATEDDATE: 'CreatedDate',
    LASTUPDATEDBY: 'LastUpdatedBy',
    LASTUPDATEDDATE: 'LastUpdatedDate',
    NORECORD: 'NoRecord',
    DELETEAPPCONFIG: 'DeleteAppConfig',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    CREATE: 'Create',
    CODETABLEMANAGEMENT: 'CodeTableManagement',
    NEWCODE: 'NewCode',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    STATUS: 'Status',
    PARENTCODE: 'ParentCode',
    UPDATECODETABLEENTRY: 'UpdateCodeTableEntry',
    PARENTCODECATEGORY: 'ParentCodeCategory',
    PARENTCATEGORY: 'ParentCategory',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SEQUENCENUMBER: 'SequenceNumber',
    COUNTRY: 'Country',
    SAVECHANGES: 'SaveChanges',
    CODETABLEENTRYDETAILS: 'CodeTableEntryDetails',
    NEWCODETABLEENTRY: 'NewCodeTableEntry',
    UPDATECODE: 'UpdateCode',
    VIEWCODE: 'ViewCode',
    VIEWCONFIG: 'ViewConfiguration',
    DELETECONFIG: 'DeleteConfiguration',
    LANGUAGE: 'Language',
    CODECATEGORY: 'CodeCategory',
    SERVERUNREACHABLE: 'ServerUnreachable',
    UPDATE: 'Update',
    CATEGORYREQUIRED: 'CategoryRequired',
    NAMEREQUIRED: 'NameRequired',
    QUESTION: 'Question',
    TAGS: 'Tags',
    REQUIRED: 'Required',
    MINLENGTH: 'MinLength',
    MAXLENGTH: 'MaxLength',
    DATEINVALID: 'DateInvalid',
    DELETECONFIGURATION: 'DeleteConfiguration',
    FAQMANAGEMENT: 'FaqManagement',
    NEWFAQ: 'NewFaq',
    APPLICATION: 'Application',
    LASTPUBLISHED: 'LastPublished',
    MULTILANG: 'MultiLang',
    QNAMAKER: 'QnAMaker',
    ADDFAQ: 'AddFaq',
    SELECTLANG: 'SelectLang',
    ENABLEAI: 'EnableAI',
    ENABLEMULTILANG: 'EnableMultiLang',
    EDITFAQ: 'EditFaq',
    PREVIEW: 'Preview',
    DELETEDRAFT: 'DeleteDraft',
    FAQTREE: 'FaqTree',
    DELETETOPIC: 'DeleteTopic',
    DELETESUBTOPIC: 'DeleteSubTopic',
    DELETEQNSET: 'DeleteQnSet',
    UPDATEQNSET: 'UpdateQnSet',
    UPDATETOPIC: 'UpdateTopic',
    UPDATEFAQ: 'UpdateFaq',
    UPDATEFAQSUBTOPIC: 'UpdateSubTopic',
    CREATETOPIC: 'CreateTopic',
    CREATESUBTOPIC: 'CreateSubTopic',
    CREATEQNSET: 'CreateQnSet',
    SETTINGS: 'Settings',
    SAVE: 'Save',
    AICHATBOT: 'AIChatbot',
    ANSWER: 'Answer',
    YES: 'Yes',
    FAQDESC: 'FaqDesc',
    RANK: 'Rank',
    TYPE: 'Type',
    SORTRANKUP: 'SortRankUp',
    SORTRANKDOWN: 'SortRankDown',
    QNSETNORECORD: 'QnSetNoRecord',
    FAQTOPICTITLE: 'FaqTopicTitle',
    ADD: 'Add',
    FAQSUBTOPICTITLE: 'FaqSubTopicTitle',
    FAQANS: 'FaqAns',
    TOGGLETREEVIEW: 'ToggleTreeView',
    SEARCH_QNSET: 'SearchQnSet',
    FAQQNSETS: "FaqQnSets",
    MOVEUP: "MoveUp",
    MOVEDOWN: "MoveDown",
    FAQSUBTOPICDESC: "FaqSubTopicDesc",
    FAQTOPICDESC: "FaqTopicDesc",
    ADDQN: "AddQn",
    PRIQN: "PriQn",
    REMOVE: "Remove",
    EDITFAQTOPIC: "EditFaqTopic",
    NEWFAQTOPIC: "NewFaqTopic",
    NEWFAQSUBTOPIC: "NewFaqSubTopic",
    NEWFAQQNSET: "NewFaqQnSet",
    EDITFAQSUBTOPIC: "EditFaqSubTopic",
    EDITFAQQNSET: "EditFaqQnSet",
    VIEWFAQ: "ViewFaq",
    VIEWFAQTOPIC: "ViewFaqTopic",
    VIEWFAQSUBTOPIC: "ViewFaqSubTopic",
    VIEWFAQQNSET: "ViewFaqQnSet",
    VIEWQNSET: "ViewQnSet",
    EDITQNSET: "EditQnSet",
    PRIMARY: "Primary",
    ADDNEWFAQTOPIC: "AddNewFaqTopic",
    ADDNEWFAQSUBTOPIC: "AddNewFaqSubTopic",
    ADDNEWQNSET: "AddNewQnSet",
    LOADING: "Loading",
    FAQ: "Faq",
    TOPIC: "Topic",
    SUBTOPIC: "SubTopic",
    SCOPEMANAGEMENT: "ScopeManagement",
    CATEGORYMANAGEMENT: "CategoryManagement",
    SUBCATEGORYMANAGEMENT: "SubCategoryManagement",
    NEWSCOPE:"NewScope",
    SCOPENORECORD: "ScopeNoRecord",
    CATEGORYNORECORD: "CategoryNoRecord",
    SUBCATEGORYNORECORD: "SubCategoryNoRecord",
    NEWCATEGORY: "NewCategory",
    NEWSUBCATEGORY: "NewSubCategory",
    EDITSCOPE: "EditScope",
    EDITCATEGORY: "EditCategory",
    EDITSUBCATEGORY: "EditSubCategory",
    VIEWSCOPE: "ViewScope",
    VIEWCATEGORY: "ViewCategory",
    VIEWSUBCATEGORY: "ViewSubCategory",
    UPDATESCOPE: "UpdateScope",
    UPDATECATEGORY: "UpdateCategory",
    UPDATESUBCATEGORY: "UpdateSubCategory",
    CREATESCOPE: "CreateScope",
    CREATECATEGORY: "CreateCategory",
    CREATESUBCATEGORY: "CreateSubCategory",
    ADDSCOPE: "AddScope",
    ADDCATEGORY: "AddCategory",
    ADDSUBCATEGORY: "AddSubCategory",
    SUBCATEGORY: "SubCategory",
    SCOPE: "Scope",
    LANGUAGEPACK: "LanguagePack",
    DOWNLOAD: "Download",
    PUBLISH: "Publish",
    UPLOADFILE: "UploadFile",
    TRANSDATANORECORD: "TransDataNoRecord",
    LANGUAGEREG: "LanguageReg",
    REGLANGUAGE: "RegLanguage",
    APPNORECORD: "AppNoRecord",
    DEFAULT: "Default",
    EDITAPPLANG: "EditAppLang",
    ADDLANG: "AddLang",
    LANGSETTINGS: "LangSettings",
    DEFAULTLANG: "DefaultLang",
    UPDATEAPPLANG: "UpdateAppLang",
    VALIDATE: "Validate",
    TRANSDATA: "TransData",
    LANGKEYUPDATE: "LangKeyUpdate",
    LANGKEY: "LangKey",
    ALIGNRIGHT: "AlignRight",
    SUBMIT: "Submit",
    FULLKEY: "FullKey",
    EDITLANGVALUE: "EditLangValue",
    UPDATEKEY: "UpdateKey",
    CREATETRANS: "CreateTrans",
    ADDLANGENTRY: "AddLangEntry",
    ADDVALUE: "AddValue",
    KEY: "Key",
    FILTERBYSCOPE: "FilterByScope",
    FILTERBYCAT: "FilterByCat",
    FILTERBYSUBCAT: "FilterBySubCat",
    FILTERBYPUBLISHED: "FilterByPublished",
    PUBLISHED: "Published",
    SORTBY: "SortBy",
    SEARCH: "Search",
    NEWENTRY: "NewEntry",
    TRANSNORECORD: "TransNoRecord",
    DEFAULTVALUE: "DefaultValue",
    UPDATEDBY: "UpdatedBy",
    UPDATEDDATE: "UpdatedDate",
    REVERT: "Revert",
    TRANSMANAGEMENT: "TransManagement",
    DELETETRANSKEY: "DeleteTransKey",
    PUBLISHTRANSKEY: "PublishTransKey",
    SOFTDELETETRANSKEY: "SoftDeleteTransKey",
    REVERTTRANSKEY: "RevertTransKey",
    HARDDELETETRANSKEY: "HardDeleteTransKey",
    DRAFTED: "Drafted",
    DELETED: "Deleted",
    ASCKEY: "AscKey",
    DESCKEY: "DescKey",
    ASCUPDATEDDATE: "AscUpdatedDate",
    DESCUPDATEDDATE: "DescUpdatedDate",
    LASTEDITEDBY: "LastEditedBy",
    PUBLISHTRANS: "PublishTrans",
    DELETETRANS: "DeleteTrans",
    REVERTTRANS: "RevertTrans",
    ENTERVALUE: "EnterValue",
    CHARREMAINING: "CharRemaining",
    FAQTITLE: "FaqTitle",
    DELETEFAQ: "DeleteFaq",
    PUBLISHFAQ: "PublishFaq",
    PREVIEWFAQ: "PreviewFaq",
    PUBLISHFAQLOCALE: "PublishFaqLocale",
    ALLQUESTIONSREQUIRED: "AllQuestionsRequired",
    ANSWERREQUIRED: "AnswerRequired",
    DELETENODE: "DeleteNode",
    NOQUESTIONS: "NoQuestions",
    DISPLAYANSWER: "DisplayAnswer",
    ADDPUBLICHOLIDAY: "AddPublicHoliday",
    CREATEPUBLICHOLIDAY: "CreatePublicHoliday",
    PUBLICHOLIDAY: "PublicHoliday",
    REMARKS: "Remarks",
    PUBLICHOLIDAYMGMT: "PublicHolidayMgmt",
    YEAR: "Year",
    ALLYEARS: "AllYears",
    NEWPUBLICHOLIDAY: "NewPublicHoliday",
    PUBLICHOLIDAYNORECORD: "PublicHolidayNoRecord",
    DELETEPUBLICHOLIDAY: "DeletePublicHoliday",
    UPDATEPUBLICHOLIDAY: "UpdatePublicHoliday",
    NOTITEMPLATEMANAGEMENT: "NotiTemplateManagement",
    SMSTEMPLATEMANAGEMENT: "SmsTemplateManagement",
    NEWSMSTEMPLATE: "NewSmsTemplate",
    DELETESMSTEMPLATE: "DeleteSmsTemplate",
    SMSTEMPLATENORECORD: "SmsTemplateNoRecord",
    TEMPLATENAME: "TemplateName",
    VERSION: "Version",
    CREATESMSTEMPLATE: "CreateSmsTemplate",
    TEMPLATESUBJECT: "TemplateSubject",
    TEMPLATEBODY: "TemplateBody",
    UPDATESMSTEMPLATE: "UpdateSmsTemplate",
    VIEWSMSTEMPLATE: "ViewSmsTemplate",
    VIEWPUBLICHOLIDAY: "ViewPublicHoliday",
    PUBLISHBYSCOPE: "PublishByScope",
    PUBLISHBYAPP: "PublishByApp",
    CREATEEMAILTEMPLATE: "CreateEmailTemplate",
    NEWEMAILTEMPLATE: "NewEmailTemplate",
    DELETEEMAILTEMPLATE: "DeleteEmailTemplate",
    UPDATEEMAILTEMPLATE: "UpdateEmailTemplate",
    EMAILTEMPLATENORECORD: "EmailTemplateNoRecord"
}
export const DEFAULT_NAMESPACE = {
    // FAQ Management
    GLOBAL_FAQSTATIC_CONTENT_TITLE_FAQMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.FAQMANAGEMENT}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_NEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWFAQ}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_LASTPUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LASTPUBLISHED}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_MULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.MULTILANG}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_QNAMAKER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.QNAMAKER}`,
    GLOBAL_FAQSTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_SETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.CREATE}`,

    // Add new FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_ADDFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDFAQ}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SELECTLANG}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEAI: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ENABLEAI}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEMULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ENABLEMULTILANG}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // View FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_VIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWFAQ}`,

    // Edit FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_EDITFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITFAQ}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_PREVIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.PREVIEW}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETEDRAFT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETEDRAFT}`,
    GLOBAL_FAQSTATIC_TREE_TITLE_FAQTREE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TREE_TITLE}.${DEFAULT_KEY.FAQTREE}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_PUBLISHFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PUBLISHFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_DELETEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_PREVIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PREVIEWFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_DELETEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQLOCALE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHFAQLOCALE}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_PUBLISH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.PUBLISH}`,

    // FAQ Settings
    GLOBAL_FAQSTATIC_CONTENT_TITLE_SETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_SAVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVE}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_MULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.MULTILANG}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_AICHATBOT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.AICHATBOT}`,

    // Preview FAQ
    GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.NOQUESTIONS}`,

    // QuestionSetCard
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_DISPLAYANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DISPLAYANSWER}`,


    // FAQ Tree
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETESUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEQNSET}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEQNSET}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEFAQ}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEQNSET}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_QUESTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.QUESTION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_HELPTEXT_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ANSWER}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTITLE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQDESC}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTOPICDESC}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQSUBTOPICDESC}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_PRIQN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.PRIQN}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_ANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ANSWER}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_RANK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.RANK}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_TYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TYPE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKUP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SORTRANKUP}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKDOWN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SORTRANKDOWN}`,
    GLOBAL_FAQSTATIC_TABLE_TEXT_QNSETNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.QNSETNORECORD}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTOPICTITLE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.ADD}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQSUBTOPICTITLE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQDESC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQANS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_TOGGLETREEVIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.TOGGLETREEVIEW}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SEARCHQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEARCH_QNSET}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_FAQQNSETS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.FAQQNSETS}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEUP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.MOVEUP}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEDOWN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.MOVEDOWN}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADDQN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.ADDQN}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_YES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.YES}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_REMOVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REMOVE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_QUESTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.QUESTION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_PRIMARY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PRIMARY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWQNSET}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_LOADING: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.LOADING}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SUBTOPIC}`,
    GLOBAL_FAQSTATIC_ERROR_TITLE_ALLQUESTIONSREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.ALLQUESTIONSREQUIRED}`,
    GLOBAL_FAQSTATIC_ERROR_TITLE_ANSWERREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.ANSWERREQUIRED}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_DELETENODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETENODE}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_DELETENODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETENODE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.VIEW}`
}

export const DEFAULT_TEXT = {
    ALL_CATEGORIES: "All Categories",
    ALL_CONFIGURATIONS: "All Configurations",
    FILTER: "Filter",
    NEW_CONFIGURATION: "New Configuration",
    BACK: "Back",
    NUM: "No.",
    ACTIONS: "Actions",
    CATEGORY: "Category",
    NAME: "Name",
    VALUE_TYPE: "Value Type",
    VALUE: "Value",
    EFFECTIVE_START_DATE: "Effective Start Date",
    EFFECTIVE_END_DATE: "Effective End Date",
    APPCONFIG_MANAGEMENT: "Application Configuration Management",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    DELETE_APPCONFIG_MODAL_TEXT: "<div>Are you sure you want to delete the Application Configuration? <br />Please be reminded that this action cannot be undone.</div>",
    DELETE_APPCONFIG_MODAL_TITLE: "Delete Application Configuration?",
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    UPDATE: 'Update',
    APPCONFIG_NO_RECORD: "No Application Configuration(s) Found",
    HELPTEXT_CATEGORY: "Please choose an existing category, or type a new one then press enter to create a new option.",
    CURRENCY: "Currency",
    DATE: "Date",
    DECIMAL: "Decimal",
    EMAIL: "Email",
    GENERAL: "General",
    NUMBER: "Number",
    PLACEHOLDER_VALUETYPE: "Value Type",
    HELPTEXT_VALUETYPE: "Select a Value Type",
    DESCRIPTION: "Description",
    HELPTEXT_DESCRIPTION: "Please key in a description",
    HELPTEXT_CURRENCY: "Currency: $10.00 or 10.00",
    HELPTEXT_DATE: "Date: 01-Aug-2020",
    HELPTEXT_DECIMAL: "Decimal: 10.0 or 10",
    HELPTEXT_EMAIL: "Email: abc@xyz.com",
    HELPTEXT_NUMBER: "Number: 1",
    VIEWCONFIGURATION: "View Configuration",
    CREATED_BY: "Created By",
    CREATED_DATE: "Created Date",
    LAST_UPDATED_DATE: "Last Updated Date",
    LAST_UPDATED_BY: "Last Updated By",
    CODE_TABLE_MANAGEMENT: "Code Table Management",
    CODE_NO_RECORD: "No Code(s) Found",
    STATUS: "Status",
    TOOLTIP_UPDATE_CODE: "Update Code",
    TOOLTIP_VIEW_CODE: "View Code",
    UPDATE_CONFIGURATION: "Update Configuration",
    VIEW_CONFIGURATION: "View Configuration",
    DELETE_CONFIGURATION: "Delete Configuration",
    NEW_CODE: "New Code Table Entry",
    HELPTEXT_PARENT_CATEGORY: "Choose a parent category to help narrow down the parent code list when choosing.",
    HELPTEXT_SEQUENCE_NUMBER: "Sequence number used for ordering purpose. Default order by ascending.",
    SEQUENCE_NO: "Sequence Number",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    PARENT_CODE: "Parent Code",
    PARENT_CODE_CATEGORY: "Parent Code Category",
    HELPTEXT_CODE_CATEGORY: "Please choose an existing code category, or type a new one then press enter to create a new option.",
    CREATE: "Create",
    UPDATE_CODE: "Update Code Table Entry",
    VIEW_CODE: "Code Table Entry Details",
    TITLE: "Title",
    CODE_CATEGORY: "Code Category",
    FAQ_MANAGEMENT: "FAQ Management",
    NEW_FAQ: "New FAQ",
    APPLICATION: "Application",
    LAST_PUBLISHED: "Last Published",
    MULTI_LANGUAGE: "Multi-Language",
    QNAMAKER: "QnAMaker",
    FAQ_NO_RECORD: "No FAQ(s) were found",
    SETTINGS: "Settings",
    CREATE_DRAFT: "Create Draft",
    ADD_FAQ: "Add FAQ",
    SELECT_APP: "Select an Application",
    ENABLE_AI: "Enable AI Chatbot",
    ENABLE_MULTI_LANG: "Enable Mult-Language",
    SELECT_LANG: "Select a Language",
    EDIT_FAQ: "Edit FAQ",
    FAQ_TREE: "FAQ Tree",
    FAQ_SETTINGS: "FAQ Settings",
    SAVE: "Save",
    AI_CHATBOT: "AI Chatbot",
    FAQ: "FAQ",
    FAQ_TOPIC: "FAQ Topic",
    FAQ_SUB_TOPIC: "FAQ Sub-Topic",
    FAQ_QN_SET: "FAQ Question Set",
    FAQ_QN_SET_UPDATE_SUCCESS: "FAQ question set, questions and answers have been created succesfully.",
    HELPTEXT_TAGS: "Please input the value and press enter to create a new tag.",
    ANSWER: "Answer",
    QUESTION: "Question",
    YES: "Yes",
    NO: "No",
    FAQ_TITLE: "FAQ Title",
    FAQ_DESCRIPTION: "FAQ Description",
    FAQ_TOPIC_DESCRIPTION: "FAQ Topic Description",
    FAQ_SUB_TOPIC_DESCRIPTION: "FAQ Sub-Topic Description",
    PRIMARY_QN: "PrimaryQuestion",
    TYPE: "Type",
    RANK: "Rank",
    FAQQNSET_NO_RECORD: "No Question Sets in Sub-Topic",
    FAQ_TOPIC_TITLE: "FAQ Topic Title",
    ADD: "Add",
    FAQ_SUB_TOPIC_TITLE: "FAQ Sub-Topic Title",
    TAGS: "Tags",
    FAQ_ANSWER: "FAQ Answer",
    TOGGLE_TREE_VIEW: "Toggle Tree View",
    SEARCH_QNSET: "Search Question Set...",
    BTN_FAQ_QNSETS: "Question Sets",
    MOVEUP: "Move Up",
    MOVEDOWN: "Move Down",
    ADD_MORE_QN: "Add More Question",
    REMOVE: "Remove",
    EDIT_FAQ_TOPIC: "Edit FAQ Topic",
    NEW_FAQ_TOPIC: "New FAQ Topic",
    NEW_FAQ_SUB_TOPIC: "New FAQ Sub-Topic",
    NEW_FAQ_QNSET: "New FAQ Question Set",
    EDIT_FAQ_SUB_TOPIC: "Edit FAQ Sub-Topic",
    EDIT_FAQ_QNSET: "Edit FAQ Question Set",
    VIEW_FAQ: "View FAQ",
    VIEW_FAQ_TOPIC: "View FAQ Topic",
    VIEW_FAQ_SUB_TOPIC: "View FAQ Sub-Topic",
    VIEW_FAQ_QNSET: "View FAQ QUestion Set",
    VIEW_QNSET: "View Question Set",
    EDIT_QN_SET: "Edit Question Set",
    PRIMARY: "(Primary)",
    ADD_NEW_FAQ_TOPIC: "Add new FAQ Topic",
    ADD_NEW_FAQ_SUB_TOPIC: "Add new FAQ Sub-Topic",
    ADD_NEW_QN_SET: "Add new Question Set",
    LOADING: "Loading...",
    TOPIC: "Topic",
    SUB_TOPIC: "Sub-Topic",
    SCOPE_MANAGEMENT: "Scope Management",
    CATEGORY_MANAGEMENT: "Category Management",
    SUB_CATEGORY_MANAGEMENT: "Sub-Category Management",
    NEW_SCOPE: "New Scope",
    SCOPE_NO_RECORD: "No Scope(s) found.",
    CATEGORY_NO_RECORD: "No Category(s) found.",
    SUBCATEGORY_NO_RECORD: "No Sub-Category(s) found.",
    NEW_CATEGORY: "New Category",
    NEW_SUB_CATEGORY: "New Sub-Category",
    EDIT_SCOPE: "Edit Scope",
    EDIT_CATEGORY: "Edit Category",
    EDIT_SUB_CATEGORY: "Edit Sub-Category",
    SCOPE_NAME: "Scope Name",
    SUB_CATEGORY_NAME: "Sub-Category Name",
    CATEGORY_NAME: "Category Name",
    VIEW_SCOPE: "View Scope",
    VIEW_CATEGORY: "View Category",
    VIEW_SUB_CATEGORY: "View Sub-Category",
    ADD_SCOPE: "Add Scope",
    ADD_CATEGORY: "Add Category",
    ADD_SUB_CATEGORY: "Add Sub-Category",
    HELPTEXT_SCOPENAME: "Scope name is required.",
    HELPTEXT_CATEGORY_NAME: "Category name is required.",
    HELPTEXT_SUB_CATEGORY_NAME: "Sub-Category name is required.",
    LANGUAGE_PACK: "Language Pack",
    ERROR_SELECT_APP: "Please select an application.",
    SELECT_SCOPE: "Select a scope",
    SCOPE: "Scope",
    ERROR_SELECT_SCOPE: "Please select a scope.",
    DOWNLOAD: "Download",
    PUBLISH: "Publish",
    UPLOAD_FILE: "Please upload a file.",
    TRANS_DATA_NO_RECORD: "No Translation Data was found.",
    DIVIDER_LANG_PACK: "Review of Updated Information",
    LANGUAGE_REG: "Language Registration",
    REG_LANGUAGE: "Registered Language",
    APP_NO_RECORD: "No Applications were found.",
    DEFAULT: "Default",
    EDIT_APP_LANG: "Edit Application Languages",
    ADD_LANG: "Add Language",
    LANG_SETTINGS: "Language Settings",
    ERROR_DEFAULT_LANG: "A default language has to be active.",
    VALIDATE: "Validate",
    SELECTLIST_SCOPE_NO_RECORD: "There are no scopes available.",
    NO_TRANS_DATA: "There is no translation data that has been updated.",
    LANG_KEY_UPDATE: "Please confirm the list of language keys below that has an update in the language translation.",
    LANG_KEY: "Language Key",
    LANGUAGE: "Language",
    ALIGN_TO_RIGHT: "Align To Right",
    SELECT_A_LANGUAGE: "Select a Language",
    UPLOAD_FILE_TITLE: "Upload File",
    SUBMIT: "Submit",
    FULL_KEY: "Full Key",
    EDIT_LANG_VALUE: "Edit Language Value",
    VALUE_REQUIRED: "Value is required.",
    ADD_LANG_ENTRY: "Add New Language Key And Value Entry",
    KEY: "Key",
    ADD_VALUE_FOR_KEY: "Add Value for Key",
    SELECT_SUB_CATEGORY: "Select a Sub-Category",
    SELECT_CATEGORY: "Select a Category",
    FILTER_BY_SCOPE: "Filter by Scope",
    FILTER_BY_CATEGORY: "Filter by Category",
    FILTER_BY_SUBCATEGORY: "Filter by Sub-Category",
    SUB_CATEGORY: "Sub-Category",
    FILTER_BY_PUBLISHED: "Filter by Published",
    PUBLISHED: "Published",
    SORT_BY: "Sort By",
    SEARCH: "Search",
    NEW_ENTRY: "New Entry",
    TRANS_NO_RECORD: "No Translations were found.",
    DEFAULT_VALUE: "Default Value",
    UPDATED_BY: "Updated By",
    UPDATED_DATE: "Updated Date",
    REVERT: "Revert",
    TRANSLATION_MANAGEMENT: "Translation Management",
    DELETE_TRANSKEY_MODAL_TITLE: "Delete Translation Key",
    REVERT_TRANSKEY_MODAL_TITLE: "Revert Translation Key",
    PUBLISH_TRANSKEY_MODAL_TITLE: "Publish entries",
    HARD_DELETE_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to delete this entry? <br />Please be reminded that this action cannot be undone.</div>",
    SOFT_DELETE_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to delete this entry? <br />Please note that this action <b>can</b> be reverted.</div>",
    REVERT_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to revert back to the published values for all languages in this entry? <br />Please be reminded that this action cannot be undone.</div>",
    PUBLISH_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to publish the entries for all languages in this application? <br />Please be reminded that this action cannot be undone.</div>",
    DRAFTED: "Drafted",
    DELETED: "Deleted",
    ASC_KEY: "Key (asc)",
    DESC_KEY: "Key (desc)",
    ASC_UPDATEDDATE: "Updated Date (asc)",
    DESC_UPDATEDDATE: "Updated Date (desc)",
    LAST_EDITED_BY: "Last edited by",
    DEFAULT_LANGUAGE: "Default Language",
    SUBCATEGORY: "Sub-Category",
    ADD_LANGUAGE: "Add Language",
    CHAR_REMAINING: "characters remaining",
    PUBLISH_FAQ_MODAL_TEXT: "<div>Are you sure you want to publish this FAQ? <br />Please be reminded that this action cannot be undone.</div>",
    PUBLISH_FAQ_MODAL_TITLE: "Publish Faq",
    DELETE_FAQ_MODAL_TITLE: "Delete FAQ draft",
    DELETE_FAQ_MODAL_TEXT: "<div>Are you sure you want to delete the Faq draft? <br />Please be reminded that this action cannot be undone.</div>",
    PREVIEW_FAQ_MODAL_TITLE: "Preview FAQ",
    PUBLISH_FAQ_LOCALE_MODAL_TEXT: "<br /><br />Are you sure you want to publish this FAQ? <br />Please be reminded that this action cannot be undone.",
    DELETE_DRAFT: "Delete Draft",
    PREVIEW: "Preview",
    FILL_UP_ALL_QUESTIONS: "Please fill up all the questions.",
    FILL_UP_ANSWER: "Please fill up the answer.",
    DELETE_NODE_MODAL_TITLE: "Delete Node",
    DELETE_NODE_MODAL_TEXT: "Are you sure you want to delete this node?",
    APPLICATION_CONFIGURATION: "Application Configuration",
    CODE: "Code",
    FAQ_DRAFT: "FAQ Draft",
    TRANSLATION: "Translation",
    APPLICATION_LANGUAGE: "Application Language",
    APPLICATION_TRANSLATION: "Application Translation",
    NO_QUESTIONS_AVAILABLE: "No questions available",
    DISPLAY_ANSWER: "Display Answer",
    ADD_PUBLIC_HOLIDAY: "Add Public Holiday",
    NAME_OF_PH: "Name of Public Holiday",
    PUBLIC_HOLIDAY: "Public Holiday",
    REMARKS: "Remarks",
    PUBLIC_HOLIDAY_MANAGEMENT: "Public Holiday Management",
    YEAR: "Year",
    ALL_YEARS: "All Years",
    NEW_PUBLIC_HOLIDAY: "New Public Holiday",
    PH_NO_RECORD: "No Public Holiday records found",
    DELETE_PH_MODAL_TEXT: "Are you sure you want to delete this public holiday?",
    DELETE_PH_MODAL_TITLE: "Delete Public Holiday",
    UPDATE_PUBLIC_HOLIDAY: "Update Public Holiday",
    SMS_TEMPLATE_MANAGEMENT: "SMS Template Management",
    SMS_TEMPLATE: "SMS Template",
    NEW_SMS_TEMPLATE: "New SMS Template",
    SMSTEMPLATE_NO_RECORD: "No SMS Template records found",
    TEMPLATE_NAME: "Template Name",
    VERSION: "Version",
    DELETE_SMSTEMP_MODAL_TITLE: "Delete SMS Template",
    DELETE_SMSTEMP_MODAL_TEXT: "<div>Are you sure you want to delete this SMS Template? <br />Please be reminded that this action cannot be undone.</div>",
    CREATE_SMS_TEMPLATE: "Create SMS Template",
    HELPTEXT_NOTITEMP_CATEGORY: "Please choose an existing category, or type a new one then press enter to create a new option.",
    TEMPLATE_SUBJECT: "Template Subject",
    TEMPLATE_BODY: "Template Body",
    HELPTEXT_NOTITEMP_TEMPLATESUBJECT: "Please type @ to get the list of available placeholders.",
    HELPTEXT_NOTITEMP_TEMPLATEBODY: "Please type @ to get the list of available placeholders.",
    VIEW_SMS_TEMPLATE: "View SMS Template",
    PUBLISH_BY_SCOPE: "Publish by Scope",
    PUBLISH_BY_APP_MODAL_TEXT: "<div>Note: Choosing <b>No</b> for Publish by Scope,<br />Translation Data will be published by <b>Application</b> level.<br />" + 
        "Please also note that Scope(s) with no translations will not be displayed in the list of scopes.<br /><br /></div>",
    PUBLISH_BY_SCOPE_MODAL_TEXT: "<div>Note: Choosing <b>Yes</b> for Publish by Scope,<br />Translation Data will be published by <b>Scope</b> level.<br /><br /></div>",
    EMAIL_TEMPLATE: "Email Template",
    CREATE_EMAIL_TEMPLATE: "Create Email Template",
    NEW_EMAIL_TEMPLATE: "New Email Template",
    EMAILTEMPLATE_NO_RECORD: "No Email Template records found",
}
