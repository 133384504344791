import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { CANCEL_LICENCE_URL, DASHBOARD_PATH, GENERAL_INFORMATION_VALIDFORMTYPE, GET_CANCELLATION_DATE_URL, GET_LICENCE_DETAILS_URL, GET_SFORM_FORM_DATA_FROM_APP_TYPE_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { BLS_FORM_APP_TYPES, DELETE_FILES_ARRAY, SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, WIZARD_TAB_NAMES } from '../../Shared/Constants/LanguageKeys';
import { SMARTForm, toastError } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import { getFormNames, getRemoteList, getTableListValues, premiseInformation } from '../FormComponents/FormUtils';
import { GeneralInformationComponent } from '../FormComponents/GeneralInformation/GeneralInformationComponent';
import { getAddressTypeOptions } from '../FormComponents/GeneralInformation/Utils.js';
import { createValidationSchemaForDeclaration, createValidationSchemaForGeneralInfo, createValidationSchemaForLicenceFields, createValidationSchemaForSupportingDocuments } from '../FormComponents/LicenceApplicationInformation/ValidationSchema';
import SupportingDocuments from '../FormComponents/SupportingDocuments';
import CancelLicenceReviewAndSubmit from './CancelLicenceReviewAndSubmit';
import FormCancel from './FormCancel';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.WIZARD;
const FORM_CONTEXT = 'CancelLicence';
const SUCCESS_URL = '/submissionSuccess';

const PAYMENT_TAB_DISPLAY = false;

class CancelLicence extends React.Component {
    constructor(props) {
        super(props);

        if (history.location.state) {
            this.state = {
                licenceId: history.location.state.IntroductoryNotes.LicenceId,
                selectedIdTypeArr: [],
                idNumberArr: [],
                addressTypeOptions: getAddressTypeOptions(),
                values: null,
                licence: null,
                formNames: null,
                optionDataList: {}
            };
        }
    }
    async componentDidMount() {
        const { loader } = this.props;
        const { licenceId } = this.state;
        loader.start();

        await this.getLicenceData(licenceId, loader);
    }

    async getLicenceCancellationData(licenceID, loader, appRefNum) {
        const { t } = this.props;
        const params = {
            licenceId: licenceID,
            appType: BLS_FORM_APP_TYPES.CANCEL
        };
        const response = await fetchRequest(GET_SFORM_FORM_DATA_FROM_APP_TYPE_URL, getParams(params));
        const { IsSuccess, Data, Messages } = response.body;

        if (response.success && IsSuccess) {
            const licenceDetails = Object.assign({}, Data, { FormData: JSON.parse(Data.FormData) });

            await this.getOptionsDataList(licenceDetails);

            this.setState(() => {
                return {
                    licenceDetails,
                    formNames: getFormNames(this.state.licence.LicenceName, licenceDetails.DocumentList, PAYMENT_TAB_DISPLAY),
                    values: this.getFilledValues(licenceDetails.FormDefinition, licenceDetails.FormData,
                        licenceDetails.GeneralDefinition, licenceDetails.DocumentList, licenceDetails.GeneralDefinition.FormType, appRefNum),
                    validationSchema: this.createValidationSchema(licenceDetails, licenceDetails.GeneralDefinition.FormType),
                    declarations: licenceDetails.DeclarationList,
                    generalInformationType: licenceDetails.GeneralDefinition.FormType
                };
            }, () => {
                loader.done();
            });
        } else {
            loader.done();
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    }

    async getLicenceData(licenceId, loader) {
        const response = await fetchRequest(GET_LICENCE_DETAILS_URL + licenceId, getParams(), false);
        const { IsSuccess, Data } = response.body;
        if (IsSuccess) {
            this.setState(() => {
                return {
                    licence: Object.assign({}, Data, { LicenceData: JSON.parse(Data.LicenceData) }),
                    licenceDetailsLoaded: true
                };
            }, async () => {
                await this.getLicenceCancellationData(this.state.licence.LicenceID, loader, this.state.licence.ApplicationRefNumber);
                this.getCancellationMinimumDate(this.state.licence.LicenceID, loader);
            });
        }
    }

    getOptionsDataList = async devData => {
        const { t } = this.props;
        const listOfCategory = [];
        //loop through all the fields to collate all the options required for the entire form

        //GeneralDefinition
        devData.GeneralDefinition.Sections.forEach(section => {
            section.Fields.forEach(field => {
                const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(field.DataParameters.Category) < 0) {
                    listOfCategory.push(field.DataParameters.Category);
                }
            });
        });

        //FormDefinition
        devData.FormDefinition.Sections.forEach(formDefSection => {
            formDefSection.Fields && formDefSection.Fields.forEach(formDefField => {
                const isRemote = formDefField.DataSource !== undefined && formDefField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefField.DataParameters.Category);
                }
            });
            formDefSection.Table && formDefSection.Table.Fields.forEach(formDefTableField => {
                const isRemote = formDefTableField.DataSource !== undefined && formDefTableField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefTableField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefTableField.DataParameters.Category);
                }
            });
        });

        let totalOptionsList = null;
        if (listOfCategory.length !== 0) {
            totalOptionsList = await getRemoteList(t, listOfCategory);
        }
        this.setState({
            optionDataList: totalOptionsList
        });
    }

    async getCancellationMinimumDate(licenceId) {
        const response = await fetchRequest(GET_CANCELLATION_DATE_URL + licenceId, getParams(), false);

        const { IsSuccess, Data } = response.body;
        if (IsSuccess) {
            this.setState(() => {
                return {
                    cancellationDate: Data
                };
            });
        }
    }

    getFilledValues = (formDefinition, formData, generalInfoFields, documentList, generalInformationType, appRefNumber) => {
        const licenceFieldNames = {};
        const formTypeNames = {
            FormTypes: [formDefinition.FormType, GENERAL_INFORMATION_VALIDFORMTYPE]
        };
        const licenceInfo = formData.LicenceInformation;
        const generalInfo = formData.GeneralInformation;
        const generalFieldNames = {
            //get filled values for general info fields
            GeneralInformation: {}
        };
        generalInfoFields.Sections.forEach(section => {
            section.Fields.forEach(field => {
                if (generalInfo[field.Name] !== undefined && generalInfo[field.Name] !== '') {
                    generalFieldNames.GeneralInformation[field.Name] = generalInfo[field.Name];
                }
                else {
                    generalFieldNames.GeneralInformation[field.Name] = '';
                }
            });
        });

        licenceFieldNames[formDefinition.FormType] = {};

        const tableValueKeys = Object.keys(licenceInfo);

        //Default cancel values
        licenceFieldNames[formDefinition.FormType]['Remarks'] = '';
        licenceFieldNames[formDefinition.FormType]['EndDate'] = '';

        //Default premise information (for display only)
        const PremiseInformation = premiseInformation;

        PremiseInformation.forEach(field => {
            if (licenceInfo[field] !== undefined && licenceInfo[field] !== '') {
                licenceFieldNames[formDefinition.FormType][field] = licenceInfo[field];
            }
            else {
                licenceFieldNames[formDefinition.FormType][field] = '';
            }
        });


        //get filled values for licence fields
        formDefinition.Sections.forEach(section => {
            section.Fields && section.Fields.forEach(field => {
                if (licenceInfo[field.Name] !== undefined && licenceInfo[field.Name] !== '') {
                    licenceFieldNames[formDefinition.FormType][field.Name] = licenceInfo[field.Name];
                }
                else {
                    licenceFieldNames[formDefinition.FormType][field.Name] = '';
                }
            });

            if (section.Table) {
                licenceFieldNames[formDefinition.FormType][section.Name] = [];
                const sectionTableList = getTableListValues(tableValueKeys, section.Name);

                sectionTableList && sectionTableList[section.Name].forEach((row, rindex) => {
                    const newTableArrObj = {};
                    section.Table.Fields && section.Table.Fields.forEach(field => {
                        if (licenceInfo[`${section.Name}.${rindex}.${field.Name}`] !== undefined && licenceInfo[`${section.Name}.${rindex}.${field.Name}`] !== '') {
                            newTableArrObj[field.Name] = licenceInfo[`${section.Name}.${rindex}.${field.Name}`];
                        }
                        else {
                            newTableArrObj[field.Name] = '';
                        }
                    });
                    licenceFieldNames[formDefinition.FormType][section.Name].push(newTableArrObj);
                });
            }
        });

        /*old supporting documents should not be extracted*/
        const supportingDocumentNames = {
            SupportingDocument: {}
        };
        documentList.forEach(field => {
            supportingDocumentNames.SupportingDocument[field.Name] = [];
        });

        const finalFields = {
            ...formTypeNames, ...generalFieldNames, ...licenceFieldNames, ...supportingDocumentNames,
            GeneralInformationType: generalInformationType
        };
        finalFields[DELETE_FILES_ARRAY] = [];
        finalFields.AppRefNum = appRefNumber;
        return finalFields;
    }

    createValidationSchema = (devData, generalInformationType) => {
        const { t } = this.props;
        const validationObject = {};
        /***********************/
        /*** General Section ***/
        /***********************/
        validationObject.GeneralInformation = createValidationSchemaForGeneralInfo(t, generalInformationType);

        /***********************/
        /*** Licence Section ***/
        /***********************/
        validationObject[devData.FormDefinition.FormType] = createValidationSchemaForLicenceFields(t, devData.FormDefinition);

        //Default cancel form validation
        validationObject[devData.FormDefinition.FormType]['Remarks'] = Yup.string();
        validationObject[devData.FormDefinition.FormType]['EndDate'] = Yup.string().required((ERROR.REQUIRED2) + t(ERROR.REQUIRED));

        // /***********************************/
        // /*** Supporting Document Section ***/
        // /***********************************/
        validationObject.SupportingDocument = createValidationSchemaForSupportingDocuments(t, devData.DocumentList);

        /***************************/
        /*** Declaration Section ***/
        /***************************/
        validationObject.DeclarationAgreement = createValidationSchemaForDeclaration(t);

        return Yup.object().shape(validationObject);
    }

    render() {
        const { cancellationDate, licenceDetails, values, licence, formNames, generalInformationType, declarations, optionDataList } = this.state;
        const { t } = this.props;
        
        if (!values || !licence || !formNames || !optionDataList) {
            return null;
        } else {
            const GeneralInformationComponentInput = GeneralInformationComponent[generalInformationType];

            return (
                <>
                    <div className="main-content">
                        <nav aria-label="breadcrumb" className="breadcrumb-main">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</Link></li>
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_CANCELLICENCE)}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{licence.LicenceName}</li>
                                </ol>
                            </div>
                        </nav>
                        <Layout type={LAYOUT_TYPE.FORM} footer>
                            <SMARTForm
                                formContext={FORM_CONTEXT}
                                className="container"
                                sectionNames={formNames}
                                defaultSection={Object.keys(formNames)[0]}
                                formValues={this.state.values}
                                validationSchema={this.state.validationSchema}
                                validateOnSubmit={true}
                                serverURL={CANCEL_LICENCE_URL}
                                nextURL={SUCCESS_URL}
                            >
                                {({ toggleSection, toggleAllSections, sectionState, onChange, onChangeField, values,
                                    submitForm, errors, currentStep, prevStep, nextStep, modalError, modalStateError }) => {

                                    const smartFormValues = {
                                        toggleSection,
                                        toggleAllSections,
                                        values,
                                        onChange,
                                        onChangeField,
                                        errors,
                                        modalError,
                                        modalStateError
                                    };
                                    return (
                                        <React.Fragment>
                                            <Col>
                                                <SectionWrapper
                                                    type={SECTION_LAYOUT_TYPE}
                                                    tabs={formNames}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}>
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}
                                                        key={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}>
                                                        <GeneralInformationComponentInput
                                                            smartFormValues={smartFormValues}
                                                            state={this.state}
                                                        />
                                                    </Section>
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={licence.LicenceName}
                                                        key={licence.LicenceName}>
                                                        <FormCancel
                                                            smartFormValues={smartFormValues}
                                                            formDefinition={licenceDetails.FormDefinition}
                                                            formName={licence.LicenceName}
                                                            licence={licence}
                                                            formType={licenceDetails.FormDefinition.FormType}
                                                            cancellationDate={cancellationDate}
                                                            optionDataList={optionDataList}
                                                        />
                                                    </Section>
                                                    {licenceDetails && licenceDetails.DocumentList && licenceDetails.DocumentList.length > 0 &&
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}
                                                            key={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}>
                                                            <SupportingDocuments
                                                                smartFormValues={smartFormValues}
                                                                files={licenceDetails.DocumentList}
                                                            />
                                                        </Section>
                                                    }
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}
                                                        key={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}>
                                                        <CancelLicenceReviewAndSubmit
                                                            displayDocuments={licenceDetails && licenceDetails.DocumentList && licenceDetails.DocumentList.length > 0}
                                                            licence={licence}
                                                            smartFormValues={smartFormValues}
                                                            applicationDetails={licenceDetails}
                                                            declarations={declarations}
                                                        />
                                                    </Section>
                                                </SectionWrapper>

                                            </Col>
                                            <PageFooter type={LAYOUT_TYPE.FORM}>
                                                <div className="button-center">

                                                    {/* Wizard Previous Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== 0)) &&
                                                        <Button color="backward" size="sm" type="button" onClick={prevStep}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_PREVIOUS)}</Button>}

                                                    {/* Submit Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep === Object.keys(this.state.formNames).length - 1)) ?
                                                        <Button color="forward" size="sm" type="button" onClick={submitForm}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_SUBMIT)}</Button> :
                                                        ((LAYOUT_TYPE.FORM_CONTENT !== 3) &&
                                                            <Button color="forward" size="sm" type="button" onClick={submitForm}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_SUBMIT)}</Button>)}

                                                    {/* Wizard Next Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== Object.keys(this.state.formNames).length - 1)) &&
                                                        <Button color="forward" size="sm" type="button" onClick={nextStep}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_NEXT)}</Button>}
                                                </div>
                                            </PageFooter>
                                        </React.Fragment>
                                    );
                                }
                                }

                            </SMARTForm>
                        </Layout>
                    </div>

                </>
            );
        }
    }
}

export default withTranslation()(withLoader(CancelLicence));