import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { compose } from 'recompose';
import { LAYOUT_TYPE } from '../../App/AppSettings';
import { FooterContentExtension } from '../../Extensions/FooterContentExtension';
import Layout from '../../Layout';
import { AuthConsumer } from '../../Shared/Authentication/AuthContext';
import { withLoader } from '../../Shared/Loading';
import Applications from './Applications';
import Licences from './Licences';
import Correspondences from './Correspondences';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import Entities from './Entities';

const withAuthContext = (WrappedComponent) => {
    class AuthContext extends React.Component {
        render() {
            return (
                <AuthConsumer>
                    {({ profile }) => (
                        <WrappedComponent
                            profile={profile}
                            {...this.props}
                        />
                    )}
                </AuthConsumer>
            );
        }
    }
    return AuthContext;
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        let tabs = {
            APPLICATIONS: 'Applications',
            SERVICES: 'Services',
            CORRESPONDENCES: 'Correspondences',
            ENTITIES: 'Entities'
        };
        this.state = {
            tabs: tabs,
            activeTab: tabs['APPLICATIONS'],
        };
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    toggleTab = (tab) => {
        this.setState({
            activeTab: tab
        });
    }

    renderFooterExtension = () => {
        return (
            <FooterContentExtension />
        );
    }

    render() {
        const { tabs, activeTab } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true} footerExtension={this.renderFooterExtension()}>
                    <div className='main-content'>

                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item active single' aria-current='page'>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_TITLE_WORKSPACE)}</li>
                                </ol>
                            </div>
                        </nav>
                        <div className='container'>
                            <div className='dashboard'>

                                <Nav tabs className='main-tab tab-nav-tabs'>
                                    <NavItem key={0} className='tab-nav-item'>
                                        <NavLink
                                            className={'tab-header tab-nav-link'}
                                            active={(activeTab === tabs['APPLICATIONS'])}
                                            id='Applications-tab'
                                            onClick={() => this.toggleTab(tabs['APPLICATIONS'])}
                                        >
                                            <span><i className='fas fa-list-alt'></i></span>
                                            {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_MENU_APPLICATIONS)}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem key={1} className='tab-nav-item'>
                                        <NavLink
                                            className={'tab-header tab-nav-link'}
                                            id='Licences-tab'
                                            active={(activeTab === tabs['SERVICES'])}
                                            onClick={() => this.toggleTab(tabs['SERVICES'])}
                                        >
                                            <span><i className='fas fa-id-card'></i></span>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_MENU_ISSUANCE)}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem key={2} className='tab-nav-item'>
                                        <NavLink
                                            className={'tab-header tab-nav-link'}
                                            active={(activeTab === tabs['CORRESPONDENCES'])}
                                            id='Correspondences-tab'
                                            onClick={() => this.toggleTab(tabs['CORRESPONDENCES'])}
                                        >
                                            <span><i className='fas fa-list-ul'></i></span>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_MENU_CORRESPONDENCES)}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem key={3} className='tab-nav-item'>
                                        <NavLink
                                            className={'tab-header tab-nav-link'}
                                            active={(activeTab === tabs['ENTITIES'])}
                                            id='Entities-tab'
                                            onClick={() => this.toggleTab(tabs['ENTITIES'])}
                                        >
                                            <span><i className='far fa-store'></i></span>
                                            {/* {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_MENU_CORRESPONDENCES)} */}
                                            Entity
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                {activeTab === tabs['APPLICATIONS']
                                    &&
                                    <Applications
                                    />
                                }
                                {
                                    activeTab === tabs['SERVICES']
                                    &&
                                    <Licences />
                                }

                                {
                                    activeTab === tabs['CORRESPONDENCES']
                                    &&
                                    <Correspondences />
                                }
                                {
                                    activeTab === tabs['ENTITIES']
                                    &&
                                    <Entities />
                                }
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withAuthContext(compose(withLoader)(Dashboard)));