import qs from 'query-string';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import { IS_DEMO, LAYOUT_TYPE, SEARCH_ALL_AGENCY_PATH, SEARCH_ALL_INDUSTRY_PATH, SEARCH_ALL_LICENCE_PATH, SEARCH_ALL_LICENCE_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { DIRECTIONS } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DirectionContextConsumer } from '../../Shared/DirectionContext';
import { withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import PreRequisiteResultContainer from './PreRequisiteResultContainer';

const RETRIEVE_URL = SEARCH_ALL_LICENCE_URL;
//Catches an 'undefined value error' if user lands on redirect page or passes an empty value from the Home.
const INITIAL_VALUES = '';
const FORM_INITIAL_VALUES = {
    title: ''
};

class SearchLicence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: undefined
        };

        if (history.location.state) {
            this.state = { values: history.location.state.title };
        }

        if (this.props.location.search) {
            //Passes search query when 'Enter' button is clicked
            const params = new URLSearchParams(this.props.location.search);
            const title = params.get('title');
            if (title !== '') {
                this.state = { values: title };
            } else {
                this.state = { values: INITIAL_VALUES };
            }
        }

        if (this.state.values === undefined || this.state.values === '') {
            this.state = {
                values: INITIAL_VALUES
            };
        }

        this.state = {
            values: this.state.values,
            licences: []
        };

        // this.searchValue = this.searchValue.bind(this)
        this.displayValue = '';
    }

    componentDidMount() {
        this.getLicences();
    }

    getLicences = async () => {
        const { loader } = this.props;
        const { currLang } = this.props.context;
        const parsedStr = qs.parse(this.props.history.location.search);
        if (parsedStr.lang !== currLang || parsedStr.title !== this.state.values) {
            this.props.history.replace({ pathname: SEARCH_ALL_LICENCE_PATH, search: '?title=' + this.state.values + "&lang=" + currLang });
        } else {
            const queryString = Object.assign({}, FORM_INITIAL_VALUES, { title: this.state.values, lang: currLang });
            // Start loading
            loader.start();
            const response = await fetchRequest(RETRIEVE_URL, getParams(queryString), IS_DEMO);
            const { Data, IsSuccess } = response.body;

            if (IsSuccess) {
                if (Data.length !== 0) {
                    this.displayValue = this.state.values;

                    this.setState(() => {
                        return { licences: Data };
                    });
                }
                else {
                    this.setState(() => {
                        return { licences: [] };
                    });
                }
            }
            // End loading
            loader.done();
        }
    };

    displayResultContainer = () => {
        return (
            <PreRequisiteResultContainer
                licences={this.state.licences}
                value={this.state.values}
                showRecommendBanner={true}
            />
        );
    };
    inputOnChangeHandler = (e) => {
        if (e.target.value === undefined || e.target.value === '') {
            this.setState({
                values: INITIAL_VALUES
            });
        }
        else {
            this.setState({
                values: e.target.value
            });
        }
    };

    searchValue = () => {
        const values = this.state.values;
        if (values === undefined || values === '') {
            this.setState({
                values: INITIAL_VALUES
            });
        }
        this.getLicences();
    };

    inputKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            const values = this.state.values;
            if (values === undefined || values === '') {
                this.setState({
                    values: INITIAL_VALUES
                });
            }
            this.getLicences();
        }
    };


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className='main-content'>
                        <div className='find-apply'>
                            <div className='container'>
                                <Row>
                                    <Col lg='6'>
                                        <div className='licvence-serach'>
                                            <h3>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SEARCHTITLE)}</h3>
                                            <div className='input-group'>
                                                <Input
                                                    dir={DIRECTIONS.AUTO}
                                                    type='text'
                                                    value={this.state.values}
                                                    name='title'
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_MODAL_SEARCHBOX)}
                                                    onChange={this.inputOnChangeHandler}
                                                    minLength={0}
                                                    maxLength={140}
                                                    onKeyDown={this.inputKeyDownHandler}
                                                />                                                    <span className='input-group-btn'>
                                                    <button className='btn btn-orange' type='button' onClick={this.searchValue}><i className='fal fa-search'></i></button>
                                                </span>
                                            </div>

                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div className='browse-by'>

                                            <button className='by-gov-agency'
                                                onClick={() => navigateTo(SEARCH_ALL_AGENCY_PATH)}>
                                                {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBY)}<strong>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_GOVAGENCY)}</strong></button>
                                            <button className='by-business-intent'
                                                onClick={() => navigateTo(SEARCH_ALL_INDUSTRY_PATH)}>
                                                {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_BROWSEBY)}<strong>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_INDUSTRY)}</strong></button>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='search-result'>
                                {this.displayResultContainer()}
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ currLang }) => (
                        <WrappedComponent
                            context={{ currLang }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};
export default withTranslation()(withRouter(withSMARTWrapper(withDirectionContext(SearchLicence))));

