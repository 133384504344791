// Const: Variable Length for Field/Label
export const FIELD_SIZE = {
    NONE: 0,
    XSMALL: 2,
    SMALL: 3,
    MEDIUM: 5,
    LARGE: 7,
    XLARGE: 9
};

// Const: Field/Label Orientation
export const ORIENTATION = {
    ROW: 'ROW',
    INLINE: 'INLINE'
};

// Const: Filters
export const FILTER = "Filter";
export const SELECT_FILTERS = "SelectFilters";

// Const: Response Object
export const Response = (responseData, isSuccess = false, status = 200) => {
    return {
        success: isSuccess,
        body: responseData,
        status: status,
    }
};

export const FILE_UPLOAD_SECTION_FILES = "FileUploadSectionFiles";
export const CART_LICENCES = "cartlicences";
export const CART_ACTION_REMOVE = "Remove_Licence";
export const CART_ACTION_ADD = "Add_Licence";
export const QN_ACTION_REMOVE = "Remove_Question";
export const QN_ACTION_ADD = "Add_Question";

export const DELETE_FILES_ARRAY = "SMARTDeleteFilesArray";

export const BLS_LICENCE_NAME = {
    SPF_LIQUOR_1TO4: 'Liquor Licence (Class 1A, 1B, 2A, 2B, 3A, 3B, 4)',
    URA_CHANGE_USE_APPROVAL: 'Change of use approval'
}

export const BLS_FORM_TYPES = {
    SPF_LIQUOR_1TO4: 'SpfLiquor1To4',
    SPF_LIQUOR_1TO4_NEW: 'SpfLiquor1To4New',
    SPF_LIQUOR_1TO4_AMEND: 'SpfLiquor1To4Amend',
    SPF_LIQUOR_1TO4_RENEW: 'SpfLiquor1To4Renew',
    SPF_LIQUOR_1TO4_CANCEL: 'SpfLiquor1To4Cancel',
    URA_CHANGE_USE_APPROVAL: 'UraChangeUseApproval',
    URA_CHANGE_USE_APPROVAL_NEW: 'UraChangeUseApprovalNew',
    URA_CHANGE_USE_APPROVAL_AMEND: 'UraChangeUseApprovalAmend',
    URA_CHANGE_USE_APPROVAL_RENEW: 'UraChangeUseApprovalRenew',
    URA_CHANGE_USE_APPROVAL_CANCEL: 'UraChangeUseApprovalCancel'
}

export const BLS_LICENCE_TYPES = {
    SPF_LIQUOR_1TO4: 'SPF Liquor Licence',
    URA_CHANGE_USE_APPROVAL: 'URA Change Of Premise Usage Licence'
}

export const BLS_FORM_APP_TYPES = {
    APPLY: "Apply",
    AMEND: "Amend",
    RENEW: "Renew",
    CANCEL: "Cancel"
}

export const BLS_RFA_TYPES = {
    REQUEST_FOR_AMENDMENT: "Request For Amendment",
    REQUEST_FOR_CLARIFICATION: "Request For Clarification",
}

export const SECTION_WRAPPER_TYPES = {
    WIZARD: "Wizard",
    TAB: "Tab",
    ACCORDION: "Accordion",
    ACCORDION_MODAL: "AccordionModal"
}

export const OPTION_DATA_CODE_CATEGORY = {
    COUNTRY: "Country",
    SALUTATION: "Salutation",
    ID_TYPE: "IdType",
}

export const BUTTON_TYPE = {
    ACTIVE: "btn active",
    INACTIVE: "btn"
}

export const MIN_FILTER_CHARS = '3';
export const DIRECTIONS = {
    RTL: 'rtl',
    LTR: 'ltr',
    AUTO: 'auto'
}

export const includesMinCharValidation = (currLang) => {
    const AR_LANG_CODE = 'ar';
    return !currLang.startsWith(AR_LANG_CODE);
}

export const PARAGRAPH_TYPES = {
    DEFAULT: 'DEFAULT',
    WITH_ICON: 'WITH_ICON'
};

//Const: Rich text toolbar
export const RICHTEXT = {
    DEFAULT: {
        options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image'],
    }
}
