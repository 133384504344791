import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';
import { convertToFormData } from '../../Shared/Forms/FormUtils';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { CONTENT_TYPE, fetchRequest, formParams, navigateTo, navigateToPageWithFormValues } from '../Actions';
import { DELETE_FILES_ARRAY } from '../Constants';
import { ERROR, SUCCESS } from '../Constants/LanguageKeys';
import { withFormAlert } from '../Forms';
import { withLoader } from '../Loading';
import { toastError, toastSuccess } from './Toaster';

// Const: Various states of the form
const FORM_STATE = {
    DRAFT: "form_state_draft",
    NAVIGATE: "form_state_navigate",
    SUBMIT: "form_state_submit",
    PLAIN_SUBMIT: "form_state_plain_submit"
};

const withLicenceContext = (WrappedComponent) => {
    class LicenceContext extends React.Component {
        render() {
            return (
                <LicenceCartContext.Consumer>
                    {({ clearCart }) => (
                        <WrappedComponent
                            context={{
                                clearCart
                            }}
                            {...this.props}
                        />
                    )}
                </LicenceCartContext.Consumer>
            );
        }
    }

    return LicenceContext;
};

// HoC function to wrap all generated forms
export const withBaseForm = (FormComponent) => {
    class BaseForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                sectionState: this.props.defaultSection,     // Section: Accordion or Tab
                referenceNo: "",
                formState: undefined,
                currentStep: 0,
                formID: 0,
                toggleToResetForm: false,
                formikValidationSchema: this.props.validationSchema,
                previousDeleteArray: []
            };
        }

        // Fn: Toggles state of Section dependently based on given sectionName
        toggleSectionDependentState = (sectionName) => {
            // Set section state as clicked section name
            let newSectionName = sectionName;

            // If hide/uncollapse is allowed & collapsed state is clicked, uncollapse it
            if (this.state.sectionState === sectionName) {
                newSectionName = '';
            }
            // Update new sectionState
            this.setState({ sectionState: newSectionName });
        };

        // Fn: Toggles state of Section independently based on given attributes in sections & sectionName
        toggleSectionIndividualState = (sections, sectionName) => {
            let newSectionName = sectionName;
            let propertyHolder; // react has problems setting state for nested objects, so need a holder to help setState

            Object.entries(sections).forEach(([key, value]) => {
                if (newSectionName === value.title) {
                    propertyHolder = value;
                    propertyHolder.status = !value.status;
                    this.setState({ propertyHolder });
                }
            });
        }

        // Fn: Changes state of section collapsing based on given sectionState
        toggleSection = (isCollapsible, isIndividual, sectionName, sections) => {

            // Set section state as clicked section name
            let newSectionName = sectionName;

            // If section is collapsible ()
            if (isCollapsible) {
                if (isIndividual) {
                    this.toggleSectionIndividualState(sections, sectionName)
                } else {
                    this.toggleSectionDependentState(newSectionName);
                }
            } else {
                this.setState({ sectionState: newSectionName });
                this.setCurrentStepFromSectionName(newSectionName);
            }
        };

        toggleAllSections = (sections, isCollapsed) => {
            let propertyHolder; // react has problems setting state for nested objects, so need a holder to help setState
            Object.entries(sections).forEach(([key, value]) => {
                propertyHolder = value;
                propertyHolder.status = !isCollapsed;
                this.setState({ propertyHolder });
            });
        }

        setCurrentStepFromSectionName(newSectionName) {
            let tabsArray = Object.values(this.props.sectionNames);

            // Loop through array to find index
            for (let i = 0; i < tabsArray.length; i++) {
                if (tabsArray[i] === newSectionName) {
                    this.setState({ currentStep: i })
                    break;
                }
            }
        }

        // Fn: Handle SMARTForm/SMARTView Submission
        submitForm = async (nextURL, values, serverURL, isDummy, formikActions) => {
            const { loader, submitCallback, resetFormAfterSubmit, passFormValuesToNextPage, formParams, defaultSection, t, formIODataArray } = this.props;

            // Start loading
            loader.start();

            if (formIODataArray !== undefined && formIODataArray !== null) {
                values = Object.assign(values, formIODataArray);
            }

            // Get results of server-side form posting
            if (formParams.contentType === CONTENT_TYPE.FORM_DATA) {
                formParams.data = convertToFormData(values);
            } else {
                formParams.data = JSON.stringify(values);
            }

            let response = await fetchRequest(serverURL, formParams, isDummy);

            // End loading
            loader.done();

            // If user specify to reset Form after submitting
            if (resetFormAfterSubmit && response.body.IsSuccess) {
                // Reset form
                if (defaultSection) {
                    this.toggleSection(false, false, this.props.defaultSection, null);
                }
                formikActions.resetForm();
                this.setState((prevState) => ({
                    toggleToResetForm: !prevState.toggleToResetForm
                }));
            }

            // If submitCallback prop is used
            if (submitCallback) {
                // Execute user's callback(s)
                submitCallback({ response });
            }

            // Otherwise, execute our default response processing logic
            else {
                // HTML status response: Good
                if (response.success) {
                    const { IsSuccess, Messages, RedirectURL } = response.body;

                    // Server response: Transaction Successful
                    if (IsSuccess) {
                        this.props.context.clearCart()
                        // 1st Priority: Check & process RedirectURL
                        if (RedirectURL) {
                            // Navigate to given RedirectURL
                            navigateTo(RedirectURL, response.body);
                        }

                        // 2nd Priority: Check & process nextURL
                        else if (nextURL) {
                            // Navigate to given nextURL
                            if (passFormValuesToNextPage) {
                                //Pass form data
                                navigateToPageWithFormValues(nextURL, values);
                            } else {
                                //Pass response data
                                navigateTo(nextURL, response.body);
                            }
                        }

                        // Defaulting action
                        else {
                            // Show Success Toaster
                            toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
                        }
                    }

                    // Server Response: Transaction Unsuccessful
                    else {
                        const { formAlert } = this.props;

                        // Display Form Alert
                        formAlert.showAlert(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
                    }
                }

                // HTML status response: Bad
                else {
                    // Show Error Toaster
                    toastError(t(ERROR.FORM_DEFAULT_FAIL));
                }
            }
        };

        // Fn: Handle SMARTForm/SMARTView Draft Submission 
        submitDraft = async (values, draftServerURL, isDummy) => {

            const { loader, submitCallback, formParams, t, formIODataArray } = this.props;

            // Start loading
            loader.start();

            if (formIODataArray !== undefined && formIODataArray !== null) {
                values = Object.assign(values, formIODataArray);
            }

            // Get results of server-side form posting
            if (formParams.contentType === CONTENT_TYPE.FORM_DATA) {
                formParams.data = convertToFormData(values);
            } else {
                formParams.data = JSON.stringify(values);
            }

            let response = await fetchRequest(draftServerURL, formParams, isDummy);

            // If submitCallback prop is used
            if (submitCallback) {
                // Execute user's callback(s)
                submitCallback({ response });
            }

            // End loading
            loader.done();

            // HTML status response: Good
            if (response.success) {
                const { IsSuccess, Messages, FormID, FileKeyIDList } = response.body;

                // Server response: Transaction Successful
                if (IsSuccess) {
                    this.props.context.clearCart()
                    toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
                    this.setState({
                        formID: FormID,
                        fileKeyIDList: FileKeyIDList
                    });
                }

                // Server response: Transaction Unsuccessful
                else {
                    toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
                }
            }

            // HTML status response: Bad
            else {
                toastError(t(ERROR.DRAFT_SAVED_FAIL));
            }
        }

        // Fn: Perform per-submission processing
        preSubmitHandler = (values, handleSubmit, formState) => {

            const { showAlert } = this.props.formAlert;
            const { draftValidationSchema, validationSchema, t } = this.props;

            let FormID = (this.state.formID !== 0) ? this.state.formID : ((values.FormID !== undefined) ? values.FormID : 0);

            this.schemaHandler(formState, draftValidationSchema, validationSchema);

            switch (formState) {
                case FORM_STATE.NAVIGATE:
                case FORM_STATE.SUBMIT:
                case FORM_STATE.PLAIN_SUBMIT:
                default:
                    this.setState({
                        formID: FormID,
                        formState
                    }, () => handleSubmit());
                    break;

                case FORM_STATE.DRAFT:
                    (this.allFieldsEmpty(values)) ?
                        showAlert(t(ERROR.DRAFT_EMPTY_FIELD))
                        :
                        this.setState({
                            formID: FormID,
                            formState
                        }, () => handleSubmit());
                    break;
            }
        };

        // Fn: Perform on-submission processing
        onSubmitHandler = (values, formikActions) => {

            // Destructure props
            const { formContext, nextURL, serverURL, draftServerURL, isDummy } = this.props;
            const { hideAlert } = this.props.formAlert;
            // Hide alert
            hideAlert();

            switch (this.state.formState) {

                case FORM_STATE.NAVIGATE:
                    // Append ReferenceNo/ formID into values before navigating
                    _.assign(values, {
                        "ReferenceNo": this.state.referenceNo,
                        "FormID": this.state.formID
                    });

                    // Navigate to new page
                    navigateTo(nextURL, { [formContext]: values });
                    break;

                case FORM_STATE.DRAFT:
                    // Append IsDraft, FormType & formID into values before submitting
                    this.filterSentFiles(values)
                    _.assign(values, {
                        "IsDraft": true,
                        "FormType": formContext,
                        "FormID": this.state.formID,
                        [DELETE_FILES_ARRAY]: this.getDeletedFiles(values)
                    });

                    // Submit Draft
                    this.submitDraft(values, draftServerURL, isDummy);
                    break;

                case FORM_STATE.PLAIN_SUBMIT:
                    this.submitForm(nextURL, values, serverURL, isDummy, formikActions);
                    break;

                case FORM_STATE.SUBMIT:
                default:
                    // // Append IsDraft & FormType into values before submitting
                    this.filterSentFiles(values)
                    _.assign(values, {
                        "IsDraft": false,
                        "FormType": formContext,
                        "FormID": this.state.formID,
                        [DELETE_FILES_ARRAY]: this.getDeletedFiles(values)
                    });
                    // Send Delete Files Array

                    // Submit Form
                    this.submitForm(nextURL, values, serverURL, isDummy, formikActions);
                    break;
            }
        };

        // Fn: Display appropriate schema based on formState
        schemaHandler = (formState, draftValidationSchema, validationSchema) => {
            switch (formState) {
                case FORM_STATE.DRAFT:
                    this.setState({ formikValidationSchema: draftValidationSchema })
                    break;

                default:
                    this.setState({ formikValidationSchema: validationSchema })
                    break;
            }
        };

        // Fn: Check if all the fields in forms are empty (not filled up)
        allFieldsEmpty = (values) => {
            let isEmpty = true;

            if (this.getAnyNotEmptyArrayFromObject(values, "values").length > 0) {
                isEmpty = false;
            }

            return isEmpty;
        };

        getAnyNotEmptyArrayFromObject = (obj, path) => {
            //Recursively go into object to find non empty values. break when any non empty value is found

            let props = [];
            let predefinedValues = ["FormType", "FormTypes", "IsDraft"]
            for (var key in obj) {
                if (!predefinedValues.includes(key)) {
                    if (obj[key] instanceof Object) {
                        props.push.apply(props, this.getAnyNotEmptyArrayFromObject(obj[key], path + '.' + key));
                    }
                    else {
                        if (!_.isEmpty(obj[key])) {
                            props.push(path + '.' + key);
                            break;
                        }
                    }
                }
            }
            return props;
        }


        scrollToTop = () => {
            scroll.scrollToTop({
                duration: 0,
                smooth: false,
            });
        }

        prevStep = () => {
            let decrementStep = this.state.currentStep - 1;
            this.setState({ currentStep: decrementStep });
            this.toggleSection(false, false, Object.values(this.props.sectionNames)[decrementStep], null);
            this.scrollToTop();
        }


        nextStep = () => {
            let incrementStep = this.state.currentStep + 1;
            this.setState({ currentStep: incrementStep });
            this.toggleSection(false, false, Object.values(this.props.sectionNames)[incrementStep], null)
            this.scrollToTop();
        }

        // getDeletedFiles = ({FileUploadFiles, FileUploadSectionFiles}) => {
        //     if(FileUploadFiles !== undefined && FileUploadSectionFiles !== undefined) {
        //         let files = FileUploadFiles.concat(FileUploadSectionFiles);
        //         let deletedFiles = _.filter(files, 'IsDeleted');
        //         let deletedFilesId = _.map(deletedFiles, 'Id');

        //         return deletedFilesId;
        //     }
        //     return [];
        // }

        filterSentFiles = ({ SupportingDocument }) => {
            const { fileKeyIDList } = this.state

            if (fileKeyIDList && SupportingDocument) {
                for (let field in SupportingDocument) {
                    for (let fileEntry in SupportingDocument[field]) {
                        if (SupportingDocument.hasOwnProperty(field)) {
                            for (let DBfile in fileKeyIDList) {
                                if (SupportingDocument[field][fileEntry]["fileKey"] === fileKeyIDList[DBfile]["FileKey"]) {
                                    SupportingDocument[field][fileEntry]["file"] = null;
                                }
                            }
                        }
                    }
                }
            }
        }
        //used when draft is saved, then while on the same page, delete a file
        getDeletedFiles = ({ SupportingDocument, [DELETE_FILES_ARRAY]: deleteArray }) => {
            const { fileKeyIDList, previousDeleteArray } = this.state //only exists if save as draft and stay on the same page
            let deletedFilesArray
            if (deleteArray !== undefined && deleteArray !== null && Array.isArray(deleteArray)) {
                //filter away files that have already been deleted
                if (previousDeleteArray.length > 0) {
                    deletedFilesArray = deleteArray.filter(da => !previousDeleteArray.map(pda => pda).includes(da))
                }
                else {
                    deletedFilesArray = deleteArray;
                }
            }
            else {
                deletedFilesArray = [];
            }

            let fileDeleted = true;
            if (fileKeyIDList && SupportingDocument) {
                //for each file in the DB 
                for (let DBfile in fileKeyIDList) {
                    fileDeleted = true;
                    // for each field in the form definition
                    for (let field in SupportingDocument) {
                        //if the file can still be found in the list, the file has not been deleted
                        if (_.findIndex(SupportingDocument[field], { 'fileKey': fileKeyIDList[DBfile]["FileKey"] }) > -1) {
                            fileDeleted = false;
                            break;
                        }
                    }
                    if (fileDeleted) {
                        deletedFilesArray = deletedFilesArray.concat(fileKeyIDList[DBfile]["DynaFormDocumentIds"])
                    }
                }
            }
            //filter to get unique values only
            deletedFilesArray = Array.from(new Set(deletedFilesArray))

            //save the id of files that have  already been deleted, to use to filter away the next time
            this.setState({ previousDeleteArray: deletedFilesArray })
            return deletedFilesArray;
        }



        render() {
            const { formValues } = this.props;
            return (
                <Formik
                    validationSchema={this.state.formikValidationSchema}
                    initialValues={formValues}
                    enableReinitialize={true}
                    onSubmit={(values, formikActions) => this.onSubmitHandler(values, formikActions)}
                >
                    {(formikProps) => (
                        <FormComponent
                            {...this.props}
                            key={this.state.toggleToResetForm}
                            baseForm={{
                                sectionState: this.state.sectionState,
                                toggleSection: this.toggleSection,
                                toggleAllSections: this.toggleAllSections,
                                formik: formikProps,
                                submitPlainForm: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.PLAIN_SUBMIT),
                                submitForm: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.SUBMIT),
                                submitDraft: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.DRAFT),
                                navigateForm: () => this.preSubmitHandler(formikProps.values, formikProps.handleSubmit, FORM_STATE.NAVIGATE),
                                referenceNo: this.state.referenceNo,
                                currentStep: this.state.currentStep,
                                prevStep: this.prevStep,
                                nextStep: this.nextStep
                            }}
                        />
                    )}
                </Formik>
            );
        }
    }

    // PropTypes: For prop validation
    BaseForm.propTypes = {
        formContext: PropTypes.string,              // Note: The unique name/type of Form          
        formValues: PropTypes.object,               // Note: initial Form Values you would want SMARTForms to manage for you    
        draftValidationSchema: PropTypes.object,    // Note: Field validation schema for DRAFT SUBMISSION
        validationSchema: PropTypes.object,         // Note: Field validation schema for FORM SUBMISSION    
        defaultSection: PropTypes.string,           // Note: Default section to open when page is loaded
        nextURL: PropTypes.string,                  // Note: Next page URL you are navigating to
        serverURL: PropTypes.string,                // Note: Server endpoint URL you are requesting
        isDummy: PropTypes.bool,                    // Note: Allow user to fetch dummy json responses when need be
        submitCallback: PropTypes.func,             // Note: Allow user to inject callbacks after form submission
        resetFormAfterSubmit: PropTypes.bool        // Note: Allow fields to be reset after submit
    };

    // PropTypes: Defaulting value for optional props
    BaseForm.defaultProps = {
        formContext: 'default',
        formValues: {},
        draftValidationSchema: Yup.object().shape(),
        validationSchema: Yup.object().shape(),
        defaultSection: undefined,
        nextURL: '',
        serverURL: '',
        isDummy: false,
        formParams: formParams()
    };


    return (compose(withLoader, withFormAlert, withTranslation())(withLicenceContext(BaseForm)));
};