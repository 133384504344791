import { getIn } from 'formik';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { DATE_DISPLAY_FORMAT, TABLE_LAYOUT } from '../../App/AppSettings';
import { AccordionContainer, DateInput, Paragraph } from '../../Shared/Forms';
import RenderFieldComponent from '../FormComponents/RenderFieldComponent';
import { BigTable, SmallTable } from '../FormComponents/TableComponent';
import LicenceOverviewContents from '../LicenceDetails/LicenceOverviewContents';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';

const INPUT_SIZE = 3;
const LABEL_SIZE = 6;

class FormCancel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Check whether webpage is loaded
            isLoaded: false,
            sectionNames: undefined
        };
    }

    componentDidMount() {
        const { t, formDefinition } = this.props;
        const sectionNames = {};

        formDefinition.Sections.forEach(formSection => {
            sectionNames[formSection.Name] =
                { key: formSection.Name, title: t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, formSection.Name)), status: true };
        });

        sectionNames['LicenceOverview'] = { key: 'LicenceOverview', title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SERVICEOVERVIEW), status: true };
        sectionNames['CANCELLATION_INFORMATION'] = { key: 'CANCELLATION_INFORMATION', title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_CANCELLATIONINFORMATION), status: true };

        this.setState(prevState => {
            if (prevState.sectionNames === undefined) {
                return {
                    sectionNames,
                    isLoaded: true
                };
            }
            else {
                return null;
            }
        });
    };

    render() {
        const { values, toggleSection, toggleAllSections, onChange, onChangeField, errors } = this.props.smartFormValues;
        const { isLoaded, sectionNames } = this.state;
        const { formName, licence, formType, formDefinition, optionDataList, cancellationDate, t } = this.props;
        const licenceInfo = values[formType];

        return (
            isLoaded &&
            <>
                <div className="heading-bg">{formName}</div>
                <div className="collapse-expand">
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(sectionNames, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(sectionNames, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                </div>

                <AccordionContainer
                    title={sectionNames.CANCELLATION_INFORMATION.title}
                    active={sectionNames.CANCELLATION_INFORMATION.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={sectionNames}
                >
                    <Paragraph
                        name={`${formType}.${'Remarks'}`}
                        value={licenceInfo.Remarks}
                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_REMARKS)}
                        onChange={onChange}
                        rows={3}
                        minLength={0}
                        inputSize={INPUT_SIZE}
                        labelSize={LABEL_SIZE}
                        error={getIn(errors, `${formType}.${'Remarks'}`)}
                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_REMARKS)}
                    />
                    <DateInput
                        name={`${formType}.${'EndDate'}`}
                        value={licenceInfo.EndDate}
                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_EFFECTIVEENDDATE)}
                        onChangeField={onChangeField}
                        time={false}
                        date={true}
                        min={cancellationDate}
                        format={DATE_DISPLAY_FORMAT.DATE}
                        inputSize={INPUT_SIZE}
                        labelSize={LABEL_SIZE}
                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_EFFECTIVEENDDATE)}
                        error={getIn(errors, `${formType}.${'EndDate'}`)}
                        required
                    />
                </AccordionContainer>

                <AccordionContainer
                    title={sectionNames && sectionNames['LicenceOverview'].title}
                    active={sectionNames && sectionNames['LicenceOverview'].status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={sectionNames}
                >
                    <>
                        <LicenceOverviewContents
                            values={values}
                            licence={licence}
                            formType={formType}
                        />
                    </>
                </AccordionContainer>
                {
                    formDefinition.Sections.map((section, index) => {
                        return (
                            <AccordionContainer
                                key={index}
                                title={sectionNames && t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, sectionNames[section.Name].key))}
                                active={sectionNames && sectionNames[section.Name].status}
                                onClick={toggleSection}
                                isIndividual={true}
                                sections={sectionNames}
                            >
                                {section.Fields && section.Fields.map((field, findex) => {

                                    return (
                                        <RenderFieldComponent
                                            key={findex + field.Name}
                                            formType={formType}
                                            formName={formDefinition.FormName}
                                            field={field}
                                            values={values}
                                            onChange={onChange}
                                            onChangeField={onChangeField}
                                            errors={errors}
                                            helpLabel={field.HelpText}
                                            optionDataList={optionDataList}
                                        />
                                    );
                                })}
                                {section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                    <SmallTable
                                        smartFormValues={this.props.smartFormValues}
                                        formType={formType}
                                        formSection={section}
                                        optionDataList={optionDataList}
                                    />
                                }
                                {section.Table && section.Table.Fields.length > TABLE_LAYOUT.COLUMNS &&
                                    <BigTable
                                        smartFormValues={this.props.smartFormValues}
                                        formType={formType}
                                        formSection={section}
                                        form={formDefinition}
                                        optionDataList={optionDataList}
                                    />
                                }
                            </AccordionContainer>
                        );
                    })
                }
            </>
        );
    }
}
export default withTranslation()(FormCancel);