import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalConfirm } from '../Modal';


class MobileCardActionButton extends React.Component {
    constructor(props) {
        super(props);

        // Merge state with props.modal object
        this.state = {
            isOpen: false,
            hasModal: false,
            modalHeader: "Confirm Action?",
            modalContent: "Action to Confirm",
            confirmText: "Yes",
            cancelText: "No",
            confirmCallback: this.props.onClick,
            cancelCallback: () => { },
            ...this.props.modal
        };
    }

    // Fn: Toggles state of Modal show/hide based on given modalName
    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    // Fn: Handles onClick event
    handleOnClick = () => {
        // If modal exist, toggle Modal
        // otherwise just launch onClick event
        (this.state.hasModal) ?
            (this.toggleModal()) :
            (this.props.onClick());
    };

    render() {
        return (
            <React.Fragment>
                {/* MobileCardActionButton component */}
                <Col className={this.props.className + " btn btn-invisible nowrap mobile-action-btn"} onClick={this.handleOnClick}>
                        {this.props.icon && <FontAwesomeIcon icon={this.props.icon} className="card-button-icon" />}
                        {this.props.label && <Label className="label-align">{this.props.label}</Label>}
                        {this.props.children}
                </Col> 

                {/* Modal component */}
                <ModalConfirm
                    isOpen={this.state.isOpen}
                    contentHeader={this.state.modalHeader}
                    contentBody={this.state.modalContent}
                    confirmText={this.state.confirmText}
                    cancelText={this.state.cancelText}
                    confirmCallback={() => { this.state.confirmCallback(); this.toggleModal(); }}
                    cancelCallback={() => { this.state.cancelCallback(); this.toggleModal(); }}
                />                 
            </React.Fragment>
        );
    }
}

//PropTypes: For prop validation
MobileCardActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    icon: PropTypes.object,
    label:  PropTypes.string,
    modal: PropTypes.shape({
        modalHeader: PropTypes.string,
        modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        confirmText: PropTypes.string,
        cancelText: PropTypes.string,
        confirmCallback: PropTypes.func,
        cancelCallback: PropTypes.func
    })
};

// PropTypes: Defaulting value for optional props
MobileCardActionButton.defaultProps = {
    className: '',
};


export default MobileCardActionButton;