import React from 'react';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { withSMARTWrapper } from '../../../Shared/Forms';
import { DASHBOARD_PATH, GET_PAYMENT_SUCCESS_URL, IS_DEMO, GET_PAYMENT_RECEIPT_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import { getParams, fetchRequest, getFileRequest, navigateTo} from '../../../Shared/Actions';
import Layout from '../../../../components/Layout';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey, getLicenceTypeNameTranslationKey, getTranslation } from '../../DisplayComponents/DisplayUtils';

const INITIAL_VALUES = 0;
const GET_PAYMENT_SUCCESS = GET_PAYMENT_SUCCESS_URL;
const GET_PAYMENT_RECEIPT = GET_PAYMENT_RECEIPT_URL;
const URL_PARAMS = 'ApplicationPaymentReferenceNo';

const EmptyOrderDetails =[{
    ApplicationName: "",
    ApplicationNumber:""
}]

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            orderNumber: INITIAL_VALUES,
            orderDetails: EmptyOrderDetails,
            IsPaid: false,
            AppPaymentReferenceNo: ""
        }
    }
    
    componentDidMount() {
         this.getPaymentSuccess();

         this.interval = setInterval(() => {
            this.getPaymentSuccess();
          }, 6000);
    }

    getPaymentSuccess = async () => {

        // Start loading
        const { loader,  } = this.props;
        loader.start();
       
        let paramValue = INITIAL_VALUES;

        //get the params
        if (this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            paramValue = params.get(URL_PARAMS);
        }
        
        let queryString = Object.assign({}, { applicationPaymentReferenceNo: paramValue});          
   
        let response = await fetchRequest(GET_PAYMENT_SUCCESS, getParams(queryString), IS_DEMO);
        
        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {
                
                if(Data.IsPaid) {
                    clearInterval(this.interval);
                }          
                this.setState(() => {
                    return { 
                        orderNumber: Data.OrderNumber,
                        orderDetails: Data.LicenceApplications,
                        IsPaid: Data.IsPaid, 
                        applicationPaymentReferenceNo: paramValue
                    };
                });
               
            }
            else {
                this.setState(() => {
                    return { orderDetails: EmptyOrderDetails };
                });
            }
  
        }
        else{
            //show error page
            this.setState(() => {
                return { orderDetails: EmptyOrderDetails };
            });
        }

         // End loading
         loader.done();
    } 

    getReceipt = async ()=>{

        const { loader } = this.props;
        loader.start();
       
        let paramValue = this.state.applicationPaymentReferenceNo;
       
        let url= GET_PAYMENT_RECEIPT.concat("applicationPaymentReferenceNo="+paramValue);

        let response = await getFileRequest(url, false, paramValue);

        loader.done();

        return response;

    }

    goToLicence = (e,id)=>{
        e.preventDefault();
        navigateTo("/licenceDetails", {licence: {LicenceID : id, }});
        
    }

    render() {
        const isPaid = this.state.IsPaid;
        const{ t } = this.props;
        
        return(
            <React.Fragment>               
               {!isPaid ? (
                <React.Fragment>
                     <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                        <p></p><p></p>
                        <h3>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_RETRIEVINGPAYMENTDETAILS)}</h3>
                     </Layout>
                   
                </React.Fragment>
               ):(
                <React.Fragment>
                      <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                      <div className="main-content">
                      <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                        <div className="success-block">
                            
                            <i className="fas fa-check-circle"></i>
                            <h4>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSUBMISSIONCOMPLETE)}</h4>   
                            <h5> {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_ORDERID)} {this.state.orderNumber}</h5>
                            <h5>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSFULLYPERFORMED)}</h5>   
                    
                            <div className="data-table">      
                                <div className="table-responsive">                                           
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_SERVICE)}</th>
                                                <th>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_APPLICATIONNO)}</th>
                                                <th>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_MODEPAYMENT)}</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orderDetails.map((item, i) => {

                                                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(item.ApplicationName);

                                                return (
                                                    <tr key={i}>
                                                    <td>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, item.LicenceTypeId))}</td>
                                                    <td>{item.ApplicationNumber}</td>
                                                    <td>{item.PaymentMode}</td>
                                                    </tr>
                                                );
                                            })}
                                            
                                        </tbody>
                                    </table>                                           
                                </div>
                            </div>
                            {this.state.orderDetails.map(item => {
                                if (item.LicenceId != null) {
                                    return (
                                        <Alert color="primary">
                                        {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSTEXT1)}
                                        <a href="#top" className="inline-link" onClick={(e) => this.goToLicence(e, item.LicenceId)}> {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSTEXT2)}</a> {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTSUCCESSTEXT3)}
                                        </Alert>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </div> 
                        
                        <div className="button-center">
                            <button className="btn" onClick={() => navigateTo(DASHBOARD_PATH)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</button>	
                            <button className="btn btn-success" onClick={this.getReceipt}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_PRINTRECEIPT)}</button>	
                        </div>
                
                        </div>   
                    </div>                  
                    </div>
                      </div>
                      
                     </Layout>
                   
                    
                </React.Fragment>
               )
               }                        
                
            </React.Fragment>
        );
        

        
    }
}

export default withTranslation()(withRouter(withSMARTWrapper(PaymentSuccess)));

