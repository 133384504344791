import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'reactstrap';
import LicenceCard from './LicenceCard';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

class LicenceItem extends React.Component {

    renderPreReqLicences = () => {
        const { preReqLicences } = this.props;
        if (preReqLicences.length > 0) {
            return preReqLicences.map(preReq => {
                return <LicenceCard key={preReq.Id} licence={preReq} />;
            });
        } else {
            return <></>;
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="box-hidden">
                    <h5>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_LICENCEREQUIREDMESSAGE)}</h5>
                    <Row>
                        <LicenceCard licence={this.props.licence} />
                        {this.renderPreReqLicences()}
                    </Row>
                </div>
            </>
        );
    }
}

LicenceItem.propTypes = {
    licence: PropTypes.object.isRequired
};

export default withTranslation()(LicenceItem);
