import "core-js/es6/object";
import "core-js/es7/object"; // ES7 Object needed
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { LAYOUT_TYPE } from "../App/AppSettings";
import Header from "../Header";
import Navbar from "../Navbar";
import BLSMobileNavbar from "../Navbar/BLS.MobileNavbar";
import {
  CART_ACTION_ADD,
  CART_ACTION_REMOVE,
  CART_LICENCES,
} from "../Shared/Constants";
import { Toaster } from "../Shared/Forms";
import { LicenceCartContext } from "../Shared/LicenceCartContext";
import { withAppWrapper } from "../App/AppWrapper";
import { withTranslation } from "react-i18next";

export const withSMARTLayout = (LoadedComponent, showNavDisplay) => {
  class SMARTLayout extends React.Component {
    constructor(props) {
      super(props);

      let cartLicences = sessionStorage.getItem(CART_LICENCES);
      let externalServices = sessionStorage.getItem("externalServices");
      this.state = {
        isSidebarOpen: false,
        navbarLayoutD: LAYOUT_TYPE.NAVBAR_DESKTOP,
        navbarLayoutM: LAYOUT_TYPE.NAVBAR_MOBILE,
        width: window.innerWidth,
        isMobile: window.innerWidth < 768,
        showNavDisplay: showNavDisplay === false ? false : true,
        cartLicences: cartLicences !== null ? JSON.parse(cartLicences) : [],
        externalServices:
          externalServices !== null ? JSON.parse(externalServices) : [],
      };
    }
    sidebarToggleClickHandler = () => {
      this.setState((prevState) => {
        return { isSidebarOpen: !prevState.isSidebarOpen };
      });
    };

    //Mobile Responsive Listener
    componentWillMount() {
      window.addEventListener("resize", this.handleWindowSizeChange);
    }
    componentWillUnmount() {
      window.removeEventListener("resize", this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
      this.setState({
        width: window.innerWidth,
        isMobile: window.innerWidth < 768,
      });
    };

    //Layout Split
    LayoutToggle = () => {
      const { isMobile } = this.state;

      if (isMobile) {
        return this.state.navbarLayoutM + "M";
      } else {
        return this.state.navbarLayoutD + "D";
      }
    };

    NavbarVerticalChange = () => {
      if (this.LayoutToggle() === "1D" || this.LayoutToggle() === "1M") {
        return <Header onClick={this.toggleSidebar} />;
      }
    };

    adjustNavDisplay = () => {
      return this.state.isMobile && this.state.isSidebarOpen ? " nav-open" : "";
    };

    updateCart = (licence, cart_action) => {
      let cartLicences = sessionStorage.getItem(CART_LICENCES);
      let currentLicences = [];
      let updatedLicences = [];
      if (cartLicences !== null) {
        currentLicences = JSON.parse(cartLicences);
      }
      if (Array.isArray(licence)) {
        if (cart_action === CART_ACTION_REMOVE) {
          updatedLicences = currentLicences.filter(
            (currentLicence) =>
              !licence.map((licence) => licence.Id).includes(currentLicence.Id)
          );
        } else if (cart_action === CART_ACTION_ADD) {
          //get new licences that are not in currentLicences
          let uniqueLicences = licence.filter(
            (li) =>
              !currentLicences
                .map((currentLicence) => currentLicence.Id)
                .includes(li.Id)
          );
          updatedLicences = currentLicences.concat(uniqueLicences);
        }
      } else {
        if (cart_action === CART_ACTION_REMOVE) {
          updatedLicences = currentLicences.filter(
            (currentLicence) => currentLicence.Id !== licence.Id
          );
        } else if (cart_action === CART_ACTION_ADD) {
          currentLicences.push(licence);
          updatedLicences = currentLicences;
        }
      }
      sessionStorage.setItem(CART_LICENCES, JSON.stringify(updatedLicences));
      this.setState(() => {
        return {
          cartLicences: updatedLicences,
          // externalServices: sessionStorage.getItem("externalServices"),
        };
      });
    };

    clearCart = () => {
      sessionStorage.removeItem(CART_LICENCES);

      this.setState(() => {
        return {
          cartLicences: [],
        };
      });
    };

    // State also contains the updater function so it will
    // be passed down into the context provider

    render() {
      const { i18n } = this.props;
      let sideNavbarClassname;

      if (this.state.isMobile && this.state.isSidebarOpen) {
        sideNavbarClassname = "side-navbar nav-open";
      } else {
        sideNavbarClassname = "side-navbar";
      }
      let providerValue = {
        currentCart: this.state.cartLicences,
        externalServices: this.state.externalServices,
        updateCart: this.updateCart,
        clearCart: this.clearCart,
      };

      return (
        <>
          {this.state.isMobile && (
            <BLSMobileNavbar
              sideNavbarClassname={sideNavbarClassname}
              {...this.props}
            />
          )}
          <LicenceCartContext.Provider value={providerValue}>
            <Container
              fluid
              className={"app-container" + this.adjustNavDisplay()}
            >
              <Row>
                <Navbar
                  showNavDisplay={this.state.showNavDisplay}
                  navbarLayout={this.LayoutToggle()}
                  isSidebarOpen={this.state.isSidebarOpen}
                  sidebarToggleClickHandler={this.sidebarToggleClickHandler}
                  cartLicences={this.state.cartLicences}
                />
              </Row>
              <Row>
                <Col className="site-container">
                  {this.NavbarVerticalChange()}
                  <LoadedComponent key={i18n.language} {...this.props} />
                </Col>
                <Toaster isStack={false} />
                {/* <ScrollToTop /> */}
              </Row>
            </Container>
          </LicenceCartContext.Provider>
        </>
      );
    }
  }
  return withTranslation()(withAppWrapper(SMARTLayout));
};
