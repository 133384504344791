import PropTypes from 'prop-types';
import React from 'react';
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from '../../../Shared/Constants';
import AnswerCheckbox from './AnswerCheckbox';
import AnswerRadio from './AnswerRadio';
import { withTranslation } from 'react-i18next';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getTransl } from '../../../../directus';

const AnswerComponents = {
    'Checkbox': AnswerCheckbox,
    'Radio': AnswerRadio
};

class QuestionItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentAnswer: null,
            currentLicence: null,
            currentSubQuestionId: null
        };
    }

    componentWillUnmount = () => {
        const ansGrp = this.props.questionAnswerSet?.question_answer_group
        const licenceArray = ansGrp.map(({ licence }) => licence);
        this.props.updateBizIntentCart(licenceArray, CART_ACTION_REMOVE);
    };

    handleAnswerClick = answer => {
        const { updateBizIntentCart } = this.props;
        const { currentAnswer, currentLicence } = this.state;

        const licenceExistsForAnswer = answer.Licence !== undefined && answer.Licence !== null;

        //If there is a change in answer
        if (currentAnswer !== answer.AnswerId) {

            //if currentAnswer is not set yet means it is an initial click
            //if initial click
            if (!currentAnswer) {
                //if there is a new licence to add
                if (licenceExistsForAnswer) {
                    updateBizIntentCart(
                        answer.Licence,
                        CART_ACTION_ADD
                    );
                }
            }
            else {
                //if there is a new licence to add
                if (licenceExistsForAnswer) {
                    //if there was a previous licence to remove, then remove, else just add new
                    if (currentLicence) {
                        updateBizIntentCart(
                            currentLicence,
                            CART_ACTION_REMOVE,
                            updateBizIntentCart(
                                answer.Licence,
                                CART_ACTION_ADD
                            )
                        );
                    }
                    else {
                        //add new licence
                        updateBizIntentCart(
                            answer.Licence,
                            CART_ACTION_ADD
                        );
                    }
                }
                else {
                    //if there was a previous licence to remove, then remove
                    if (currentLicence) {
                        updateBizIntentCart(
                            currentLicence,
                            CART_ACTION_REMOVE
                        );
                    }
                }
            }

            this.setState({
                currentAnswer: answer.AnswerId,
                currentLicence: answer.Licence,
                currentSubQuestionId: answer.SubQuestionId
            });
        }
    };

    verifyIfLicenceDisabled = questionAnswerSet => {
        const { currLang } = this.props;
        const currQuestion = questionAnswerSet?.question;
        const currQuestionType = getTransl(currQuestion.question_type, currLang).title;
        const currSubquestion = questionAnswerSet?.subquestion;
        const currSubquestionAnswerGroup = questionAnswerSet?.subquestion_answer_group;
        const currQuestionHasLicence = questionAnswerSet?.question_answer_group && questionAnswerSet?.question_answer_group.length > 0;
        const currQuestionHasSubquestion = !!questionAnswerSet?.subquestion;
        const currSubquestionHasLicence = questionAnswerSet?.subquestion_answer_group && questionAnswerSet?.subquestion_answer_group.length > 0;
        const isAnswerLicenceAvailable = (currQuestionHasLicence || currQuestionHasSubquestion) || currSubquestionHasLicence;

        if (isAnswerLicenceAvailable) {
            
            if (currQuestionType === 'Checkbox') {
                //checkbox with only one answer
                let answerSet = null;
                if (currQuestionHasLicence)
                    answerSet = questionAnswerSet.question_answer_group[0];
                else if (currSubquestionHasLicence)
                    answerSet = questionAnswerSet.subquestion_answer_group[0];
                
                return this.checkAnswerLicenceIsDisabled(answerSet, currSubquestion, currSubquestionAnswerGroup);
            }
            else {
                //radio button with multiple answers
                if (currQuestionHasLicence) {
                    for (const answerSet of questionAnswerSet.question_answer_group) {
                        return this.checkAnswerLicenceIsDisabled(answerSet, currSubquestion, currSubquestionAnswerGroup);
                    }
                } else if (currSubquestionHasLicence) {
                    for (const answerSet of questionAnswerSet.subquestion_answer_group) {
                        return this.checkAnswerLicenceIsDisabled(answerSet, currSubquestion, currSubquestionAnswerGroup);
                    }
                }
            }
        }
        //question does not have a enabled licence
        return true;
    };

    checkAnswerLicenceIsDisabled = (answerSet, subquestion, subquestionAnswerGroup) => {
        const { exclusiveLicenceCart, bizIntentCart } = this.props;

        if (answerSet !== null && answerSet.licence !== null) {
            if (bizIntentCart.length > 0 && answerSet.licence.Exclusive.length > 0) {
                const bizIntentCartLicenceTypes = bizIntentCart.map(licence => licence.Id);
                const answerLicenceExclusives = answerSet.licence.Exclusive.map(lic => lic.LicenceTypeId);

                if (bizIntentCartLicenceTypes.some(r => answerLicenceExclusives.includes(r))) {
                    //check if current question has answer with licence that has exclusive that is currently in the cart (bi-directional exclusive)
                    return true;
                }
            }
            //check if current question has answer with licence that is in the exclusive cart and thus should be disabled
            return exclusiveLicenceCart.length > 0 && exclusiveLicenceCart.includes(answerSet.licence.Id);

        } else {
            if (subquestion !== null) {
                //if question has subquestion, traverse again
                const questionAnswerSet = {
                    question: subquestion,
                    question_answer_group: subquestionAnswerGroup,
                    subquestion: null,
                    subquestion_answer_group: null
                }
                return this.verifyIfLicenceDisabled(questionAnswerSet);
            }
            else {
                //question has no licence and no subquestion. failsafe statement which should not be hit
                return false;
            }
        }
    };

    render() {
        const { questionAnswerSet, updateBizIntentCart, bizIntentCart, exclusiveLicenceCart, preReqLicences, t, currLang, isSubquestion } = this.props;
        const { question, question_answer_group, subquestion, subquestion_answer_group } = questionAnswerSet;
        const { id, question_type } = question;
        const questionType = getTransl(question_type, currLang).title;
        const questionDescription = getTransl(question, currLang).description;
        const RenderAnswer = AnswerComponents[questionType];
        //traverse through all answers and subquestions(and subquestions of subquestions) to determine if all options are disabled
        const isDisabled = this.verifyIfLicenceDisabled(questionAnswerSet);

        return (
            <RenderAnswer
                QuestionId={id}
                Description={questionDescription}
                AnswerGrp={question_answer_group}
                ShowOnLoad={!isSubquestion}
                updateBizIntentCart={updateBizIntentCart}
                bizIntentCart={bizIntentCart}
                exclusiveLicenceCart={exclusiveLicenceCart}
                isDisabled={isDisabled}
                preReqLicences={preReqLicences}
                currLang={currLang}
                Subquestion={subquestion}
                SubAnsGrp={subquestion_answer_group}
            />
        );
    }
}

QuestionItem.propTypes = {
    questionAnswerSet: PropTypes.object.isRequired,
    isSubquestion: PropTypes.bool.isRequired
};

export default withTranslation()(QuestionItem);
