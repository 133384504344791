import { getIn } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DATE_DISPLAY_FORMAT, MASK, REGEX } from '../../App/AppSettings';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { Paragraph, Text } from '../../Shared/Forms';
import Checkbox from '../../Shared/Forms/Checkbox';
import DateInput from '../../Shared/Forms/DateInput';
import MaskInput from '../../Shared/Forms/MaskedInput';
import NumberInput from '../../Shared/Forms/NumberInput';
import Radio from '../../Shared/Forms/Radio';
import SelectList from '../../Shared/Forms/SelectList';
import { getLangKey, getTranslation } from '../DisplayComponents/DisplayUtils';


const RENDER_FIELD_COMPONENT_SIZE = {
    'Small': FIELD_SIZE.XSMALL,
    'Medium': FIELD_SIZE.SMALL,
    'Large': FIELD_SIZE.MEDIUM,
    'Extra Large': FIELD_SIZE.LARGE
};

class RenderTableFieldComponent extends React.Component {

    constructor(props) {
        super(props);

        const { field, optionDataList } = this.props;
        const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
        const isLocal = field.Data && (field.DataSource === undefined || field.DataSource === 'Local');
        let newOptionsList = [];

        if (isRemote) {
            newOptionsList = optionDataList[field.DataParameters.Category];
        } else if (isLocal) {
            newOptionsList = this.getLocalList(field);
        } else {
            newOptionsList = [];
        }

        this.state = {
            optionDataList: newOptionsList
        };
    }

    errorMessage = (value, fieldsError, field) => {

        const { t } = this.props; 
        if (!value && fieldsError) {
            return ((ERROR.REQUIRED2) + t(ERROR.REQUIRED));
        } else if (field.InputType === 'Email' && !REGEX.EMAIL.test(value) && fieldsError) {
            return t(ERROR.EMAIL_INVALID);
        } else if (field.InputType === 'Number' && !REGEX.NUMBER.test(value) && fieldsError) {
            return t(ERROR.NUMBER_INVALID);
        } else if (field.InputType === 'FileUpload' && value.length <= 0 && fieldsError) {
            return t(ERROR.FILE_ITEM_REQUIRED);
        } else {
            if (field.Validation && field.Validation.MinimumLength && value.length < field.Validation.MinimumLength && fieldsError) {
                return (t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
            }
            if (field.Validation && field.Validation.MaximumLength && value.length > field.Validation.MaximumLength && fieldsError) {
                return (t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
            }
        }
    };

    getLocalList = field => field.Data.map(({ Value }) => ({ label: Value, value: Value, key: Value }));

    isPopUpRequired = (popUp, field) => {
        const popUpRequiredObj = {};
        const {t} = this.props;
        const isFieldRequired = field.Validation ? (field.Validation.Required === true) : false;

        popUpRequiredObj['label'] = popUp ? getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name)) : undefined;
        popUpRequiredObj['inputSize'] = popUp ? RENDER_FIELD_COMPONENT_SIZE[field.FieldSize] : 10;
        popUpRequiredObj['labelSize'] = popUp ? FIELD_SIZE.MEDIUM : null;
        popUpRequiredObj['Required'] = popUp ? isFieldRequired : null;

        return popUpRequiredObj;
    }

    render() {

        // De-structure props to be used
        const { formType, field, sectionName, rowIndex, values, onChange, onChangeField, errors, popUp, fieldsError, t } = this.props;
        const { optionDataList } = this.state;
        const popUpLabel = this.isPopUpRequired(popUp, field)['label'];
        const popUpInputSize = this.isPopUpRequired(popUp, field)['inputSize'];
        const popUpLabelSize = this.isPopUpRequired(popUp, field)['labelSize'];
        const popUpRequired = this.isPopUpRequired(popUp, field)['Required'];
        
        let displayError = '';

        if (popUp) {
            displayError = this.errorMessage(values[formType][sectionName][rowIndex][field.Name], fieldsError, field);
        } else {
            displayError = getIn(errors, `${formType}.${sectionName}[${rowIndex}][${field.Name}]`);
        }

        let isFieldDisabled = false;

        if (field.InputDisabled === 'Yes') {
            isFieldDisabled = true;
        }

        switch (field.InputType) {
            case 'Text':
            case 'Email':
                return <Text
                    // className="col-form-label-text"
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    label={popUpLabel}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    minLength={field.Validation ? field.Validation.MinimumLength : undefined}
                    maxLength={field.Validation ? field.Validation.MaximumLength : undefined}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    onChange={onChange}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    readOnly={isFieldDisabled}
                >
                </Text>;

            case 'Radio':
                return <Radio
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    options={optionDataList}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    onChange={onChange}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </Radio>;

            case 'Date':
                return <DateInput
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    onChangeField={onChangeField}
                    time={false}
                    date={true}
                    min={field.Validation ? field.Validation.MinimumValue : undefined}
                    max={field.Validation ? (field.Validation.MaximumValue === 'Current Date' ? (new Date()).setHours(0, 0, 0, 0) : field.Validation.MaximumValue) : undefined}
                    format={DATE_DISPLAY_FORMAT.DATE}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'Time':
                return <DateInput
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min={field.Validation ? field.Validation.MinimumValue : undefined}
                    max={field.Validation ? field.Validation.MaximumValue : undefined}
                    format={DATE_DISPLAY_FORMAT.TIME}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'DateTime':
                return <DateInput
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    label={popUpLabel}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    onChangeField={onChangeField}
                    time={true}
                    date={true}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue === 'Current Date' ? (new Date()).setHours(0, 0, 0, 0) : field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.DATE_TIME}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'DropDownList':
                return <SelectList
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    isMulti={false}
                    isClearable={true}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </SelectList>;

            case 'Number':
                return <NumberInput
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    onChangeField={onChangeField}
                    unitOfMeasurement=""
                    step={1.0}
                    min={field.Validation ? field.Validation.MinimumValue : undefined}
                    max={field.Validation ? field.Validation.MaximumValue : undefined}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={(displayError !== undefined &&
                        values[formType][sectionName][rowIndex][field.Name] === null) ? `${field.DisplayName} is required.` :
                        displayError}
                    key={field.Name}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </NumberInput>;

            case 'Paragraph':
                return <Paragraph
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    onChange={onChange}
                    rows={3}
                    minLength={field.Validation ? field.Validation.MinimumLength : undefined}
                    maxLength={field.Validation ? field.Validation.MaximumLength : undefined}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    key={field.Name}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    readOnly={isFieldDisabled}
                >
                </Paragraph>;

            case 'Checkbox':
                return <Checkbox
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </Checkbox>;

            case 'Multiselect':
                return <SelectList
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    isMulti={true}
                    isClearable={true}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    required={popUpRequired}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    isDisabled={isFieldDisabled}
                >
                </SelectList>;

            case 'MaskedInput':
                return <MaskInput
                    name={`${formType}.${sectionName}[${rowIndex}][${field.Name}]`}
                    placeholder={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    onChange={onChange}
                    mask={MASK.TELEPHONE}
                    inputSize={popUpInputSize}
                    labelSize={popUpLabelSize}
                    label={popUpLabel}
                    value={values[formType][sectionName][rowIndex][field.Name]}
                    helpLabel={getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={displayError}
                    required={popUpRequired}
                    isDisabled={isFieldDisabled}
                >
                </MaskInput>;

            default:
                return null;
        }
    }
}

export default withTranslation()(RenderTableFieldComponent);