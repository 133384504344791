import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { AMEND_LICENCE_PATH, CANCEL_LICENCE_PATH, HOME_PATH, LAYOUT_TYPE, SEARCH_ALL_LICENCE_PATH } from '../.././App/AppSettings';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import { SMARTForm } from '../../Shared/Forms';
import history from '../../Shared/History';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import PageFooter from '../../Shared/PageFooter';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getTranslation } from '../DisplayComponents/DisplayUtils';

const FORM_CONTEXT = 'IntroductoryNotes';

// Navigation URLs
let NEXT_URL = '/services/devform/new';

const withLicenceContext = WrappedComponent => {
    class LicenceContext extends React.Component {
        render() {
            return (
                <LicenceCartContext.Consumer>
                    {({ currentCart }) => (
                        <WrappedComponent
                            context={{ currentCart }}
                            {...this.props}
                        />
                    )}
                </LicenceCartContext.Consumer>
            );
        }
    }

    return LicenceContext;
};

class DevStart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            prevPage: HOME_PATH,
            values: {
                TermsOfUse: ''
            }
        };

        // In addition, for apply and renew
        if (history.location.state) {
            this.state = {
                prevPage: history.location.state.from,
                values: {
                    TermsOfUse: '',

                    SFormId: history.location.state.SFormId,
                    LicenceId: history.location.state.LicenceId
                }
            };
            switch (history.location.state.applicationType) {
                case 'AMEND':
                    NEXT_URL = AMEND_LICENCE_PATH;
                    break;
                case 'CANCEL':
                    NEXT_URL = CANCEL_LICENCE_PATH;
                    break;
                case 'APPLY':
                default:
                    NEXT_URL = '/services/devform/new';
                    break;
            }
        }
    }

    prepareSectionList(t) {
        let allSectionList = [];

        let sectionListOne = [];
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1A, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1B, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1C, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1D, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1E, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1F, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1G, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1H, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1I, true));
        sectionListOne.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_1J, true));

        let sectionListTwo = [];
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2A, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2B, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2C, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2D, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2E, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2F, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2G, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2H, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2I, true));
        sectionListTwo.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_2J, true));

        let sectionListThree = [];
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3A, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3B, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3C, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3D, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3E, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3F, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3G, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3H, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3I, true));
        sectionListThree.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_3J, true));

        let sectionListFour = [];
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4A, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4B, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4C, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4D, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4E, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4F, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4G, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4H, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4I, true));
        sectionListFour.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_4J, true));

        let sectionListFive = [];
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5A, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5B, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5C, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5D, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5E, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5F, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5G, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5H, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5I, true));
        sectionListFive.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_5J, true));

        let sectionListSix = [];
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6A, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6B, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6C, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6D, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6E, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6F, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6G, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6H, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6I, true));
        sectionListSix.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_6J, true));

        let sectionListSeven = [];
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7A, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7B, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7C, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7D, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7E, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7F, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7G, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7H, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7I, true));
        sectionListSeven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_7J, true));

        let sectionListEight = [];
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8A, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8B, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8C, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8D, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8E, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8F, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8G, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8H, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8I, true));
        sectionListEight.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_8J, true));

        let sectionListNine = [];
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9A, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9B, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9C, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9D, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9E, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9F, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9G, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9H, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9I, true));
        sectionListNine.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_9J, true));

        let sectionListTen = [];
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10A, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10B, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10C, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10D, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10E, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10F, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10G, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10H, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10I, true));
        sectionListTen.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_10J, true));

        let sectionListEleven = [];
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11A, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11B, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11C, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11D, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11E, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11F, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11G, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11H, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11I, true));
        sectionListEleven.push(getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SUBSECTION_11J, true));

        allSectionList.push(sectionListOne);
        allSectionList.push(sectionListTwo);
        allSectionList.push(sectionListThree);
        allSectionList.push(sectionListFour);
        allSectionList.push(sectionListFive);
        allSectionList.push(sectionListSix);
        allSectionList.push(sectionListSeven);
        allSectionList.push(sectionListEight);
        allSectionList.push(sectionListNine);
        allSectionList.push(sectionListTen);
        allSectionList.push(sectionListEleven);

        return allSectionList;
    }

    prepareSectionHeaderList(t) {
        return [
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_1),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_2),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_3),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_4),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_5),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_6),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_7),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_8),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_9),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_10),
            t(LANGUAGE_KEYS.BLS_PUBLICTERMSANDCONDITIONS_CONTENT_SECTION_11)
        ];
    }

    renderHTMLSection(objects) {
        let result = '';
        objects.forEach(item => {
            if ((typeof item) !== 'object') return;
            
            result += `<${item.type}>`;
            const contents = item.props.children;
            contents.forEach(content => {
                if ((typeof content) === "object") 
                    result += this.renderHTMLSection([content]);
                else
                    result += content;
            });
            result += `</${item.type}>`
        });
        return result;
    }

    renderSection(t) {
        let displayList = '';
        let sectionHeaderCount = 0;
        let allSectionList = this.prepareSectionList(t);
        let allSectionHeaderList = this.prepareSectionHeaderList(t);

        allSectionList.forEach(section => {
            let subSectionList = '';
            section.forEach(subSection => {
                if(subSection.length === 0)
                    return;
                if (subSection.length === 1)
                {
                    if ((typeof subSection[0]) === 'object')
                        subSectionList += '<li>' + this.renderHTMLSection(subSection) +'</li>';
                    else {
                        let subSectionText = subSection[0];
                        if (subSectionText !== 'undefined' && subSectionText)
                            subSectionList += '<li>' + subSectionText + '</li>';
                    }
                }
                else 
                    subSectionList += '<li>' + this.renderHTMLSection(subSection) +'</li>'
            });

            //If sub-section is empty, do not add section and header in
            displayList += subSectionList && subSectionList.length > 0 ?
                '<li><strong>' + allSectionHeaderList[sectionHeaderCount] + '</strong><ol>'
                + subSectionList + '</ol></li>'
                : "";

            //Move on to next header count.
            sectionHeaderCount++;
        })

        return (
            <>
                {
                    <ol dangerouslySetInnerHTML={{ __html: displayList }} type="I">
                    </ol>
                }
            </>
        );
    }

    render() {
        const { values, prevPage } = this.state;
        const { t } = this.props;

        if (!values.LicenceId && (this.props.context.currentCart === undefined || this.props.context.currentCart.length === 0)) {
            navigateTo(SEARCH_ALL_LICENCE_PATH);
        }

        return (
            <React.Fragment>
                <div className="main-content">
                    <nav aria-label="breadcrumb" className="breadcrumb-main">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href={HOME_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_HOME)}</a></li>
                                <li className="breadcrumb-item"><a href={SEARCH_ALL_LICENCE_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_SERVICEAPPLICATION)}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{t(LANGUAGE_KEYS.BLS_PUBLICHOME_NAV_MENU_APPLYFORSERVICE)}</li>
                            </ol>
                        </div>
                    </nav>
                    <div className="container">
                        <Layout type={LAYOUT_TYPE.FORM} footer>
                            <SMARTForm
                                formContext={FORM_CONTEXT}
                                formValues={values}
                                nextURL={NEXT_URL}
                                validateOnSubmit={true}
                                navigateURL={NEXT_URL}
                            >
                                {({ navigateForm }) => (
                                    <React.Fragment>
                                        <Row>
                                            <div className="termsncond">
                                                <h4>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_TERMSANDCONDITONS)}</h4>

                                                <div className="termsncond-block">
                                                    {this.renderSection(t)}
                                                </div>
                                            </div>

                                        </Row>
                                        <PageFooter type={LAYOUT_TYPE.FORM}>
                                            <div className="button-center">
                                                <Button color="forward" size="sm" type="button" onClick={() => navigateTo(prevPage)}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_PREVIOUS)}</Button>
                                                <Button color="forward" size="sm" type="button" onClick={navigateForm}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_AGREE)}</Button>
                                            </div>
                                        </PageFooter>
                                    </React.Fragment>
                                )}
                            </SMARTForm>
                        </Layout>
                    </div >
                </div >

            </React.Fragment >
        );
    }
}

export default withTranslation()(withRouter(withLicenceContext(DevStart)));

