import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import LicenceDetailsModalContent from '../DisplayComponents/LicenceDetailsModalContent';
import RecommendedItemResult from './RecommendedItemResult';

const MODAL_NAMES = { DETAILS: 'Details' };

const EmptyLicence = {
    Description: '',
    DocumentDesc: '',
    FaqDesc: '',
    FeeDesc: '',
    Id: '',
    TimeDesc: '',
    Title: '',
    Url: '',
    DocumentDict: {}
};

class RecommendationResultContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stateLicence: EmptyLicence
        };
    }

    // For the 'detail' button to open modal
    handleDetailClick = (licence) => {
        this.props.modal.toggleModal(MODAL_NAMES.DETAILS);
        this.setState({ stateLicence: licence });
    };

    renderRows = (licences) => {
        return licences.map((licence, i) => {
            return (
                <RecommendedItemResult
                    key={i}
                    licence={licence}
                    onClick={() => this.handleDetailClick(licence)}
                    isTdNavigation={false}
                    inLineStyle={null}
                    isShowDescription={true}
                    isShowFeeTime={true}
                    isShowAddToCart={true}
                    isShowApply={true}
                    isShowDetails={true}
                />
            );
        });
    };

    render() {
        const { licences, modal, t } = this.props;
        const { stateLicence } = this.state;

        return (
            <React.Fragment>
                <div className='recommend-licence'>
                    {licences.length > 0 &&
                        <h4>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_RECOMMENDEDSERVICESFORYOU)}</h4>
                    }
                    <Row>
                        {this.renderRows(licences)}
                    </Row>
                </div>
                {stateLicence && stateLicence !== EmptyLicence &&
                    <LicenceDetailsModalContent
                        stateLicence={stateLicence}
                        modal={modal}
                    />
                }
            </React.Fragment >
        );
    }
}
export default withTranslation()(withModalWrapper(RecommendationResultContainer));
