import React from 'react';
// import { Container, Col, Row } from 'reactstrap';

class FooterContentExtension extends React.Component {
    render() {
        return (
            <React.Fragment>
                {/* <div className="footer-container">
                    <Container>
                        <div className="footer-inner">
                            <Row className="footer-extension">
                                <Col>
                                    Content here
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div> */}
            </React.Fragment>
        );
    }
}

export { FooterContentExtension };
