import React from 'react';
import { BLS_RFA_TYPES } from '../../Shared/Constants';
import history from '../../Shared/History';
import RFAAmendment from './RFAAmendment';
import RFAClarification from './RFAClarification';

class RFAResponse extends React.Component {
    constructor(props) {
        super(props);

        if (history.location.state) {
            const { Application, ApplicationRfa } = history.location.state.Application;
            this.state = {
                application: Application,
                applicationRfa: ApplicationRfa,
                additionalDocuments: ApplicationRfa.AdditionalDocuments ? JSON.parse(ApplicationRfa.AdditionalDocuments) : []
            };
        }
    }

    render() {
        const { application, applicationRfa } = this.state;
        return (
            <>
                {this.state.applicationRfa.RFAType === BLS_RFA_TYPES.REQUEST_FOR_AMENDMENT &&
                    <RFAAmendment
                        applicationRfa={applicationRfa}
                        application={application}
                    />
                }
                {this.state.applicationRfa.RFAType === BLS_RFA_TYPES.REQUEST_FOR_CLARIFICATION &&
                    <RFAClarification
                        applicationRfa={applicationRfa}
                        application={application}
                    />
                }
            </>
        );
    }
}
export default RFAResponse;
