import { default as React } from 'react';
import { Button } from 'reactstrap';
import { TABLE_LAYOUT } from '../../App/AppSettings';
import { AccordionContainer } from '../../Shared/Forms';
import RenderFieldComponent from '../FormComponents/RenderFieldComponent';
import { BigTable, SmallTable } from '../FormComponents/TableComponent';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

class RFAFormAmend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Check whether webpage is loaded
            isLoaded: false,
            sectionNames: undefined
        };
    }

    componentDidMount() {
        const { formDefinition, t } = this.props;
        const sectionNames = {};

        formDefinition.Sections.forEach(formSection => {
            sectionNames[formSection.Name] = { title: formSection.DisplayName, status: true };
        });

        sectionNames['LicenceOverview'] = { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_LICENCEOVERVIEW), status: true };

        this.setState({
            sectionNames,
            isLoaded: true
        });
    };

    render() {
        const { values, toggleSection, toggleAllSections, onChange, onChangeField, errors } = this.props.smartFormValues;
        const { isLoaded, sectionNames } = this.state;
        const { formName, formType, formDefinition, optionDataList, t } = this.props;

        return (
            isLoaded &&
            <>
                <div className="heading-bg">{formName}</div>
                <div className="collapse-expand">
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(sectionNames, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(sectionNames, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                </div>
                {
                    formDefinition.Sections.map((section, index) => {
                        return (
                            <AccordionContainer
                                key={index}
                                title={sectionNames && sectionNames[section.Name].title}
                                active={sectionNames && sectionNames[section.Name].status}
                                onClick={toggleSection}
                                isIndividual={true}
                                sections={sectionNames}
                            >
                                {section.Fields && section.Fields.map((field, findex) => {
                                    return (
                                        <RenderFieldComponent
                                            key={findex + field.Name}
                                            formType={formType}
                                            formName={formDefinition.FormName}
                                            field={field}
                                            values={values}
                                            onChange={onChange}
                                            onChangeField={onChangeField}
                                            errors={errors}
                                            helpLabel={field.HelpText}
                                            optionDataList={optionDataList}
                                        />
                                    );
                                })}
                                {section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                    <SmallTable
                                        smartFormValues={this.props.smartFormValues}
                                        formType={formType}
                                        formSection={section}
                                        optionDataList={optionDataList}
                                    />
                                }
                                {section.Table && section.Table.Fields.length > TABLE_LAYOUT.COLUMNS &&
                                    <BigTable
                                        smartFormValues={this.props.smartFormValues}
                                        formType={formType}
                                        formSection={section}
                                        form={formDefinition}
                                        optionDataList={optionDataList}
                                    />
                                }
                            </AccordionContainer>
                        );
                    })
                }
            </>
        );
    }
}
export default withTranslation()(RFAFormAmend);