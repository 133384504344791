import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { withLoader } from '../../Shared/Loading';
import { AccordionContainer, Plaintext, SMARTView, ScrollToTop } from '../../Shared/Forms';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import { DASHBOARD_PATH, GET_FILE_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import history from '../../Shared/History';
import { DataTableDemo } from '../../Shared/DataTable';
import { MIN_FILTER_CHARS } from '../../Shared/Constants';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const FORM_CONTEXT = 'BusinessDetails';

class BusinessDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            business: null
        };
        if (history.location.state) {
            this.state = {
                business: history.location.state.business
            };
        }
    }

    render() {
        const { t } = this.props;
        const SECTION_NAMES = {
            ENTITY_OVERVIEW: { title: "Entity Overview", status: true },
            ENTITY_SUPPORTING_DOCS: { title: "Entity Supporting Documents", status: true },
            ENTITY_APPLICATION_DETAILS: { title: "Entity Application Details", status: true },
            ENTITY_APPLICATION_HISTORY: { title: "Service Application Hitory", status: true }
        };
        const Business = this.state.business;
        const { EntityNumber, EntityName, EntityType, Status, StartDate, IssuedDate, ExpiryDate, EntityDocuments, PostalCode, StreetName, State, Country,
            PrimaryActivity, PrimaryActivityDescription, SecondaryActivity, SecondaryActivityDescription } = Business;
        return (
            <>
                <ScrollToTop />
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className='main-content'>
                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item'><a href={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</a></li>
                                    <li className='breadcrumb-item'><a href={DASHBOARD_PATH}>Entity</a></li>
                                    <li className='breadcrumb-item active' aria-current='page'>{EntityName}</li>
                                </ol>
                                <div className='back-btn'>
                                    <Button
                                        color='header'
                                        className='btn-header'
                                        onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                </div>
                            </div>
                        </nav>
                        <div className='container'>
                            <SMARTView
                                formContext={FORM_CONTEXT}
                                isDummy={false}
                            >
                                {({ toggleSection, toggleAllSections }) => (
                                    <>
                                        <div className='form-collapse detailed-box-form margin'>
                                            <div className='collapse-expand'>
                                                <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                                                <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                                            </div>
                                            <AccordionContainer
                                                title={SECTION_NAMES.ENTITY_OVERVIEW.title}
                                                active={SECTION_NAMES.ENTITY_OVERVIEW.status}
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}
                                            >
                                                {Business &&
                                                    <>
                                                        <div className='card-body'>
                                                            <div className='form-body form-preview'>
                                                                <Plaintext
                                                                    label="Entity Number"
                                                                    name='EntityNumber'
                                                                    value={EntityNumber}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Entity Name"
                                                                    name='Entity Name'
                                                                    value={EntityName}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Entity Type"
                                                                    name='EntityType'
                                                                    value={EntityType}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Status"
                                                                    name='Status'
                                                                    value={Status}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Registration/Incorporate Date"
                                                                    name='RegistrationDate'
                                                                    value={StartDate}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_ISSUEDDATE)}
                                                                    name='IssuedDate'
                                                                    value={IssuedDate}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_EXPIRYDATE)}
                                                                    name='ExpiryDate'
                                                                    value={ExpiryDate}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </AccordionContainer>

                                            <AccordionContainer
                                                title={SECTION_NAMES.ENTITY_SUPPORTING_DOCS.title}
                                                active={SECTION_NAMES.ENTITY_SUPPORTING_DOCS.status}
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}
                                            >
                                                {EntityDocuments && EntityDocuments.length > 0 &&
                                                    EntityDocuments.map(function (doc, i) {
                                                        return <Plaintext
                                                            key={i}
                                                            label={`${doc.FileDescription}:`}
                                                            name={`${doc.Filename}-${doc.Id}`}
                                                            getFileURL={GET_FILE_URL}
                                                            value={doc}
                                                            isSupportingDocument={true}
                                                            labelSize={LABEL_SIZE}
                                                            inputSize={2}
                                                        />;
                                                    })
                                                }
                                            </AccordionContainer>

                                            <AccordionContainer
                                                title={SECTION_NAMES.ENTITY_APPLICATION_DETAILS.title}
                                                active={SECTION_NAMES.ENTITY_APPLICATION_DETAILS.status}
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}
                                            >
                                                <div className="card-body">
                                                    <div className="form-body form-preview">
                                                        <div className="multi-row-form">
                                                            <h3>Registered Office Address</h3>
                                                            <Plaintext
                                                                label="Postal Code"
                                                                name="PostalCode"
                                                                value={PostalCode}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Street Name"
                                                                name="StreetName"
                                                                value={StreetName}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="State/City"
                                                                name="State"
                                                                value={State}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Country"
                                                                name="Country"
                                                                value={Country}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                        </div>

                                                        <div className="multi-row-form">
                                                            <h3>Business Activity</h3>
                                                            <Plaintext
                                                                label="Primary Activity"
                                                                name="PrimaryActivity"
                                                                value={PrimaryActivity}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Primary Activity Description"
                                                                name="PrimaryActivityDescription"
                                                                value={PrimaryActivityDescription}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Secondary Activity"
                                                                name="SecondaryActivity"
                                                                value={SecondaryActivity}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Secondary Activity Description"
                                                                name="SecondaryActivityDescription"
                                                                value={SecondaryActivityDescription}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionContainer>


                                                <AccordionContainer
                                                    title={SECTION_NAMES.ENTITY_APPLICATION_HISTORY.title}
                                                    active={SECTION_NAMES.ENTITY_APPLICATION_HISTORY.status}
                                                    onClick={toggleSection}
                                                    isIndividual={true}
                                                    sections={SECTION_NAMES}
                                                >
                                                    <DataTableDemo
                                                        pageSize='5'
                                                        minFilterChars={MIN_FILTER_CHARS}
                                                        dataType="entity"
                                                        //url={GET_LICENCE_APPLICATION_HISTORY_URL + LicenceNumber}
                                                        noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCEAPPHISTORYFOUND)}
                                                        components={getCustomComponents()}
                                                        columns={
                                                            {
                                                                ApplicationRefNumber: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER), width: '20%', DBkey: 'ApplicationRefNumber', setContent: this.setContent },
                                                                ApplicationType: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONTYPE), width: '20%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getTranslatedString },
                                                                SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONDATE), width: '20%', DBkey: 'SubmissionDate', isDate: true },
                                                                Status: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_STATUS), width: '15%', DBkey: 'Status' }
                                                            }
                                                        }
                                                        initialSort={
                                                            { id: 'SubmissionDate', sortAscending: false }
                                                        }
                                                    />

                                                </AccordionContainer>

                                            <div className='button-center'>
                                                <Button className='btn' onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                                <Button color='hazard' className='btn' onClick={(e) => e.preventDefault()}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_WITHDRAWAPPLICATION)}</Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </SMARTView>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default withTranslation()(withRouter(compose(withLoader, withModalWrapper)(BusinessDetails)));