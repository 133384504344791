import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { withBaseControl } from './BaseControl';


class Password extends React.Component {
    constructor(props) {
        super(props);
        this.state = { passwordType: 'password' }
    }
    
    showHidePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordType: this.state.passwordType === 'input' ? 'password' : 'input'
        })
    }

    render() {
        return (
            <div className="password-container">
                <Input
                    type={this.state.passwordType}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                />
                <span className="show-hide-password" onClick={this.showHidePassword}>
                    {this.state.passwordType === 'input' ? 'HIDE' : 'SHOW'}
                </span>
            </div>
        );
    }
}

// PropTypes: For prop validation
Password.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};


export default withBaseControl(Password);