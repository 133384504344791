import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { withBaseControl } from './BaseControl';
import { InputNumber } from 'antd';


class NumberInput extends React.Component {

    onChangeNumber = (e, { name, onChangeField }) => {
        if (e === undefined) {
            e = null;
        }
        onChangeField(name, e);
    }

    render() {
        const NumPicker = (
            <InputNumber
                className={(this.props.appendBack === undefined) ? '' : (this.props.appendBack) ? 'appendBack' : 'appendFront'}
                name={this.props.name}
                value={this.props.value ? this.props.value : this.props.defaultValue}
                onChange={e => this.onChangeNumber(e, this.props)}
                defaultValue={this.props.defaultValue}
                step={this.props.step}
                min={this.props.min}
                max={this.props.max}
                precision={this.props.precision}
                size="small"
                unitOfMeasurement={this.props.unitOfMeasurement}
                appendBack={this.props.appendBack}
                disabled={this.props.isDisabled}
            />
        );

        return (
            <React.Fragment>
                {(this.props.appendBack) ?

                    // InputGroupAddon Append
                    <InputGroup>
                        {NumPicker}
                        {(!this.props.unitOfMeasurement) ?
                            (null) :
                            (<InputGroupAddon addonType="append">
                                {this.props.unitOfMeasurement}
                            </InputGroupAddon>)
                        }
                    </InputGroup> :

                    // InputGroupAddon Prepend
                    <InputGroup>
                        {(!this.props.unitOfMeasurement) ?
                            (null) :
                            (<InputGroupAddon addonType="prepend">
                                {this.props.unitOfMeasurement}
                            </InputGroupAddon>)
                        }
                        {NumPicker}
                    </InputGroup>
                }
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
NumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeField: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    unitOfMeasurement: PropTypes.string,
    // Note: unitOfMeasurements must be present to be appended or prepended
    appendBack: PropTypes.bool,
    precision: PropTypes.number,
    isDisabled: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
NumberInput.defaultProps = {
    step: 0.1,
    min: 0,
    appendBack: undefined
};


export default withBaseControl(NumberInput);