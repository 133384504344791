import React from 'react';
// import NavContainer from '../Navbar/NavContainer';
// import NavLink from '../Navbar/NavLink';

class NavbarServiceExtension extends React.Component {

    render() {
        return (

            <React.Fragment>

            {/* Paste generated path below to add-on */}
			
			
            </React.Fragment>
        );
    }
}

class NavbarAuthExtension extends React.Component {

    render() {
        return (

            <React.Fragment>

            {/* Paste generated path below to add-on */}
			
			
            </React.Fragment>
        );
    }
}

class NavbarExtension extends React.Component {

    render() {
        return (

            <React.Fragment>
            {/* Sample Path
            <NavContainer navbarLayout={this.props.navbarLayout} path='/serviceName' name='Service Name'>
                 <NavLink onClick={this.props.onClick} navbarLayout={this.props.navbarLayout} to='/serviceName/pageName'>Page Name</NavLink>
            </NavContainer>
            */}

            {/* Paste generated path below to add-on */}

            
            </React.Fragment>
        );
    }
}


export {
    NavbarExtension,
    NavbarServiceExtension,
    NavbarAuthExtension
}