import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';

class TabHeader extends React.Component {

    // Fn: Render Tab Items
    TabItems = ({ tabs, activeTab, toggleTab }) => {
            return _.map(tabs, (tab, i) => {
                return (
                    <NavItem key={i} className="tab-nav-item">
                        <NavLink
                            className= {"tab-header tab-nav-link"}
                            active={(activeTab === tab)}
                            onClick={() => toggleTab(false, false, tab, null)}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                );
            });
    };

    render() {
        return (
            <React.Fragment>
                <Nav tabs className="tab-nav-tabs">
                    {this.TabItems(this.props)}
                </Nav>
                <TabContent activeTab={this.props.activeTab}>
                    {this.props.children}
                </TabContent>
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
TabHeader.propTypes = {
    tabs: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    toggleTab: PropTypes.func.isRequired
};


export default TabHeader;