import React, { Component } from 'react';
import AuthHelper from './AuthHelper';
import { SESSIONSTORAGE_IS_LOGIN, LOCALSTORAGE_IS_LOGIN } from '../../App/AppSettings';
import { withLoader } from '../Loading';

class PublicRoute extends Component {

  state = {
    renderPage: false
  }

  componentDidMount() {
    
    const isLogin = sessionStorage.getItem(SESSIONSTORAGE_IS_LOGIN);
    const isRouteLogin = localStorage.getItem(LOCALSTORAGE_IS_LOGIN);

    const { loader } = this.props;

    if (isLogin !== 'true' && isRouteLogin === 'true') {
      loader.start();
      //Sign to sync login state
      AuthHelper.getManager().signinRedirect({
        state: {
          returnUrl: process.env.PUBLIC_URL+this.props.path,
          referrerAppUrl: document.referrer
        }
      });
    }
    else {
      this.setState({ 
        renderPage: true 
      });
    }

  }

  render() {
    let LoadedComponent = this.props.component;
    return (
      this.state.renderPage &&
      <LoadedComponent {...this.props} />
    );
  }
}

export default withLoader(PublicRoute);