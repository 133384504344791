import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'reactstrap';

class ModalAccordionContainer extends React.Component {

    // Fn: Render the styling of accordion title based on the state of accordion 
    TitleState = () => {
        return (this.props.active) ? 'card-link' : 'card-link collapsed';
    };

    // Fn: Render the icon of caret based on the state of accordion 
    Caret = () => {
        return (this.props.active) ? faCaretDown : faCaretRight;
    };

    render() {
        return (
            
            <div className="card"  >
                <div className="card-header-modal" onClick={() => this.props.onClick(true, this.props.isIndividual, this.props.title, this.props.sections)}>
                    <div className={this.TitleState()}>
                        {this.props.title}
                    </div>
                </div>
                
                <Collapse className="" isOpen={this.props.active}>
                    <div className="card-body">
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        );
    }
}

// PropTypes: For prop validation
ModalAccordionContainer.propTypes = {
    active: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isIndividual: PropTypes.bool,
    sections: PropTypes.object
};

ModalAccordionContainer.defaultProps = {
    isIndividual: false
};

export default ModalAccordionContainer;