import React, { useState, useRef } from "react";
import {
	GoogleMap,
	Marker,
	LoadScript,
	Autocomplete,
} from "@react-google-maps/api";
import "../../../Css/formio.custom.css";

const containerStyle = {
	width: "100%",
	height: "400px",
};

const center = {
	lat: 1.29027,
	lng: 103.851959,
};

const GoogleMapComponent = ({ value, onChange, isView }) => {
	let inputCoord;
	const [address, setAddress] = useState("");
	const geocoderRef = useRef(null);
    
    if (value) {
        const valueSplit = value.split(", ");
        const lat = parseFloat(valueSplit[0].split(": ")[1]);
        const lng = parseFloat(valueSplit[1].split(": ")[1]);

        inputCoord = {
            lat,
            lng,
        };
    } else {
        inputCoord = center;
    }

    const disableMapOptions = {
        draggable: false, // Disable dragging
        scrollwheel: false, // Disable zooming with the scroll wheel
        disableDefaultUI: true, // Disable default UI controls
        zoomControl: false, // Disable zoom control
    };

    const enableMapOptions = {
        draggable: true, 
        scrollwheel: true, 
        disableDefaultUI: false, 
        zoomControl: true, 
    };
	
	const fetchAddressFromCoord = () => {
		if (value && geocoderRef.current) {
            const location = inputCoord;
			geocoderRef.current.geocode({location}, (results, status) => {
				if (status === "OK" && results[0]) {
					setAddress(results[0].formatted_address);
				} else {
					console.error("Geocoder failed due to: " + status);
				}
			});
		}
	};

	const [map, setMap] = useState(null);
	const [markerPosition, setMarkerPosition] = useState(inputCoord);
	const autocompleteRef = useRef(null);
	const [zoom, setZoom] = useState(12);

	const onPlaceChanged = () => {
		const place = autocompleteRef.current.getPlace();
		if (place && place.geometry) {
			const addressFull = place.address_components.map(item => item.long_name).join(', ');
            setAddress(addressFull);
			const { lat, lng } = place.geometry.location;
			const newCenter = {
				lat: lat(),
				lng: lng(),
			};
			const newCenterString =
				"lat: " + newCenter.lat + ", lng: " + newCenter.lng;
			setZoom(14);
			setMarkerPosition(newCenter);
			onChange(newCenterString  + ", address: " + addressFull);
			map.panTo(newCenter);
		}
	};

	const onLoad = (map) => {
		setMap(map);
		geocoderRef.current = new window.google.maps.Geocoder();
        fetchAddressFromCoord();
	};

	return (
		<LoadScript
			googleMapsApiKey="AIzaSyAU583GE9E8p0gkshu6vavl7J3beUhDp20"
			libraries={["places"]}
		>
			<div>
				<Autocomplete
					onLoad={(autocomplete) =>
						(autocompleteRef.current = autocomplete)
					}
					onPlaceChanged={onPlaceChanged}
				>
					<input
						type="text"
						placeholder="Search for a location"
						className="form-control"
						value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        disabled={isView}
					/>
				</Autocomplete>
				<GoogleMap
					id="search-map"
					mapContainerStyle={containerStyle}
					zoom={zoom}
					center={markerPosition}
					onLoad={onLoad}
                    options={!isView ? enableMapOptions : disableMapOptions}
				>
					<Marker position={markerPosition} />
				</GoogleMap>
			</div>
		</LoadScript>
	);
};

export default React.memo(GoogleMapComponent);
