import React from 'react';
import { Col, Row, TabPane } from 'reactstrap';

class WizardContainer extends React.Component {

    render() {
        return (
                <TabPane
                    className={""} 
                >
                    <Row>
                        <Col className="wizard-content">
                            {this.props.children}
                        </Col>
                    </Row>
                </TabPane>
        );
    }
}

export default WizardContainer;