import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as yup from 'yup';
import { ERROR } from '../../Shared/Constants/LanguageKeys';
import { FIELD_SIZE } from '../Constants';
import { FileUpload, Text } from '../Forms';
import { withTranslation } from 'react-i18next';


class ModalFileUpload extends React.Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            fileSelected: '',
            fileDescription: '',
            fileSelectedError: '',
            fileDescriptionError: '',
            fileSelectedSchema : yup.object({
                fileSelected: yup.string().required(t(ERROR.FILE_ITEM_REQUIRED))
            }),
            
            fileDescriptionSchema : yup.object({
                fileDescription: yup.string().required(t(ERROR.FILE_DESC_REQUIRED))
            })
        };
    }

    // Fn: Function to validate file selected
    validateFileSelected = async () => {
        return await this.state.fileSelectedSchema
            .validate({ fileSelected: this.state.fileSelected })
            .then(() => { this.setState({ fileSelectedError: '' }); return true; })
            .catch((err) => { this.setState({ fileSelectedError: err.message }); return false; });
    };

    // // Fn: Function to validate file description
    validateFileDesc = async () => {
        return await this.state.fileDescriptionSchema
            .validate({ fileDescription: this.state.fileDescription })
            .then(() => { this.setState({ fileDescriptionError: '' }); return true; })
            .catch((err) => { this.setState({ fileDescriptionError: err.message }); return false; });
    };

    // Fn: Add selected file into Form state managed by Formik
    confirmFile = async ({ fileName, fileList, onConfirm, toggleModal }) => {

        // Perform validation
        const isFileValid = await this.validateFileSelected();
        const isDescValid = await this.validateFileDesc();

        // If validation did not return error
        if (isFileValid && isDescValid) {
            // Append file description in file object
            Object.defineProperty(this.state.fileSelected, "description", {
                writable: true,
                value: this.state.fileDescription
            });

            // Add file content into existing files array without mutating it
            let newFileList = fileList.concat(this.state.fileSelected);

            // Perform update to Formik managed form state
            onConfirm(fileName, newFileList);

            // Close modal, with a callback to clear local state first
            toggleModal(this.clearFieldStates);
        }
    };

    // Fn: Clears modal fields
    clearFieldStates = () => {
        // Clear local state
        this.setState({
            fileSelected: '',
            fileDescription: '',
            fileSelectedError: '',
            fileDescriptionError: ''
        });
    };

    // Fn: Custom onChange handler for FileUpload & Text component
    onChangeModalField = async (name, value, isFile) => {

        // If file is selected
        if (isFile === true) {
            // Save the file in state locally, perform validation thereafter
            this.setState({ [name]: value });
        }

        // If non-file input is selected (Description input)
        if (isFile === false) {
            // Save the text in state locally, perform validation thereafter
            this.setState({ [name]: value });
        }
    };

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className={"modal-content " + this.props.className} >
                    <ModalHeader>{this.props.contentHeader}</ModalHeader>
                    <ModalBody>
                        <FileUpload
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            name="fileSelected"
                            label="File"
                            onChangeField={(name, value) => this.onChangeModalField(name, value, true)}
                            value={this.state.fileSelected}
                            error={this.state.fileSelectedError}
                            multiple={false}
                            required
                        />
                        <Text
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.XLARGE}
                            name="fileDescription"
                            label="Description"
                            placeholder="Description"
                            onChange={(e) => this.onChangeModalField(e.currentTarget.name, e.currentTarget.value, false)}
                            value={this.state.fileDescription}
                            error={this.state.fileDescriptionError}
                            required
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="backward" size="sm" onClick={() => this.props.toggleModal(this.clearFieldStates)}>{this.props.cancelText}</Button>
                        <Button color="forward" size="sm" onClick={() => this.confirmFile(this.props)}>{this.props.confirmText}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

// PropTypes: For prop validation
ModalFileUpload.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileList: PropTypes.array.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    contentHeader: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string
};

// PropTypes: Defaulting value for optional props
ModalFileUpload.defaultProps = {
    confirmText: 'Confirm',
    cancelText: 'Cancel'
};


export default withTranslation()(ModalFileUpload);