import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Input, Row } from 'reactstrap';
import { GET_FAQ_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import { FooterContentExtension } from '../../Extensions/FooterContentExtension';
import Layout from '../../Layout';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { DEFAULT_NAMESPACE, ERROR } from '../../Shared/Constants/LanguageKeys.js';
import { toastError } from '../../Shared/Forms/Toaster.js';
import { withLoader } from '../../Shared/Loading';
import AccordionTopicSubTopic from './AccordionTopicSubTopic';
// import { default as faqjson } from './faq.json';
import QuestionAnswerDisplay from './QuestionAnswerDisplay';

const dropDownArraySupportedLanguage = 'dropDownArraySupportedLanguage';

class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faqName: '',
            selectedTopic: '',
            selectedSubTopic: '',
            topics: [],
            questionSets: [],
            [dropDownArraySupportedLanguage]: []
        };
    }

    componentDidMount = async () => {
        await this.getFaq();
    };

    // Fn: Get Faq details
    getFaq = async () => {
        const { loader, t, i18n } = this.props;
        console.log(i18n)

        loader.start();
        const response = await fetchRequest(GET_FAQ_URL +  `&LanguageCode=${i18n.language}&IsPublished=true`, getParams());
        // const response = faqjson;

        const { Data, IsSuccess, Message } = response.body;
        if (response.success) {
            if (IsSuccess) {
                let selectedTopic = '';
                let selectedSubTopic = '';
                let questionSets = [];

                if (Data.Topics.length !== 0 && Data.Topics[0].SubTopics.length !== 0) {
                    selectedTopic = Data.Topics[0];
                    selectedSubTopic = Data.Topics[0].SubTopics[0];
                    questionSets = Data.Topics[0].SubTopics[0].QuestionSets;
                }

                await this.setState({
                    values: Data,
                    topics: Data.Topics,
                    faqName: Data.FaqName,
                    isSuccess: true,
                    questionSets,
                    selectedTopic,
                    selectedSubTopic
                });
            } else {
                toastError(Message);
            }
        } else {
            toastError(t(ERROR.BACKEND_ERROR_MESSAGE));
        }

        loader.done();

    };

    handleOnClickSubTopic = (questionSets, selectedTopic, selectedSubTopic) => {
        this.setState({ questionSets, selectedTopic, selectedSubTopic });
    };


    changePageBasedOnLanguage = async (name, value) => {
        this.getFaq(GET_FAQ_URL, value);

    };

    render() {
        const { questionSets, topics, selectedTopic, selectedSubTopic } = this.state;
        const { t } = this.props;

        return (
            <>
                <Layout
                    type={LAYOUT_TYPE.FORM}
                    footer={true}
                    footerExtension={<FooterContentExtension />}
                >
                    <div className='main-content'>

                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    {/* <li className='breadcrumb-item active single' aria-current='page'>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_CONTENT_TITLE_WORKSPACE)}</li> */}
                                    <li className='breadcrumb-item active single' aria-current='page'>FAQ</li>
                                </ol>
                            </div>
                        </nav>
                        <div className='container'>
                            <div className='faq-search'>
                                <p className='tagline'>
                                    How Can we Help You?
                                </p>
                                <div className='faq-searchbar'>
                                    <div className='input-group'>
                                        <Input
                                            className='form-control'
                                            type='text'
                                            value={this.state.intentSearchValue}
                                            name='title'
                                            placeholder={'Type keywords to find your answer'}
                                            onChange={this.inputOnChangeHandler}
                                            minLength={0}
                                            maxLength={140}
                                            onKeyDown={this.inputKeyDownHandler}
                                            innerRef={this.intentInput}
                                        />
                                        <button className="btn btn-orange" type="button" onClick={this.searchValue}>
                                            <i className="fal fa-search" />
                                        </button>
                                    </div>
                                </div>
                                <br />

                            </div>
                            <hr />
                            <div className='main-faq'>
                                <Row>
                                    {
                                        <>
                                            <Row>
                                                <Col className='browse-by-topics' >Browse by Topics</Col>
                                            </Row>
                                            {topics.length > 0 ?
                                                <Row className='faq-questions'>
                                                    <Col xs='12' md='3' className="topic-subtopic">
                                                        {topics.map((topic, topicIndex) => (
                                                            <AccordionTopicSubTopic
                                                                topic={topic}
                                                                key={'topic' + topicIndex}
                                                                handleOnClickSubTopic={this.handleOnClickSubTopic}
                                                                selectedSubTopic={selectedSubTopic}
                                                                t = {t}
                                                            >
                                                            </AccordionTopicSubTopic>
                                                        ))}
                                                    </Col>
                                                    <Col xs='12' md='9' className="question-answer">
                                                        {questionSets.length > 0 ?
                                                            <QuestionAnswerDisplay
                                                                questionSets={questionSets}
                                                                selectedTopic={selectedTopic}
                                                                selectedSubTopic={selectedSubTopic}
                                                            /> :
                                                            t(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS)}
                                                    </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col xs='12'>
                                                        <div>
                                                            {t(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default withTranslation()(withLoader(Faq));
