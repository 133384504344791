import React from 'react';
import { withTranslation } from 'react-i18next';
import { includesMinCharValidation } from '../../Shared/Constants';
import { DirectionContextConsumer } from '../../Shared/DirectionContext';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import RecommendBanner from '../../Shared/Recommend';
import LicenceItemResult from '../../Shared/SearchLicence/LicenceItemResult';
import SearchResultNotFound from '../../Shared/SearchResultNotFound';
import LicenceDetailsModalContent from '../DisplayComponents/LicenceDetailsModalContent';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

const MODAL_NAMES = { DETAILS: 'Details' };

const SECTION_NAME = {
    DOCUMENTS: 'Supporting documents required',
    QUESTIONS: 'FAQ'
};

const EmptyLicence = {
    Description: '',
    DocumentDesc: '',
    FaqDesc: '',
    FeeDesc: '',
    Id: '',
    TimeDesc: '',
    Title: '',
    Url: '',
    DocumentDict: {}
};

const searchMsg = 'Search Results';

class ResultContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sectionState: SECTION_NAME.DOCUMENTS,
            stateLicence: EmptyLicence
        };
    }

    // For the 'detail' button to open modal
    handleDetailClick = (licence, modal) => {
        modal.toggleModal(MODAL_NAMES.DETAILS);
        this.setState({ stateLicence: licence });
    };

    render() {
        const { t, licences, value, modal, fromCart, showRecommendBanner, documents } = this.props;
        const { currLang } = this.props.context;
        const { stateLicence } = this.state;

        let isError;
        let isSuccessNull;

        if (!fromCart && ((includesMinCharValidation(currLang) && value.length < 3) || value === undefined)) {
            isError = true;
        } else if (!fromCart && licences.length === 0) {
            isSuccessNull = true;
        }

        const searchResults = !fromCart ?
            <>
                <h3>{searchMsg} <span>{licences.length} {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_RESULTSMESSAGE)} '{value}'</span></h3>
                <h4>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SERVICE)}</h4>
            </>
            :
            <>
                <h4>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_TITLE_SELECTEDSERVICES)}</h4>
            </>;
        return (
            <React.Fragment>
                {!fromCart && (isError || isSuccessNull) ?
                    (
                        <SearchResultNotFound
                            earchTermLengthError={isError}
                        />
                    ) :
                    (
                        <>
                            {searchResults}
                            {fromCart && licences.length <= 0 &&
                                <SearchResultNotFound />
                            }
                            {licences.map((licence, i) => {
                                return (
                                    <LicenceItemResult
                                        key={i}
                                        licence={licence}
                                        onClick={() => this.handleDetailClick(licence, modal)}
                                        isTdNavigation={false}
                                        inLineStyle={null}
                                        isShowDescription={true}
                                        isShowFeeTime={true}
                                        isShowAddToCart={true}
                                        isShowApply={!fromCart && true}
                                        isShowDetails={true}
                                        isShowPreReq={true}
                                        toggleSection={true}
                                    />
                                )
                            })}
                            {showRecommendBanner && <RecommendBanner />}
                        </>
                    )}
                {stateLicence && stateLicence !== EmptyLicence &&
                    <LicenceDetailsModalContent
                        stateLicence={stateLicence}
                        modal={modal}
                        documents={documents}
                    />
                }
            </React.Fragment>
        );
    }
}

const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ currLang }) => (
                        <WrappedComponent
                            context={{ currLang }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};

export default withTranslation()(withModalWrapper(withDirectionContext(ResultContainer)));
