import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom'
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { CART_ACTION_REMOVE, CART_ACTION_ADD } from '../Constants';
import { navigateTo, fetchRequest, getParams } from '../Actions';
import { LICENCE_CART_PATH, SEARCH_LICENCE_BY_ID_LIST, IS_DEMO } from '../../App/AppSettings';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

class ItemDetailModal extends React.Component {
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    getPreRequisiteLicences = async (licence, updateCart, currentCart) => {
        await updateCart(licence, CART_ACTION_ADD);
        let RETRIEVE_URL = SEARCH_LICENCE_BY_ID_LIST;
        const idList = licence.PreRequisite.map(required => {
            if (!currentCart.some(existing => existing.Id === required.LicenceTypeId)) {
                return `idList=${required.LicenceTypeId}`;
            } else {
                return null;
            }
        });
        RETRIEVE_URL += idList.join('&');
        const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data } = response.body;
        if (response.success && IsSuccess) {
            await updateCart(Data, CART_ACTION_ADD);
        }
        
    };
    displayCartButton = (licence, updateCart, currentCart) => {
        const {t} = this.props;
        if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length > 0) {
            return (
                <button className="btn btn-add-to-cart added" key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE) + licence.Id} onClick={() => updateCart(licence, CART_ACTION_REMOVE)}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_REMOVE)}</button>
            );
        } else {
            return (
                <button className="btn btn-add-to-cart" key={t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART) + licence.Id} onClick={
                    async () => this.getPreRequisiteLicences(licence, updateCart, currentCart)//updateCart(licence, CART_ACTION_ADD)
                }>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_ADDTOCART)}</button>
            );
        }
    };

    pressApplyButton = async (licence, updateCart, currentCart) => {
        if (currentCart.filter(cartLicence => cartLicence.Id === licence.Id).length === 0) {
            //updateCart(licence, CART_ACTION_ADD);
            await this.getPreRequisiteLicences(licence, updateCart, currentCart);
        }
        navigateTo(LICENCE_CART_PATH);
        this.props.applyCallBack();
    }

    render() {
        const {t} = this.props;
        return (
            <LicenceCartContext.Consumer>
                {({ updateCart, currentCart }) => (
                    <div>
                        <Modal size="lg" toggle={this.props.closeCallBack} isOpen={this.props.isOpen} className={"modal-content " + this.props.className}>
                            <ModalHeader toggle={this.props.closeCallBack} charCode='&#x1F5D9;'>{this.props.contentHeader}</ModalHeader>
                            <ModalBody>
                                {this.props.children}
                            </ModalBody>
                            <ModalFooter>
                                {this.props.isShowAddCart && this.displayCartButton(this.props.licence, updateCart, currentCart)}
                                {this.props.isShowApply && <Button outline color="success" size="sm" onClick={async () => this.pressApplyButton(this.props.licence, updateCart, currentCart)}>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_APPLY)}</Button>}
                            </ModalFooter>
                        </Modal>
                    </div>
                )}
            </LicenceCartContext.Consumer>
        );
    }
}

// PropTypes: For prop validation
ItemDetailModal.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    //contentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeText: PropTypes.string,
    closeCallBack: PropTypes.func,
    applyCallBack: PropTypes.func,
    isShowAddCart: PropTypes.bool,
    isShowApply: PropTypes.bool,
    licence: PropTypes.object
};

// PropTypes: Defaulting value for optional props
ItemDetailModal.defaultProps = {
    isOpen: false,
    closeText: 'Close'
};


export default withTranslation()(withRouter(ItemDetailModal));