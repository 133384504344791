import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { DRAFT_SERVER_URL } from '../../App/AppSettings';
import { deleteParams, fetchRequest, navigateTo } from '../../Shared/Actions';
import { ActionButton, MobileCardActionButton, ServerSideDataTable } from '../../Shared/DataTable';
import { toastSuccess, withSMARTWrapper } from '../../Shared/Forms';
import { Modal, ModalConfirm } from '../../Shared/Modal';
import { MIN_FILTER_CHARS } from '../../Shared/Constants';
import { withTranslation } from 'react-i18next';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const FORM_INITIAL_VALUES = {
    FormName: '',
    ReferenceNo: '',
    Status: ''
};

const DELETE_MODAL = {
    hasModal: true,
    name: "Delete",
    modalHeader: "Delete Draft",
    modalContent: "Are you sure you want to delete this draft?"
};

class DraftInbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            data: [],
            collapse: false,
            count: 0,
            modalState: undefined,
            formtypes: [],
            deleteId: 0
        };
    }

    setTranslatedContent = (rowData) => {
        const { t } = this.props;
        let formNamesString = "";
        rowData.FormTypes.forEach((formType, idx) => {
            const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(formType.LicenceTypeName);
            formNamesString += t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, formType.LicenceTypeId)) + (idx === rowData.FormTypes.length - 1 ? "" : ", ")
        })
        return (
            <>{formNamesString}</>
        );
    }

    deleteButtonHandler = (ID, modal) => {
        this.setState({
            deleteId: ID
        })
        modal.toggleModal(DELETE_MODAL.name)

    }

    deleteRow = async () => {

        const { loader, t } = this.props;

        // Set URL
        const DELETE_URL = (DRAFT_SERVER_URL + "/" + this.state.deleteId);

        // Start loading
        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(DELETE_URL, deleteParams(), false);
        const { IsSuccess, Messages } = response.body;
        if (IsSuccess) {
            // Reload Grid Data after deleting form
            await this.props.refreshDraftData();
            toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
            // End loading
            loader.done();

        }
        else {
            // Error message to be loaded here
            loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
        }
    }

    renderMobileCardActions = (data) => {
        const { ID } = data;
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo((`/services/devform/new`), { ID })}
                    icon={faEdit}
                    label='Edit'
                />
                <MobileCardActionButton
                    onClick={() => this.deleteRow}
                    icon={faTrash}
                    label='Delete'
                />
            </React.Fragment>
        )
    }

    renderGrid = () => {

        const { url, extraGetParams, triggerDraftTable, t } = this.props;

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Modal render={({ modal }) => (
                            <>
                                {!extraGetParams.inboxType &&
                                    <ServerSideDataTable
                                        pageSize={5}
                                        minFilterChars={MIN_FILTER_CHARS}
                                        //data={draftData}
                                        extraGetParams={extraGetParams}
                                        url={url}
                                        isCustomFilter={true}
                                        key={triggerDraftTable}
                                        components={this.props.getCustomComponents()}
                                        noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NODRAFTSPENDING)}
                                        columns={
                                            {
                                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_NO), width: '5%', DBkey: 'Id' },
                                                Actions: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTIONS), width: '15%', DBkey: 'Actions' },
                                                FormTypes: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICES), width: '40%', DBkey: 'FormTypes', setContent: this.setTranslatedContent },
                                                LastUpdatedDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_DRAFTDATE), width: '20%', DBkey: 'LastUpdatedDate', isDate: true },
                                            }
                                        }
                                        renderActions={({ ID }) => (
                                            <React.Fragment>
                                                <ActionButton tooltip="Edit" color="neutral" onClick={() => navigateTo(`/services/devform/new`, { ID })}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                                <ActionButton tooltip="Delete" color="danger" onClick={() => this.deleteButtonHandler(ID, modal)}><FontAwesomeIcon icon={faTrash} /></ActionButton>
                                            </React.Fragment>
                                        )}
                                        renderMobileCardActions={this.renderMobileCardActions}
                                    />
                                }
                                <ModalConfirm
                                    isOpen={(modal.modalState === DELETE_MODAL.name)}
                                    contentHeader={DELETE_MODAL.modalHeader}
                                    contentBody={DELETE_MODAL.modalContent}
                                    confirmText="Confirm"
                                    confirmCallback={() => { modal.toggleModal(DELETE_MODAL.name); this.deleteRow(); }}
                                    cancelText="Cancel"
                                    cancelCallback={() => modal.toggleModal(DELETE_MODAL.name)}
                                />
                            </>
                        )} />
                    </Col>
                </Row>
            </React.Fragment>
        );

    }

    render() {
        return (
            <React.Fragment>
                {this.renderGrid()}
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(DraftInbox));