import React from 'react';
import { Button } from 'reactstrap';
import { ADDRESS, DATE_DISPLAY_FORMAT, DEFAULT_HOME_COUNTRY, DEFAULT_OVERSEAS_COUNTRY_ID_TYPE, MASK } from '../../../App/AppSettings';
import { FIELD_SIZE, OPTION_DATA_CODE_CATEGORY } from '../../../Shared/Constants';
import { AccordionContainer, MaskedInput, SelectList, Text } from '../../../Shared/Forms';
import DateInput from '../../../Shared/Forms/DateInput';
import Radio from '../../../Shared/Forms/Radio';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';

const SECTION_NAMES = {
    APPLICANT_PARTICULARS: { title: "Applicant's Particulars", status: true },
    APPLICANT_ADDRESS: { title: "Applicant's Address", status: true },
    ENTITY_DETAILS: { title: `Entity details`, status: true },
    ENTITY_REGISTERED_ADDRESS: { title: `Entity registered address`, status: true },
    ENTITY_OPERATING_ADDRESS: { title: `Entity operating address`, status: true },
    ENTITY_CONTACT_DETAILS: { title: `Entity contact details`, status: true },
    MAILING_ADDRESS: { title: `Mailing Address`, status: true }
};

class CorporateGI extends React.Component {
    copyRegisteredAddressToMailingAddress = (onChangeField, values) => {
        const vgi = values.GeneralInformation;
        onChangeField('GeneralInformation.GI_MailingAddressAddressType', ADDRESS.ADDRESS_TYPE.LOCAL);
        onChangeField('GeneralInformation.GI_MailingAddressTypeOfPremises', vgi.GI_EntityregisteredaddressTypeofpremise);
        onChangeField('GeneralInformation.GI_MailingAddressPostalCode', vgi.GI_EntityregisteredaddressPostalcode);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1BlockNo', vgi.GI_EntityregisteredaddressBlockHousenumber);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1StreetName', vgi.GI_EntityregisteredaddressStreetname);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2LevelNo', vgi.GI_EntityregisteredaddressLevel);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2UnitNo', vgi.GI_EntityregisteredaddressUnitNumber);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2BuildingName', vgi.GI_EntityregisteredaddressBuildingName);
    };

    copyOperatingAddressToMailingAddress = (onChangeField, values) => {
        const vgi = values.GeneralInformation;
        onChangeField('GeneralInformation.GI_MailingAddressAddressType', ADDRESS.ADDRESS_TYPE.LOCAL);
        onChangeField('GeneralInformation.GI_MailingAddressTypeOfPremises', vgi.GI_EntityoperatingaddressTypeofpremise);
        onChangeField('GeneralInformation.GI_MailingAddressPostalCode', vgi.GI_EntityoperatingaddressPostalcode);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1BlockNo', vgi.GI_EntityoperatingaddressBlockHousenumber);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine1StreetName', vgi.GI_EntityoperatingaddressStreetname);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2LevelNo', vgi.GI_EntityoperatingaddressLevel);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2UnitNo', vgi.GI_EntityoperatingaddressUnitNumber);
        onChangeField('GeneralInformation.GI_MailingAddressAddressLine2BuildingName', vgi.GI_EntityoperatingaddressBuildingName);
    };

    render() {
        const { values, onChange, onChangeField, errors, toggleSection, toggleAllSections } = this.props.smartFormValues;
        const { state, t } = this.props;

        return (
            <>
                <div className="heading-bg">General Information</div>
                <div className="collapse-expand">
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(SECTION_NAMES, true)} >collapse all</Button>
                    <Button color="toggle" className="toggle-all" onClick={() => toggleAllSections(SECTION_NAMES, false)} >expand all</Button>
                </div>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantParticulars'))}
                    active={SECTION_NAMES.APPLICANT_PARTICULARS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <SelectList
                            name="GeneralInformation.GI_Salutation"
                            value={values.GeneralInformation.GI_Salutation}
                            options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.SALUTATION]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Salutation'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Salutation'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Salutation}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_Name"
                            value={values.GeneralInformation.GI_Name}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Name'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Name'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Name}
                            required
                            readOnly
                        />
                        <SelectList
                            name="GeneralInformation.GI_IDtype"
                            value={values.GeneralInformation.GI_IDtype}
                            options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.ID_TYPE]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_IDtype'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Salutation'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_IDtype}
                            required
                            isDisabled
                        />

                        <Text
                            name="GeneralInformation.GI_IDnumber"
                            value={values.GeneralInformation.GI_IDnumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_IDnumber'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDnumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_IDnumber}
                            required
                            readOnly
                        />
                        {values.GeneralInformation.GI_IDtype === DEFAULT_OVERSEAS_COUNTRY_ID_TYPE &&
                            <SelectList
                                name="GeneralInformation.GI_Passportissuingcountry"
                                value={values.GeneralInformation.GI_Passportissuingcountry}
                                options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY].filter(function (value) {
                                    return value.value !== DEFAULT_HOME_COUNTRY
                                })}
                                onChangeField={onChangeField}
                                placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Passportissuingcountry'))}
                                isMulti={false}
                                labelSize={FIELD_SIZE.MEDIUM}
                                inputSize={FIELD_SIZE.MEDIUM}
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Passportissuingcountry'))}
                                error={errors.GeneralInformation && errors.GeneralInformation.GI_Passportissuingcountry}
                                required
                                isDisabled
                            />
                        }
                        <SelectList
                            name="GeneralInformation.GI_Nationality"
                            value={values.GeneralInformation.GI_Nationality}
                            options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Nationality'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Nationality'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Nationality}
                            required
                            isDisabled
                        />
                        <Radio
                            name="GeneralInformation.GI_Gender"
                            value={values.GeneralInformation.GI_Gender}
                            options={[
                                { label: 'Male', value: 'Male', key: 'Male' },
                                { label: 'Female', value: 'Female', key: 'Female' }
                            ]}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Gender'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Gender}
                            required
                            disabled
                        />
                        <DateInput
                            name="GeneralInformation.GI_DateOfBirth"
                            value={values.GeneralInformation.GI_DateOfBirth}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_DateOfBirth'))}
                            onChangeField={onChangeField}
                            time={false}
                            date={true}
                            dropUp={true}
                            min="1900-01-01"
                            max={state.todayDate}
                            format={DATE_DISPLAY_FORMAT.DATE}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_DateOfBirth'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_DateOfBirth}
                            required
                            disabled
                        />
                        <Radio
                            name="GeneralInformation.GI_PrimaryContact"
                            value={values.GeneralInformation.GI_PrimaryContact}
                            options={[
                                { label: 'Mobile Number', value: 'Mobile' },
                                { label: 'Office Number', value: 'Office' },
                                { label: 'Home Number', value: 'Home' }]}
                            onChange={onChange}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_PrimaryContact'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_PrimaryContact'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_PrimaryContact}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_MobileNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MobileNumber'))}
                            value={values.GeneralInformation.GI_MobileNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MobileNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MobileNumber}
                            required={values.GeneralInformation.GI_PrimaryContact === 'Mobile'}
                        />
                        <Text
                            name="GeneralInformation.GI_OfficeNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_OfficeNumber'))}
                            value={values.GeneralInformation.GI_OfficeNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_OfficeNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_OfficeNumber}
                            required={values.GeneralInformation.GI_PrimaryContact === 'Office'}
                        />
                        <Text
                            name="GeneralInformation.GI_HomeNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_HomeNumber'))}
                            value={values.GeneralInformation.GI_HomeNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_HomeNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_HomeNumber}
                            required={values.GeneralInformation.GI_PrimaryContact === 'Home'}
                        />
                        <Text
                            name="GeneralInformation.GI_FaxNumber"
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_FaxNumber'))}
                            value={values.GeneralInformation.GI_FaxNumber}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_FaxNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_FaxNumber}
                        />
                        <SelectList
                            name="GeneralInformation.GI_Designation"
                            value={values.GeneralInformation.GI_Designation}
                            options={[
                                { label: 'Managing Director', value: 'Managing Director' },
                                { label: 'Manager', value: 'Manager' },
                                { label: 'Officer', value: 'Officer' },
                                { label: 'Secretary', value: 'Secretary' },
                                { label: 'Director', value: 'Director' },
                                { label: 'General Manager', value: 'General Manager' },
                                { label: 'Shareholder', value: 'Shareholder' },
                                { label: 'Director and shareholder', value: 'Director and shareholder' },
                                { label: 'Auditor', value: 'Auditor' },
                                { label: 'Agent', value: 'Agent' },
                                { label: 'Others', value: 'Others' }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Designation'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Designation'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Designation}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_Email"
                            value={values.GeneralInformation.GI_Email}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_Email'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Email'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_Email}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_AltEmailAddress"
                            value={values.GeneralInformation.GI_AltEmailAddress}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AltEmailAddress'))}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AltEmailAddress'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AltEmailAddress}
                        />
                    </div>
                </AccordionContainer>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantAddress'))}
                    active={SECTION_NAMES.APPLICANT_ADDRESS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <Radio
                            name="GeneralInformation.GI_AddressAddressType"
                            value={values.GeneralInformation.GI_AddressAddressType}
                            options={state.addressTypeOptions}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressType'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressType}
                            required
                        />
                        <SelectList
                            name="GeneralInformation.GI_AddressTypeOfPremises"
                            value={values.GeneralInformation.GI_AddressTypeOfPremises}
                            options={[
                                { label: 'Residential', value: 'Residential' },
                                { label: 'Office', value: 'Office' },
                                { label: 'Others', value: 'Others' }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressTypeOfPremises'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressTypeOfPremises'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressTypeOfPremises}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_AddressPostalCode"
                            value={values.GeneralInformation.GI_AddressPostalCode}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressPostalCode'))}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressPostalCode'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressPostalCode}
                            required
                        />
                        {values.GeneralInformation.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                            <>
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine1BlockNo"
                                    value={values.GeneralInformation.GI_AddressAddressLine1BlockNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine1BlockNo}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine1StreetName"
                                    value={values.GeneralInformation.GI_AddressAddressLine1StreetName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine1StreetName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1StreetName'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine1StreetName}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2LevelNo"
                                    value={values.GeneralInformation.GI_AddressAddressLine2LevelNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2UnitNo"
                                    value={values.GeneralInformation.GI_AddressAddressLine2UnitNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2BuildingName"
                                    value={values.GeneralInformation.GI_AddressAddressLine2BuildingName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                />
                            </>
                        }
                        {values.GeneralInformation.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                            <>
                                <SelectList
                                    name="GeneralInformation.GI_AddressCountry"
                                    value={values.GeneralInformation.GI_AddressCountry}
                                    options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY].filter(country => {
                                        return country.value !== DEFAULT_HOME_COUNTRY
                                    })}
                                    onChangeField={onChangeField}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressCountry'))}
                                    isMulti={false}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCountry'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressCountry}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine1AddressLine1"
                                    value={values.GeneralInformation.GI_AddressAddressLine1AddressLine1}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                    // helpLabel="Address Line 1"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine1AddressLine1}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressAddressLine2AddressLine2"
                                    value={values.GeneralInformation.GI_AddressAddressLine2AddressLine2}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                    // helpLabel="Address Line 2"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressAddressLine2AddressLine2}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressCity"
                                    value={values.GeneralInformation.GI_AddressCity}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressCity'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCity'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressCity}
                                />
                                <Text
                                    name="GeneralInformation.GI_AddressState"
                                    value={values.GeneralInformation.GI_AddressState}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_AddressState'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressState'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_AddressState}
                                    required
                                />
                            </>
                        }
                    </div>
                </AccordionContainer>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_EntityDetails'))}
                    active={SECTION_NAMES.ENTITY_DETAILS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <Text
                            name="GeneralInformation.GI_EntitydetailsUEN"
                            value={values.GeneralInformation.GI_EntitydetailsUEN}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntitydetailsUEN'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntitydetailsUEN'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntitydetailsUEN}
                            readOnly
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntitydetailsEntityname"
                            value={values.GeneralInformation.GI_EntitydetailsEntityname}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntitydetailsEntityname'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntitydetailsEntityname'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntitydetailsEntityname}
                            required
                        />
                        <SelectList
                            name="GeneralInformation.GI_EntitydetailsOrganisationType"
                            value={values.GeneralInformation.GI_EntitydetailsOrganisationType}
                            options={[
                                { label: 'Private Company', value: 'PRIVATE COMPANY' },
                                { label: 'Public Company', value: 'PUBLIC COMPANY' },
                                { label: 'Limited Liability Partnership', value: 'LIMITED LIABILITY PARTNERSHIP' },
                                { label: 'Limited Partnership', value: 'LIMITED PARTNERSHIP' },
                                { label: 'Sole Proprietorship', value: 'SOLE PROPRIETORSHIP' }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntitydetailsOrganisationType'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntitydetailsOrganisationType'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntitydetailsOrganisationType}
                            required
                        />
                    </div>
                </AccordionContainer>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_EntityRegisteredAddress'))}
                    active={SECTION_NAMES.ENTITY_REGISTERED_ADDRESS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <SelectList
                            name="GeneralInformation.GI_EntityregisteredaddressTypeofpremise"
                            value={values.GeneralInformation.GI_EntityregisteredaddressTypeofpremise}
                            options={[
                                { label: 'Residential', value: 'Residential' },
                                { label: 'Office', value: 'Office' },
                                { label: 'Others', value: 'Others' },
                                { label: 'Retail outlet', value: 'Retail outlet' },
                                { label: 'Show room', value: 'Show room' },
                                { label: 'Warehouse', value: 'Warehouse' }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressTypeofpremise'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressTypeofpremise'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressTypeofpremise}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityregisteredaddressPostalcode"
                            value={values.GeneralInformation.GI_EntityregisteredaddressPostalcode}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressPostalcode'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressPostalcode'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressPostalcode}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityregisteredaddressBlockHousenumber"
                            value={values.GeneralInformation.GI_EntityregisteredaddressBlockHousenumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressBlockHousenumber'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressBlockHousenumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressBlockHousenumber}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityregisteredaddressStreetname"
                            value={values.GeneralInformation.GI_EntityregisteredaddressStreetname}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressStreetname'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressStreetname'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressStreetname}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityregisteredaddressLevel"
                            value={values.GeneralInformation.GI_EntityregisteredaddressLevel}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressLevel'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressLevel'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressLevel}
                        />
                        <Text
                            name="GeneralInformation.GI_EntityregisteredaddressUnitNumber"
                            value={values.GeneralInformation.GI_EntityregisteredaddressUnitNumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressUnitNumber'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressUnitNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressUnitNumber}
                        />
                        <Text
                            name="GeneralInformation.GI_EntityregisteredaddressBuildingName"
                            value={values.GeneralInformation.GI_EntityregisteredaddressBuildingName}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityregisteredaddressBuildingName'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityregisteredaddressBuildingName'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityregisteredaddressBuildingName}
                        />
                    </div>
                </AccordionContainer>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_EntityOperatingAddress'))}
                    active={SECTION_NAMES.ENTITY_OPERATING_ADDRESS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <SelectList
                            name="GeneralInformation.GI_EntityoperatingaddressTypeofpremise"
                            value={values.GeneralInformation.GI_EntityoperatingaddressTypeofpremise}
                            options={[
                                { label: 'Residential', value: 'Residential' },
                                { label: 'Office', value: 'Office' },
                                { label: 'Others', value: 'Others' },
                                { label: 'Retail outlet', value: 'Retail outlet' },
                                { label: 'Show room', value: 'Show room' },
                                { label: 'Warehouse', value: 'Warehouse' }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressTypeofpremise'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressTypeofpremise'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressTypeofpremise}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityoperatingaddressPostalcode"
                            value={values.GeneralInformation.GI_EntityoperatingaddressPostalcode}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressPostalcode'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressPostalcode'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressPostalcode}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityoperatingaddressBlockHousenumber"
                            value={values.GeneralInformation.GI_EntityoperatingaddressBlockHousenumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressBlockHousenumber'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressBlockHousenumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressBlockHousenumber}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityoperatingaddressStreetname"
                            value={values.GeneralInformation.GI_EntityoperatingaddressStreetname}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressStreetname'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressStreetname'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressStreetname}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_EntityoperatingaddressLevel"
                            value={values.GeneralInformation.GI_EntityoperatingaddressLevel}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressLevel'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressLevel'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressLevel}
                        />
                        <Text
                            name="GeneralInformation.GI_EntityoperatingaddressUnitNumber"
                            value={values.GeneralInformation.GI_EntityoperatingaddressUnitNumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressUnitNumber'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressUnitNumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressUnitNumber}
                        />
                        <Text
                            name="GeneralInformation.GI_EntityoperatingaddressBuildingName"
                            value={values.GeneralInformation.GI_EntityoperatingaddressBuildingName}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntityoperatingaddressBuildingName'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntityoperatingaddressBuildingName'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntityoperatingaddressBuildingName}
                        />
                    </div>
                </AccordionContainer>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_EntityContactDetails'))}
                    active={SECTION_NAMES.ENTITY_CONTACT_DETAILS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <MaskedInput
                            name="GeneralInformation.GI_EntitycontactdetailsTelephone"
                            value={values.GeneralInformation.GI_EntitycontactdetailsTelephone}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntitycontactdetailsTelephone'))}
                            onChange={onChange}
                            mask={MASK.TELEPHONE}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntitycontactdetailsTelephone'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntitycontactdetailsTelephone}
                            required
                        />
                        <MaskedInput
                            name="GeneralInformation.GI_EntitycontactdetailsFaxnumber"
                            value={values.GeneralInformation.GI_EntitycontactdetailsFaxnumber}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntitycontactdetailsFaxnumber'))}
                            onChange={onChange}
                            mask={MASK.TELEPHONE}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntitycontactdetailsFaxnumber'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntitycontactdetailsFaxnumber}
                        />
                        <Text
                            name="GeneralInformation.GI_EntitycontactdetailsBusinessemail"
                            value={values.GeneralInformation.GI_EntitycontactdetailsBusinessemail}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_EntitycontactdetailsBusinessemail'))}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_EntitycontactdetailsBusinessemail'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_EntitycontactdetailsBusinessemail}
                            required
                        />
                    </div>
                </AccordionContainer>
                <AccordionContainer
                    className="accordion"
                    title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_MailingAddress'))}
                    active={SECTION_NAMES.MAILING_ADDRESS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <div className="card-body">
                        <Button color="mailing" size="sm" type="button" onClick={() => this.copyRegisteredAddressToMailingAddress(onChangeField, values)}>Copy from Registered Address</Button>
                        <Button color="mailing" size="sm" type="button" onClick={() => this.copyOperatingAddressToMailingAddress(onChangeField, values)}>Copy from Operating Address</Button>
                        <Radio
                            name="GeneralInformation.GI_MailingAddressAddressType"
                            value={values.GeneralInformation.GI_MailingAddressAddressType}
                            options={state.addressTypeOptions}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.NONE}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressType'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressType}
                            required
                        />
                        <SelectList
                            name="GeneralInformation.GI_MailingAddressTypeOfPremises"
                            value={values.GeneralInformation.GI_MailingAddressTypeOfPremises}
                            options={[
                                { label: 'Residential', value: 'Residential' },
                                { label: 'Office', value: 'Office' },
                                { label: 'Retail outlet', value: 'Retail outlet' },
                                { label: 'Show room', value: 'Show room' },
                                { label: 'Warehouse', value: 'Warehouse' },
                                { label: 'Others', value: 'Others' }]}
                            onChangeField={onChangeField}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressTypeOfPremises'))}
                            isMulti={false}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressTypeOfPremises'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressTypeOfPremises}
                            required
                        />
                        <Text
                            name="GeneralInformation.GI_MailingAddressPostalCode"
                            value={values.GeneralInformation.GI_MailingAddressPostalCode}
                            onChange={onChange}
                            labelSize={FIELD_SIZE.MEDIUM}
                            inputSize={FIELD_SIZE.MEDIUM}
                            placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressPostalCode'))}
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressPostalCode'))}
                            error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressPostalCode}
                            required
                        />
                        {values.GeneralInformation.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                            <>
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine1BlockNo"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine1BlockNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine1BlockNo}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine1StreetName"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine1StreetName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine1StreetName}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2LevelNo"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2LevelNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2UnitNo"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2UnitNo}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2BuildingName"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2BuildingName}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                />
                            </>
                        }
                        {values.GeneralInformation.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                            <>
                                <SelectList
                                    name="GeneralInformation.GI_MailingAddressCountry"
                                    value={values.GeneralInformation.GI_MailingAddressCountry}
                                    options={state.optionDataList[OPTION_DATA_CODE_CATEGORY.COUNTRY].filter(country => {
                                        return country.value !== DEFAULT_HOME_COUNTRY
                                    })}
                                    onChangeField={onChangeField}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressCountry'))}
                                    isMulti={false}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCountry'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressCountry}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine1AddressLine1"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine1AddressLine1}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                    // helpLabel="Address Line 1"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine1AddressLine1}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressAddressLine2AddressLine2"
                                    value={values.GeneralInformation.GI_MailingAddressAddressLine2AddressLine2}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                    // helpLabel="Address Line 2"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressAddressLine2AddressLine2}
                                    required
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressCity"
                                    value={values.GeneralInformation.GI_MailingAddressCity}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressCity'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCity'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressCity}
                                />
                                <Text
                                    name="GeneralInformation.GI_MailingAddressState"
                                    value={values.GeneralInformation.GI_MailingAddressState}
                                    onChange={onChange}
                                    labelSize={FIELD_SIZE.MEDIUM}
                                    inputSize={FIELD_SIZE.MEDIUM}
                                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY, 'GI_MailingAddressState'))}
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressState'))}
                                    error={errors.GeneralInformation && errors.GeneralInformation.GI_MailingAddressState}
                                    required
                                />
                            </>
                        }
                    </div>
                </AccordionContainer>
            </>
        );
    }
}

export default withTranslation()(CorporateGI);
