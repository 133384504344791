import React from 'react';
import { compose } from 'recompose';
import { withLoader } from '../Shared/Loading';
import { withPageNotFound } from '../Shared/Error';

export const withAppWrapper = (FormComponent) => {

    class AppWrapper extends React.Component {
        render() {
            return (
                <FormComponent
                    {...this.props}
                />
            );
        }
    };

    return compose(withPageNotFound, withLoader)(AppWrapper);
};