import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import LicenceDetailsModalContent from '../DisplayComponents/LicenceDetailsModalContent';
import ExclusiveLicenceItemResult from './ExclusiveLicenceItemResult';
import { LicenceCartContext } from '../../Shared/LicenceCartContext';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

const MODAL_NAMES = { DETAILS: 'Details' };

const EmptyLicence = {
    Description: '',
    DocumentDesc: '',
    FaqDesc: '',
    FeeDesc: '',
    Id: '',
    TimeDesc: '',
    Title: '',
    Url: '',
    DocumentDict: {}
};

const withLicenceContext = WrappedComponent => {
    class LicenceContext extends React.Component {
        render() {
            return (
                <LicenceCartContext.Consumer>
                    {({ currentCart }) => (
                        <WrappedComponent
                            context={{
                                currentCart
                            }}
                            {...this.props}
                        />
                    )}
                </LicenceCartContext.Consumer>
            );
        }
    }

    return LicenceContext;
};

class ExclusiveModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stateLicence: EmptyLicence,
            buttonDisabled: true
        };
    }

    // This method is used to disable the button when exclusive pairs exists in the currentCart
    // It will be triggered whenever the cart is updated from the add/remove to cart button.
    customUpdateCartCallback = (exclusiveCart, currentCart, checkExclusiveCondition) => {
        let checkButtonDisabled = false;
        //Exclusive cart is the list of exclusive licence pairs in the current cart.
        // List of set of exclusive licence
        exclusiveCart.forEach((ec, index) => {
            // Licence1 is the First licence that come in the loop
            let licence1 = null;
            // Licence2 is the second licence that come in the loop
            let licence2 = null;
            // by looping through each pair of licence in the cart, we would know if any pair exists at the same time
            // If both licence exist at the same time, it will disable the 'Continue' button accordingly
            ec[index].forEach(licence => {
                // If exclusive licence Id exist in currentCart
                if (currentCart.some(cc => cc.Id === licence.Id)) {
                    // If licence 1 is in currentCart
                    if (licence1 == null) {
                        licence1 = true;
                    }
                    // If licence 2 is in currentCart
                    else {
                        licence2 = true;
                    }
                }
                // If licence 1 & 2 is in currentCart (this will disable the button)
                if (licence1 && licence2) {
                    checkButtonDisabled = true;
                }
            });
        });

        if (checkButtonDisabled) {
            //Disable continue button
            checkExclusiveCondition(true);
        } else {
            //Enable continue button
            checkExclusiveCondition(false);
        }
    };

    // For the 'detail' button to open modal
    handleDetailClick = (licence, modal) => {
        modal.toggleModal(MODAL_NAMES.DETAILS);
        this.setState({ stateLicence: licence });
    };

    render() {
        const { buttonDisabled, checkExclusiveCondition, className, closeCallBack, contentHeader, displayExclusiveModal, exclusiveCart, isOpen, modal, t } = this.props;
        const { stateLicence } = this.state;

        return (
            <div>
                <Modal size='lg' isOpen={isOpen} className={`modal-content ${className}`}>
                    <ModalHeader toggle={closeCallBack} charCode='&#x1F5D9;'>{contentHeader}</ModalHeader>
                    <ModalBody>
                        <div className="div-modal-body">
                            <p>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_EXCLUSIVELICENCEMESSAGE)}</p>
                            <p className="label-error">
                                {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_PLEASE)} <u>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_REMOVEINLOWERCASE)}</u> {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_REMOVELICENCEMESSAGE)}
                            </p>
                        </div>
                        {exclusiveCart.length > 0 && exclusiveCart.map((ec, index) => {
                            return (
                                <div>
                                    <p><u>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_EXCLUSIVELICENCESSET)}</u></p>
                                    <div key={index} className="div-exclusive-licence-set">
                                        {ec[index].map((licence, eindex) => {
                                            return (
                                                <>
                                                    {
                                                        // Add only the licence name if is the first licence
                                                        eindex % 2 === 0 &&
                                                        <ExclusiveLicenceItemResult
                                                            key={eindex}
                                                            licence={licence}
                                                            onClick={() => this.handleDetailClick(licence, modal)}
                                                            customUpdateCartCallback={() =>
                                                                this.customUpdateCartCallback(exclusiveCart, this.props.context.currentCart, checkExclusiveCondition)
                                                            }
                                                            isShowAddToCart={true}
                                                        />
                                                    }
                                                    {
                                                        // Check if it is the second licence of the set.
                                                        // Add div in the middle to seperate the first licence and second licence
                                                        eindex % 2 === 1 &&
                                                        <>
                                                            <div className="div-exclusive-licence-divider">
                                                                <span className="div-exclusive-licence-centre-box">
                                                                    {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_MANDATORY_CANNOTBEAPPLIEDTOGETHER)}
                                                                </span>
                                                            </div>
                                                            <ExclusiveLicenceItemResult
                                                                key={eindex}
                                                                licence={licence}
                                                                onClick={() => this.handleDetailClick(licence, modal)}
                                                                customUpdateCartCallback={() =>
                                                                    this.customUpdateCartCallback(exclusiveCart, this.props.context.currentCart, checkExclusiveCondition)
                                                                }
                                                                isShowAddToCart={true}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {/* Detail Modal */}
                        {stateLicence && stateLicence !== EmptyLicence &&
                            <LicenceDetailsModalContent
                                stateLicence={stateLicence}
                                modal={modal}
                            />
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='primary'
                            onClick={closeCallBack}>
                            Back to cart summary
                        </Button>
                        <Button
                            color='primary'
                            onClick={() => displayExclusiveModal()}
                            disabled={buttonDisabled}
                        >
                            Continue
                        </Button>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }
}

// PropTypes: For prop validation
ExclusiveModal.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    //contentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeCallBack: PropTypes.func,
    addToCartCallBack: PropTypes.func,
    applyCallBack: PropTypes.func,
    isShowAddCart: PropTypes.bool,
    isShowApply: PropTypes.bool,
    errorMessage: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
ExclusiveModal.defaultProps = {
    isOpen: false,
    errorMessage: false
};


export default withTranslation()(withRouter(withModalWrapper(withLicenceContext(ExclusiveModal))));
