import React from 'react';
import { Button, Col } from 'reactstrap';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
    AMEND_APPLICATION_URL, DASHBOARD_PATH, FORM_TYPE, GENERAL_INFORMATION_VALIDFORMTYPE,
    GET_LICENCE_DETAILS_FROM_SFORM_ID_URL, GET_RFA_SFORM_FORM_DATA_URL, LAYOUT_TYPE
} from '../../App/AppSettings';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { SECTION_WRAPPER_TYPES, DELETE_FILES_ARRAY } from '../../Shared/Constants';
import { SMARTForm } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import { getFormNames, getRemoteList, getTableListValues } from '../FormComponents/FormUtils';
import { GeneralInformationComponent } from '../FormComponents/GeneralInformation/GeneralInformationComponent';
import { getAddressTypeOptions } from '../FormComponents/GeneralInformation/Utils.js';
import {
    createValidationSchemaForDeclaration, createValidationSchemaForGeneralInfo, createValidationSchemaForLicenceFields,
    createValidationSchemaForSupportingDocuments
} from '../FormComponents/LicenceApplicationInformation/ValidationSchema';
import SupportingDocuments from '../FormComponents/SupportingDocuments';
import RFAReviewAndSubmit from './RFAReviewAndSubmit';
import RFAFormAmend from './RFAFormAmend';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS, WIZARD_TAB_NAMES } from '../../Shared/Constants/LanguageKeys';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.WIZARD;
const FORM_CONTEXT = 'RFAForm';

const PREVIEW_URL = '/services/devform/preview';
const NAVIGATE_URL = PREVIEW_URL;
const SUCCESS_URL = '/submissionSuccess';

const PAYMENT_TAB_DISPLAY = false;

class RFAAmendment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            devData: {},
            licenceIsLoaded: false,
            formNames: {},
            validationSchema: '',
            FORM: {
                FORM_CONTEXT,
                FORM_TYPE: FORM_TYPE.NEW,
                FORM_INITIAL_VALUES: {}
            },
            selectedIdTypeArr: [],
            idNumberArr: [],
            isIdNumbersValid: true,
            idNumberValidationError: '',
            selectedCountries: [],
            addressTypeOptions: getAddressTypeOptions(),
            applicationDetails: {
                DocumentList: [],
                FormData: {
                    LicenceInformation: null
                }
            },
            optionDataList: {}
        };
    }

    async componentDidMount() {
        this.getAmendLicence();
    }

    async getAmendLicence() {
        const { loader, application } = this.props;
        loader.start();
        const response = await fetchRequest(GET_RFA_SFORM_FORM_DATA_URL + this.props.application.SFormId, getParams());
        const { IsSuccess, Data } = response.body;
        if (response.success && IsSuccess) {
            const applicationDetails = Object.assign({}, Data, { FormData: JSON.parse(Data.FormData) });

            await this.getOptionsDataList(applicationDetails);

            this.setState(() => {
                return {
                    applicationDetails,
                    formNames: getFormNames(application.LicenceName, applicationDetails.DocumentList, PAYMENT_TAB_DISPLAY),
                    values: this.getFilledValues(applicationDetails.FormDefinition, applicationDetails.FormData, applicationDetails.FileKeyIDList,
                        applicationDetails.GeneralDefinition, applicationDetails.DocumentList, applicationDetails.GeneralDefinition.FormType),
                    form: Object.assign({}, applicationDetails.FormDefinition, { FormName: application.LicenceName, FormType: application.FormType }),
                    validationSchema: this.createValidationSchema(applicationDetails, applicationDetails.GeneralDefinition.FormType),
                    declarations: applicationDetails.DeclarationList,
                    generalInformationType: applicationDetails.GeneralDefinition.FormType
                };
            }, () => this.getLicenceDetails(application.SFormId, loader));
        }
        loader.done();
    }


    async getLicenceDetails(sFormId, loader) {
        const response = await fetchRequest(GET_LICENCE_DETAILS_FROM_SFORM_ID_URL + sFormId, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {
            if (Data.HasApplicationInProgress) {
                this.setState(() => {
                    return {
                        licence: Object.assign({}, Data, { LicenceData: JSON.parse(Data.LicenceData) })
                    };
                }, () => {
                    loader.done();
                });
            }
            this.setState({
                licenceIsLoaded: true
            });
            loader.done();
        } else {
            loader.done();
        }
    }

    getOptionsDataList = async devData => {
        const { t } = this.props;
        const listOfCategory = [];
        //loop through all the fields to collate all the options required for the entire form

        //GeneralDefinition
        devData.GeneralDefinition.Sections.forEach(section => {
            section.Fields.forEach(field => {
                const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(field.DataParameters.Category) < 0) {
                    listOfCategory.push(field.DataParameters.Category);
                }
            });
        });

        //FormDefinition
        devData.FormDefinition.Sections.forEach(formDefSection => {
            formDefSection.Fields && formDefSection.Fields.forEach(formDefField => {
                const isRemote = formDefField.DataSource !== undefined && formDefField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefField.DataParameters.Category);
                }
            });
            formDefSection.Table && formDefSection.Table.Fields.forEach(formDefTableField => {
                const isRemote = formDefTableField.DataSource !== undefined && formDefTableField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefTableField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefTableField.DataParameters.Category);
                }
            });
        });
        let totalOptionsList = null;
        if (listOfCategory.length !== 0) {
            totalOptionsList = await getRemoteList(t, listOfCategory);
        }
        this.setState({
            optionDataList: totalOptionsList
        });
    }

    getFilledValues = (formDefinition, formData, fileKeyIDList, generalInfoFields, documentList, generalInformationType) => {
        const licenceFieldNames = {};
        const formTypeNames = {
            FormTypes: [formDefinition.FormType, GENERAL_INFORMATION_VALIDFORMTYPE]
        };

        const licenceInfo = formData.LicenceInformation;
        const generalInfo = formData.GeneralInformation;
        licenceFieldNames[formDefinition.FormType] = {};

        const tableValueKeys = Object.keys(licenceInfo);

        //get filled values for licence fields
        formDefinition.Sections.forEach(section => {
            section.Fields && section.Fields.forEach(field => {
                if (licenceInfo[field.Name] !== undefined && licenceInfo[field.Name] !== '') {
                    licenceFieldNames[formDefinition.FormType][field.Name] = licenceInfo[field.Name];
                }
                else {
                    licenceFieldNames[formDefinition.FormType][field.Name] = '';
                }
            });

            if (section.Table) {
                licenceFieldNames[formDefinition.FormType][section.Name] = [];
                const sectionTableList = getTableListValues(tableValueKeys, section.Name);

                sectionTableList && sectionTableList[section.Name].forEach((row, rindex) => {
                    const newTableArrObj = {};
                    section.Table.Fields && section.Table.Fields.forEach(field => {
                        if (licenceInfo[`${section.Name}.${rindex}.${field.Name}`] !== undefined && licenceInfo[`${section.Name}.${rindex}.${field.Name}`] !== '') {
                            newTableArrObj[field.Name] = licenceInfo[`${section.Name}.${rindex}.${field.Name}`];
                        }
                        else {
                            newTableArrObj[field.Name] = '';
                        }
                    });
                    licenceFieldNames[formDefinition.FormType][section.Name].push(newTableArrObj);
                });
            }
        });

        const generalFieldNames = {
            GeneralInformation: {}
        };

        //get filled values for general info fields
        generalInfoFields.Sections.forEach(section => {
            section.Fields.forEach(field => {
                if (generalInfo[field.Name] !== undefined && generalInfo[field.Name] !== '') {
                    generalFieldNames.GeneralInformation[field.Name] = generalInfo[field.Name];
                }
                else {
                    generalFieldNames.GeneralInformation[field.Name] = '';
                }
            });
        });

        //get filled values for supporting document fields
        const supportingDocumentNames = {
            SupportingDocument: {}
        };
        documentList.forEach(field => {
            const fileList = [];
            fileKeyIDList.forEach(file => {
                if (file.FileUploadField === field.Name) {
                    fileList.push({ fileName: file.FileKey, file: null, fileKey: file.FileKey, fileID: file.DynaFormDocumentIds });
                }
            });
            supportingDocumentNames.SupportingDocument[field.Name] = fileList;
        });

        const finalFields = { ...formTypeNames, ...generalFieldNames, ...licenceFieldNames, ...supportingDocumentNames, GeneralInformationType: generalInformationType };
        finalFields[DELETE_FILES_ARRAY] = [];
        finalFields.AppRefNum = this.props.application.ApplicationRefNumber;
        return finalFields;
    }

    createValidationSchema = (devData, generalInformationType) => {
        const { t } = this.props;
        const validationObject = {
            /***********************/
            /*** General Section ***/
            /***********************/
            GeneralInformation: createValidationSchemaForGeneralInfo(t, generalInformationType),

            /***********************/
            /*** Licence Section ***/
            /***********************/
            [devData.FormDefinition.FormType]: createValidationSchemaForLicenceFields(t, devData.FormDefinition),

            // /***********************************/
            // /*** Supporting Document Section ***/
            // /***********************************/
            SupportingDocument: createValidationSchemaForSupportingDocuments(t, devData.DocumentList),

            /***************************/
            /*** Declaration Section ***/
            /***************************/
            DeclarationAgreement: createValidationSchemaForDeclaration(t)
        };
        return Yup.object().shape(validationObject);

    }

    render() {
        const { application, t } = this.props;
        const { LicenceInformation } = application.FormData;
        const { applicationDetails, licence, generalInformationType, licenceIsLoaded, formNames, declarations, optionDataList } = this.state;

        if (!this.state.values) {
            return null;
        } else {
            const GeneralInformationComponentInput = GeneralInformationComponent[generalInformationType];
            return (
                <>
                    <div className="main-content">
                        <nav aria-label="breadcrumb" className="breadcrumb-main">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</Link></li>
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_RESPONDTORFA)}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{application.LicenceName}</li>
                                </ol>
                            </div>
                        </nav>
                        <Layout type={LAYOUT_TYPE.FORM} footer>
                            <SMARTForm
                                className="container"
                                formContext={FORM_CONTEXT}
                                sectionNames={formNames}
                                defaultSection={Object.keys(formNames)[0]}
                                formValues={this.state.values}
                                validationSchema={this.state.validationSchema}
                                validateOnSubmit={true}
                                navigateURL={NAVIGATE_URL}
                                nextURL={SUCCESS_URL}
                                serverURL={AMEND_APPLICATION_URL}
                            >
                                {({ toggleSection, toggleAllSections, sectionState, onChange, onChangeField, values,
                                    submitForm, errors, currentStep, prevStep, nextStep, modalError, modalStateError }) => {
                                    const smartFormValues = {
                                        toggleSection,
                                        toggleAllSections,
                                        values,
                                        onChange,
                                        onChangeField,
                                        errors,
                                        modalError,
                                        modalStateError
                                    };
                                    return (
                                        <React.Fragment>
                                            <Col>
                                                <SectionWrapper
                                                    type={SECTION_LAYOUT_TYPE}
                                                    tabs={formNames}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}>

                                                    <Section
                                                        className="completed"
                                                        type={SECTION_LAYOUT_TYPE}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}
                                                        key={t(WIZARD_TAB_NAMES.GENERAL_INFORMATION)}
                                                    >
                                                        {LicenceInformation &&
                                                            <GeneralInformationComponentInput
                                                                smartFormValues={smartFormValues}
                                                                state={this.state}
                                                            />
                                                        }
                                                    </Section>
                                                    {/* Licence Data Code */}
                                                    {LicenceInformation && licenceIsLoaded &&
                                                        <>
                                                            <Section
                                                                type={SECTION_LAYOUT_TYPE}
                                                                sectionState={sectionState}
                                                                toggleSection={toggleSection}
                                                                sectionName={application.LicenceName}
                                                            >
                                                                <RFAFormAmend
                                                                    smartFormValues={smartFormValues}
                                                                    formDefinition={applicationDetails.FormDefinition}
                                                                    formName={application.LicenceName}
                                                                    licence={licence}
                                                                    formType={application.FormType}
                                                                    optionDataList={optionDataList}
                                                                />
                                                            </Section>
                                                        </>
                                                    }

                                                    {/* Supporting Documents Section */}
                                                    {applicationDetails.DocumentList.length > 0 &&
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}
                                                            key={t(WIZARD_TAB_NAMES.SUPPORTING_DOCUMENTS)}>
                                                            <SupportingDocuments
                                                                smartFormValues={smartFormValues}
                                                                files={applicationDetails.DocumentList}
                                                            />
                                                        </Section>
                                                    }

                                                    {/* Review Section */}
                                                    {applicationDetails.FormData.LicenceInformation &&
                                                        <Section
                                                            type={SECTION_LAYOUT_TYPE}
                                                            sectionState={sectionState}
                                                            toggleSection={toggleSection}
                                                            sectionName={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}
                                                            key={t(WIZARD_TAB_NAMES.REVIEW_INFORMATION)}>
                                                            <RFAReviewAndSubmit
                                                                smartFormValues={smartFormValues}
                                                                applicationDetails={applicationDetails}
                                                                declarations={declarations}
                                                                displayDocuments={true}
                                                            />
                                                        </Section>
                                                    }
                                                </SectionWrapper>
                                            </Col>
                                            <PageFooter type={LAYOUT_TYPE.FORM}>
                                                <div className="button-center">

                                                    {/* Wizard Previous Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== 0)) ?
                                                        <Button color="backward" size="sm" type="button" onClick={prevStep}>
                                                            {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_PREVIOUS)}
                                                        </Button> : ''
                                                    }

                                                    {/* Submit Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep === Object.keys(this.state.formNames).length - 1)) ?
                                                        <Button color="forward" size="sm" type="button" onClick={submitForm}>
                                                            {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_SUBMIT)}
                                                        </Button> :
                                                        ((LAYOUT_TYPE.FORM_CONTENT !== 3) ?
                                                            <Button color="forward" size="sm" type="button" onClick={submitForm}>
                                                                {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_SUBMIT)}
                                                            </Button> : '')
                                                    }

                                                    {/* Wizard Next Button */}
                                                    {((LAYOUT_TYPE.FORM_CONTENT === 3) && (currentStep !== Object.keys(this.state.formNames).length - 1)) ?
                                                        <Button color="forward" size="sm" type="button" onClick={nextStep}>
                                                            {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_NAVIGATION_BUTTON_NEXT)}
                                                        </Button> : ''
                                                    }
                                                </div>
                                            </PageFooter>
                                        </React.Fragment>
                                    );
                                }}
                            </SMARTForm>
                        </Layout>
                    </div>

                </>
            );
        }
    }
}

export default withTranslation()(compose(withLoader)(RFAAmendment));