//this will contain the search result to display the business intents

import { default as React } from 'react';
import { BUSINESS_ADVISOR_PATH } from '../../../App/AppSettings';
import { navigateTo } from '../../../Shared/Actions';
import { includesMinCharValidation } from '../../../Shared/Constants';
import { DirectionContextConsumer } from '../../../Shared/DirectionContext';
import { withModalWrapper } from '../../../Shared/Modal/ModalWrapper';
import RelatedResultItem from './RelatedResultItem.js';
import ResultItem from './ResultItem';
import SearchIntentNotFound from './SearchIntentNotFound';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

const INITIAL_VALUES = '';


class ResultContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showRelated: false
        };
    }

    onclickRelatedButton = () => {
        this.setState({ showRelated: true });
    };

    handleBusinessIntentItemClick = (e, intentId) => {
        e.preventDefault();
        navigateTo(BUSINESS_ADVISOR_PATH, { intentId });
    };


    render() {
        const {
            businessIntent,
            relatedBusinessIntent,
            criteria,
            t
        } = this.props;
        const { currLang } = this.props.context;

        let isError = false;
        let isSuccessNull = false;

        if (criteria === INITIAL_VALUES) {
            isError = false;
        } else if (criteria === undefined || (includesMinCharValidation(currLang) && criteria.length < 3)) {
            isError = true;
        }

        if (businessIntent == null) {
            isSuccessNull = true;
        }

        return (
            <React.Fragment>

                {isError || isSuccessNull ? (
                    <SearchIntentNotFound
                        searchValue={criteria}
                        searchTermLengthError={isError} />
                ) : (
                        <React.Fragment>
                            <h4>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SUBTITLE)}</h4>


                            <ResultItem
                                intent={businessIntent}
                                relatedIntent={relatedBusinessIntent}
                                onclickFunc={this.onclickRelatedButton}
                            />


                            {this.state.showRelated ?
                                (
                                    <div className="guided-box-select" style={{ display: "block" }}>
                                        <h4> {t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_CLICKBUSINESS)} </h4>
                                        {relatedBusinessIntent.map((item, i) => {
                                            return (
                                                <RelatedResultItem
                                                    key={i}
                                                    relatedIntent={item}
                                                />

                                            );
                                        })}
                                    </div>
                                )
                                : null

                            }

                            <div className="refine">
                                <h5>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_REFINEBUSINESS)}</h5>
                                <div className="tips">
                                    <h5>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SEARCHTIPS)}</h5>
                                    <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_CHECKSPELLING)}</p>
                                    <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_TRYDIFFSEARCHTERM)}</p>
                                </div>

                            </div>

                        </React.Fragment>
                    )}



            </React.Fragment>
        );
    }
}
const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return (
                <DirectionContextConsumer>
                    {({ currLang }) => (
                        <WrappedComponent
                            context={{ currLang }}
                            {...this.props}
                        />
                    )}
                </DirectionContextConsumer>
            );
        }
    }

    return DirectionOnPageContext;
};

export default withTranslation()(withModalWrapper(withDirectionContext(ResultContainer)));
