import React from 'react';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import RecommendBanner from '../Recommend';

class SearchResultNotFound extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="search-not-found">
                    {
                        this.props.searchTermLengthError ?
                            <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SEARCHTERMTOOSHORT)}</p>
                            :
                            <p>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_MESSAGE_NOTE_NORESULTS)}</p>
                    }
                </div>

                {this.props.recommendBanner && <RecommendBanner />}

            </React.Fragment>
        );
    }
}

SearchResultNotFound.defaultProps = {
    recommendBanner: true,
    searchTermLengthError: false
}

export default withTranslation()(SearchResultNotFound);