import React from 'react';
import { Helmet } from "react-helmet";
import { SITE_NAME, SITE_FAVICON } from '../App/AppSettings'

class Site extends React.Component {
    render() {
        return (
            <Helmet>    
                <title>{ SITE_NAME }</title>
                <link rel="shortcut icon" href={ SITE_FAVICON } />
            </Helmet>
        );
    }
}

export default Site;