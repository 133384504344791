import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Home from './Home';

import Login from './Login';
import { RouterExtension } from '../Extensions/RouterExtension';
import ProtectedRoute from '../Shared/Authentication/ProtectedRoute';
import Callback from '../Shared/Authentication/CallBack';
import Silent from '../Shared/Authentication/Silent';
import { withSMARTLayout  } from '../Layout/SMARTLayout';
import { withEmptyLayout } from '../Layout/EmptyLayout';

import SearchAllAgency from '../Content/SearchAgency';
import SearchAllLicence from '../Content/SearchLicence';
import LicenceCart from '../Content/LicenceCart';
import SearchAllIndustry from '../Content/SearchIndustry';
import SearchIntent from '../Content/SearchIntent/Search';
import BusinessAdvisor from '../Content/SearchIntent/BusinessAdvisor';

import MakePayment from '../Content/Payment';
import PaymentSuccess from '../Content/Payment/PaymentSuccess';
import MakePaymentSummary from '../Content/Payment/PaymentSummary';

// Development of Dynamic Form
import DevForm from '../Content/DevForm';
import DevStart from '../Content/DevForm/start';
import DevPreview from '../Content/DevForm/preview';
import DevOfflineSuccess from '../Content/DevForm/offlineSuccess';

import Dashboard from './Dashboard';
import ApplicationDetails from './ApplicationDetails';
import BusinessDetails from './BusinessDetails';
import CitizenDetails from './CitizenDetails';
import LicenceDetails from './LicenceDetails';
import RFAResponse from './RFAResponse';
import RFASuccessPage from './RFAResponse/RFASuccessPage';
import RenewLicence from './RenewLicence';
import CancelLicence from './CancelLicence';
import AmendLicence from './AmendLicence';
import CorrespondenceDetails from './Dashboard/CorrespondenceDetails';
import { DirectionContextProvider } from '../Shared/DirectionContext';
import PublicRoute from '../Shared/Authentication/PublicRoute';
import Logout from '../Shared/Authentication/Logout';
import Faq from './Faq';
import MirroredHome from './MirroredHome';


class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: ''
        };
    }

    render() {
        return (
            <React.Fragment>
                {/*routing order matters*/}
                {/*please add any routes related to Auth here in this switch to prevent re rendering of the
                direction context in any authentication request/redirect*/}
                <Switch>
                    <Route path="/Authentication/CallBack" component={withEmptyLayout(Callback)} />
                    <Route path="/Authentication/Silent" component={withEmptyLayout(Silent)} />
                    <Route path="/Authentication/Logout" component={withEmptyLayout(Logout)} />
                </Switch>

                <DirectionContextProvider>
                    {/*all other routes not related to Auth*/}
                    <Switch>
                        {/* BLS General */}
                        <Route exact path="/" render={() => <Redirect to="/Home" />} />
                        <PublicRoute path="/Home" component={withSMARTLayout(Home)} />
                        <PublicRoute path="/Mirrored" component={withSMARTLayout(MirroredHome)} />
                        <PublicRoute path="/Faq" component={withSMARTLayout(Faq)} />

                        <PublicRoute path="/login" component={withSMARTLayout(Login)} />

                        <PublicRoute path="/searchAllAgency" component={withSMARTLayout(SearchAllAgency)} />
                        <PublicRoute path="/searchAllLicence" component={withSMARTLayout(SearchAllLicence)} />
                        <PublicRoute path="/licenceCart" component={withSMARTLayout(LicenceCart)} />
                        <PublicRoute path="/searchAllIndustry" component={withSMARTLayout(SearchAllIndustry)} />
                        <PublicRoute path="/searchIntent" component={withSMARTLayout(SearchIntent)} />
                        <PublicRoute path="/businessAdvisor" component={withSMARTLayout(BusinessAdvisor)} />

                        <ProtectedRoute path="/makePayment" component={withSMARTLayout(MakePayment)} />
                        <ProtectedRoute path="/paymentSuccess" component={withSMARTLayout(PaymentSuccess)} />
                        <ProtectedRoute path="/makePaymentSummary" component={withSMARTLayout(MakePaymentSummary)} />

                        {/* BLS Dashboard */}
                        <ProtectedRoute path="/dashboard" component={withSMARTLayout(Dashboard)} />
                        <ProtectedRoute path="/applicationDetails/" component={withSMARTLayout(ApplicationDetails)} />
                        <ProtectedRoute path="/serviceDetails/" component={withSMARTLayout(LicenceDetails)} />
                        <ProtectedRoute path="/respondToRequestForAction/" component={withSMARTLayout(RFAResponse)} />
                        <ProtectedRoute path="/submissionSuccess/" component={withSMARTLayout(RFASuccessPage)} />
                        <ProtectedRoute path="/renewLicence/" component={withSMARTLayout(RenewLicence)} />
                        <ProtectedRoute path="/cancelLicence/" component={withSMARTLayout(CancelLicence)} />
                        <ProtectedRoute path="/amendLicence/" component={withSMARTLayout(AmendLicence)} />
                        <ProtectedRoute path="/correspondenceDetails/" component={withSMARTLayout(CorrespondenceDetails)} />
                        <ProtectedRoute path="/businessDetails/" component={withSMARTLayout(BusinessDetails)} />
                        <ProtectedRoute path="/citizenDetails/" component={withSMARTLayout(CitizenDetails)} />

                        {/* Development of Dynamic Form */}
                        <ProtectedRoute path="/services/devform/start" component={withSMARTLayout(DevStart)} />
                        <ProtectedRoute path="/services/devform/new" component={withSMARTLayout(DevForm)} />
                        <ProtectedRoute path="/services/devform/preview" component={withSMARTLayout(DevPreview)} />
                        <ProtectedRoute path="/services/devform/applySuccess" component={withSMARTLayout(DevOfflineSuccess)} />
                        <RouterExtension />
                    </Switch>
                </DirectionContextProvider>
            </React.Fragment>
        );
    }
}

export default Content;