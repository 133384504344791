import Griddle, { RowDefinition } from 'griddle-react';
import _ from 'lodash';
import React from 'react';
import { DIRECTIONS, FILTER, SELECT_FILTERS } from '../../Shared/Constants';
import { MobileCard } from '../../Shared/DataTable';
import { toastError } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { fetchRequest, getParams } from '../Actions';
import { getColDefinition, getCustomComponents } from './BaseTable';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS, ERROR } from '../Constants/LanguageKeys';

class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullData: [],
            gridData: [],
            currentPage: 1,
            recordCount: 0,
            filterText: {
                [FILTER]: "",
                [SELECT_FILTERS]: {}
            },
            visibleColKeys: [],
            width: window.innerWidth,
            renderMobileCardActions: this.props.renderMobileCardActions,
            toggleUpdate: false
        };
    }

    // Lifecycle: When component has successfully mounted
    async componentDidMount() {
        const { data, url, loader, initialSort, t } = this.props;
        let response = '';

        // Start loading
        loader.start();
        let loadData = [];

        if (url) {
            response = await fetchRequest(url, getParams(), this.props.IS_DEMO);

            const { IsSuccess, Data } = response.body;

            // If success, load retrieved data into state
            if (response.success && IsSuccess) {
                loadData = Data;
            }
            else {
                toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
            }

        } else {
            // Get grid data
            loadData = data;
        }

        if (initialSort) {
            const { currentPage, filterText } = this.state;
            const { pageSize } = this.props;
            let col = initialSort.id;
            let dir = initialSort.sortAscending === true ? "asc" : "desc";

            loadData.sort((a, b) => {
                let x = a[col];
                let y = b[col];

                if (initialSort.isDate) {
                    x = new Date(x);
                    y = new Date(y);
                }

                if (x < y) {
                    return dir === "asc" ? -1 : 1;
                }

                if (x > y) {
                    return dir === "asc" ? 1 : -1;
                }

                return 0;
            })
            await this.initGrid(loadData);
            this.setState({
                sortProperties: initialSort,
                sortColumn: col,
                sortDir: dir,
                fullData: loadData
            });
            let data = this.filterByValue(loadData, filterText).slice((currentPage - 1) * pageSize, pageSize * currentPage);
            this.updateTableState(false, data, currentPage, loadData.length);
        }
        else {
            await this.initGrid(loadData);
        }
        // Done loading
        loader.done();
    }

    componentDidUpdate(prevProps) {      
        // only update chart if the data has changed
        //if (!this.props.url) { //url is for initial loading, no need to check this
            let currentData = this.props.data;
            if (prevProps.data !== currentData) {
                const { sortProperties, currentPage } = this.state;
                this.setState({
                    fullData: currentData,
                    recordCount: currentData.length
                }, () => {
                    if (sortProperties !== undefined) {
                        this.getSort(sortProperties);
                    } else {
                        this.getPage(currentPage);
                    }
                });
            }
        //}

        this.setAutocompleteOff();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    setAutocompleteOff = () => {
        var inputElements = document.getElementsByTagName("input");
        for (var i = 0; inputElements[i]; i++) {
            inputElements[i].setAttribute("autocomplete", "off");
        }
    }

    // Fn: Load retrieved data from async server request to state
    initGrid = async (data) => {
        this.setState({
            fullData: data,
            fullDataRecordCount: 0,
            gridData: data.slice(0, this.props.pageSize),
            visibleColKeys: this.getVisibleColKeys(),
            recordCount: data.length
        });
    }

    getVisibleColKeys = () => {
        let cols = Object.assign({}, this.props.columns);
        delete cols.Actions;

        let visibleCols = _.filter(cols, function (col) { return col.DBkey !== undefined && col.visible !== false; });
        let visibleColKeys = [];
        _.forEach(visibleCols, function (col) {
            visibleColKeys.push(col.DBkey);
        });

        return visibleColKeys;
    }

    // Fn: Update the records to display by changing the state for display
    updateTableState = (toToggle, data, currentPage, recordCount) => {
        // Get first page's data when data is empty
        if (data.length === 0 && currentPage !== 1) {
            this.getFirst();
        } else {
            this.setState({
                gridData: data,
                currentPage: currentPage,
                recordCount: recordCount
            });
        }

        // Update Grid
        if (toToggle) {
            this.setState({
                toggleUpdate: !this.state.toggleUpdate
            })
        }
    }

    togglePaginationDisplay = () => {
        if (this.state.recordCount === 0) {
            return "no-pagination";
        }
        return "";
    }

    // Fn: Return a new array where Select Filter(s) and Search Filter is applied
    filterByValue = (array, filterArray) => {

        // Select Filters:
        // - All fields (including !visible columns) can be filtered
        // - The column DBkey and its filter value must be present to filter the grid
        // - If filter value is empty (''), grid will not be filtered
        let selectFilters = filterArray[SELECT_FILTERS];
        for (var key in selectFilters) {
            if (selectFilters[key] !== '') {
                array = _.filter(array, { [key]: selectFilters[key] });
            }
        };

        // Search Filter: Only visible fields on the grid can be filtered
        array = array.filter((o) =>
            Object.keys(o).some((k) => {
                let translatedStr;
                const existingColumn = this.props.columns[k];
                //use "translatedString" in column attribute to get translated string
                if (existingColumn && existingColumn.getTranslatedString) {
                    translatedStr = existingColumn.getTranslatedString(o);
                }

                return this.state.visibleColKeys.includes(k) &&
                (typeof o[k] === 'string') && 
                (translatedStr ? translatedStr.toLowerCase().includes(filterArray[FILTER].toLowerCase()) :
                (o[k].toLowerCase().includes(filterArray[FILTER].toLowerCase())));
            })
        );
        return array === undefined ? [] : array;
    }

    // Fn: Get & display records for First page
    getFirst = () => {
        const { fullData, filterText, recordCount } = this.state;
        const { pageSize } = this.props;
        let nextPage = 1;
        let data = this.filterByValue(fullData, filterText).slice((nextPage - 1) * pageSize, pageSize * nextPage);
        this.updateTableState(true, data, nextPage, recordCount);
    }

    // Fn: Get & display records for Last page
    getLast = () => {
        const { fullData, filterText, recordCount } = this.state;
        const { pageSize } = this.props;
        let nextPage = Math.ceil(recordCount / pageSize);
        let data = this.filterByValue(fullData, filterText).slice((nextPage - 1) * pageSize, pageSize * nextPage);
        this.updateTableState(true, data, nextPage, recordCount);
    }

    // Fn: Get & display records for Next page
    getNext = () => {
        const { currentPage, fullData, filterText, recordCount } = this.state;
        const { pageSize } = this.props;
        let nextPage = currentPage + 1
        let data = this.filterByValue(fullData, filterText).slice(currentPage * pageSize, pageSize * nextPage);
        this.updateTableState(true, data, nextPage, recordCount);
    }

    // Fn: Get & display records for Previous page
    getPrev = () => {
        const { currentPage, fullData, filterText, recordCount } = this.state;
        const { pageSize } = this.props;
        let prevPage = currentPage - 1
        let data = this.filterByValue(fullData, filterText).slice((prevPage - 1) * pageSize, pageSize * prevPage);
        this.updateTableState(true, data, prevPage, recordCount);
    }

    // Fn: Get & display records for given page number
    getPage = (pageNumber) => {
        const { fullData, filterText, recordCount } = this.state;
        const { pageSize } = this.props;
        let data = this.filterByValue(fullData, filterText).slice((pageNumber - 1) * pageSize, pageSize * pageNumber);
        this.updateTableState(true, data, pageNumber, recordCount);
    }

    getCurrentPage = (e) => {
        let page = parseInt(e.target.value, 10);
        this.getPage(page);
    }

    applyFilter = (filterText) => {
        const { minFilterChars } = this.props;
        const minFilterCharacters = (typeof minFilterChars === 'undefined' ? 0 : minFilterChars);
        const searchFilterLength = filterText[FILTER].length;

        return (searchFilterLength >= minFilterCharacters) || (searchFilterLength === 0);
    }

    getFilter = async (filterText) => {
        const { fullData } = this.state;
        const { isCustomFilter, pageSize } = this.props;

        // Construct filterText
        filterText = isCustomFilter ? filterText : { [FILTER]: filterText };

        let toToggle = false;
        if (!_.isEqual(filterText[SELECT_FILTERS], this.state.filterText[SELECT_FILTERS])) {
            toToggle = true; //toggle update if select filter has changed
        }

        await this.setState({ filterText });

        if (this.applyFilter(filterText)) {
            let filterData = this.filterByValue(fullData, filterText);
            let data = filterData.slice(0, pageSize);
            this.updateTableState(toToggle, data, 1, filterData.length);
        }
    }

    // Fn: Get & display records for given sorted properties
    getSort = (sortProperties) => {
        const { currentPage, fullData, filterText, recordCount } = this.state;
        const { pageSize } = this.props;
        let col = sortProperties.id;
        let dir = sortProperties.sortAscending === true ? "asc" : "desc";
        let colDef = _.find(this.props.columns, { 'DBkey': col });

        fullData.sort((a, b) => {
            let x = a[col];
            let y = b[col];

            if (colDef.isDate) {
                x = new Date(x);
                y = new Date(y);
            }

            if (x < y) {
                return dir === "asc" ? -1 : 1;
            }

            if (x > y) {
                return dir === "asc" ? 1 : -1;
            }

            return 0;
        })

        this.setState({
            sortProperties: sortProperties,
            sortColumn: col,
            sortDir: dir,
            fullData: fullData
        });

        let data = this.filterByValue(fullData, filterText).slice((currentPage - 1) * pageSize, pageSize * currentPage);
        this.updateTableState(false, data, currentPage, recordCount);
    }

    Filter = (props) => {
        const { t } = this.props;
        return (
            <input
                dir={DIRECTIONS.AUTO}
                type="text"
                name="filter"
                defaultValue={this.state.filterText[FILTER]}
                className={props.className}
                placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER_FILTER)}
                onChange={(e) => props.setFilter(e.target.value)}
            />
        );
    };

    desktopLayout = ({ Table, Pagination, Filter }) => {
        return (
            <div>
                <Filter />
                <Table />
                <Pagination />
            </div>
        )
    }

    mobileLayout = ({ Filter, Table }) => {
        return (
            <div>
                <Filter />
                <Table />
            </div>
        )
    }

    mobileTable = () => {
        const { gridData, currentPage, recordCount } = this.state;
        const { pageSize } = this.props;
        const NoOfPage = Math.ceil(recordCount / pageSize);

        return (
            <MobileCard
                data={gridData}
                getPrev={this.getPrev}
                onChange={this.getCurrentPage}
                value={currentPage}
                NoOfPage={NoOfPage}
                getNext={this.getNext}
                getFirst={this.getFirst}
                getLast={this.getLast}
                columns={this.props.columns}
                currentPage={this.state.currentPage}
                renderMobileCardActions={this.props.renderMobileCardActions}
                noResultsMessage={this.props.noResultsMessage}
                filterExcludedFieldsArray={this.props.filterExcludedFieldsArray}
            />
        )
    }

    getComponents = (isMobile) => {
        const { isCustomLayout, isCustomFilter, components } = this.props;

        let customComponents = getCustomComponents();
        let dataTableComponents = components === undefined ? Object.assign({}, customComponents) : components;
        dataTableComponents.Filter = isCustomFilter ? components.Filter : this.Filter;
        dataTableComponents.Table = isMobile ? this.mobileTable : customComponents.Table;

        if (isCustomLayout) {
            dataTableComponents.Layout = components.Layout;
        } else {
            dataTableComponents.Layout = isMobile ? this.mobileLayout : this.desktopLayout;
        }

        return dataTableComponents;
    }

    render() {

        const { gridData, currentPage, recordCount, width, toggleUpdate } = this.state;
        // const { pageSize, noResultsMessage, data } = this.props;
        const { pageSize, noResultsMessage } = this.props;

        const isMobile = width <= 992;
        let colDef = getColDefinition(this.props);

        return (
            <div className={"data-table " + this.togglePaginationDisplay()}>
                <Griddle
                    key={isMobile + " " + toggleUpdate}
                    // data={(data === undefined) ? gridData : data}
                    data={gridData}
                    pageProperties={{
                        currentPage,
                        pageSize,
                        recordCount
                    }}
                    textProperties={
                        this.noResultsMessage = { noResultsMessage }
                    }
                    components={this.getComponents(isMobile)}
                    events={{
                        onNext: this.getNext,
                        onPrevious: this.getPrev,
                        onGetPage: this.getPage,
                        onFilter: this.getFilter,
                        onSort: this.getSort,
                        onFirst: this.getFirst,
                        onLast: this.getLast
                    }}>
                    <RowDefinition>
                        {colDef}
                    </RowDefinition>
                </Griddle>
            </div>
        )
    }
}

export default withTranslation()(withLoader(DataTable));
