import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH, GET_CORRESPONDENCES_DETAILS_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { Plaintext, toastError } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { withTranslation } from 'react-i18next';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

const LABEL_SIZE = 4;
const INPUT_SIZE = 8;

class CorrespondenceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            correspondenceId: null,
            data: {}
        }
        if (history.location.state) {
            this.state = {
                correspondenceId: history.location.state.ID,
            }
        }
    }

    componentDidMount = async () => {
        const { loader, t } = this.props;

        // Get data
        // Start loading
        loader.start();

        const response = await fetchRequest(GET_CORRESPONDENCES_DETAILS_URL + this.state.correspondenceId, getParams(), false);
        const { IsSuccess, Data } = response.body;

        // If success, load retrieved data into state
        if (response.success && IsSuccess) {
            this.setState({ data: Data });
        }
        else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }

        // Done loading
        loader.done();
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className="main-content">

                        <nav aria-label="breadcrumb" className="breadcrumb-main">
                            <div className="container">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</Link></li>
                                    <li className="breadcrumb-item"><Link to={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU_CORRESPONDENCE)}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_NAVIGATION_MENU_CORRESPONDENCEDETAILS)}</li>
                                </ol>
                                <div className="back-btn">
                                    <Button
                                        color="header"
                                        className="btn-header"
                                        onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                </div>
                            </div>
                        </nav>
                        {this.state.data &&
                            <div className="container">
                                <div className="correspondences-details">
                                    <div className="detailed-box db_corr_details">
                                        <div className="form-body form-preview">
                                            <Plaintext
                                                name="FromName"
                                                value={this.state.data.FromName}
                                                label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_FROM)}
                                                inputSize={INPUT_SIZE}
                                                labelSize={LABEL_SIZE}
                                            />
                                            <Plaintext
                                                name="ToName"
                                                value={this.state.data.ToName}
                                                label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_TO)}
                                                inputSize={INPUT_SIZE}
                                                labelSize={LABEL_SIZE}
                                            />
                                            <Plaintext
                                                name="SentDate"
                                                value={this.state.data.SentDate}
                                                label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_SENTDATE)}
                                                inputSize={INPUT_SIZE}
                                                labelSize={LABEL_SIZE}
                                            />
                                            <Plaintext
                                                name="Title"
                                                value={this.state.data.Title}
                                                label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_TITLE)}
                                                inputSize={INPUT_SIZE}
                                                labelSize={LABEL_SIZE}
                                            />
                                            <Plaintext
                                                name="Delivery Channel"
                                                value={this.state.data.DeliveryChannel}
                                                label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_DELIVERYCHANNEL)}
                                                inputSize={INPUT_SIZE}
                                                labelSize={LABEL_SIZE}
                                            />
                                        </div>
                                        <div className="writeups">
                                            {this.state.data.TextType === "html" ?
                                                <div className="content" dangerouslySetInnerHTML={{ __html: this.state.data.Content }}></div>
                                                :
                                                <div className="content">
                                                    <p>{this.state.data.Content}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="button-center">
                                    <Button className="btn" onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                </div>
                            </div>}
                    </div>
                </Layout>
            </React.Fragment >
        )
    }
}

export default withTranslation()(withLoader(CorrespondenceDetails));
