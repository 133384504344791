import React from 'react';
import PropTypes from 'prop-types';
import { TabPane, Row, Container } from 'reactstrap';


class TabContainer extends React.Component {
    
    render() {
        return (
            <TabPane
                className="tab-container"
                tabId={this.props.tab}
            >
                <Row>
                    <Container>
                        {this.props.children}
                    </Container>
                </Row>
            </TabPane>
        );
    }
}

// PropTypes: For prop validation
TabContainer.propTypes = {
    tab: PropTypes.string.isRequired
};


export default TabContainer;