import Oidc from 'oidc-client';
import { AUTHORITY_CONFIG, LOCALSTORAGE_IS_LOGIN, SESSIONSTORAGE_IS_LOGIN } from '../../App/AppSettings.js';
import { CART_LICENCES } from '../Constants/index.js';
import { getCookie } from '../SmartMe/Actions/index.js';

let publicmgr = new Oidc.UserManager(AUTHORITY_CONFIG.openid_client_configuration.authentication_settings);
publicmgr.events.addAccessTokenExpiring(function () {});
publicmgr.events.addUserLoaded(function(user) {
  sessionStorage.setItem(SESSIONSTORAGE_IS_LOGIN, true);
  localStorage.setItem(LOCALSTORAGE_IS_LOGIN, true);

  window.location.href = user.state.returnUrl;
})

//clear session
publicmgr.events.addUserUnloaded(function() {
  sessionStorage.removeItem(SESSIONSTORAGE_IS_LOGIN);
  localStorage.removeItem(LOCALSTORAGE_IS_LOGIN);
})

//logout all active tabs when user logs out from one of them (single sign-out)
window.addEventListener('storage', event => {
  if (event.storageArea === localStorage) {
      let token = localStorage.getItem(LOCALSTORAGE_IS_LOGIN);
      if (token === null) {
          // Perform logout
          sessionStorage.removeItem(CART_LICENCES);
          publicmgr.signoutRedirect();
      }
  }
});

let corpmgr = new Oidc.UserManager(AUTHORITY_CONFIG.corppass_client_configuration.authentication_settings);
corpmgr.events.addAccessTokenExpiring(function () {});
corpmgr.events.addUserLoaded(function(user) {
  sessionStorage.setItem(SESSIONSTORAGE_IS_LOGIN, true);
  localStorage.setItem(LOCALSTORAGE_IS_LOGIN, true);

  window.location.href = user.state.returnUrl;
})

//clear session
corpmgr.events.addUserUnloaded(function() {
  sessionStorage.removeItem(SESSIONSTORAGE_IS_LOGIN);
  localStorage.removeItem(LOCALSTORAGE_IS_LOGIN);
})

//check if any one tab has logged out, then log out every other active tab also
window.addEventListener('storage', (event) => {
  if (event.storageArea === localStorage) {
      let token = localStorage.getItem(LOCALSTORAGE_IS_LOGIN);
      if (token == null) {
          // Perform logout
          sessionStorage.removeItem(CART_LICENCES);
          corpmgr.signoutRedirect();
      }
  }
});

class AuthHelper {

  static mgr;

  static async getUser(callback) {
    this.getManager().getUser().then(function (user) {
      return callback(user);
    });
  }

  static intersect(a, b) {
    var t;
    if (b.length > a.length) 
    {
      t = b; 
      b = a; 
      a = t;
    }
    
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
  }

  static getManager()
  {
    let loginType = getCookie("blsLoginType");
    if (loginType === "corp") {
      return corpmgr;
    }
    else {
      return publicmgr;
    }
  }
}

export default AuthHelper;