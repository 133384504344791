import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { withBaseControl } from './BaseControl';


class Autocomplete extends React.Component {

    // Fn: Render Search bar icon 
    DropdownIndicator = () => (
        <div style={{
            color: '#ccc',
            height: 24,
            width: 32
        }} >
            <FontAwesomeIcon icon={faSearch} />
        </div>
    );

    // Fn: Retrieve value and convert it to react-select readable label-value pair
    mapValuesToOptions = ({ options, value }) => {
        // Only takes in 'value' prop for each object
        let valueArr = [];
        _.map(options, (option) => {
            if (value && value.includes(option.value)) {
                valueArr.push(option);
            }
        });

        return valueArr;
    };

    // Fn: Handle change for autocomplete field
    handleAutocompleteChange = (e, { onChangeField, name }) => {
        let value = (e) ? e.value : '';

        onChangeField(name, value);
    };

    render() {
        return (
            <Select
                name={this.props.name}
                value={this.mapValuesToOptions(this.props)}
                placeholderText={this.props.placeholder}
                onChange={(e) => this.handleAutocompleteChange(e, this.props)}
                options={this.props.options}
                components={{ DropdownIndicator: this.DropdownIndicator, IndicatorSeparator: null }}
                isClearable={true}
                isMulti={false}
                maxMenuHeight={150}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                }}
                className={ this.props.className + " auto-complete" }
            />
        );
    }
}

// PropTypes: For prop validation
Autocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    placeholder: PropTypes.string,
    onChangeField: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
};


export default withBaseControl(Autocomplete);