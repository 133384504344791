import React from 'react';

class ItemResult extends React.Component {

  handleItemClick = () => {
    const {Id, Title, Description} = this.props.agency;
    this.props.GetLicence(Id, Title, Description);
  };

  render() {
    const {
      Title,
      Description,
      LicenceCount,
      Id
    } = this.props.agency;
    const { selectedId } = this.props;


    return (
      <React.Fragment>
       <li className="nav-item">
          <div onClick={this.handleItemClick} className={`nav-link ${selectedId===Id ? "active" : ""}`}>
            <strong>{Title}</strong>{Description}<span className="sup">{LicenceCount}</span>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

export default ItemResult;