import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardText } from 'reactstrap';
import { Checkbox } from '../../../Shared/Forms';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { DISPLAY_RICH_TEXT, getLangKey, getTranslation } from '../../DisplayComponents/DisplayUtils';

export default function DeclarationSection(props) {
    const [declarationState, setDeclarationResult] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        const renderDeclarations = declarations => {
            let formDeclarations = [];
            const completeDeclarations = [];

            const uniqueLicenceTypeNames = [...new Set(declarations.map(item => item.Name))];

            uniqueLicenceTypeNames.forEach(licenceTypeName => {
                if (licenceTypeName === "GeneralInformationDeclaration") {
                    formDeclarations.push(<b key={licenceTypeName}><u key={licenceTypeName}>{getTranslation(t, LANGUAGE_KEYS.BLS_GENERALINFORMATIONDECLARATION_LICENCETYPEDECLARATION_DECLARATIONDESC_GENERALDECLARATIONTITLE)}</u></b>);
                }
                else {
                    formDeclarations.push(<b key={licenceTypeName}><u key={licenceTypeName}>{licenceTypeName}</u></b>);
                }
                
                // Prep Licence Name into Translation Key Format\
                let licenceTypeNameTranslationKey = licenceTypeName;
                licenceTypeNameTranslationKey = licenceTypeNameTranslationKey.replace(/ /g, "_");
                licenceTypeNameTranslationKey = licenceTypeNameTranslationKey.replace(/[^0-9a-zA-Z_ ]/g, "");

                declarations.forEach(declaration => {
                    if (declaration.Name === licenceTypeName) {
                        formDeclarations.push(
                            <CardText key={declaration.Name + '.' + declaration.Id}>
                                <span>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY, licenceTypeNameTranslationKey, declaration.Id), DISPLAY_RICH_TEXT)}<br /></span>
                            </CardText>
                        );
                    }
                });
                completeDeclarations.push(formDeclarations);
                formDeclarations = [];
            });

            setDeclarationResult(completeDeclarations);
        };

        renderDeclarations(props.declarations);
    }, [t, props.declarations]);

    return (
        <div className='declaration'>
            <h6>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_DECLARATION)}</h6>
            <div className='help'>{getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_NOTE_DECLARATIONINSTRUCTIONS)}</div>
            <div className='acknowledge'>
                <div className='acknowledge-details'>
                    {declarationState}
                </div>
                <div className='acknowledge-check'>
                    <Checkbox
                        className='checkbox-declaration'
                        name='DeclarationAgreement'
                        value={props.values}
                        options={[
                            {
                                label: getTranslation(t, LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_NOTE_ACKNOWLEDGEMENTTEXT),
                                value: 'I AGREE TO THE ABOVE.', key: ['DeclarationAgreement']
                            }]}
                        onChangeField={props.onChangeField}
                        error={props.errors}
                        labelRequired
                        labelSize={0}
                        key={props.values}
                    />
                </div>
            </div>
        </div>
    );
}