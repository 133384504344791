import PropTypes from 'prop-types';
import React from 'react';
import { ADDRESS, DEFAULT_OVERSEAS_COUNTRY_ID_TYPE } from '../../../App/AppSettings';
import { Plaintext } from '../../../Shared/Forms';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';

class IndividualGIView extends React.Component {
    render() {
        const { values, labelSize, inputSize, t } = this.props;

        return (
            <>

                <div className="card-body">
                    <div className="form-body form-preview">
                        <div className="multi-row-form">
                            <h3>{t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantParticulars'))}</h3>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Salutation'))}
                                name="GI_Salutation"
                                value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Salutation', values.GI_Salutation))}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Name'))}
                                name="GI_Name"
                                value={values.GI_Name}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDtype'))}
                                name="GI_IDtype"
                                value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'IdType', values.GI_IDtype))}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDnumber'))}
                                name="GI_IDnumber"
                                value={values.GI_IDnumber}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            {values.GI_IDtype === DEFAULT_OVERSEAS_COUNTRY_ID_TYPE &&

                                <Plaintext
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Passportissuingcountry'))}
                                    name="GI_Passportissuingcountry"
                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Country', values.GI_Passportissuingcountry))}
                                    labelSize={labelSize}
                                    inputSize={inputSize}
                                />
                            }
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Nationality'))}
                                name="GI_Nationality"
                                value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Country', values.GI_Nationality))}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Gender'))}
                                name="GI_Gender"
                                value={values.GI_Gender}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_DateOfBirth'))}
                                name="GI_DateOfBirth"
                                value={values.GI_DateOfBirth}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_PrimaryContact'))}
                                name="GI_PrimaryContact"
                                value={values.GI_PrimaryContact}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MobileNumber'))}
                                name="GI_MobileNumber"
                                value={values.GI_MobileNumber}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_OfficeNumber'))}
                                name="GI_OfficeNumber"
                                value={values.GI_OfficeNumber}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_HomeNumber'))}
                                name="GI_HomeNumber"
                                value={values.GI_HomeNumber}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_FaxNumber'))}
                                name="GI_FaxNumber"
                                value={values.GI_FaxNumber}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Designation'))}
                                name="GI_Designation"
                                value={values.GI_Designation}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Email'))}
                                name="GI_Email"
                                value={values.GI_Email}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AltEmailAddress'))}
                                name="GI_AltEmailAddress"
                                value={values.GI_AltEmailAddress}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />

                        </div>
                        <div className="multi-row-form">
                            <h3>{t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantAddress'))}</h3>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressType'))}
                                name="GI_AddressAddressType"
                                value={values.GI_AddressAddressType}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressTypeOfPremises'))}
                                name="GI_AddressTypeOfPremises"
                                value={values.GI_AddressTypeOfPremises}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressPostalCode'))}
                                name="GI_AddressPostalCode"
                                value={values.GI_AddressPostalCode}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            {values.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                <>
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                        name="GI_AddressAddressLine1BlockNo"
                                        value={values.GI_AddressAddressLine1BlockNo}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1StreetName'))}
                                        name="GI_AddressAddressLine1StreetName"
                                        value={values.GI_AddressAddressLine1StreetName}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                        name="GI_AddressAddressLine2LevelNo"
                                        value={values.GI_AddressAddressLine2LevelNo}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                        name="GI_AddressAddressLine2UnitNo"
                                        value={values.GI_AddressAddressLine2UnitNo}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                        name="GI_AddressAddressLine2BuildingName"
                                        value={values.GI_AddressAddressLine2BuildingName}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                </>
                            }
                            {values.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                <>
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCountry'))}
                                        name="GI_AddressCountry"
                                        value={values.GI_AddressCountry}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                        name="GI_AddressAddressLine1AddressLine1"
                                        value={values.GI_AddressAddressLine1AddressLine1}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                        name="GI_AddressAddressLine2AddressLine2"
                                        value={values.GI_AddressAddressLine2AddressLine2}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCity'))}
                                        name="GI_AddressCity"
                                        value={values.GI_AddressCity}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressState'))}
                                        name="GI_AddressState"
                                        value={values.GI_AddressState}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                </>
                            }
                        </div>
                        <div className="multi-row-form">
                            <h3>{t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_MailingAddress'))}</h3>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressType'))}
                                name="GI_MailingAddressAddressType"
                                value={values.GI_MailingAddressAddressType}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressTypeOfPremises'))}
                                name="GI_MailingAddressTypeOfPremises"
                                value={values.GI_MailingAddressTypeOfPremises}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressPostalCode'))}
                                name="GI_MailingAddressPostalCode"
                                value={values.GI_MailingAddressPostalCode}
                                labelSize={labelSize}
                                inputSize={inputSize}
                            />
                            {values.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                <>
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                        name="GI_MailingAddressAddressLine1BlockNo"
                                        value={values.GI_MailingAddressAddressLine1BlockNo}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                        name="GI_MailingAddressAddressLine1StreetName"
                                        value={values.GI_MailingAddressAddressLine1StreetName}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                        name="GI_MailingAddressAddressLine2LevelNo"
                                        value={values.GI_MailingAddressAddressLine2LevelNo}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                        name="GI_MailingAddressAddressLine2UnitNo"
                                        value={values.GI_MailingAddressAddressLine2UnitNo}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                        name="GI_MailingAddressAddressLine2BuildingName"
                                        value={values.GI_MailingAddressAddressLine2BuildingName}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                </>
                            }
                            {values.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                <>
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCountry'))}
                                        name="GI_MailingAddressCountry"
                                        value={values.GI_MailingAddressCountry}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                        name="GI_MailingAddressAddressLine1AddressLine1"
                                        value={values.GI_MailingAddressAddressLine1AddressLine1}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                        name="GI_MailingAddressAddressLine2AddressLine2"
                                        value={values.GI_MailingAddressAddressLine2AddressLine2}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCity'))}
                                        name="GI_MailingAddressCity"
                                        value={values.GI_MailingAddressCity}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                    <Plaintext
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressState'))}
                                        name="GI_MailingAddressState"
                                        value={values.GI_MailingAddressState}
                                        labelSize={labelSize}
                                        inputSize={inputSize}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

IndividualGIView.propTypes = {
    values: PropTypes.object.isRequired
};

export default withTranslation()(IndividualGIView);
