import React from 'react'
import AuthHelper from './AuthHelper.js';
import { setCookie } from '../SmartMe/Actions'
import { CART_LICENCES } from '../Constants';
import { IDENTITY_URL } from '../../Shared/SmartMe/Constants';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { toastError} from '../../Shared/Forms';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils.js';
import { ERROR } from '../Constants/LanguageKeys.js';
import { withTranslation } from 'react-i18next';

const AuthContext = React.createContext()
const ROLE_CLAIMS = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const PERMISSIONS = "permission";

const GET_USER_ACCOUNT_SETTINGS_URL = IDENTITY_URL + 'Account/GetUserAccountSettings';
const IS_USER_SECURITY_QNS_PRESENT_URL = IDENTITY_URL + 'Account/IsSecurityQuestionsPresent/';

class AuthProvider extends React.Component {
  constructor() {
    super()

    this.state = {
      isAuthenticated: false,
      profile: null,
      roles: [],
      permissions: [],
      isReady: false
    }
  }

  async getUserAccountSettings() {
    const { t } = this.props;
    const response = await fetchRequest(GET_USER_ACCOUNT_SETTINGS_URL, getParams());
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
    }
  }

  async isSecurityQuestionsPresent(userId) {
    const { t } = this.props;
    const response = await fetchRequest(IS_USER_SECURITY_QNS_PRESENT_URL + userId, getParams());
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
    }
  }

  componentWillMount() {
    let self = this;

    if (AuthHelper.getManager() !== undefined) {
      AuthHelper.getManager().getUser().then(async function (user) {
        let isAuth = !(typeof user === 'undefined' || user === null);
        self.setState({ isAuthenticated: isAuth })
    
        if (isAuth) {
          self.setState({
            userAccountSettings: await self.getUserAccountSettings(),
            isSecurityQuestionsPresent: await self.isSecurityQuestionsPresent(user.profile.sub),
            profile: user.profile,
            roles: typeof user.profile[ROLE_CLAIMS] === 'undefined' ? [] :
              typeof user.profile[ROLE_CLAIMS] !== 'object' ? [user.profile[ROLE_CLAIMS]] :
                user.profile[ROLE_CLAIMS],
            permissions: typeof user.profile[PERMISSIONS] === 'undefined' ? [] :
              typeof user.profile[PERMISSIONS] !== 'object' ? [user.profile[PERMISSIONS]] :
                user.profile[PERMISSIONS]
          });
        }
  
        
      });
    }

    self.setState({
      isReady: true
    });
  }

  login = (returnUrl, type) => {
    // Set default
    if (type === undefined) {
      type = "public"
    }
    
    // Set auth type
    setCookie("blsLoginType", type, 1);
    // Sign in
    AuthHelper.getManager().signinRedirect({
      state: {
        returnUrl: process.env.PUBLIC_URL+returnUrl,
        referrerAppUrl: document.referrer
       
      }
    });
  }

  logout = (e) => {
    e.preventDefault();
    this.setState({
      isAuthenticated: false,
      profile: null
    });
    sessionStorage.removeItem(CART_LICENCES);
    AuthHelper.getManager().signoutRedirect();
  }

  render() {
    return (this.state.isReady ?
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          getIsAuthenticatedFn: this.getIsAuthenticatedFn,
          userAccountSettings: this.state.userAccountSettings,
          isSecurityQuestionsPresent: this.state.isSecurityQuestionsPresent,
          profile: this.state.profile,
          roles: this.state.roles,
          permissions: this.state.permissions,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider> :
      <AuthContext.Provider></AuthContext.Provider>
    )
  }
}

export const AuthConsumer = AuthContext.Consumer

const AuthProviderHoc = withTranslation()(AuthProvider)
export { AuthProviderHoc as AuthProvider }
