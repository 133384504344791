import React from 'react';
import { withTranslation } from 'react-i18next';
import { BUSINESS_ADVISOR_PATH } from '../../App/AppSettings';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { navigateTo } from '../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.ACCORDION_MODAL;

class DetailContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sectionState: '',
    };

  }

  handleBusinessIntentClick = (e, intentId) => {
    e.preventDefault();
    navigateTo(BUSINESS_ADVISOR_PATH, { intentId });
  };

  displayBusinessIntent = businessIntents => {
    return (
      <React.Fragment>
        {businessIntents.map((item, i) => {
          return (
            <a key={i} href="#top" onClick={e => this.handleBusinessIntentClick(e, item.BusinessIntentId)}>{this.props.t(getLangKey(LANGUAGE_KEYS.BLS_QUESTIONNAIRE_BUSINESSINTENT_NAME_KEY, item.BusinessIntentId))}</a>
          );
        })}
      </React.Fragment>
    );
  };


  //toggle related functions
  toggleSection = (isCollapsible, isIndividual, sectionName, sections) => {

    // Set section state as clicked section name
    const newSectionName = sectionName;

    // If section is collapsible ()
    if (isCollapsible) {
      if (isIndividual) {
        this.toggleSectionIndividualState(sections, sectionName);
      } else {
        this.toggleSectionDependentState(newSectionName);
      }
    } else {
      this.setState({ sectionState: newSectionName });
      this.setCurrentStepFromSectionName(newSectionName);
    }
  };
  toggleSectionDependentState = sectionName => {
    // Set section state as clicked section name
    let newSectionName = sectionName;

    // If hide/uncollapse is allowed & collapsed state is clicked, uncollapse it
    if (this.state.sectionState === sectionName) {
      newSectionName = '';
    }
    // Update new sectionState
    this.setState({ sectionState: newSectionName });
  };
  toggleSectionIndividualState = (sections, sectionName) => {
    const newSectionName = sectionName;
    let propertyHolder; // react has problems setting state for nested objects, so need a holder to help setState

    Object.entries(sections).forEach(([key, value]) => {
      if (newSectionName === value.title) {
        propertyHolder = value;
        propertyHolder.status = !value.status;
        this.setState({ propertyHolder });
      }
    });
  };

  render() {
    const { subIndustries, industryCodeName, t } = this.props;
    let isSuccessNull;
    const message = t(LANGUAGE_KEYS.BLS_PUBLICBROWSEBYINDUSTRY_CONTENT_TITLE_SUBTITLEINDUSTRY);

    if (subIndustries.length === 0) {
      isSuccessNull = true;
    }

    return (
      <React.Fragment>
        {isSuccessNull ? (
          <React.Fragment>
            <p></p>
            <p className="error-message">{message}</p>
            <p className="error-message"></p>
          </React.Fragment>
        ) : (
            <>
              <div className="search-result">
                <h4>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_INDUSTRY_TITLE_KEY, industryCodeName))}<span>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_INDUSTRY_TITLE_KEY, industryCodeName))}</span></h4>
                <SectionWrapper
                  type={SECTION_LAYOUT_TYPE}
                  tabs={subIndustries}
                  sectionState={this.state.sectionState}
                  toggleSection={this.toggleSection}
                >
                  {subIndustries.SubIndustries && subIndustries.SubIndustries.map((item, i) => {
                    return (
                      <Section key={i}
                        type={SECTION_LAYOUT_TYPE}
                        sectionState={this.state.sectionState}
                        sectionName={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_SUBINDUSTRY_TITLE_KEY, item.SubIndustryCodeName))}
                        toggleSection={this.toggleSection}
                      >
                        <div className="search-result-links">
                          {this.displayBusinessIntent(item.BusinessIntentList)}
                        </div>
                      </Section>
                    );
                  })}
                </SectionWrapper>
              </div>
            </>
          )}
      </React.Fragment>
    );
  }
}
export default withTranslation()(withModalWrapper(DetailContainer));
