import React from 'react';
import DeclarationSection from '../../Content/FormComponents/DeclarationComponent/DeclarationSection';
import GeneralInformationCorporateLicenceSummary from '../../Content/FormComponents/LicenceSummary/GeneralInformationCorporateLicenceSummary';
import GeneralInformationLicenceSummary from '../../Content/FormComponents/LicenceSummary/GeneralInformationLicenceSummary';
import AuthHelper from '../../Shared/Authentication/AuthHelper';
import CancelLicenceReviewModalContent from './CancelLicenceReviewModalContent';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withTranslation } from 'react-i18next';

let isCorporateProfile;

// Auth
AuthHelper.getUser((user) => {
    if (user !== undefined && user !== null) {
        isCorporateProfile = (user.profile.uen !== undefined);
    }
});
// Auth

class CancelLicenceReviewAndSubmit extends React.Component {
    render() {
        const { values, toggleSection, onChangeField, errors } = this.props.smartFormValues;
        const { applicationDetails, declarations, licence, displayDocuments, t } = this.props;
        const generalDefinition = applicationDetails.GeneralDefinition;

        const formFileList = [];
        applicationDetails.DocumentList.forEach(doc => {
            for (const i in values['SupportingDocument'][doc.Name]) {
                formFileList.push(values['SupportingDocument'][doc.Name][i].fileName);
            }
        });


        return (
            <>
                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_REVIEWANDSUBMIT)}</div>

                <div className="review-submit">
                    {isCorporateProfile ?
                        <GeneralInformationCorporateLicenceSummary
                            formName={t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}
                            values={values.GeneralInformation}
                            formSections={generalDefinition.Sections}
                            key={generalDefinition.FormName}
                            commonFieldList={[]}
                            documentList={[]}
                            toggleSection={toggleSection}>
                        </GeneralInformationCorporateLicenceSummary>
                        :
                        <GeneralInformationLicenceSummary
                            formName={t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}
                            values={values.GeneralInformation}
                            formSections={generalDefinition.Sections}
                            key={generalDefinition.FormName}
                            commonFieldList={[]}
                            documentList={[]}
                            toggleSection={toggleSection}>
                        </GeneralInformationLicenceSummary>
                    }

                    <CancelLicenceReviewModalContent
                        licence={licence}
                        formName={applicationDetails.FormDefinition.FormName}
                        formType={applicationDetails.FormDefinition.FormType}
                        formDefinition={applicationDetails.FormDefinition}
                        values={values}
                        toggleSection={toggleSection}
                        documentList={formFileList}
                        displayDocuments={displayDocuments}
                    >
                    </CancelLicenceReviewModalContent>

                    {declarations &&
                        <DeclarationSection
                            declarations={declarations}
                            values={values.DeclarationAgreement}
                            errors={errors.DeclarationAgreement}
                            key={values.DeclarationAgreement}
                            onChangeField={onChangeField}
                            labelSize={0}
                            labelRequired />
                    }
                </div>

            </>

        );
    }
}

export default withTranslation()(CancelLicenceReviewAndSubmit);
