import React from 'react';
import { navigateTo } from '../../../Shared/Actions';
import { BUSINESS_ADVISOR_PATH } from '../../../App/AppSettings';

class RelatedResultItem extends React.Component {

    handleBusinessIntentItemClick = (e, intentId) => {
        e.preventDefault();
        navigateTo(BUSINESS_ADVISOR_PATH, { intentId });
    }


    render() {
        const { relatedIntent } = this.props;

        return (
            <a className="guided-box" href="#top" onClick={(e) => this.handleBusinessIntentItemClick(e, relatedIntent.BusinessIntentId)}>
                <h4>{relatedIntent.BusinessIntentName}</h4>
                <p>{relatedIntent.BusinessIntentDesc}</p>
            </a>
        );
    }
}
export default RelatedResultItem;