import React from 'react';
import Layout from '../../Layout';
import { Formik } from 'formik';
import { LAYOUT_TYPE, FORM_TYPE, CREATE_FORM_URL } from '../../App/AppSettings';
import { AccordionContainer, withSMARTWrapper, SMARTView, Plaintext, Checkbox } from '../../Shared/Forms';
import { Row, Col, Button } from 'reactstrap';
import { ERROR } from '../../Shared/Constants/LanguageKeys';
import { Modal, ModalConfirm } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';
import { formParams, METHOD_TYPE } from '../../Shared/Actions';
import * as Yup from 'yup';
import devData from '../../Data/temp-licence-data.json';
import SubSection from '../../Section/SubSection';
import { withTranslation } from 'react-i18next';

const FORM_CONTEXT = 'DevForm';
const MODAL_NAMES = { SUBMIT: 'Submit' };

const FORM = {
    FORM_TYPE: FORM_TYPE.PREVIEW,
    FORM_CONTEXT
};

const RETRIEVE_URL = CREATE_FORM_URL;

class DevPreview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            devData,
            formNames: this.getFormNames(devData),
            declarationInitialValues: this.getDeclarationInitialValues(devData),
            declarationValidationSchema: this.getDeclarationValidationSchema(devData)
        };
    }

    getDeclarationInitialValues = devData => {
        const result = {};
        devData.DeclarationDefinitions.Declarations.forEach(declaration => {
            result[declaration.Name] = '';
        });

        return result;
    }

    getDeclarationValidationSchema = devData => {
        const { t } = this.props;
        let validationObject = {};

        devData.DeclarationDefinitions.Declarations.forEach(declaration => {
            let validationRule = Yup.string();

            // Required
            validationRule = validationRule.required(t(ERROR.TICK_REQUIRED));

            // Add into main validation object
            validationObject[declaration.Name] = validationRule;
        });

        return Yup.object().shape(validationObject);
    }

    getFormNames = devData => {

        // General Form
        const generalInformation = 'General Information';
        const declaration = 'Declarations';
        const generalForm = devData.GeneralDefinition !== undefined ? generalInformation : null;

        // Specific Forms
        const formNames = devData.FormDefinitions.map(formDefinition =>  formDefinition.FormName);

        // Declaration Form
        const declarationForm = devData.DeclarationDefinitions !== undefined ? declaration : null;

        const result = {
            generalInformation: { title: generalForm, status: true },
            declaration: { title: declarationForm, status: true }
        };

        for (let i = 0; i < formNames.length; i++) {
            result[formNames[i]] = { title: formNames[i], status: true };
        }

        return result;
    }

    render() {
        const { values } = this.props;

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="Dynamic Dev Form Preview">
                    <SMARTView
                        formContext={FORM.FORM_CONTEXT}
                        formValues={values}
                        serverURL={RETRIEVE_URL}
                        formParams={formParams(METHOD_TYPE.POST)}
                    >
                        {({ toggleSection, submitForm, exitPreview }) => (
                            <Formik
                                initialValues={this.state.declarationInitialValues}
                                validationSchema={this.state.declarationValidationSchema}>
                                {subFormik => (
                                    <React.Fragment>
                                        <Row>
                                            <Col className="body-content">

                                                {/*General Information Section*/}
                                                {this.state.devData.GeneralDefinition !== undefined ?
                                                    <AccordionContainer
                                                        title="General Information"
                                                        active={this.state.formNames['General Information'].status}
                                                        onClick={toggleSection}
                                                        isCollapsible={true}
                                                        isIndividual={true}
                                                        sections={this.state.formNames}
                                                        key="General Information">

                                                        {this.state.devData.GeneralDefinition.Sections.map(section => {
                                                            return <SubSection
                                                                title={section.DisplayName}
                                                                key={section.Name}>
                                                                {section.Fields.map(field => {
                                                                    return <Plaintext
                                                                        label={field.DisplayName}
                                                                        name={`GeneralInformation.${field.Name}`}
                                                                        value={values.GeneralInformation[field.Name]}
                                                                        isUpper={true}
                                                                        key={field.Name} />;
                                                                })}
                                                            </SubSection>;
                                                        })}

                                                    </AccordionContainer>
                                                    : null
                                                }

                                                {this.state.devData.FormDefinitions.map(form => {
                                                    return <AccordionContainer
                                                        title={form.FormName}
                                                        active={this.state.formNames[form.FormName].status}
                                                        onClick={toggleSection}
                                                        isCollapsible={true}
                                                        isIndividual={true}
                                                        sections={this.state.formNames}
                                                        key={form.FormName}
                                                    >
                                                        {form.Sections.map(section => {
                                                            return <SubSection
                                                                title={section.DisplayName}
                                                                key={section.Name}>
                                                                {section.Fields.map(field => {
                                                                    return <Plaintext
                                                                        label={field.DisplayName}
                                                                        name={`${form.FormType}.${field.Name}`}
                                                                        value={values[form.FormType][field.Name]}
                                                                        isUpper={true}
                                                                        key={field.Name}
                                                                    />;
                                                                })}
                                                            </SubSection>;
                                                        })}

                                                    </AccordionContainer>;
                                                })}

                                                <AccordionContainer
                                                    title="Declarations"
                                                    active={this.state.formNames['Declarations'].status}
                                                    onClick={toggleSection}
                                                    isCollapsible={true}
                                                    isIndividual={true}
                                                    sections={this.state.formNames}
                                                    key="Declarations"
                                                >
                                                    <React.Fragment>
                                                        {
                                                            this.state.devData.DeclarationDefinitions.Declarations.map(declaration => {
                                                                return (
                                                                    <React.Fragment key={declaration.Name}>
                                                                        <p>{declaration.Label}</p>
                                                                        <Checkbox
                                                                            name={declaration.Name}
                                                                            value={subFormik.values[declaration.Name]}
                                                                            options={[
                                                                                { label: 'I agree to the above. ', value: 'I AGREE TO THE ABOVE.', key: [declaration.Name] }]}
                                                                            onChangeField={subFormik.setFieldValue}
                                                                            error={subFormik.errors[declaration.Name]}
                                                                            labelRequired
                                                                            key={declaration.Name}
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                        }
                                                    </React.Fragment>

                                                </AccordionContainer>

                                            </Col>
                                        </Row>

                                        <Modal render={({ modal }) => (
                                            <>
                                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                                    <Button color="backward" size="sm" type="button" onClick={() => exitPreview()}>Back</Button>
                                                    <Button
                                                        color="forward"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => subFormik.validateForm().then(subFormik.isValid ?
                                                            modal.toggleModal(MODAL_NAMES.SUBMIT) : '')}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </PageFooter>

                                                <ModalConfirm
                                                    isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                                                    contentBody={
                                                        <p>
                                                            By clicking on confirm, you declare that you agree to the terms and conditions written on this form and that
                                                            all information that has been provided is true to the best of your knowledge.
                                                            These terms and conditions will be governed by and interpreted in accordance with the laws of the country.
                                                        </p>
                                                    }
                                                    confirmText="Confirm"
                                                    confirmCallback={() => { modal.toggleModal(MODAL_NAMES.SUBMIT); submitForm(); }}
                                                    cancelText="Cancel"
                                                    cancelCallback={() => modal.toggleModal(MODAL_NAMES.SUBMIT)}
                                                />
                                            </>
                                        )} />
                                    </React.Fragment>
                                )}
                            </Formik>
                        )}
                    </SMARTView>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(DevPreview, FORM));