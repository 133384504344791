import { default as React } from 'react';
import { Button } from 'reactstrap';
import { ADDRESS, DEFAULT_OVERSEAS_COUNTRY_ID_TYPE } from '../../../App/AppSettings';
import { AccordionContainer, Plaintext } from '../../../Shared/Forms';
import { withModalWrapper } from '../../../Shared/Modal/ModalWrapper';
import LicenceSummaryModal from './LicenceSummaryModal';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { withTranslation } from 'react-i18next';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';

const SECTION_NAMES = {
    APPLICANT_PARTICULARS: { title: "Applicant's Particulars", status: true },
    APPLICANT_ADDRESS: { title: "Applicant's Address", status: true },
    MAILING_ADDRESS: { title: 'Mailing Address', status: true }
}

const IS_UPPER = false;

const MODAL_NAME_REVIEW = 'REVIEW_LICENCES';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

class GeneralInformationLicenceSummary extends React.Component {

    handleReviewClick(modal) {
        modal.toggleModal(MODAL_NAME_REVIEW);
    };


    render() {
        const { modal, values,
            documentList, formName, toggleSection, t } = this.props;

        return (
            <React.Fragment>
                <div className="review-submit-blog">
                    <div className="review-header">
                        <h4>{formName}</h4>
                        <div className="review-action">
                            {/*note: cannot use <button> for some reason so color has to be passed in to get
                            rid of the default secondary color */}
                            <Button className="btn-bordered-small-review-info" color="grey"
                                onClick={() => this.handleReviewClick(modal)}>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_REVIEWINFORMATION)}
                            </Button>
                            {/* <button className="btn btn-bordered-small">Edit</button> */}
                        </div>
                    </div>
                    {documentList.length > 0 ?
                        <div className="review-details">
                            <h5>{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_DOCUMENTSSUBMITTED)}</h5>
                            {documentList.map((doc, i) => {
                                return <p
                                    key={i} className="file-pdf icons">{doc}</p>;
                            })}
                        </div>
                        : null
                    }
                </div>

                <LicenceSummaryModal
                    isOpen={modal.modalState === MODAL_NAME_REVIEW}
                    closeCallBack={() => modal.toggleModal(MODAL_NAME_REVIEW)}
                    contentHeader={this.props.formName}
                >
                    <>
                        <AccordionContainer
                            className="accordion"
                            title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantParticulars'))}
                            active={SECTION_NAMES.APPLICANT_PARTICULARS.status}
                            onClick={toggleSection}
                            isIndividual={true}
                            sections={SECTION_NAMES}
                        >
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Salutation'))}
                                name="GI_Salutation"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_Salutation}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Name'))}
                                name="GI_Name"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_Name}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDtype'))}
                                name="GI_IDtype"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_IDtype}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDnumber'))}
                                name="GI_IDnumber"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_IDnumber}
                                isUpper={IS_UPPER}
                            />
                            {values.GI_IDtype === DEFAULT_OVERSEAS_COUNTRY_ID_TYPE &&

                                <Plaintext
                                    className="modal-plaintext"
                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Passportissuingcountry'))}
                                    name="GI_Passportissuingcountry"
                                    inputSize={INPUT_SIZE}
                                    labelSize={LABEL_SIZE}
                                    value={values.GI_Passportissuingcountry}
                                    isUpper={IS_UPPER}
                                />
                            }
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Nationality'))}
                                name="GI_Nationality"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_Nationality}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Gender'))}
                                name="GI_Gender"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_Gender}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_DateOfBirth'))}
                                name="GI_DateOfBirth"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_DateOfBirth}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_PrimaryContact'))}
                                name="GI_PrimaryContact"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_PrimaryContact}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MobileNumber'))}
                                name="GI_MobileNumber"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_MobileNumber}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_OfficeNumber'))}
                                name="GI_OfficeNumber"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_OfficeNumber}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_HomeNumber'))}
                                name="GI_HomeNumber"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_HomeNumber}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_FaxNumber'))}
                                name="GI_FaxNumber"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_FaxNumber}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Designation'))}
                                name="GI_Designation"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_Designation}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Email'))}
                                name="GI_Email"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_Email}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AltEmailAddress'))}
                                name="GI_AltEmailAddress"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_AltEmailAddress}
                                isUpper={IS_UPPER}
                            />
                        </AccordionContainer>
                        <AccordionContainer
                            className="accordion"
                            title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantAddress'))}
                            active={SECTION_NAMES.APPLICANT_ADDRESS.status}
                            onClick={toggleSection}
                            isIndividual={true}
                            sections={SECTION_NAMES}
                        >

                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressType'))}
                                name="GI_AddressAddressType"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_AddressAddressType}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressTypeOfPremises'))}
                                name="GI_AddressTypeOfPremises"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_AddressTypeOfPremises}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressPostalCode'))}
                                name="GI_AddressPostalCode"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_AddressPostalCode}
                                isUpper={IS_UPPER}
                            />
                            {values.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                <>
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                        name="GI_AddressAddressLine1BlockNo"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine1BlockNo}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1StreetName'))}
                                        name="GI_AddressAddressLine1StreetName"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine1StreetName}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                        name="GI_AddressAddressLine2LevelNo"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine2LevelNo}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                        name="GI_AddressAddressLine2UnitNo"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine2UnitNo}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                        name="GI_AddressAddressLine2BuildingName"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine2BuildingName}
                                        isUpper={IS_UPPER}
                                    />
                                </>
                            }
                            {values.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                <>
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCountry'))}
                                        name="GI_AddressCountry"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressCountry}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                        name="GI_AddressAddressLine1AddressLine1"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine1AddressLine1}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                        name="GI_AddressAddressLine2AddressLine2"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressAddressLine2AddressLine2}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCity'))}
                                        name="GI_AddressCity"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressCity}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressState'))}
                                        name="GI_AddressState"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_AddressState}
                                        isUpper={IS_UPPER}
                                    />
                                </>
                            }
                        </AccordionContainer>
                        <AccordionContainer
                            className="accordion"
                            title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_MailingAddress'))}
                            active={SECTION_NAMES.MAILING_ADDRESS.status}
                            onClick={toggleSection}
                            isIndividual={true}
                            sections={SECTION_NAMES}
                        >
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressType'))}
                                name="GI_MailingAddressAddressType"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_MailingAddressAddressType}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressTypeOfPremises'))}
                                name="GI_MailingAddressTypeOfPremises"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_MailingAddressTypeOfPremises}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                className="modal-plaintext"
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressPostalCode'))}
                                name="GI_MailingAddressPostalCode"
                                inputSize={INPUT_SIZE}
                                labelSize={LABEL_SIZE}
                                value={values.GI_MailingAddressPostalCode}
                                isUpper={IS_UPPER}
                            />
                            {values.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                                <>
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                        name="GI_MailingAddressAddressLine1BlockNo"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine1BlockNo}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                        name="GI_MailingAddressAddressLine1StreetName"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine1StreetName}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                        name="GI_MailingAddressAddressLine2LevelNo"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine2LevelNo}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                        name="GI_MailingAddressAddressLine2UnitNo"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine2UnitNo}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                        name="GI_MailingAddressAddressLine2BuildingName"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine2BuildingName}
                                        isUpper={IS_UPPER}
                                    />
                                </>
                            }
                            {values.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                                <>
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCountry'))}
                                        name="GI_MailingAddressCountry"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressCountry}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                        name="GI_MailingAddressAddressLine1AddressLine1"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine1AddressLine1}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                        name="GI_MailingAddressAddressLine2AddressLine2"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressAddressLine2AddressLine2}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCity'))}
                                        name="GI_MailingAddressCity"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressCity}
                                        isUpper={IS_UPPER}
                                    />
                                    <Plaintext
                                        className="modal-plaintext"
                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressState'))}
                                        name="GI_MailingAddressState"
                                        inputSize={INPUT_SIZE}
                                        labelSize={LABEL_SIZE}
                                        value={values.GI_MailingAddressState}
                                        isUpper={IS_UPPER}
                                    />
                                </>
                            }
                        </AccordionContainer>
                    </>

                </LicenceSummaryModal>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withModalWrapper(GeneralInformationLicenceSummary));
