import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class LicenceSummaryModal extends React.Component {
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <div>
                <Modal toggle={this.props.closeCallBack} size="lg" isOpen={this.props.isOpen} className={"modal-content " + this.props.className}>
                    <ModalHeader toggle={this.props.closeCallBack} charCode='&#x1F5D9;'>{this.props.contentHeader}</ModalHeader>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default LicenceSummaryModal;