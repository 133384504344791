import React from 'react';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';


class SearchIntentNotFound extends React.Component {

    render() {
        const { searchValue, t } = this.props;

        return (
            <React.Fragment>
                <div className="not-found">
                    {
                        this.props.searchTermLengthError ?
                            <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SEARCHTERMTOOSHORT)}</p>
                            :
                            <>
                                <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_NOMATCHESFOUND)}<strong>{searchValue}</strong>"</p>
                                <div className="tips">
                                    <h5>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_TITLE_SEARCHTIPS)}</h5>
                                    <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_CHECKSPELLING)}</p>
                                    <p>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_TRYDIFFSEARCHTERM)}</p>
                                </div>
                            </>
                    }
                </div>
            </React.Fragment>
        );
    }
}

SearchIntentNotFound.defaultProps = {
    searchTermLengthError: false
}

export default withTranslation()(SearchIntentNotFound);