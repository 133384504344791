import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavDropdownConsumer } from "./NavDropdownContext"

class NavLink extends React.Component {

    // Fn: returns true if these additional paths are matched
    matchPath = ({ to, includePaths, location }) => {
        return (_.isEqual(to, location.pathname) || _.isEqual(includePaths, location.pathname)) ? true : false;
        };

    //Optional fontawesome icon
    getIcon =({icon}) =>{
        const iconString = icon ;
        if(!icon){
            return "";
        } else {
            return <i aria-hidden="false"> <FontAwesomeIcon size="lg" className="align-middle navbar-item-icon"  fixedWidth icon={iconString} /></i>;
        }
    }

    getClassName = () => {
        return "navbar-item"  
        + (this.props.dropdownlevel !== undefined ? " dropdownlevel-" + this.props.dropdownlevel:"")
    }

    render() {
        switch(this.props.navbarLayout){
            case "1D":
            default:
                return (
                    <RouterLink
                        className={this.getClassName()}
                        to={this.props.to}
                        isActive={() => this.matchPath(this.props)}
                        onClick={this.props.onClick}
                    >
                        {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                    </RouterLink>
                );
            case "1M":
                return (
                    <RouterLink
                        className={this.getClassName()}
                        to={this.props.to}
                        isActive={() => this.matchPath(this.props)}
                        onClick={this.props.onClick}
                    >
                        {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                    </RouterLink>
                );
            case "2D":
            case "2M":
                return (
                    <NavDropdownConsumer> 
                    {({setNavDropdown}) => 
                        <RouterLink
                            className={this.getClassName() + " nav-link"}
                            to={this.props.to}
                            isActive={() => this.matchPath(this.props)}
                            onClick={() => {this.props.onClick() ; setNavDropdown("")}}
                        >
                            {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                        </RouterLink>
                    }
                    </NavDropdownConsumer>
                );
        }
    }
}

NavLink.propTypes = {
    to: PropTypes.string.isRequired,
    dropdownlevel: PropTypes.string
};


export default withRouter(NavLink);