import React from 'react';
import Alert from 'react-s-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const toastInfo = (message) => {
  Alert.closeAll();
  Alert.info(info(message), {
    position: 'top',
    timeout: null
  });
}

export const toastSuccess = (message, defaultmessage) => {
  Alert.closeAll();
  if (message === '') {
    Alert.success(success(defaultmessage), {
      position: 'top',
      timeout: null
    });
  } else {
    Alert.success(success(message), {
      position: 'top',
      timeout: null
    });
  }
}

export const toastError = (message, defaultmessage) => {
  Alert.closeAll();
  if (message === '') {
    Alert.error(error(defaultmessage), {
      position: 'top',
      timeout: null
    });
  } else {
    Alert.error(error(message), {
      position: 'top',
      timeout: null
    });
  }
}

const info = (message) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={faInfoCircle} size="lg" className="toaster-icon" />
      {message}
    </React.Fragment>
  );
}

const success = (message) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={faCheck} size="lg" className="toaster-icon" />
      {message}
    </React.Fragment>
  );
}

const error = (message) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="toaster-icon" />
      {message}
    </React.Fragment>
  );
}


class Toaster extends React.Component {

  render() {
    return <Alert stack={this.props.isStack} />;
  }
}


export default Toaster;