import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { CART_ACTION_ADD, CART_ACTION_REMOVE } from '../../../Shared/Constants';
import LicenceItem from './LicenceItem';
import QuestionItem from './QuestionItem';
import { withTranslation } from 'react-i18next';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getTransl } from '../../../../directus';

class AnswerRadio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentAnswer: null,
            currentLicence: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isDisabled, exclusiveLicenceCart, AnswerGrp, updateBizIntentCart, bizIntentCart } = this.props;
        const { currentLicence } = this.state;

        const isDisabledProp = nextProps.isDisabled !== isDisabled && nextProps.isDisabled === true;

        const isDisabledChanged = nextProps.isDisabled !== isDisabled;
        const exclusivePropChanged = nextProps.exclusiveLicenceCart !== exclusiveLicenceCart;
        const bizIntentCartChanged = nextProps.bizIntentCart !== bizIntentCart;
        const stateChanged = nextState !== this.state;

        // Get array with all licence ids of the question
        const licenceArray = AnswerGrp.filter((aSet) => aSet.licence != null).map((aSet) => aSet.licence);
        const licenceArrayId = licenceArray.map(lic => lic.Id);

        // if all licences are exclusive, isAllExclusive will be true
        const isAllExclusive = exclusivePropChanged && (licenceArrayId.length > 0 && licenceArrayId.every(val => nextProps.exclusiveLicenceCart.includes(val)));
        // if current licence is exclusive, isCurrentAnswerExclusiveLicence will be true
        let isCurrentAnswerExclusiveLicence = false;
        if (currentLicence !== null) {
            isCurrentAnswerExclusiveLicence = exclusivePropChanged && nextProps.exclusiveLicenceCart.includes(currentLicence.Id);
        }

        //Add prerequisite Licences to array to remove
        let licenceArrayWithPreReq = JSON.parse(JSON.stringify(licenceArray));
        licenceArray.forEach(licence => {
            licenceArrayWithPreReq = licenceArrayWithPreReq.concat(this.getPreReqLicences(licence));
        });

        // if disable the entire question, or all licences in this question are exclusive
        if (isDisabledProp || isAllExclusive) {
            updateBizIntentCart(licenceArrayWithPreReq, CART_ACTION_REMOVE);
            this.setState({
                currentAnswer: null,
                currentLicence: null
            });
        }
        // if current selected option has the exclusive licence
        else if (isCurrentAnswerExclusiveLicence) {
            updateBizIntentCart(
                currentLicence,
                CART_ACTION_REMOVE,
                updateBizIntentCart(
                    this.getPreReqLicences(currentLicence),
                    CART_ACTION_REMOVE
                )
            );
            this.setState({
                currentAnswer: null,
                currentLicence: null
            });
        }

        return isDisabledChanged || exclusivePropChanged || bizIntentCartChanged || stateChanged;
    }

    handleAnswerClick = (answerSet) => {
        const { updateBizIntentCart } = this.props;
        let { currentAnswer, currentLicence } = this.state;
        const { licence, id } = answerSet;

        const licenceExistsForAnswer = answerSet.licence !== undefined && answerSet.licence !== null;
        //If there is a change in answer
        if (currentAnswer !== id) {

            //if currentAnswer is not set yet means it is an initial click
            //if initial click
            if (!currentAnswer) {
                //if there is a new licence to add
                if (licenceExistsForAnswer) {
                    updateBizIntentCart(
                        licence,
                        CART_ACTION_ADD,
                        updateBizIntentCart(this.getPreReqLicences(licence), CART_ACTION_ADD)
                    );
                }
            }
            else {
                //if there is a new licence to add
                if (licenceExistsForAnswer) {
                    //if there was a previous licence to remove, then remove, else just add new
                    if (currentLicence) {
                        updateBizIntentCart(
                            currentLicence,
                            CART_ACTION_REMOVE,
                            updateBizIntentCart(
                                this.getPreReqLicences(currentLicence),
                                CART_ACTION_REMOVE,
                                updateBizIntentCart(
                                    licence,
                                    CART_ACTION_ADD,
                                    updateBizIntentCart(
                                        this.getPreReqLicences(licence),
                                        CART_ACTION_ADD
                                    )
                                )
                            )
                        );
                    }
                    else {
                        //add new licence
                        updateBizIntentCart(
                            licence,
                            CART_ACTION_ADD,
                            updateBizIntentCart(this.getPreReqLicences(licence), CART_ACTION_ADD)
                        );
                    }
                }
                else {
                    //if there was a previous licence to remove, then remove
                    if (currentLicence) {
                        updateBizIntentCart(
                            currentLicence,
                            CART_ACTION_REMOVE,
                            updateBizIntentCart(this.getPreReqLicences(currentLicence), CART_ACTION_REMOVE)
                        );
                    }
                }
            }

            this.setState({
                currentAnswer: id,
                currentLicence: licence
            });
        }
    };

    sortAnswerGrp = (aGrp) => {
        aGrp.sort(function (a, b) { return a.answer_order - b.answer_order; });
        return aGrp;
    };

    getPreReqLicences = (licence) => {
        const { preReqLicences } = this.props;
        let currentPreReqLicences = [];

        //get prereq from master prereq list
        if (licence.PreRequisite.length > 0 && preReqLicences.length > 0) {
            const preReqIds = licence.PreRequisite.map(lic => lic.LicenceTypeId);
            currentPreReqLicences = preReqLicences.filter(lic => {
                return preReqIds.includes(lic.Id);
            });
        }

        return currentPreReqLicences;
    };

    render() {
        const { QuestionId, Description, AnswerGrp, updateBizIntentCart,
             bizIntentCart, ShowOnLoad, isDisabled, exclusiveLicenceCart, preReqLicences,
             currLang, Subquestion, SubAnsGrp } = this.props;
        const { currentAnswer } = this.state;

        return (
            //ShowOnLoad tells us whether or not this is a subquestion, thus toggling the css
            <div className={ShowOnLoad ? '' : 'box-hidden'}>
                <Row className={`bordered-box${currentAnswer !== null ? ' selected' : ''}${isDisabled ? ' disabled' : ''}`} >
                    <Col>
                        <div className='box-selection'>
                            <label className='check-control no-input'>
                                <strong>{Description}</strong>
                            </label>
                        </div>
                        <div className='bls-radio-select-main'>
                            {this.sortAnswerGrp(AnswerGrp).map((answerSet) => {
                                const { licence, answer, id } = answerSet;
                                const answerSetId = id;
                                const questionAnswerSet = {
                                    question: Subquestion,
                                    question_answer_group: SubAnsGrp,
                                    subquestion: null,
                                    subquestion_answer_group: null
                                };
                                const isAnswerDisabled = isDisabled || (licence && exclusiveLicenceCart.includes(licence.Id));

                                return (
                                    <div key={'Answer' + answerSetId} className='bls-radio-select'>
                                        <label className='bls-radio-control'>
                                            <input
                                                type='radio'
                                                name={'Answer' + QuestionId}
                                                className='bls-radio-btn'
                                                checked={currentAnswer === answerSetId}
                                                onChange={() => { /*used only to remove warnings*/ }}
                                                onClick={() => this.handleAnswerClick(answerSet)}
                                                disabled={isAnswerDisabled}
                                            >
                                            </input>
                                            <span className={`bls-overlay-radio${isAnswerDisabled ? ' disabled' : ''}`}></span>{getTransl(answer, currLang).description}</label>
                                        {
                                            currentAnswer === answerSetId && licence &&
                                            <>
                                                <LicenceItem
                                                    licence={licence}
                                                    preReqLicences={this.getPreReqLicences(licence)}
                                                />
                                            </>
                                        }
                                        {currentAnswer === answerSetId && Subquestion &&
                                            // if subquestion exists, filter through the entire question list to find the subquestion to display
                                            <QuestionItem
                                                key={'SubQuestion' + Subquestion.id}
                                                questionAnswerSet={questionAnswerSet}
                                                updateBizIntentCart={updateBizIntentCart}
                                                bizIntentCart={bizIntentCart}
                                                exclusiveLicenceCart={exclusiveLicenceCart}
                                                preReqLicences={preReqLicences}
                                                currLang={currLang}
                                                isSubquestion={true}
                                            />
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

AnswerRadio.propTypes = {
    Description: PropTypes.string.isRequired,
    AnswerGrp: PropTypes.array.isRequired,
    updateBizIntentCart: PropTypes.func.isRequired,
    ShowOnLoad: PropTypes.bool.isRequired
};

export default withTranslation()(AnswerRadio);
