import React from 'react';
import { Col, Row } from 'reactstrap';
import AccordionQuestion from './AccordionQuestion';
import './Faq.css';

class QuestionAnswerDisplay extends React.Component {

    render() {
        const { questionSets } = this.props;
        return (
            <Row >
                <Col xs="12" className='question-answer-display'>
                    {questionSets.map((questionSet, questionSetIndex) => (
                        <AccordionQuestion
                            questionSet={questionSet}
                            questionSetIndex={questionSetIndex}
                        />
                    ))}
                </Col>
            </Row>

        );
    }
}
export default (QuestionAnswerDisplay);
