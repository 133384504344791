import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';


class ModalBasic extends React.Component {
    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }

    render() {
        return (
            <div>
            <Modal size="lg" toggle={this.props.closeCallBack} isOpen={this.props.isOpen} className={"modal-content " + this.props.className}>
                <ModalHeader toggle={this.props.closeCallBack} charCode='&#x1F5D9;'>{this.props.contentHeader}</ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
            </Modal>
            </div>
        );
    }
}

// PropTypes: For prop validation
ModalBasic.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    //contentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeCallBack: PropTypes.func,
    addToCartCallBack: PropTypes.func,
    applyCallBack: PropTypes.func,
    isShowAddCart: PropTypes.bool,
    isShowApply: PropTypes.bool,
};

// PropTypes: Defaulting value for optional props
ModalBasic.defaultProps = {
    isOpen: false,
};


export default withRouter(ModalBasic);