import _ from "lodash";
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Button } from "reactstrap";
import { compose } from "recompose";
import {
  DASHBOARD_PATH,
  GET_APPLICATION_FORM_DATA_URL,
  GET_APPLICATION_PENDING_PAYMENT_DATA_URL,
  GET_APPLICATION_RFA_DETAILS_URL,
  GET_APPLICATION_RFA_HISTORY_LIST_URL,
  GET_FILE_URL,
  GET_PAYMENT_DETAILS_URL,
  GET_PAYMENT_RECEIPT_URL,
  LAYOUT_TYPE,
  GENERAL_INFORMATION_FORMTYPE,
  GET_LICENCE_DOCUMENT_FILE_URL,
} from "../../App/AppSettings";
import Layout from "../../Layout";
import {
  fetchRequest,
  getFileRequest,
  getParams,
  navigateTo,
} from "../../Shared/Actions";
import { FIELD_SIZE, MIN_FILTER_CHARS } from "../../Shared/Constants";
import { DataTable } from "../../Shared/DataTable";
import { getCustomComponents } from "../../Shared/DataTable/BaseTable.js";
import {
  AccordionContainer,
  Plaintext,
  SMARTView,
  toastError,
  ScrollToTop,
} from "../../Shared/Forms";
import history from "../../Shared/History";
import { withLoader } from "../../Shared/Loading";
import ModalRemarksView from "../../Shared/Modal/ModalRemarksView";
import { withModalWrapper } from "../../Shared/Modal/ModalWrapper";
import {
  IndividualGIView,
  CorporateGIView,
} from "../FormComponents/GeneralInformation";
import FormApplicationDetails from "./FormApplicationDetails";
import { withTranslation } from "react-i18next";
import { ERROR, LANGUAGE_KEYS } from "../../Shared/Constants/LanguageKeys";
import {
  getLangKey,
  getLicenceTypeNameTranslationKey,
} from "../DisplayComponents/DisplayUtils";

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const MODAL_NAMES = {
  REMARKS_VIEW: "Remarks",
};

const WORKFLOW_STATE = {
  PENDING_ADDITIONAL_PAYMENT: "Pending Additional Payment",
  PENDING_ONLINE_PAYMENT: "Pending Payment",
};

const FORM_CONTEXT = "ApplicationDetails";

export const GeneralInformationComponent = {
  [GENERAL_INFORMATION_FORMTYPE.INDIVIDUAL]: IndividualGIView,
  [GENERAL_INFORMATION_FORMTYPE.CORPORATE]: CorporateGIView,
};

class ApplicationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application: null,
    };
    if (history.location.state) {
      this.state = {
        application: history.location.state.application,
      };
    }
  }

  componentDidMount() {
    const { loader } = this.props;

    loader.start();
    this.getApplicationFormData(loader);
  }

  getApplicationFormData = async (loader) => {
    const { t } = this.props;
    const response = await fetchRequest(
      GET_APPLICATION_FORM_DATA_URL +
        this.state.application.ApplicationRefNumber,
      getParams(),
      false
    );
    const { IsSuccess, Data } = response.body;
    if (IsSuccess) {
      this.setState((prevState) => {
        return {
          application: Object.assign(prevState.application, Data, {
            FormData: JSON.parse(Data.FormData),
          }),
          applicationDataLoaded: true,
        };
      });
      this.getApplicationRfaDetails(loader);
      this.getPendingPaymentDetails(loader);
      this.getRFAHistoryData(loader);
      this.getPaymentDetails(loader);
      this.stopLoader(loader);
    } else {
      this.setState({
        applicationDataLoaded: true,
      });
      toastError(t(ERROR.FORM_DATA_NOT_FETCHED));
      this.stopLoader(loader);
    }
  };

  getApplicationRfaDetails = async (loader) => {
    const { t } = this.props;
    const response = await fetchRequest(
      GET_APPLICATION_RFA_DETAILS_URL +
        this.state.application.ApplicationRefNumber,
      getParams(),
      false
    );
    const { IsSuccess, Data } = response.body;

    if (IsSuccess) {
      this.setState({
        applicationRfa: Data,
        applicationRfaLoaded: true,
      });
      this.stopLoader(loader);
    } else {
      this.setState({
        applicationRfaLoaded: true,
      });
      toastError(t(ERROR.RFA_DETAILS_NOT_FETCHED));
      this.stopLoader(loader);
    }
  };

  getRFAHistoryData = async (loader) => {
    const { t } = this.props;
    const response = await fetchRequest(
      GET_APPLICATION_RFA_HISTORY_LIST_URL + this.state.application.SFormId,
      getParams(),
      false
    );
    const { IsSuccess, Data } = response.body;

    if (IsSuccess) {
      const rfaHistoryData = Data;
      rfaHistoryData.forEach((rfaHistory) => {
        let { LastUpdatedBy, LastUpdatedDate } = rfaHistory;

        rfaHistory.LastUpdatedBy = !LastUpdatedBy ? "-" : LastUpdatedBy;
        rfaHistory.LastUpdatedDate = !LastUpdatedDate ? "-" : LastUpdatedDate;
      });
      this.setState(() => {
        return {
          rfaHistory: rfaHistoryData,
          applicationRfaHistoryLoaded: true,
        };
      });
      this.stopLoader(loader);
    } else {
      this.setState({
        applicationRfaHistoryLoaded: true,
      });
      toastError(t(ERROR.RFA_DETAILS_HISTORY_NOT_FETCHED));
      this.stopLoader(loader);
    }
  };

  // Loader done
  stopLoader = async (loader) => {
    const {
      applicationRfaLoaded,
      applicationDataLoaded,
      applicationRfaHistoryLoaded,
      applicationPaymentLoaded,
      applicationPendingPaymentDetailsLoaded,
    } = this.state;

    if (
      applicationRfaLoaded &&
      applicationDataLoaded &&
      applicationRfaHistoryLoaded &&
      applicationPaymentLoaded &&
      applicationPendingPaymentDetailsLoaded
    ) {
      loader.done();
    }
  };

  openRFAHistoryRemarks = async (modal, internalRemarks, publicResponse) => {
    this.setState(() => {
      return {
        currInternalRemarks: internalRemarks ? internalRemarks : "",
        currPublicResponse: publicResponse ? publicResponse : "",
      };
    });

    modal.toggleModal(MODAL_NAMES.REMARKS_VIEW);
  };

  getPaymentDetails = async (loader) => {
    const { t } = this.props;
    const response = await fetchRequest(
      GET_PAYMENT_DETAILS_URL + this.state.application.SFormId,
      getParams(),
      false
    );
    const { IsSuccess, Data } = response.body;

    if (IsSuccess) {
      this.setState({
        applicationPayment: Data,
        applicationPaymentLoaded: true,
      });
      this.stopLoader(loader);
    } else {
      this.setState({
        applicationPaymentLoaded: true,
      });
      toastError(t(ERROR.PAYMENT_DETAILS_NOT_FETCHED));
      this.stopLoader(loader);
    }
  };

  setContent = (rowData) => (
    <div
      className="application-link div-table-link"
      onClick={() => this.getReceipt(rowData.PaymentReferenceNumber)}
    >
      {rowData.PaymentReferenceNumber}
    </div>
  );

  setPaymentModeTranslatedContent = (rowData) => {
    const { t } = this.props;
    return (
      <>
        {t(
          getLangKey(
            LANGUAGE_KEYS.BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY,
            rowData.PaymentMode.toLowerCase()
          )
        )}
      </>
    );
  };

  getTranslatedString = (rowData) => {
    const { t } = this.props;
    return t(
      getLangKey(
        LANGUAGE_KEYS.BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY,
        rowData.PaymentMode.toLowerCase()
      )
    );
  };

  getReceipt = async (PaymentReferenceNumber) => {
    const { loader } = this.props;
    loader.start();

    const paramValue = PaymentReferenceNumber;
    const url = GET_PAYMENT_RECEIPT_URL.concat(
      "applicationPaymentReferenceNo=" + paramValue
    );
    const response = await getFileRequest(url, false, paramValue);

    loader.done();

    return response;
  };

  getPendingPaymentDetails = async (loader) => {
    const { t } = this.props;
    if (
      this.state.application.ApplicationInternalStatus ===
        WORKFLOW_STATE.PENDING_ONLINE_PAYMENT ||
      this.state.application.ApplicationInternalStatus ===
        WORKFLOW_STATE.PENDING_ADDITIONAL_PAYMENT
    ) {
      const response = await fetchRequest(
        GET_APPLICATION_PENDING_PAYMENT_DATA_URL +
          this.state.application.ApplicationRefNumber,
        getParams(),
        false
      );
      const { IsSuccess, Data } = response.body;

      if (IsSuccess) {
        this.setState({
          applicationPendingPaymentDetails: { Data },
          applicationPendingPaymentDetailsLoaded: true,
        });
        this.stopLoader(loader);
      } else {
        this.setState({
          applicationPendingPaymentDetailsLoaded: true,
        });
        toastError(t(ERROR.PAYMENT_STATUS_NOT_FETCHED));
        this.stopLoader(loader);
      }
    } else {
      this.setState({
        applicationPendingPaymentDetailsLoaded: true,
      });
      this.stopLoader(loader);
    }
  };

  getCustomDocument = async (id, fileName) => {
    const { loader } = this.props;
    loader.start();
    const url =
      GET_LICENCE_DOCUMENT_FILE_URL + "id=" + id + "&fileName=" + fileName;

    const response = await getFileRequest(url, false, fileName);

    loader.done();

    return response;
  };

  renderApplicationDocumentLink = (rowData) => (
    <div
      className="application-link div-table-link"
      onClick={() => this.getCustomDocument(rowData.Id, rowData.Filename)}
    >
      {rowData.DocumentName}
    </div>
  );

  setMobileContent = (rowData) => {
    const { t } = this.props;
    return (
      <tr key={"PaymentRefNum"}>
        <td width="40%" className="card-table-td card-table-title">
          <b>
            {t(
              LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_PAYMENTREFNUMBER
            )}
          </b>
          :
        </td>
        <td width="60%" className="card-table-td card-table-data">
          {this.setContent(rowData)}
        </td>
      </tr>
    );
  };

  render() {
    const { t } = this.props;
    const SECTION_NAMES = {
      APP_INFO: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_INFORMATIONOFAPPLICATION
        ),
        status: true,
      },
      GENERAL_INFO: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_GENERALINFORMATION
        ),
        status: true,
      },
      LICENCE_OVERVIEW: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SERVICEOVERVIEW
        ),
        status: true,
      },
      SERVICE_APP_DETAILS: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SERVICEAPPLICATIONDETAILS
        ),
        status: true,
      },
      CANCELLATION_INFORMATION: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_CANCELLATIONINFORMATION
        ),
        status: true,
      },
      SUPPORTING_DOCS: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_SUPPORTINGDOCUMENTS
        ),
        status: true,
      },
      SERVICE_APP_DOCUMENTS: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_APPLICATIONDOCUMENTS
        ),
        status: true,
      },
      PAYMENT_RECEIPT: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_PAYMENTRECEIPT
        ),
        status: true,
      },
      CORRESPONDENCE: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_CORRESPONDENCE
        ),
        status: true,
      },
      REQUEST_FOR_ACTION: {
        title: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_SECTION_REQUESTFORACTIONHISTORY
        ),
        status: true,
      },
    };

    const ApplicationValues = {
      Application: this.state.application,
      ApplicationRfa: this.state.applicationRfa,
      ApplicationPayment: this.state.applicationPayment,
      ApplicationPendingPayment: this.state.applicationPendingPaymentDetails,
      ApplicationDocuments: this.state.applicationDocuments,
    };

    const { Application } = ApplicationValues;
    const ApplicationRfa = ApplicationValues.ApplicationRfa;
    const ApplicationRfaExists =
      ApplicationRfa && Object.keys(ApplicationRfa).length > 0;
    const RFAHistory = this.state.rfaHistory;
    const PaymentInformation = ApplicationValues.ApplicationPayment;
    const PendingPaymentInfo = ApplicationValues.ApplicationPendingPayment;
    const ApplicationDocuments = Application.LicenceDocuments;
    let SupportingDocumentsCategories = [];
    if (Application && Application.SupportingDocuments) {
      const docs = Application.SupportingDocuments;
      SupportingDocumentsCategories = _.groupBy(
        docs,
        (doc) => doc.FileDescription
      );
    }

    const {
      AgencyCode,
      AgencyDisplayName,
      FormData,
      LicenceName,
      GeneralInformationType,
      LicenceTypeId,
      DocumentsFailedToGenerate,
    } = Application;
    const { GeneralInformation, LicenceInformation } = FormData ? FormData : "";
    const { modal } = this.props;
    const { currInternalRemarks, currPublicResponse } = this.state;

    const GeneralInformationComponentDisplay =
      GeneralInformationComponent[GeneralInformationType];
    const licenceTypeNameTranslationKey =
      getLicenceTypeNameTranslationKey(LicenceName);

    return (
      <>
        <ScrollToTop />
        <Layout type={LAYOUT_TYPE.FORM} footer={true}>
          <div className="main-content">
            <nav aria-label="breadcrumb" className="breadcrumb-main">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={DASHBOARD_PATH}>
                      {t(
                        LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2
                      )}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={DASHBOARD_PATH}>
                      {t(
                        LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_VIEWAPPLICATIONDETAILS
                      )}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t(
                      getLangKey(
                        LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY,
                        licenceTypeNameTranslationKey,
                        LicenceTypeId
                      )
                    )}
                  </li>
                </ol>
                <div className="back-btn">
                  <Button
                    color="header"
                    className="btn-header"
                    onClick={() => navigateTo(DASHBOARD_PATH)}
                  >
                    {t(
                      LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE
                    )}
                  </Button>
                </div>
              </div>
            </nav>
            <div className="container">
              {ApplicationRfaExists && (
                <>
                  <div className="detailed-box db-app-details">
                    <div className="db-details-head">
                      {ApplicationRfa.RFAType}
                    </div>
                    <div className="details-box">
                      <h5>
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_APPLICATIONBEINGPROCESSED
                        )}{" "}
                        <bdi>{AgencyDisplayName}</bdi>{" "}
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_MESSAGESENT
                        )}{" "}
                      </h5>
                      <div className="details-body">
                        <p>{ApplicationRfa.InternalRemarks} </p>
                      </div>
                      <div className="details-footer">
                        <p>
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_RESPONDBY
                          )}{" "}
                          <strong>{ApplicationRfa.DueDate}</strong>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="details-buttons">
                      <Button
                        color="rfa"
                        className="btn btn-bordered-small  btn-apply"
                        onClick={() =>
                          navigateTo("/respondToRequestForAction", {
                            Application: ApplicationValues,
                          })
                        }
                      >
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_MESSAGE_INFORMATION_RESPOND
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {PendingPaymentInfo &&
                this.state.application.ApplicationInternalStatus ===
                  WORKFLOW_STATE.PENDING_ADDITIONAL_PAYMENT && (
                  <>
                    <div className="detailed-box db_lice_renew_details">
                      <div className="db-details-head">
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADDITIONALPAYMENTREQUIRED
                        )}
                      </div>
                      <div className="details-box">
                        <h5>
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_ADDITIONALPAYMENTINFO
                          )}
                          <br />
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_SUBMITSECONDPAYMENT
                          )}
                        </h5>
                        <div className="details-footer">
                          <p className="date-fee">
                            {t(
                              LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_PAYMENTDUEDATE
                            )}
                            <strong>
                              {PendingPaymentInfo.Data.PaymentDueDate}
                            </strong>
                          </p>
                          <p className="date-fee">
                            {t(
                              LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_ADDITIONALPAYMENTFEE
                            )}
                            <strong>
                              {t(
                                LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_INFORMATION_CURRENCYSYMBOL
                              )}{" "}
                              {PendingPaymentInfo.Data.TotalAmountDue}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div className="details-buttons">
                        <Button
                          color="renew"
                          className="btn btn-bordered-small  btn-apply"
                          onClick={() =>
                            navigateTo(PendingPaymentInfo.Data.RedirectURL, {
                              Data: PendingPaymentInfo.Data.SubmissionNumber,
                            })
                          }
                        >
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_MAKEPAYMENT
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              {PendingPaymentInfo &&
                this.state.application.ApplicationInternalStatus ===
                  WORKFLOW_STATE.PENDING_ONLINE_PAYMENT && (
                  <>
                    <div className="detailed-box db_lice_renew_details">
                      <div className="db-details-head">
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_PAYMENTREQUIRED
                        )}
                      </div>
                      <div className="details-box">
                        <h5>
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_PAYMENTREQUIREDINFO
                          )}
                        </h5>
                      </div>
                      <div className="details-buttons">
                        <Button
                          color="renew"
                          className="btn btn-bordered-small  btn-apply"
                          onClick={() =>
                            navigateTo(PendingPaymentInfo.Data.RedirectURL, {
                              Data: PendingPaymentInfo.Data.SubmissionNumber,
                            })
                          }
                        >
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_MAKEPAYMENT
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                )}

              <SMARTView formContext={FORM_CONTEXT} isDummy={false}>
                {({ toggleSection, toggleAllSections }) => (
                  <>
                    <div
                      className={`${
                        ApplicationRfaExists
                          ? "form-collapse detailed-box-form"
                          : "form-collapse detailed-box-form margin"
                      }`}
                    >
                      <div className="collapse-expand">
                        <Button
                          color="toggle"
                          className="toggle-all"
                          onClick={() => toggleAllSections(SECTION_NAMES, true)}
                        >
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL
                          )}
                        </Button>
                        <Button
                          color="toggle"
                          className="toggle-all"
                          onClick={() =>
                            toggleAllSections(SECTION_NAMES, false)
                          }
                        >
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL
                          )}
                        </Button>
                      </div>
                      <AccordionContainer
                        title={SECTION_NAMES.APP_INFO.title}
                        active={SECTION_NAMES.APP_INFO.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                      >
                        {Application && (
                          <>
                            <div className="card-body">
                              <div className="form-body form-preview">
                                <Plaintext
                                  label={t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER
                                  )}
                                  name="ApplicationRefNumber"
                                  value={Application.ApplicationRefNumber}
                                  labelSize={LABEL_SIZE}
                                  inputSize={INPUT_SIZE}
                                />
                                <Plaintext
                                  label={t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME
                                  )}
                                  name="LicenceName"
                                  value={t(
                                    getLangKey(
                                      LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY,
                                      licenceTypeNameTranslationKey,
                                      LicenceTypeId
                                    )
                                  )}
                                  labelSize={LABEL_SIZE}
                                  inputSize={INPUT_SIZE}
                                />
                                {Application.LicenceNumber && (
                                  <Plaintext
                                    label={t(
                                      LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_FIELD_SERVICENUMBER
                                    )}
                                    name="LicenceNumber"
                                    value={Application.LicenceNumber}
                                    labelSize={LABEL_SIZE}
                                    inputSize={INPUT_SIZE}
                                  />
                                )}
                                <Plaintext
                                  label={t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_STATUS
                                  )}
                                  name="ApplicationStatus"
                                  value={Application.ApplicationStatus}
                                  labelSize={LABEL_SIZE}
                                  inputSize={INPUT_SIZE}
                                />
                                <Plaintext
                                  label={t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_AGENCY
                                  )}
                                  name="Agency"
                                  value={`${AgencyDisplayName} (${AgencyCode})`}
                                  labelSize={LABEL_SIZE}
                                  inputSize={INPUT_SIZE}
                                />
                                <Plaintext
                                  label={t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONNUMBER
                                  )}
                                  name="SubmissionNumber"
                                  value={Application.SubmissionNumber}
                                  labelSize={LABEL_SIZE}
                                  inputSize={INPUT_SIZE}
                                />
                                <Plaintext
                                  label={t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONDATE
                                  )}
                                  name="SubmissionDate"
                                  value={Application.SubmissionDate}
                                  labelSize={LABEL_SIZE}
                                  inputSize={INPUT_SIZE}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </AccordionContainer>

                      {GeneralInformation && (
                        <AccordionContainer
                          title={SECTION_NAMES.GENERAL_INFO.title}
                          active={SECTION_NAMES.GENERAL_INFO.status}
                          onClick={toggleSection}
                          isIndividual={true}
                          sections={SECTION_NAMES}
                        >
                          <GeneralInformationComponentDisplay
                            values={GeneralInformation}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                          />
                        </AccordionContainer>
                      )}

                      {LicenceInformation && LicenceName && (
                        <FormApplicationDetails
                          values={Application}
                          toggleSection={toggleSection}
                          sectionNames={SECTION_NAMES}
                          applicationType={Application.ApplicationType}
                          formDefinition={Application.SFormDefinition}
                        />
                      )}

                      {SupportingDocumentsCategories &&
                        Object.keys(SupportingDocumentsCategories).length >
                          0 && (
                          <AccordionContainer
                            title={SECTION_NAMES.SUPPORTING_DOCS.title}
                            active={SECTION_NAMES.SUPPORTING_DOCS.status}
                            onClick={toggleSection}
                            isIndividual={true}
                            sections={SECTION_NAMES}
                          >
                            <div className="card-body">
                              <div className="form-body form-preview">
                                {Object.keys(SupportingDocumentsCategories).map(
                                  (key, catIndex) => {
                                    return SupportingDocumentsCategories[
                                      key
                                    ].map((doc, docIndex) => {
                                      const categoryLabel =
                                        docIndex === 0
                                          ? t(
                                              getLangKey(
                                                LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY,
                                                doc.FileUploadField
                                              )
                                            )
                                          : "";
                                      return (
                                        <Plaintext
                                          key={`${catIndex}-${docIndex}`}
                                          name={`${doc.Filename}-${doc.Id}`}
                                          label={`${categoryLabel}`}
                                          getFileURL={GET_FILE_URL}
                                          value={doc}
                                          isSupportingDocument={true}
                                          labelSize={LABEL_SIZE}
                                          inputSize={2}
                                        />
                                      );
                                    });
                                  }
                                )}
                              </div>
                            </div>
                          </AccordionContainer>
                        )}

                      <AccordionContainer
                        title={SECTION_NAMES.SERVICE_APP_DOCUMENTS.title}
                        active={SECTION_NAMES.SERVICE_APP_DOCUMENTS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                      >
                        {DocumentsFailedToGenerate &&
                          DocumentsFailedToGenerate.length > 0 && (
                            <p className="ml-3 text-danger text-left">
                              {t(ERROR.UNABLETOGENARATEDOCUMENT) +
                                " " +
                                DocumentsFailedToGenerate.join(", ")}
                              <br />
                              {t(ERROR.CONTACTAGENCYFORSUPPORT)}
                            </p>
                          )}
                        {ApplicationDocuments && (
                          <DataTable
                            pageSize="5"
                            minFilterChars={MIN_FILTER_CHARS}
                            data={ApplicationDocuments}
                            noResultsMessage={t(
                              LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOAPPLICATIONDOCUMENTSFOUND
                            )}
                            components={getCustomComponents()}
                            columns={{
                              DocumentName: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DOCUMENTNAME
                                ),
                                width: "5%",
                                DBkey: "DocumentName",
                                setContent: this.renderApplicationDocumentLink,
                              },
                              Status: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_STATUS
                                ),
                                width: "5%",
                                DBkey: "Status",
                              },
                              IssuseDate: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_ISSUEDDATE
                                ),
                                width: "5%",
                                DBkey: "CreatedDate",
                                isDate: true,
                              },
                            }}
                          />
                        )}
                      </AccordionContainer>

                      {PaymentInformation && (
                        <AccordionContainer
                          title={SECTION_NAMES.PAYMENT_RECEIPT.title}
                          active={SECTION_NAMES.PAYMENT_RECEIPT.status}
                          onClick={toggleSection}
                          isIndividual={true}
                          sections={SECTION_NAMES}
                        >
                          {PaymentInformation && (
                            <DataTable
                              pageSize="5"
                              minFilterChars={MIN_FILTER_CHARS}
                              data={PaymentInformation}
                              noResultsMessage={t(
                                LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOPAYMENTREQUIRED
                              )}
                              components={getCustomComponents()}
                              columns={{
                                PaymentReferenceNumber: {
                                  title: t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_PAYMENTREFNUMBER
                                  ),
                                  width: "5%",
                                  DBkey: "PaymentReferenceNumber",
                                  setContent: this.setContent,
                                  setMobileContent: this.setMobileContent,
                                },
                                PaymentDate: {
                                  title: t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DATEOFPAYMENT
                                  ),
                                  width: "5%",
                                  DBkey: "PaymentDate",
                                  isDate: true,
                                },
                                Amount: {
                                  title: t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_AMOUNTPAID
                                  ),
                                  width: "5%",
                                  DBkey: "AmountPaid",
                                },
                                PaymentMode: {
                                  title: t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_MODEPAYMENT
                                  ),
                                  width: "5%",
                                  DBkey: "PaymentMode",
                                  setContent:
                                    this.setPaymentModeTranslatedContent,
                                  getTranslatedString: this.getTranslatedString,
                                },
                              }}
                            />
                          )}
                        </AccordionContainer>
                      )}

                      {RFAHistory && (
                        <AccordionContainer
                          title={SECTION_NAMES.REQUEST_FOR_ACTION.title}
                          active={SECTION_NAMES.REQUEST_FOR_ACTION.status}
                          onClick={toggleSection}
                          isIndividual={true}
                          sections={SECTION_NAMES}
                        >
                          <DataTable
                            pageSize="5"
                            minFilterChars={MIN_FILTER_CHARS}
                            data={RFAHistory}
                            noResultsMessage={t(
                              LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOREQFORACTIONHISTORY
                            )}
                            components={getCustomComponents()}
                            columns={{
                              RFAType: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_TYPEOFREQUEST
                                ),
                                width: "15%",
                                DBkey: "RFAType",
                              },
                              CreatedDate: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_REQUESTDATE
                                ),
                                width: "15%",
                                DBkey: "CreatedDate",
                                isDate: true,
                              },
                              DueDate: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_DEADLINE
                                ),
                                width: "15%",
                                DBkey: "DueDate",
                              },
                              LastUpdatedDate: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_RESPONSEDATE
                                ),
                                width: "15%",
                                DBkey: "LastUpdatedDate",
                                isDate: true,
                              },
                              Actions: {
                                title: t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_TABLE_TITLE_OFFICERREMARKSRESPONSE
                                ),
                                width: "20%",
                                DBkey: "Actions",
                              },
                            }}
                            renderActions={({ RowData }) => (
                              <React.Fragment>
                                <Button
                                  color="view"
                                  className="btn-bordered-small"
                                  onClick={() => {
                                    this.openRFAHistoryRemarks(
                                      modal,
                                      RowData.InternalRemarks,
                                      RowData.PublicResponse
                                    );
                                  }}
                                >
                                  {t(
                                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_VIEW
                                  )}
                                </Button>
                              </React.Fragment>
                            )}
                            initialSort={{
                              id: "CreatedDate",
                              sortAscending: false,
                            }}
                          />
                        </AccordionContainer>
                      )}

                      <div className="button-center">
                        <Button
                          className="btn"
                          onClick={() => navigateTo(DASHBOARD_PATH)}
                        >
                          {t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE
                          )}
                        </Button>
                        {/* <Button color="hazard" className="btn" onClick={e => e.preventDefault()}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_WITHDRAWAPPLICATION)}</Button> */}
                      </div>

                      <ModalRemarksView
                        isOpen={modal.modalState === MODAL_NAMES.REMARKS_VIEW}
                        modalClassName="rfaDetailsModal"
                        modalHeaderClassName="rfaDetailsHeaderFont"
                        cancelText="Close"
                        cancelCallback={() =>
                          modal.toggleModal(MODAL_NAMES.REMARKS_VIEW)
                        }
                        contentBody={
                          <>
                            <h5>
                              <b>
                                {t(
                                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL_OFFICERREMARKS
                                )}
                              </b>
                            </h5>
                            <Plaintext
                              name="InternalRemarks"
                              value={currInternalRemarks}
                              labelSize={FIELD_SIZE.NONE}
                            />
                            {currPublicResponse !== "" && (
                              <>
                                <h5>
                                  <b>
                                    {t(
                                      LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_MODAL_RESPONSE
                                    )}
                                  </b>
                                </h5>
                                {currPublicResponse && (
                                  <Plaintext
                                    name="PublicResponse"
                                    value={currPublicResponse}
                                    labelSize={FIELD_SIZE.NONE}
                                  />
                                )}
                              </>
                            )}
                          </>
                        }
                      />
                    </div>
                  </>
                )}
              </SMARTView>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}
export default withTranslation()(
  withRouter(compose(withLoader, withModalWrapper)(ApplicationDetails))
);
