import React from 'react';
import { withTranslation } from 'react-i18next';
import { getLicenceTypeNameTranslationKey, getLangKey, DISPLAY_RICH_TEXT, getTranslation } from '../../Content/DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

class PreRequisiteItem extends React.Component {
    displayDescription = (desc, isShow) => {
        if (isShow) {
            return (
                <div dangerouslySetInnerHTML={{ __html: desc }} />
            );
        } else {
            return null;
        }
    };

    displayFeeTime = (licence, isShow) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        if (isShow) {
            return (
                <>
                    <p className='fee'>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_FEE)}&nbsp;:&nbsp;$
                        <strong>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_FEEDESC_KEY, licenceTypeNameTranslationKey,licence.LicenceDefinitionId))}</strong>
                    </p>
                    <p className='time'>{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_PROCESSINGTIME)}&nbsp;:&nbsp;
                        <strong>{licence.TimeDesc}</strong> {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_FIELD_WORKINGDAYS)}
                    </p>
                </>
            );
        } else {
            return null;
        }
    };

    displayDetailsButton = (id, onClick, isShow) => {
        const { t } = this.props;
        if (isShow) {
            return (
                <button className='btn btn-details' onClick={onClick} >{t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_BUTTON_DETAILS)}        </button>
            );
        }
        else {
            return null;
        }
    };

    externalLink = (e, url) => {
        e.preventDefault();
        const httpSubstring = 'http';
        let newUrl = url;
        if (url.indexOf(httpSubstring) !== 0) {
            newUrl = `http://${url}`;
        }
        window.open(newUrl, '_blank');
    };

    render() {
        const { t, licence, isShowDescription, isShowFeeTime, onClick, isShowDetails } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence.Title);

        return (
            <div className='result-block'>
                <div className='pre-req-result-details'>
                    <h5>{getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licence.Id))}</h5>
                    {this.displayDescription(
                        getTranslation(t, getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEF_LICENCEDESC_KEY, licenceTypeNameTranslationKey,licence.LicenceDefinitionId), DISPLAY_RICH_TEXT),
                        isShowDescription
                    )}
                </div>
                <div className='result-footer'>
                    <div className='float-left'>
                        {this.displayFeeTime(licence, isShowFeeTime)}

                    </div>
                    <div className='float-right'>
                        {this.displayDetailsButton(licence.Id, onClick, isShowDetails)}
                        {licence.IsExternalLicence ?
                            (
                                <>
                                    <div className='search-licence-link link-margin-left' onClick={e => this.externalLink(e, licence.Url)}>
                                        {t(LANGUAGE_KEYS.BLS_PUBLICLICENCESEARCH_CONTENT_LINK_AGENCYWEBSITE)}</div>
                                </>
                            ) :
                            null}
                    </div>
                </div>
            </div>
        );
    }
}

PreRequisiteItem.defaultProps = {
    customUpdateCartCallback: () => { /*default empty function */ }
};

export default withTranslation()(PreRequisiteItem);
