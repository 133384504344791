import React from 'react';
import { Row, Col } from 'reactstrap';


class Title extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      className: this.props.className
    };
  }

  render() {
    return (
      <React.Fragment>
        <Row className={this.state.className}>
          <Col>
            {(this.props.titleValue && 
            <Row>
              <Col className="title-text">
                {this.props.titleValue}
              </Col>
            </Row>)}
            {this.props.children}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Title;