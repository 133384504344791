import React from 'react';
import { withTranslation } from 'react-i18next';
import AuthHelper from '../../../Shared/Authentication/AuthHelper';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import DeclarationSection from '../DeclarationComponent/DeclarationSection';
import LicenceSummary from '../LicenceSummary';
import GeneralInformationCorporateLicenceSummary from '../LicenceSummary/GeneralInformationCorporateLicenceSummary';
import GeneralInformationLicenceSummary from '../LicenceSummary/GeneralInformationLicenceSummary';
import utils from 'formiojs/utils';

let isCorporateProfile;

// Auth
AuthHelper.getUser(user => {
    if (user !== undefined && user !== null) {
        isCorporateProfile = (user.profile.uen !== undefined);
    }
});
// Auth

class ReviewAndSubmit extends React.Component {
    render() {
        const { values, errors, onChangeField, toggleSection, toggleAllSections } = this.props.smartFormValues;
        const { devData, declarations, displayDocuments, t, formLanguageKeys, isUsingFormIO, formIODataArray, formIOFormsList } = this.props;

        const generalDefinition = devData.GeneralDefinition;

        return (
            <>
                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}</div>
                <div className="review-submit">
                    {isCorporateProfile ?
                        <GeneralInformationCorporateLicenceSummary
                            formName={t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}
                            values={values.GeneralInformation}
                            formSections={generalDefinition.Sections}
                            key={generalDefinition.FormName}
                            commonFieldList={[]}
                            documentList={[]}
                            toggleSection={toggleSection}>
                        </GeneralInformationCorporateLicenceSummary>
                        :
                        <GeneralInformationLicenceSummary
                            formName={t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_TITLE_GENERALINFORMATION)}
                            values={values.GeneralInformation}
                            formSections={generalDefinition.Sections}
                            key={generalDefinition.FormName}
                            commonFieldList={[]}
                            documentList={[]}
                            toggleSection={toggleSection}>
                        </GeneralInformationLicenceSummary>
                    }
                    {!isUsingFormIO && devData.OriginalFormDefinitions.map((form, i) => {
                        const formSections = form.Sections;
                        const formFileList = [];
                        const formCommonFieldList = [];
                        devData.CommonDefinitions.FilesDefinition.Fields.forEach(fileField => {
                            if (fileField.UsedByFormType.includes(form.FormType)) {
                                for (const i in values.SupportingDocument[fileField.Name]) {
                                    formFileList.push(values.SupportingDocument[fileField.Name][i].fileName);
                                }
                            }
                        });
                        devData.CommonDefinitions.FieldsDefinition.forEach(section => {
                            section.Fields.forEach(formField => {
                                if (formField.UsedByFormType.includes(form.FormType)) {
                                    formCommonFieldList.push(formField);
                                }
                            });
                        });

                        return <LicenceSummary
                            formName={formLanguageKeys[i]}
                            commonFieldList={formCommonFieldList}
                            valuesCommonInfo={values.CommonInformation}
                            valuesFormInfo={values[form.FormType]}
                            formSections={formSections}
                            documentList={formFileList}
                            key={form.FormName}
                            toggleAllSections={toggleAllSections}
                            toggleSection={toggleSection}
                            displayDocuments={displayDocuments}
                        >
                        </LicenceSummary>;
                    })}
                    {isUsingFormIO && devData.OriginalFormDefinitions.map((form, i) => {
                        const formSections = [];
                        const formIOForm = formIOFormsList[i].json;
                        utils.eachComponent(formIOForm.components, (component) => {
                            if (utils.isLayoutComponent(component) && component.type !== "datagrid") {
                                formSections.push({
                                    DisplayName: component.title,
                                    Name: component.key,
                                    Fields: [],
                                    Datagrids: []
                                });
                            } else {
                                const parentSection = formSections.find(section => component.key.includes(section.Name));
                                if (parentSection) {
                                    if (component.type === "datagrid") {
                                        parentSection.Datagrids.push({
                                            DisplayName: component.title,
                                            Name: component.key,
                                            Fields: []
                                        })
                                    } else {
                                        if (component.key.includes("Datagrid")) {
                                            const parentGrid = parentSection.Datagrids.find(grid => component.key.includes(grid.Name));
                                            if (parentGrid) {
                                                parentGrid.Fields.push({
                                                    DisplayName: component.label,
                                                    Name: component.key,
                                                    Data: []
                                                });
                                            }
                                        } else {
                                            if (parentSection) {
                                                parentSection.Fields.push({
                                                    DisplayName: component.label,
                                                    Name: component.key,
                                                    Data: []
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }, true)

                        return <LicenceSummary
                            formName={formLanguageKeys[i]}
                            key={form.formName}
                            formSections={formSections}
                            toggleAllSections={toggleAllSections}
                            toggleSection={toggleSection}
                            formTranslationKey={formIOFormsList[i].translationKey}
                            valuesFormInfo={formIODataArray[form.FormType]}
                        />
                    })}
                </div>
                {declarations &&
                    <DeclarationSection
                        declarations={declarations}
                        values={values.DeclarationAgreement}
                        errors={errors.DeclarationAgreement}
                        key={values.DeclarationAgreement}
                        onChangeField={onChangeField}
                        labelSize={0}
                        labelRequired />
                }
            </>
        );
    }
}
export default withTranslation()(ReviewAndSubmit);
