import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { Plaintext } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getTableListValues } from '../FormUtils';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';

const MODAL_NAMES = { SUBMIT: 'Submit', TablePopUp: 'TablePopUp' };

class BigPreviewTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    render() {
        const { values, section, labelSize, inputSize, t } = this.props;
        const tableValues = values.FormData.LicenceInformation;
        const tableValueKeys = Object.keys(values.FormData.LicenceInformation);

        return (
            <Table className="griddle-table">
                <thead className="griddle-table-heading">
                    <tr>
                        {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                            return (
                                field.ShowField === 'Show' && <th key={findex} className="griddle-table-heading-cell">{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}</th>
                            );
                        })}
                        <th className="griddle-table-heading-cell">Action</th>
                    </tr>
                </thead>
                <tbody className="griddle-table-body">
                    {
                        getTableListValues(tableValueKeys, section.Name) &&
                        getTableListValues(tableValueKeys, section.Name)[section.Name] &&
                        getTableListValues(tableValueKeys, section.Name)[section.Name].map((row, rindex) => {
                            return (
                                <tr key={rindex} className="griddle-row">
                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                        if (field.ShowField === 'Show' && field.InputType !== 'FileUpload' && field.InputType === 'DropDownList' && field.DataSource === 'Remote') {
                                            return (
                                                <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                    <Plaintext
                                                        className="modal-plaintext"
                                                        key={findex + rindex}
                                                        label={undefined}
                                                        name={field.Name}
                                                        value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, row[field.Name]))}
                                                        labelSize={null}
                                                        inputSize={inputSize}
                                                    />
                                                </td>
                                            );
                                        } else {
                                            return (
                                                field.ShowField === 'Show' && <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                    {
                                                        field.InputType !== 'FileUpload' && <Plaintext
                                                            label={undefined}
                                                            className="modal-plaintext"
                                                            key={findex + rindex}
                                                            name={field.Name}
                                                            value={tableValues[`${section.Name}.${rindex}.${field.Name}`]}
                                                            inputSize={inputSize}
                                                            labelSize={null}
                                                        />
                                                    }
                                                </td>
                                            );
                                        }
                                    })}
                                    <td className="griddle-cell table-griddle-cell">
                                        <div className="action-button-group table-button">
                                            <Button
                                                className="action-btn"
                                                color="forward"
                                                type="button"
                                                onClick={() => this.toggleModal(MODAL_NAMES.TablePopUp, rindex)}
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                    <ModalConfirm
                        className="modal-table"
                        isOpen={this.state.modalState === MODAL_NAMES.TablePopUp}
                        contentHeader={`View ${section.DisplayName}`}
                        contentBody={
                            tableValues &&
                            section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                if (field.InputType !== 'FileUpload' && field.InputType === 'DropDownList' && field.DataSource === 'Remote') {
                                    return (
                                        <td key={findex + this.state.modalID}>
                                            <Plaintext
                                                className="modal-plaintext"
                                                label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                name={field.Name}
                                                value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, tableValues[`${section.Name}.${this.state.modalID}.${field.Name}`]))}
                                                labelSize={labelSize}
                                                inputSize={inputSize}
                                            />
                                        </td>
                                    );
                                } else {
                                    return (
                                        <div key={findex + this.state.modalID}>
                                            {
                                                field.InputType !== 'FileUpload' && <Plaintext
                                                    className="modal-plaintext"
                                                    name={field.Name}
                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                    value={tableValues[`${section.Name}.${this.state.modalID}.${field.Name}`]}
                                                    labelSize={labelSize}
                                                    inputSize={inputSize}
                                                />
                                            }
                                        </div>
                                    );
                                }
                            })
                        }
                        confirmText="Close"
                        confirmCallback={() => { this.toggleModal(MODAL_NAMES.TablePopUp, this.state.modalID); }}
                    />
                </tbody>
            </Table>
        );
    }
}
export default withTranslation()(BigPreviewTable);
