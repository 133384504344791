
//Error Messages
export const VALIDATION_MSG ={
    APPLICATION_NAME_REQUIRED : "Application name is required.",
    INTERNAL_ROLE_NAME_REQUIRED : "Internal Role Name is required.",
    EMAIL_REQUIRED : "Email is required.",
    EMAIL_INVALID : "Email is not valid.",
    DOB_REQUIRED: "Date of Birth is required",
    ID_REQUIRED: "Identification No. is required",
    GENDER_REQUIRED: "Gender is required",
    FIRST_NAME_REQUIRED : "First name is required.",
    LAST_NAME_REQUIRED : "Last name is required.",
    EFFECTIVE_START_DATE_REQUIRED : "Effective start date is required.",
    START_DATE_LATER_THAN : "Please select a start date from today onwards.",
    FIRSTNAME_REQUIRED : "First name is required.",
    LASTNAME_REQUIRED : "Last name is required.",
    USERNAME_REQUIRED : "User name is required.",
    USERGROUP_NAME_REQUIRED : "User group name is required.",
    PERMISSION_NAME_REQUIRED : "Permission name is required.",
    PERMISSION_TYPE_REQUIRED : "Permission type is required.",
    DESCRIPTION_REQUIRED : "Description is required.",
    DISPLAY_ROLE_NAME_REQUIRED : "Display Role Name is required.",
    APPROVAL_REQUIRED : "Approval is required.",
    REJECTION_REASON_REQUIRED : "Rejection Reason is required.",
    USER_TYPE_REQUIRED : "User Type is required.",
    IS_MONITORED_REQUIRED : "Monitored value is Required."
}

export const URL = process.env.REACT_APP_AUTHORITY_ADMINISTRATION_BASE_URL;
export const IDENTITY_URL = process.env.REACT_APP_AUTHORITY_URL;
export const CLIENT_URL = process.env.REACT_APP_AUTHORITY_POST_LOGOUT_REDIRECT_URL;

export const REDIRECT_URI = "redirectUri";

export const LABEL_TEXT = {
    USER_TYPE: "User Type",
    USERNAME: "Username",
    FIRST_NAME: "First Name",
    MIDDLE_NAME: "Middle Name",
    LAST_NAME: "Last Name",
    DOB: "Date Of Birth",
    EMAIL: "Email",
    INTERNAL_GROUP_NAME: "Group Name",
    PERMISSION_NAME: "Permission Name",
    PERMISSION_TYPE: "Permission Type",
    NID : "Identification Number",
    GENDER : "Gender",
    DESCRIPTION : "Description",
    ROLE_NAME : "Role Name",
    DISPLAY_ROLE_NAME : "Display Name",
    ROLE_TO_PERMISSION : "Select Permission For Role",
    USER_GROUP : "User Group",
    ROLE_TO_USERGROUP : "Select Roles For User Group",
    ROLE_TO_USER : "Select Role For User",
    USERGROUP_TO_USER : "Select Users For User Group",
    USER_GROUP_NAME : "User Group Name"
};
