import React from 'react';
import { Button } from 'reactstrap';
import { navigateTo } from '../../../Shared/Actions';
import { BUSINESS_ADVISOR_PATH } from '../../../App/AppSettings';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';

class LicenceItemResult extends React.Component {

  handleBusinessIntentButtonClick = intentId => {
    navigateTo(BUSINESS_ADVISOR_PATH, { intentId });
  };

  displayRelatedButton = (relatedIntent, onclickFunc) => {
    const { t } = this.props;
    if (relatedIntent != null && relatedIntent.length !== 0) {
      return (
        <Button className="btn btn-green" onClick={() => onclickFunc()}>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_SOMETHINGELSE)}</Button>
      );
    }

  };

  render() {
    const { intent, relatedIntent, onclickFunc, t } = this.props;
    const { BusinessIntentId } = intent;

    return (

      <div className="guided-box" stickertitle={t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_CONTENT_INFORMATION_BESTMATCH)}>
        <h4>{t(getLangKey(LANGUAGE_KEYS.BLS_QUESTIONNAIRE_BUSINESSINTENT_NAME_KEY, BusinessIntentId))}</h4>
        <p>{t(getLangKey(LANGUAGE_KEYS.BLS_QUESTIONNAIRE_BUSINESSINTENT_DESCRIPTION_KEY, BusinessIntentId))}</p>
        <div className="button-center">
          <Button className="btn btn-blue" onClick={() => this.handleBusinessIntentButtonClick(intent.BusinessIntentId)}>{t(LANGUAGE_KEYS.BLS_PUBLICQUESTIONNAIRE_NAVIGATION_BUTTON_CORRECTBUSINESS)}</Button>
          {this.displayRelatedButton(relatedIntent, onclickFunc)}

        </div>
      </div>

    );
  }
}
export default withTranslation()(LicenceItemResult);
