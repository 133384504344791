import React from 'react';
import PropTypes from 'prop-types';


class SubSection extends React.PureComponent {

    render() {

        const { className, title } = this.props;

        return (
            <div className={"sub-section " + className}>
                {/* <Divider className="sub-section-divider" orientation={orientation}>
                    {title}
                </Divider> */}
                <br/>
                <h3>{title}</h3>
                <hr/>
                {this.props.children}
            </div>
        );
    }
};

// PropTypes: For prop validation
SubSection.propTypes = {
    orientation: PropTypes.string,
    title: PropTypes.string.isRequired,
    className: PropTypes.string
};

// PropTypes: Defaulting value for optional props
SubSection.defaultProps = {
    orientation: undefined,      // Note: Undefined is seen as "center" for this ant-design component
    title: undefined,
    className: ""
};


export default SubSection;