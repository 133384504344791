import React from 'react';
import { DASHBOARD_PATH } from '../../App/AppSettings';
import { navigateTo } from '../../Shared/Actions';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';

class RFASuccessPage extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <>
                <div className="container">
                    <div className="middle">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="success-block">
                                    <i className="fas fa-check-circle"></i>
                                    <h4>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSUBMISSIONCOMPLETE)}</h4>
                                    <h5>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_APPLICATIONSUBMITTEDMESSAGE)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-center">
                    <button className="btn" onClick={() => navigateTo(DASHBOARD_PATH)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</button>
                </div>
            </>
        );
    }
}

export default withTranslation()(RFASuccessPage);